import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchCurriculumResultDetail, useDeleteCurriculumResult, useUpdateCurriculumResult, useEditCurriculumResult } from '../../../Queries/CurriculumResultQuery';
import LoaderComponent from '../../../Components/Layout/Loader';
import ConfirmDeleteModal from '../../../Components/Modal/ConfirmDeleteModal';
import EditModal from '../../../Components/Modal/CurriculumResultEditModal';

const EducationResultDetail = (): JSX.Element => {
    const { curriculumResultId } = useParams<{ curriculumResultId: string }>();
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [isEditModal, setIsEditModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { data: resultData, status: resultDataStatus } = useFetchCurriculumResultDetail(Number(curriculumResultId));
    const { data: editCurriculum, status: editCurriculumStatus } = useEditCurriculumResult(Number(curriculumResultId));
    const deleteCurriculumResult = useDeleteCurriculumResult();

    /* 編集モーダル */
    // 開ける
    const handleEditModalOpen = () => {
        setIsEditModal(true);
    }
    // 閉じる
    const handleEditModalClose = () => {
        setIsEditModal(false);
    };
    /* 削除モーダル */
    // 閉じる
    const handleDeleteModalOpen = () => {
        setIsDeleteModal(true);
    };
    // 開く
    const handleDeleteModalClose = () => {
        setIsDeleteModal(false);
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${year}年${month}月${day}日`;
    };

    const formatTime = (time) => {
        if (!time) return "";
        let [hours, minutes] = time.split(":");
        hours = parseInt(hours, 10).toString(); // Remove leading zero
        return `${hours}:${minutes}`;
    };

    useEffect(() => {
        // isLoadingをresultDataStatusとeditCurriculumStatusに基づいて更新
        if (resultDataStatus === 'loading' || editCurriculumStatus === 'loading') {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [resultDataStatus, editCurriculumStatus]);

    if(isLoading) return  <LoaderComponent />;

    return (
        <>
            <ConfirmDeleteModal
                title="教育実績削除"
                selectedId={curriculumResultId}
                isOpen={isDeleteModal}
                handleClose={handleDeleteModalClose}
                deleteMutation={deleteCurriculumResult}
            />
            <EditModal
                selectedId={curriculumResultId}
                isOpen={isEditModal}
                editDate={editCurriculum}
                handleClose={handleEditModalClose}
                updateMutaion={useUpdateCurriculumResult}
            />
            <div className="l-board">
                <div className="l-board__inner">
                    <div className="l-board__wrap p-board__btn no-border">
                        <div className="l-board__btn--box">
                            <button
                                className="c-btn c-btn__blue c-btn__table"
                                onClick={handleEditModalOpen}
                            >編集</button>
                        </div>
                        <div className="l-board__btn--box">
                            <button
                                className="c-btn c-btn__red c-btn__table"
                                onClick={handleDeleteModalOpen}
                            >削除</button>
                        </div>
                    </div>
                    <div className="l-board__wrap p-board__main">
                        <table className="p-table p-table__detail">
                            <tbody>
                                <tr>
                                    <td className="headline">カリキュラム名</td>
                                    <td>{resultData.data.education_curriculum.name}: {resultData.data.education_curriculum.detail}</td>
                                </tr>
                                <tr>
                                    <td className="headline">教育担当者</td>
                                    <td>
                                        {resultData.data.educator_name}
                                        {resultData.data.educator_id === null && (
                                            <span className="u-tSub">(社外教育担当)</span>
                                        )}
                                    </td>

                                </tr>
                                <tr>
                                    <td className="headline">受講者</td>
                                    <td>{resultData.data.educated_user.last_name} {resultData.data.educated_user.first_name}</td>
                                </tr>
                                <tr>
                                    <td className="headline">実施日</td>
                                    <td>{formatDate(resultData.data.done_on)}</td>
                                </tr>
                                <tr>
                                    <td className="headline">時間</td>
                                    <td>{formatTime(resultData.data.start_time)}-{formatTime(resultData.data.finish_time)}</td>
                                </tr>
                                <tr>
                                    <td className="headline">教育内容</td>
                                    <td>{resultData.data.curriculum_detail}</td>
                                </tr>
                                <tr>
                                    <td className="headline">備考</td>
                                    <td>{resultData.data.curriculum_remark}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
};
export default EducationResultDetail;
