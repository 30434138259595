import axios from "axios";
import { Holiday } from "../Types/Holiday"

// 休日一覧
export const getHolidays = async (): Promise<Holiday[]> => {
    const { data } = await axios.get(
        '/api/users/holiday/list',
    );
    // company_holidays と user_holidays を結合
    const holidays: Holiday[] = [...data.company_holidays, ...data.user_holidays];
    return holidays;
}

// ユーザーの休日情報を更新
export const updateUserHoliday = async (holiday: Holiday): Promise<void> => {
    await axios.put(`/api/users/holiday/update/user`, holiday);
}

// 会社の休日情報を更新
export const updateCompanyHoliday = async (holiday: Holiday): Promise<void> => {
    await axios.put(`/api/users/holiday/update/company`, holiday);
}


// 会社・全ユーザーの休日情報を更新
export const updateAllHoliday = async (holiday: Holiday): Promise<void> => {
    await axios.put(`/api/users/holiday/update/all`, holiday);
}
