import axios from "axios";
import { EvaluationItem } from "../Types/EvaluationItem"

// 一覧
const getEvaluationItemList = async () => {
    const { data } = await axios.get<EvaluationItem[]>(
        '/api/users/evaluation-item/list'
    );
    return data
}

// 一覧_勤務
const getEvaluationItemListOfWork = async () => {
    const { data } = await axios.get<EvaluationItem[]>(
        '/api/users/evaluation-item/list/work'
    );
    return data
}

// 追加
const createEvaluationItem = async (newEvaluationItem: { name: string }) => {
    const { data } = await axios.post<EvaluationItem[]>(
        '/api/users/evaluation-item/add', newEvaluationItem
    );
    return data
}

// 更新
const updateEvaluationItem = async ({id, name, detail}: {id: number, name: string, detail: string}) => {
    const { data } = await axios.put<EvaluationItem[]>(
        `/api/users/evaluation-item/update/${id}`, { id, name, detail }
    );
    return data
}

// 削除
const deleteEvaluationItem = async (id: number) => {
    const { data } = await axios.delete(
        `/api/users/evaluation-item/delete/${id}`
    );
    return data;
}

export {
    getEvaluationItemList,
    getEvaluationItemListOfWork,
    createEvaluationItem,
    updateEvaluationItem,
    deleteEvaluationItem
};
