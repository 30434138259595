import React, { useState, useEffect } from 'react';
import { useUsersForSelectQuery } from "../../../Queries/UserQuery";
import { Link, useLocation } from 'react-router-dom';
import { useFetchYearsSinceCreation } from "../../../Queries/CompanyQuery";
import LoaderComponent from '../../../Components/Layout/Loader';
import { useFetchPerformanceWithGoalData } from "../../../Queries/PerformanceResultQuery";
import { useAuthority } from '../../../Contexts/AuthorityContext';

const CURRENCY_UNIT = "円";
const PEOPLE_UNIT = "人";
const REVENUE_NAMES = ['技術(指名)売上額', '技術売上', '物販売上', '技術(フリー)売上額', '物販売上'];

const formatNumber = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
const appendUnit = (name, value) => {
    const unit = REVENUE_NAMES.includes(name) ? CURRENCY_UNIT : PEOPLE_UNIT;
    return formatNumber(value) + unit;
};

const PerformanceStaff = () => {
    const { authority } = useAuthority();
    const location = useLocation();

    // session storage
    const savedYear = parseInt(sessionStorage.getItem('performanceStaffSelectedYear') || '');
    const savedUserId = parseInt(sessionStorage.getItem('performanceStaffSelectedUserId') || '');

    // 現在年
    const initialYear = String(new Date().getFullYear());

    // データと選択されたID、年のステート
    // const [selectedYear, setSelectedYear] = useState<number | undefined>(savedYear);
    const [selectedYear, setSelectedYear] = useState(savedYear || initialYear);
    const [selectedUserId, setSelectedUserId] = useState<number | undefined>(savedUserId);

    // データ取得用のReact Queryフックをそれぞれ利用
    const { data: users, isLoading: isLoadingUsers } = useUsersForSelectQuery();
    const { data: yearList, isLoading: isLoadingYearList } = useFetchYearsSinceCreation();
    const { data: performanceData, isLoading: isLoadingPerformanceData } = useFetchPerformanceWithGoalData(selectedUserId, selectedYear);

    // 全体ローディング管理
    const [isLoading, setIsLoading] = useState(true);

    // ローディング管理
    useEffect(() => {
        // 全てのデータソースのローディング状態を監視
        const allLoading = isLoadingUsers || isLoadingYearList || isLoadingPerformanceData;
        setIsLoading(allLoading);
    }, [isLoadingUsers, isLoadingYearList, isLoadingPerformanceData]);


    // sessionStorageから値を取得する際の処理を修正
    useEffect(() => {
        // usersやyearListがロードされた後に、デフォルト値を設定
        if (!savedYear) {
            setSelectedYear(initialYear);
        }

        if (!savedUserId && users && users.length > 0) {
            const userId = users[0].id;
            setSelectedUserId(userId);
        }
    }, [users, yearList]);

    useEffect(() => {
        const state = location.state as { userId: string; year: string } | null;
        if (state) {
            setSelectedUserId(state.userId);
            setSelectedYear(state.year);
        }
    }, [location.state]);

    const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const year = parseInt(event.target.value);
        setSelectedYear(year);
        sessionStorage.setItem('performanceStaffSelectedYear', year.toString());
    };

    const handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const userId = parseInt(event.target.value);
        setSelectedUserId(userId);
        sessionStorage.setItem('performanceStaffSelectedUserId', userId.toString());
    };


    const formatColumnName = (name: string) => {
        if (name === '技術(指名)売上額' || name === '技術(フリー)売上額') {
            return name.replace('売上額', '<br>売上額');
        }
        return name;
    };

    const calculateItemTotals = (data, performanceItems) => {
        if (!data || !performanceItems) return []; // データが存在しない場合は空の配列を返す
        const totals = performanceItems.map(() => ({ totalResult: 0, totalUserGoal: 0 }));

        data.forEach(monthData => {
            monthData.performance_result.forEach(performance => {
                const index = performanceItems.findIndex(item => item.id === performance.performance_id);
                if (index !== -1) {
                    if (performance.result) {
                        totals[index].totalResult += performance.result.result || 0;
                    }
                    if (performance.user_goal) {
                        totals[index].totalUserGoal += performance.user_goal.goal || 0;
                    }
                }
            });
        });

        return totals;
    };
    if (isLoading) {
        return <LoaderComponent />;
    }
    if (!performanceData) {
        return <p>データが利用できません。</p>;
    }

    // PerformanceDataが存在するときのみ合計値を計算
    const itemTotals = calculateItemTotals(performanceData.data, performanceData.performance_items);

    return (
        <>
            <div className="l-board__wrap p-board__btn">
                <div className="l-flex p-board__btn--area">
                    <div className="p-board__btn--input">
                        <select
                            className="c-input c-input__select type-shadow"
                            onChange={handleYearChange}
                            value={selectedYear}
                        >
                            {yearList?.map((year, index) => (
                                <option key={index} value={year}>{year}年</option>
                            ))}
                        </select>
                    </div>
                    <div className="p-board__btn--input">
                        <select
                            className="c-input c-input__select type-shadow"
                            onChange={handleUserChange}
                            value={selectedUserId}
                        >
                            {users?.map((user, index) => (
                                <option key={index} value={user.id}>
                                    {user.last_name} {user.first_name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {authority == '0' && (
                <div className="l-flex p-board__btn--area space-around">
                    <Link
                        to={`/hc-user/performance/staff/edit/${selectedUserId}/${selectedYear}`}
                        className="p-board__btn--item c-btn c-btn__blue pc-only"
                    >
                        スタッフ業績を編集
                    </Link>
                </div>
                )}
            </div>
            <div className="l-board__wrap p-board__main">
                <div className="p-board__main--box">
                    {performanceData ? (
                        <table className="p-table p-table__fixed">
                            <thead>
                                <tr>
                                    <th>該当月</th>
                                    {performanceData?.performance_items.map((item, index) => (
                                        <th key={index} dangerouslySetInnerHTML={{ __html: formatColumnName(item.name) }}></th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {performanceData?.data.map((monthData, mIndex) => (
                                    <tr key={mIndex}>
                                        <td>{monthData.date}</td>
                                        {monthData.performance_result.map((result, rIndex) => {
                                            const name = performanceData.performance_items.find(item => item.id === result.performance_id)?.name;
                                            return (
                                                <td key={rIndex} className="u-tAlign--right">
                                                    {result.result?.result ? appendUnit(name, result.result?.result) : '-'}
                                                    <br />
                                                    {result.user_goal ? (
                                                        <span className="u-tSub">(目標: {appendUnit(name, result.user_goal?.goal)})</span>
                                                    ) : (
                                                        <span className="u-tSub">-</span>
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))}
                                {performanceData && (
                                    <tr>
                                        <td>合計</td>
                                        {itemTotals.map((total, index) => (
                                            <td key={index} className="u-tAlign--right">
                                                {/* 各項目の名前に基づいてユニットを動的に決定 */}
                                                {formatNumber(total.totalResult) + (REVENUE_NAMES.includes(performanceData.performance_items[index].name) ? CURRENCY_UNIT : PEOPLE_UNIT)}
                                                <br />
                                                <span className="u-tSub">
                                                    {formatNumber(total.totalUserGoal) + (REVENUE_NAMES.includes(performanceData.performance_items[index].name) ? CURRENCY_UNIT : PEOPLE_UNIT)}
                                                </span>
                                            </td>
                                        ))}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    ) : (
                        <p>データが利用できません。</p>
                    )}
                </div>
            </div>
        </>
    );
};
export default PerformanceStaff;
