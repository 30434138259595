import { AxiosError } from 'axios';
import * as api from "../Api/EmploymentStatusAPI"
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from "react-toastify";

const useEmploymentStatusList = () => {
    return useQuery('employmentList', () => api.getEmploymentStatusList())
}

// // const useEmploymentStatusEdit = (id: number) => {
// //     return useQuery(['employmentEdit', id], () => api.getEmploymentStatusEdit(id));
// // }
// const useEmploymentStatusEdit = (id: number | null) => {
//     return useQuery(['employmentEdit', id], () => api.getEmploymentStatusEdit(id), {
//         enabled: id !== null,
//     });
// }

const useCreateEmploymentStatus = () => {
    const queryClient = useQueryClient();
    return useMutation(api.createEmploymentStatus, {
        onSuccess: () => {
            queryClient.invalidateQueries('employments');
            toast.success('雇用形態の登録に成功しました。');
        },
        onError: (error: AxiosError) => {
            if (error.response?.data.errors) {
                Object.values(error.response?.data.errors).forEach((messages: any) => {
                    messages.forEach((message: string) => {
                        toast.error(message)
                    })
                })
            } else {
                toast.error('雇用形態の登録に失敗しました。');
            }
        }
    })
}

const useUpdateEmploymentStatus = () => {
    const queryClient = useQueryClient();
    return useMutation(api.updateEmploymentStatus, {
        onSuccess: () => {
            queryClient.invalidateQueries('employments');
            toast.success('雇用形態の更新に成功しました。');
        },
        onError: (error: AxiosError) => {
            if (error.response?.data.errors) {
                Object.values(error.response?.data.errors).forEach((messages: any) => {
                    messages.forEach((message: string) => {
                        toast.error(message)
                    })
                })
            } else {
                toast.error('雇用形態の更新に失敗しました。');
            }
        }
    })
}

const useDeleteEmploymentStatus = () => {
    const queryClient = useQueryClient();
    return useMutation(api.deleteEmploymentStatus, {
        onSuccess: () => {
            queryClient.invalidateQueries('employments');
            toast.success('雇用形態を削除しました。');
        },
        onError: (error: AxiosError) => {
            if (error.response?.data.errors) {
                Object.values(error.response?.data.errors).forEach((messages: any) => {
                    messages.forEach((message: string) => {
                        toast.error(message)
                    })
                })
            } else {
                toast.error('雇用形態の削除に失敗しました。');
            }
        }
    })
}

export {
    useEmploymentStatusList,
    useCreateEmploymentStatus,
    useUpdateEmploymentStatus,
    useDeleteEmploymentStatus
}
