// 日付を 'YYYY年MM月DD日' 形式に変換
export const formatDate = (isoDate) => {
  if (!isoDate) {
    return "-";
  }
  const date = new Date(isoDate);
  if (isNaN(date.getTime())) {
    return "不正な日付";
  }
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}年${month}月${day}日`;
};

// 時間を 'HH:mm' 形式に変換（HH:mm:ss 形式を前提とする）
export const formatTime = (timeString) => {
  if (typeof timeString !== 'string') {
    return "-";
  }
  const parts = timeString.split(":");
  if (parts.length >= 2) {
    const hours = parts[0];
    const minutes = parts[1];
    return `${parseInt(hours, 10)}:${minutes}`;
  }
  return timeString;
};

// 日付の '月日' をフォーマット（例：'1月1日'）
export const formatDateToMonthDay = (date) => {
  if (!date) {
    return "不正な日付";
  }

  // 日付に時間情報を追加してローカルタイムゾーンとして解釈させる
  const dateObj = new Date(date + "T00:00:00");

  if (isNaN(dateObj.getTime())) {
    return "不正な日付";
  }

  return `${dateObj.getMonth() + 1}月${dateObj.getDate()}日`;
};

// 曜日を取得（例：'月'、'火'、...）
export const getDayOfWeek = (date) => {
  if (!date) {
    return "(不明)";
  }
  const days = ["日", "月", "火", "水", "木", "金", "土"];
  const dateObj = new Date(date);
  if (isNaN(dateObj.getTime())) {
    return "(不明)";
  }
  return `(${days[dateObj.getDay()]})`;
};
