import React, { useState, useEffect } from 'react';
import { useQueryClient, UseMutationResult } from 'react-query';
/* 登録機能 */
import { useAddCurriculumProgress } from '../../Queries/CurriculumProgressQuery';
/* データ */
import { useEducatorList, useUserProfile } from '../../Queries/UserQuery';
/* ローディング */
import LoaderComponent from '../Layout/Loader';
/* datepicker */
import DatePicker from 'react-datepicker';
import { ja } from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css";
/* エラーtoast */
import { toast } from "react-toastify";

interface CurriculumProgressEditModalProps {
    // モーダル系
    isOpen: boolean;
    handleClose: () => void;
    // API
    createMutation: UseMutationResult;
    // カリキュラムID
    educationCurriculumId: number;
    userId: number;
}

const  CurriculumProgressEditModal: React.VFC<CurriculumProgressEditModalProps> =
    ({ isOpen, handleClose, createMutation, educationCurriculumId, userId}) => {
    /* 関数 */
    const queryClient = useQueryClient();
    const { data: educators, status: educatorsStatus } = useEducatorList();

    /* カリキュラム進捗共有データ */
    // 登録API
    const addCurriculumProgressMutation = useAddCurriculumProgress();
    // 教育担当者
    const [educator, setEducator] = useState("");
    const handleEducatorsChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setEducator(e.target.value);
    };
    useEffect(() => {
        if (educators && educators.length > 0 && educators[0]) {
            setEducator(educators[0].id.toString());
        }
    }, [educators]);

    // 終了日
    const [completeDate, setCompleteDate] = useState<Date | null>(new Date());

    // 教育記録
    const [educationRecord, setEducationRecord] = useState("");
    const handleEducationRecordChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setEducationRecord(e.target.value);
    };

    // 記入者
    const { data: enter, status: enterStatus } = useUserProfile();

    // 追加ボタン
    const handleAddBtnClick = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // バリデーション
        if (!educationCurriculumId) {
            toast.error('教育カリキュラムは必須です。');
            return;
        }
        if (!userId) {
            toast.error('受講者は必須です。');
            return;
        }
        if (!enter || !enter.id) {
            toast.error('記入者は必須です。');
            return;
        }
        if (!educator || !educator) {
            toast.error('教育担当者は必須です。');
            return;
        }
        if (!completeDate) {
            toast.error('終了日は必須です。');
            return;
        }
        addCurriculumProgressMutation.mutate(
            {
                education_curriculum_id: educationCurriculumId,
                educated_user_id: userId,
                enter_id: enter.id,
                educator_id: parseInt(educator), // 教育者のIDがstringとして取得されているため、integerに変換する必要があります
                complete_on: completeDate,
                detail: educationRecord
            },
            {
                onSuccess: () => {
                    queryClient.resetQueries();
                    handleClose();
                },
            }
        );
    };

    // 閉じるボタン
    const handleCloseBtnClick = () => {
        handleClose();
    };

    const isLoading =
        educatorsStatus === 'loading' ||
        enterStatus === 'loading';

    if (!isOpen) {
        return null;
    }

    return (
        <div className="p-modal" onClick={handleCloseBtnClick}>
            <div className="p-modal__content" onClick={(e) => e.stopPropagation()}>
                <button
                    className="p-modal__delete"
                    onClick={handleCloseBtnClick}>
                    <img src={'/image/icon_btn_batsu_green.png'} />
                </button>
                <h3 className="p-modal__headline">カリキュラム進捗更新</h3>

                {isLoading ? (
                    <LoaderComponent />
                ) : (
                    <>
                        {/* 教育担当者: educator */}
                        <div className="p-input__area">
                            <div className="area-label">
                                <label className="c-label">教育担当者</label>
                            </div>
                            <div className="area-input">
                                <select
                                    className="c-input c-input__text"
                                    onChange={handleEducatorsChange}
                                >
                                    {educators?.map(educator => (
                                        <option key={educator.id} value={educator.id}>{educator.last_name} {educator.first_name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {/* 終了日: completeDate */}
                        <div className="p-input__area">
                            <div className="area-label">
                                <label className="c-label">終了日</label>
                            </div>
                            <div className="area-input">
                                <DatePicker
                                    className="c-input c-input__text"
                                    selected={completeDate}
                                    onChange={(date: Date) => setCompleteDate(date)}
                                    dateFormat="yyyy/MM/dd"
                                    dateFormatCalendar={'yyyy年 MMMM'}
                                    locale={ja}
                                />
                            </div>
                        </div>
                        {/* 教育記録: educationRecord */}
                        <div className="p-input__area">
                            <div className="area-label">
                                <label className="c-label">教育記録</label>
                            </div>
                            <div className="area-input">
                                <textarea
                                    className="c-input c-input__textarea"
                                    onChange={handleEducationRecordChange}
                                ></textarea>
                            </div>
                        </div>
                        <div className="p-input__area">
                            <button
                                className="c-btn c-btn__green c-btn__bgGreen c-btn__small position-center"
                                onClick={handleAddBtnClick}
                            >
                                追加する
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
export default CurriculumProgressEditModal;
