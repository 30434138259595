// EducationTraining.tsx

import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { formatDate, formatTime } from "../../../Utils/formatUtils";
/* Query */
import { useYearMonthSinceCreation } from "../../../Queries/CompanyQuery";
import { useUsersForSelectQuery, useAssistantList } from "../../../Queries/UserQuery";
import { useTrainingRecordsByUserAndDate } from "../../../Queries/TrainingRecordQuery";
/* Components */
import LoaderComponent from "../../../Components/Layout/Loader";
// import TrainingAddModal from "../../../Components/Modal/TrainingAddModal"; // コメントアウトされたまま
import TrainingRecordModal from "../../../Components/Modal/TrainingRecordModal";
/* Context */
import { useAuthority } from "../../../Contexts/AuthorityContext";

interface TrainingRecord {
  id: number;
  trainee_id: number;
  instructor_name: string;
  instructor_id: number;
  training_item: string;
  training_name: string;
  training_detail: string;
  complete_on: string; // 'YYYY-MM-DD' 形式の日付
  created_at?: string; // 'YYYY-MM-DD HH:mm:ss' 形式のタイムスタンプ
  updated_at?: string; // 'YYYY-MM-DD HH:mm:ss' 形式のタイムスタンプ
  start_time: string; // 'HH:mm:ss' 形式の時間
  finish_time: string; // 'HH:mm:ss' 形式の時間
}

interface Assistant {
  id: number;
  is_authority: string;
  last_name: string;
  first_name: string;
  // 他のフィールドも必要に応じて追加
}

const EducationTraining = (): JSX.Element => {
  // AuthorityContextからauthority, userShopId, userId, isLoadingを取得
  const { authority, userShopId, userId, isLoading: isAuthorityLoading } = useAuthority();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  /* ページ内データ */
  const [selectedTraineeId, setSelectedTraineeId] = useState<number | null>(null);
  const [selectedYearMonth, setSelectedYearMonth] = useState<string>("");

  /* APIからデータを受け取る */
  const { data: trainees, status: traineesStatus } = useUsersForSelectQuery();
  const { data: assistants, status: assistantsStatus } = useAssistantList();
  const { data: yearMonthList, status: yearMonthListStatus } = useYearMonthSinceCreation();

  /* レコードモーダルのハンドラ */
  const [isRecordModal, setIsRecordModal] = useState<boolean>(false);

  const handleRecordModalOpen = () => {
    setIsRecordModal(true);
  };
  const handleRecordModalClose = () => {
    setIsRecordModal(false);
  };

  /* 選択系 */
  // 年月変更ハンドラ
  const handleYearMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYearMonth(event.target.value);
  };

  // トレーニー（ユーザー）変更ハンドラ
  const handleTraineeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTraineeId(Number(event.target.value));
  };

  /* フィルタリングロジックを useMemo で最適化 */
  const filteredAssistants = useMemo<Assistant[]>(() => {
    if (!assistants) return [];

    switch (authority) {
      case 0:
        // 管理者: 全てのデータを返す
        console.log("Authority 0: Returning all assistants");
        return assistants;
      case 1:
        // 教育: is_authority が '0' 以外のデータのみ
        console.log("Authority 1: Filtering assistants where is_authority !== '0'");
        return assistants.filter((assistant) => assistant.is_authority !== "0");
      case 2:
        // 一般: ログインしている自分のデータのみ
        console.log("Authority 2: Filtering assistants where id === userId");
        return assistants.filter((assistant) => assistant.id === userId);
      default:
        // その他: 空配列を返す
        console.log("Authority not recognized: Returning empty array");
        return [];
    }
  }, [assistants, authority, userId]);

  // ログイン不可またはデータがない場合のメッセージ表示
  const noAssistants = useMemo<boolean>(() => {
    if (authority === null || ![0, 1, 2].includes(authority)) {
      return true;
    }
    if (authority === 2 && filteredAssistants.length === 0) {
      return true;
    }
    return false;
  }, [authority, filteredAssistants]);

  /* ページ内データの初期設定 */
  useEffect(() => {
    if (authority === 2 && userId !== null) {
      // 一般ユーザーの場合、自分自身のIDを設定
      setSelectedTraineeId(userId);
    } else if (filteredAssistants && filteredAssistants.length > 0) {
      setSelectedTraineeId(filteredAssistants[0].id);
    }
    if (yearMonthList && yearMonthList.length > 0) {
      setSelectedYearMonth(yearMonthList[0]);
    }
  }, [filteredAssistants, yearMonthList, authority, userId]);

  /* APIからのデータ取得 */
  const { data: trainingRecords, status: trainingRecordsStatus } = useTrainingRecordsByUserAndDate(
    selectedTraineeId,
    selectedYearMonth
  );

  /* ローディング状態の管理 */
  useEffect(() => {
    setIsLoading(
      isAuthorityLoading ||
        traineesStatus === "loading" ||
        yearMonthListStatus === "loading" ||
        trainingRecordsStatus === "loading"
    );
  }, [isAuthorityLoading, traineesStatus, yearMonthListStatus, trainingRecordsStatus]);

  /* デバッグ用のログ */
  useEffect(() => {
    console.log("Authority:", authority);
    console.log("User ID:", userId);
    console.log("Assistants fetched:", assistants);
    console.log("Filtered assistants:", filteredAssistants);
    console.log("Selected Trainee ID:", selectedTraineeId);
    console.log("Selected Year-Month:", selectedYearMonth);
  }, [authority, userId, assistants, filteredAssistants, selectedTraineeId, selectedYearMonth]);

  return (
    <>
      {/* 研修記録追加モーダル */}
      <TrainingRecordModal
        isOpen={isRecordModal}
        handleClose={handleRecordModalClose}
      />

      {/* ボタンエリア */}
      <div className="l-board__wrap p-board__btn no-border">
        <div className="l-flex p-board__btn--area space-around">
          {/* 年月セレクト */}
          <div className="p-board__btn--input">
            <select
              className="c-input c-input__select type-shadow"
              onChange={handleYearMonthChange}
              value={selectedYearMonth}
            >
              {yearMonthList &&
                yearMonthList.map((yearMonth, index) => {
                  // "-" で分割して、年と月を取得
                  const [year, month] = yearMonth.split("-");
                  return (
                    <option key={index} value={yearMonth}>
                      {`${year}年${month}月`}
                    </option>
                  );
                })}
            </select>
          </div>

          {/* アシスタント選択セレクトボックス */}
          {/* is_authority == 1 または 2 の場合にのみ表示 */}
          {(authority == 0 || authority == 1) && (
            <div className="p-board__btn--input">
              <select
                className="c-input c-input__select type-shadow"
                onChange={handleTraineeChange}
                value={selectedTraineeId !== null ? selectedTraineeId : ""}
              >
                {filteredAssistants.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.last_name} {user.first_name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* 研修記録追加ボタン（権限が「0」または「1」の場合のみ表示） */}
          {(authority == 0 || authority == 1 || authority == 2) && (
            <div className="p-board__btn--input">
              <Link
                className="p-board__btn--item c-btn c-btn__green icon icon-plus"
                onClick={handleRecordModalOpen}
              >
                研修記録を追加
              </Link>
            </div>
          )}
        </div>
      </div>

      {/* メインコンテンツ */}
      <div className="l-board__wrap p-board__main">
        <div className="p-board__main--box">
          <table className="p-table">
            <thead>
              <tr>
                <th>日付</th>
                <th>時間</th>
                <th>教育担当者</th>
                <th>研修項目</th>
                <th>研修カリキュラム名</th>
              </tr>
            </thead>
            <tbody>
              {trainingRecords && trainingRecords.data.length > 0 ? (
                trainingRecords.data.map((record: TrainingRecord) => (
                  <tr
                    key={record.id} // indexではなくrecord.idを使用
                    onClick={() => navigateTrainingDetail(record.id)}
                  >
                    <td>{formatDate(record.complete_on)}</td>
                    <td>
                      {formatTime(record.start_time)}-{formatTime(record.finish_time)}
                    </td>
                    <td>{record.instructor_name}</td>
                    <td>{record.training_item}</td>
                    <td>{record.training_name}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>該当する研修記録がありません。</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* エラーメッセージ表示 */}
      {traineesStatus === "error" && (
        <div className="error-message">トレーニー情報の取得に失敗しました。</div>
      )}
      {yearMonthListStatus === "error" && (
        <div className="error-message">年月情報の取得に失敗しました。</div>
      )}
      {trainingRecordsStatus === "error" && (
        <div className="error-message">研修記録の取得に失敗しました。</div>
      )}

      {/* ローディング表示 */}
      {isLoading && <LoaderComponent />}
    </>
  );
};

export default EducationTraining;
