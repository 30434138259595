import React, { useState, useEffect } from "react";
import LoaderComponent from "../../../Components/Layout/Loader";
import { useEvaluationPeriods } from "../../../Queries/EvaluationRuleQuery";
import { getComparisonResultsForUser } from "../../../Queries/EvaluationResultQuery";
import {
  useUsersForSelectQuery,
  useUserProfile,
} from "../../../Queries/UserQuery";
import RadarChart from "./components/RadarChart";

const HrCompareEvaluation = (): JSX.Element => {
  // AuthorityContext から authority を取得（今回は使用していませんが、必要に応じて活用できます）

  // API クエリの取得
  const { data: users, status: usersStatus } = useUsersForSelectQuery();
  const { data: profile, status: profileStatus } = useUserProfile();
  const { data: periods, status: periodsStatus } = useEvaluationPeriods();

  // 状態管理
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);

  const [beforePeriodStart, setBeforePeriodStart] = useState<string>("");
  const [beforePeriodEnd, setBeforePeriodEnd] = useState<string>("");
  const [beforeTotalScore, setBeforeTotalScore] = useState<string>("0/0");

  const [afterPeriodStart, setAfterPeriodStart] = useState<string>("");
  const [afterPeriodEnd, setAfterPeriodEnd] = useState<string>("");
  const [afterTotalScore, setAfterTotalScore] = useState<string>("0/0");

  const [comparisonResults, setComparisonResults] = useState<any>(null);

  const formatPeriod = (period: { start: string; end: string }) => {
    const startYear = period.start.slice(0, 4).slice(-2);
    const startMonth = parseInt(period.start.slice(4, 6), 10);
    const endYear = period.end.slice(0, 4).slice(-2);
    const endMonth = parseInt(period.end.slice(4, 6), 10);
    return `${startYear}年${startMonth}月-${endYear}年${endMonth}月`;
  };

  const [isLoading, setIsLoading] = useState<boolean>(true);

  // selectedUserId を profile.id で初期化および固定化
  useEffect(() => {
    if (users && users.length > 0 && profile) {
      if (profile.isAuthority === "0") {
        // isAuthority が "0" の場合、ユーザー選択を許可
        const userExists = users.find(
          (user: { id: number }) => user.id === profile.id
        );
        if (userExists) {
          setSelectedUserId(profile.id);
        } else {
          // profile.id が存在しない場合は最初のユーザーを設定
          setSelectedUserId(users[0].id);
        }
      } else {
        // isAuthority が "0" でない場合、selectedUserId を profile.id に固定
        setSelectedUserId(profile.id);
      }
    } else if (users && users.length > 0) {
      // profile がまだロードされていない場合、または存在しない場合は最初のユーザーを設定
      setSelectedUserId(users[0].id);
    }

    if (periods && periods.length > 0) {
      setBeforePeriodStart(periods[0].start);
      setBeforePeriodEnd(periods[0].end);
      setAfterPeriodStart(periods[0].start);
      setAfterPeriodEnd(periods[0].end);
    }
  }, [users, periods, profile]);

  // 評価実績を取得
  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (selectedUserId !== null && beforePeriodStart && afterPeriodStart) {
        const beforeYM = `${beforePeriodStart}${beforePeriodEnd}`;
        const afterYM = `${afterPeriodStart}${afterPeriodEnd}`;
        const data = await getComparisonResultsForUser(
          selectedUserId.toString(),
          beforeYM,
          afterYM
        );
        setComparisonResults(data);
      }
    } catch (error) {
      console.error("Error fetching evaluation comparison results:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [
    selectedUserId,
    beforePeriodStart,
    beforePeriodEnd,
    afterPeriodStart,
    afterPeriodEnd,
  ]);

  // トータルスコアの計算
  useEffect(() => {
    if (comparisonResults) {
      const newBeforeTotalScore = calculateTotalScore(
        comparisonResults,
        "before"
      );
      setBeforeTotalScore(newBeforeTotalScore);
      const newAfterTotalScore = calculateTotalScore(
        comparisonResults,
        "after"
      );
      setAfterTotalScore(newAfterTotalScore);
    }
  }, [comparisonResults]);

  // ユーザー選択ハンドラー
  const handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedUserId(Number(event.target.value));
  };

  // 期間選択ハンドラー
  const handleBeforePeriodChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const [start, end] = event.target.value.split("-");
    setBeforePeriodStart(start);
    setBeforePeriodEnd(end);
  };

  const handleAfterPeriodChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const [start, end] = event.target.value.split("-");
    setAfterPeriodStart(start);
    setAfterPeriodEnd(end);
  };

  // トータルスコアの計算関数
  const calculateTotalScore = (
    comparisonResults: any,
    type: string = "before"
  ): string => {
    if (!comparisonResults) return "0/0";

    const numerator =
      comparisonResults.job_evaluations.reduce(
        (acc: number, evaluation: any) => acc + evaluation[`${type}Result`],
        0
      ) +
      comparisonResults.performance_evaluations.reduce(
        (acc: number, evaluation: any) => acc + evaluation[`${type}Result`],
        0
      ) +
      comparisonResults.role_evaluations.reduce(
        (acc: number, evaluation: any) => acc + evaluation[`${type}Result`],
        0
      ) +
      comparisonResults.work_evaluations.reduce(
        (acc: number, evaluation: any) => acc + evaluation[`${type}Result`],
        0
      );

    const denominator =
      (comparisonResults.job_evaluations.length +
        comparisonResults.performance_evaluations.length +
        comparisonResults.role_evaluations.length +
        comparisonResults.work_evaluations.length) *
      7;

    return `${numerator}/${denominator}`;
  };

  // レーダーチャート用データフォーマット関数
  const formatEvaluationDataForRadarChart = (
    evaluations: any[],
    type: string
  ) => {
    return evaluations.map((evaluation) => ({
      subject: evaluation.name,
      A: evaluation[`${type}Result`],
    }));
  };

  // レーダーチャート用データ取得関数
  const getBeforeEvaluationData = () => {
    if (!comparisonResults) return [];
    return [
      ...formatEvaluationDataForRadarChart(
        comparisonResults.job_evaluations,
        "before"
      ),
      ...formatEvaluationDataForRadarChart(
        comparisonResults.role_evaluations,
        "before"
      ),
    ];
  };

  const getAfterEvaluationData = () => {
    if (!comparisonResults) return [];
    return [
      ...formatEvaluationDataForRadarChart(
        comparisonResults.job_evaluations,
        "after"
      ),
      ...formatEvaluationDataForRadarChart(
        comparisonResults.role_evaluations,
        "after"
      ),
    ];
  };

  const getBeforePerformanceData = () => {
    if (!comparisonResults) return [];
    return formatEvaluationDataForRadarChart(
      comparisonResults.performance_evaluations,
      "before"
    );
  };

  const getAfterPerformanceData = () => {
    if (!comparisonResults) return [];
    return formatEvaluationDataForRadarChart(
      comparisonResults.performance_evaluations,
      "after"
    );
  };

  // ローディング状態の管理
  useEffect(() => {
    setIsLoading(
      usersStatus === "loading" ||
        periodsStatus === "loading" ||
        profileStatus === "loading"
    );
  }, [usersStatus, periodsStatus, profileStatus]);

  if (isLoading) return <LoaderComponent />;

  return (
    <div className="l-board">
      <div className="l-board__inner">
        {/* 比較セクション */}
        <div className="l-board__wrap p-comp__headline p-comp__content">
          <div className="p-comp__headline-ttl"></div>
          <div className="p-comp__headline-select p-comp__headline-user">
            {/* ユーザー選択ドロップダウンを条件付きで表示 */}
            {profile?.isAuthority === "0" && (
              <select
                className="c-input c-input__select type-shadow"
                onChange={handleUserChange}
                value={selectedUserId !== null ? selectedUserId : ""}
              >
                {users &&
                  users.map((user, index) => (
                    <option key={index} value={user.id}>
                      {user.last_name} {user.first_name}
                    </option>
                  ))}
              </select>
            )}
          </div>
          {/* 期間選択ドロップダウン */}
          <div className="p-comp__headline-select p-comp__headline-beforeDate">
            <select
              className="c-input c-input__select type-shadow"
              onChange={handleBeforePeriodChange}
              value={`${beforePeriodStart}-${beforePeriodEnd}`}
            >
              {periods &&
                periods.map((period, index) => (
                  <option key={index} value={`${period.start}-${period.end}`}>
                    {formatPeriod(period)}
                  </option>
                ))}
            </select>
          </div>
          <div className="p-comp__headline-select p-comp__headline-afterDate">
            <select
              className="c-input c-input__select type-shadow"
              onChange={handleAfterPeriodChange}
              value={`${afterPeriodStart}-${afterPeriodEnd}`}
            >
              {periods &&
                periods.map((period, index) => (
                  <option key={index} value={`${period.start}-${period.end}`}>
                    {formatPeriod(period)}
                  </option>
                ))}
            </select>
          </div>
        </div>

        {/* 総合評価セクション */}
        <div className="l-board__wrap p-comp__content">
          <div className="p-compare__box">
            <p className="p-compare__ttl">総合評価</p>
            <div className="p-compare__content">
              <p className="content-headline"></p>
              <div className="content-inner">
                <div className="content-item">
                  <span className="total">{beforeTotalScore}</span>
                </div>
                <div className="content-item">
                  <span className="total">{afterTotalScore}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 仕事・役割評価セクション */}
        <div className="l-board__wrap p-comp__content">
          <div className="p-compare__box">
            <p className="p-compare__ttl">
              仕事評価
              <br />
              役割評価
            </p>
            <div className="p-compare__content">
              <p className="content-headline"></p>
              <div className="content-inner content-chart">
                <RadarChart
                  beforeEvaluationData={getBeforeEvaluationData()}
                  afterEvaluationData={getAfterEvaluationData()}
                />
              </div>
            </div>
          </div>
          {comparisonResults &&
            comparisonResults.job_evaluations.map(
              (jobEval: any, index: number) => (
                <div className="p-compare__box" key={index}>
                  <p className="p-compare__ttl"></p>
                  <div className="p-compare__content">
                    <p className="content-headline">{jobEval.name}</p>
                    <div className="content-inner">
                      <div className="content-item">
                        <span className="data color-orange">
                          {jobEval.beforeResult}
                        </span>
                      </div>
                      <div className="content-item">
                        <span className="data">{jobEval.afterResult}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          {comparisonResults &&
            comparisonResults.role_evaluations.map(
              (roleEval: any, index: number) => (
                <div className="p-compare__box" key={index}>
                  <p className="p-compare__ttl"></p>
                  <div className="p-compare__content">
                    <p className="content-headline">{roleEval.name}</p>
                    <div className="content-inner">
                      <div className="content-item">
                        <span className="data color-orange">
                          {roleEval.beforeResult}
                        </span>
                      </div>
                      <div className="content-item">
                        <span className="data">{roleEval.afterResult}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
        </div>

        {/* 業績評価セクション */}
        <div className="l-board__wrap p-comp__content">
          <div className="p-compare__box">
            <p className="p-compare__ttl">業績評価</p>
            <div className="p-compare__content">
              <p className="content-headline"></p>
              <div className="content-inner content-chart">
                <RadarChart
                  beforeEvaluationData={getBeforePerformanceData()}
                  afterEvaluationData={getAfterPerformanceData()}
                />
              </div>
            </div>
          </div>
          {comparisonResults &&
            comparisonResults.performance_evaluations.map(
              (perEval: any, index: number) => (
                <div className="p-compare__box" key={index}>
                  {/* タイトルは最初のアイテムのみ表示 */}
                  {/* <p className="p-compare__ttl">{index === 0 ? '業績評価' : ''}</p> */}
                  <p className="p-compare__ttl"></p>
                  <div className="p-compare__content">
                    <p className="content-headline">{perEval.name}</p>
                    <div className="content-inner">
                      <div className="content-item">
                        <span className="data color-orange">
                          {perEval.beforeResult}
                        </span>
                      </div>
                      <div className="content-item">
                        <span className="data">{perEval.afterResult}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
        </div>

        {/* 勤務評価セクション */}
        <div className="l-board__wrap p-comp__content">
          {comparisonResults &&
            comparisonResults.work_evaluations.map(
              (jobEval: any, index: number) => (
                <div className="p-compare__box" key={index}>
                  {/* タイトルは最初のアイテムのみ表示 */}
                  <p className="p-compare__ttl">
                    {index === 0 ? "勤務評価" : ""}
                  </p>
                  <div className="p-compare__content">
                    <p className="content-headline">{jobEval.name}</p>
                    <div className="content-inner">
                      <div className="content-item">
                        <span className="data color-orange">
                          {jobEval.beforeResult}
                        </span>
                      </div>
                      <div className="content-item">
                        <span className="data">{jobEval.afterResult}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
      </div>
    </div>
  );
};

export default HrCompareEvaluation;
