import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import * as api from "../Api/EducationCategoryAPI";
import { toast } from "react-toastify";


export const useEducationCategories = () => {
    return useQuery("educationCategories", api.getEducationCategories, {
        onSuccess: () => {
            // データの取得が成功した場合の処理（例: toastメッセージの表示など）
        },
        onError: (error: AxiosError) => {
            // Check if the error response contains a data object with a message property
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error('カテゴリデータの取得に失敗しました。');
            }
        }
    });
}

export const useEducationCategoriesWithCurriculum = (userId: string) => {
    return useQuery(["educationCategoriesWithCurriculum", userId], () => api.getListWithCurriculum(userId), {
        onSuccess: () => {
            // データの取得が成功した場合の処理（例: toastメッセージの表示など）
        },
        onError: (error: AxiosError) => {
            // Check if the error response contains a data object with a message property
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error('カリキュラムを含むカテゴリデータの取得に失敗しました。');
            }
        }
    });
}
