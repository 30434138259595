import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  useFetchEditableAttendanceData,
  useUpdateAttendanceData,
} from "../../../Queries/UserWorkQuery";
import LoaderComponent from "../../../Components/Layout/Loader";
import { toast } from "react-toastify";
import { generateErrorMessages } from "../../../Utils/attendanceErrorUtils";
import { formatDateToMonthDay, getDayOfWeek } from "../../../Utils/formatUtils";
import { UserWork } from "../../../Types/UserWork";
import { useAuthority } from "../../../Contexts/AuthorityContext";

const AttendanceEdit = (): JSX.Element => {
  const { authority } = useAuthority();
  /* ページ内データ */
  const { userId, yearMonth } = useParams<{ userId: string; yearMonth: string }>();

  /* APIデータ */
  const [initialData, setInitialData] = useState<UserWork[]>([]);
  const [currentData, setCurrentData] = useState<UserWork[]>([]);
  const { data: editAttendanceData, status: editAttendanceDataStatus } =
    useFetchEditableAttendanceData(userId, yearMonth);

  const isFutureDate = (dateStr: string): boolean => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to start of the day
    const checkDate = new Date(dateStr);
    return checkDate >= today;
  };

  /* データの初期化 */
  useEffect(() => {
    if (
      editAttendanceData &&
      (!currentData.length ||
        JSON.stringify(editAttendanceData.data) !== JSON.stringify(initialData))
    ) {
      setInitialData(JSON.parse(JSON.stringify(editAttendanceData.data)));
      setCurrentData(editAttendanceData.data);
    }
  }, [editAttendanceData]);

  const handleInputChange = (index: number, field: keyof UserWork, value: string) => {
    setCurrentData(prevData => {
      const newData = [...prevData];
      if (typeof newData[index] !== "object") {
        newData[index] = { userId } as UserWork;
      }
      if (newData[index][field] !== value) {
        newData[index][field] = value;
      }
      return newData;
    });
  };

  const updateAttendanceData = useUpdateAttendanceData();
  const handleUpdateAttendanceData = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // フォーム送信などを防ぐ場合

    if (authority !== 0) { // 数値型で比較
      toast.error("権限が付与されていないため実行できません。");
      return;
    }

    // 変更されたデータのみを抽出
    const updatedData = currentData.filter((data, index) => {
      return !isEqual(data, initialData[index]); // lodashのisEqualを使用
    });

    // 更新処理を実行
    if (updatedData.length > 0) {
      try {
        await updateAttendanceData.mutateAsync({ userId, updatedData });
        toast.success("勤怠情報が更新されました。");
      } catch (error) {
        // エラーハンドリング（Axiosインターセプターで処理される場合不要）
      }
    } else {
      toast.error("変更されたデータはありません。");
    }
  };


  /* リセット機能 */
  const resetRowData = (index: number) => {
    setCurrentData(prevData => {
      const newData = [...prevData];
      if (typeof newData[index] !== "object") {
        newData[index] = { userId } as UserWork;
      }
      [
        "startAt",
        "finishAt",
        "firstRestStartAt",
        "firstRestFinishAt",
        "secondRestStartAt",
        "secondRestFinishAt",
      ].forEach((field) => {
        newData[index][field] = "";
      });
      return newData;
    });
  };

  const [isErrorMessagesReady, setIsErrorMessagesReady] = useState(false);
  useEffect(() => {
    if (currentData && Array.isArray(currentData)) {
      // エラーメッセージが準備できたら状態を更新
      setIsErrorMessagesReady(true);
    }
  }, [currentData]);

  const errorMessages = isErrorMessagesReady ? generateErrorMessages(currentData) : "";

  /* 画面系 */
  const isLoading = editAttendanceDataStatus === "loading";

  /* ローディング */
  if (editAttendanceDataStatus === "error") {
    return <div className="align-center">データの読み込みに失敗しました。</div>;
  }
  if (isLoading || !isErrorMessagesReady) {
    return <LoaderComponent />;
  }

  return (
    <>
      <div className="l-board is_scroll l-board__edit">
        <div className="l-board__inner">
          <div className="l-board__wrap p-board__main">
            <div className="p-board__main--box p-input__area">
              {errorMessages && (
                <div className="c-msgError">
                  {errorMessages.split("<br>").map((message, index) => (
                    <p key={index}>{message}</p>
                  ))}
                </div>
              )}
              <table className="p-table p-table__small">
                <thead>
                  <tr>
                    <th>日付</th>
                    <th>出勤時間</th>
                    <th>退勤時間</th>
                    <th>休憩1 開始</th>
                    <th>休憩1 終了</th>
                    <th>休憩2 開始</th>
                    <th>休憩2 終了</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {currentData &&
                    Array.isArray(currentData) &&
                    currentData.map((attendance, index) => {
                      const isValidDate = attendance.workOn !== null;
                      const formattedDate = isValidDate
                        ? formatDateToMonthDay(attendance.workOn)
                        : "未定";
                      const dayOfWeek = isValidDate
                        ? getDayOfWeek(attendance.workOn)
                        : "";

                      let rowClass = "";
                      if (
                        attendance.startAt === null &&
                        (attendance.finishAt ||
                          attendance.firstRestStartAt ||
                          attendance.firstRestFinishAt ||
                          attendance.secondRestStartAt ||
                          attendance.secondRestFinishAt)
                      ) {
                        rowClass = "u-bgRed";
                      } else if (
                        attendance.startAt !== null &&
                        attendance.finishAt === null
                      ) {
                        rowClass = "u-bgRed";
                      }

                      return (
                        <tr key={index} className={rowClass}>
                          <td>
                            {formattedDate}
                            {dayOfWeek}
                          </td>
                          {[
                            "startAt",
                            "finishAt",
                            "firstRestStartAt",
                            "firstRestFinishAt",
                            "secondRestStartAt",
                            "secondRestFinishAt",
                          ].map((field, i) => (
                            <td key={i}>
                              <input
                                type="time"
                                name={field}
                                className="c-input c-input__time"
                                value={attendance[field] || ""}
                                onChange={(e) => {
                                  handleInputChange(
                                    index,
                                    field as keyof UserWork,
                                    e.target.value
                                  );
                                }}
                                disabled={
                                  !isValidDate ||
                                  isFutureDate(attendance.workOn)
                                }
                              />
                            </td>
                          ))}
                          <td>
                            {!isFutureDate(attendance.workOn) && (
                              <button
                                className="c-btn c-btn__red c-btn__table"
                                onClick={() => resetRowData(index)}
                              >
                                リセット
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {authority == '0' && (
        <div className="l-button">
          <button
            className="c-btn c-btn__bgGreen c-btn__submit"
            onClick={handleUpdateAttendanceData}
          >
            勤怠情報を更新
          </button>
        </div>
      )}
    </>
  );
};
export default AttendanceEdit;
