// AuthorityContext.tsx

import React, { createContext, useContext, useEffect, useState, ReactNode } from "react";
import { useUserAuthorityQuery } from "../Queries/UserQuery";

type AuthorityType = 0 | 1 | 2 | 9; // 9: ログイン不可

interface AuthorityContextProps {
  authority: AuthorityType | null;
  userShopId: number | null;
  userId: number | null;
  isLoading: boolean;
}

const AuthorityContext = createContext<AuthorityContextProps>({
  authority: null,
  userShopId: null,
  userId: null,
  isLoading: true,
});

interface AuthorityProviderProps {
  children: ReactNode;
}

export const AuthorityProvider: React.FC<AuthorityProviderProps> = ({ children }) => {
  const [authority, setAuthority] = useState<AuthorityType | null>(null);
  const [userShopId, setUserShopId] = useState<number | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const {
    data: userAuthorityResponse,
    isLoading,
    error,
  } = useUserAuthorityQuery();

  useEffect(() => {
    if (userAuthorityResponse) {
      const fetchedAuthority = Number(userAuthorityResponse.authority);
      const fetchedShopId = Number(userAuthorityResponse.shopId);
      const fetchedUserId = Number(userAuthorityResponse.id);

      if ([0, 1, 2].includes(fetchedAuthority)) {
        setAuthority(fetchedAuthority as AuthorityType);
      } else {
        setAuthority(9); // ログイン不可
      }

      setUserShopId(isNaN(fetchedShopId) ? null : fetchedShopId);
      setUserId(isNaN(fetchedUserId) ? null : fetchedUserId);
    }

    if (error) {
      console.error("Error fetching user authority:", error);
      setAuthority(9); // ログイン不可
      setUserShopId(null);
      setUserId(null);
    }
  }, [userAuthorityResponse, error]);

  return (
    <AuthorityContext.Provider value={{ authority, userShopId, userId, isLoading }}>
      {children}
    </AuthorityContext.Provider>
  );
};

export const useAuthority = () => useContext(AuthorityContext);
