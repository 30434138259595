const BASE_URL = "/api/users/evaluation-result/list";

export const fetchEvaluationResults = async (userId: string, yearMonth: string): Promise<any> => {
    try {
        const response = await fetch(`${BASE_URL}/${userId}/${yearMonth}`);
        if (!response.ok) {
        throw new Error("Network response was not ok");
        }
        return await response.json();
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
}

// Add a new function to fetch comparison data
export const fetchComparisonResults = async (userId: string, beforeYearMonth: string, afterYearMonth: string): Promise<any> => {
    try {
        const response = await fetch(`/api/users/evaluation-result/list-comparison/${userId}/before/${beforeYearMonth}/after/${afterYearMonth}`);
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        return await response.json();
    } catch (error) {
        console.error("Error fetching comparison data:", error);
        throw error;
    }
};
