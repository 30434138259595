import React from 'react';

interface Props {
    activeIndex: number; // ここで受け取るのは職種のID
    setActiveIndex: (id: number) => void;
    btnTexts: string[];
    occupationIds: number[]; // 職種のIDの配列
}

const ToggleOccupationBtn = ({ activeIndex, setActiveIndex, btnTexts, occupationIds }) => {
    return (
        <ul className="p-toggle__tab">
            {btnTexts.map((text, index) => (
                <li key={index} className={`p-toggle__tab--btn ${activeIndex === occupationIds[index] ? "is_active" : ""}`} onClick={() => setActiveIndex(index)}>
                    {text}
                </li>
            ))}
            <div className={`p-toggle__tab--indicator p-toggle__tab--indicator-pos-${occupationIds.indexOf(activeIndex)}`}></div>
        </ul>
    );
};


export default ToggleOccupationBtn;
