import axios from 'axios';
import { CalculationRule } from "../Types/CalculationRule"

// 一覧取得
export const fetchCalculationRules = async (): Promise<CalculationRule[]> => {
    const { data } = await axios.get<CalculationRule[]>('/api/users/c-rule/list');
    return data;
};

// 労働時間情報の詳細取得
export const getCalculationRuleDetail = async (): Promise<CalculationRule[]> => {
    const response = await axios.get<CalculationRule[]>('/api/users/c-rule/detail');
    return response.data;
}

// 労働時間情報の更新
export const updateCalculationRulesApi = async (data: CalculationRule[]) => {
    try {
        await axios.put('/api/users/c-rule/update', data);
    } catch (error) {
        throw new Error('API呼び出し中にエラーが発生しました。');
    }
};
