import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as api from '../Api/CompanyEvaluationRuleAPI';
import { toast } from 'react-toastify';

// 会社
export const useCompanyEvaluationRuleQuery = () => {
    return useQuery('companyEvaluationRule', api.fetchCompanyEvaluationRule);
};

// 更新処理
export const useUpdateCompanyEvaluationRuleMutation = (onSuccessCallback) => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ companyData, evaluationRuleData }) => api.updateCompanyEvaluationRule(companyData, evaluationRuleData),
        {
            onSuccess: () => {
                toast.success('会社情報の更新に成功しました。');
                queryClient.resetQueries();
                onSuccessCallback(); // コールバック関数を実行
            },
            onError: (error) => {
                // バリデーションエラーメッセージを取得
                let errorMessage = '会社情報の更新に失敗しました。'; // デフォルトのエラーメッセージ
                if (error.message) {
                    // Laravelからのバリデーションエラーメッセージを抽出
                    const errors = error.message;
                    errorMessage = errors;
                }
                // エラーメッセージを表示
                toast.error(errorMessage);
            }
        }
    );
};
