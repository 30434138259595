import axios from "axios";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AddModal from '../../../Components/Modal/AddModal';
import { useShopList, useCreateShop } from "../../../Queries/ShopQuery";
import LoaderComponent from '../../../Components/Layout/Loader';
import { useAuthority } from '../../../Contexts/AuthorityContext';

const ShopList = (): JSX.Element => {
    const { authority } = useAuthority();
    const navigate = useNavigate();
    const [isAddModal, setIsAddModal] = useState(false);
    const createShop = useCreateShop();

    const { data: shops, status: shopsStatus } = useShopList();

    const navigateShopDetail = (shopId) => {
        const url = `/hc-user/shop/detail/${shopId}`;
        navigate(url);
    };

    const handleAddModalOpen = () => {
        setIsAddModal(true);
    };

    const handleAddModalClose = () => {
        setIsAddModal(false);
    };

    const isLoading = ['loading'].includes(shopsStatus);
    // const isError = ['error'].includes(shopsStatus);

    if (isLoading) return <LoaderComponent />;
    // if (isError) return <ErrorComponent />;

    return (
        <div className="l-board">
            {authority == '0' && (
                <AddModal
                    title="店舗追加"
                    optionDetail={false}
                    optionRank={false}
                    optionOccupation={false}
                    isOpen={isAddModal}
                    handleClose={handleAddModalClose}
                    createMutation={createShop}
                />
            )}
            <div className="l-board__inner">

                {authority == '0' && (
                    <div className="l-board__wrap p-board__btn">
                        <div className="l-flex p-board__btn--area space-around">
                            <Link
                                className="p-board__btn--item c-btn c-btn__green icon icon-plus"
                                onClick={handleAddModalOpen}
                            >
                                店舗を追加
                            </Link>
                        </div>
                    </div>
                )}
                <div className="l-board__wrap p-board__main">
                    {shops && shops.length > 0 ? (
                        <div className="p-board__main--box">
                            <table className="p-table">
                                <thead>
                                    <tr>
                                        <th>店舗名</th>
                                        <th>所属スタッフ名</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {shops.map((shop) => (
                                        <tr
                                            key={shop.id} // ユニークな `id` をキーとして使用
                                            onClick={() => navigateShopDetail(shop.id)}
                                        >
                                            <td>{shop.name}</td>
                                            <td>
                                                {shop.users && shop.users.length > 0
                                                    ? shop.users.map((user) => `${user.last_name} ${user.first_name}`).join(', ')
                                                    : 'なし'}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div className="align-center">店舗がありません。</div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ShopList;
