import { useState, useEffect } from 'react';
import * as api from '../Api/PerformanceResultAPI';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from 'react-query';

export const useFetchPerformanceWithGoalData = (userId, year) => {
    return useQuery(
        ['performanceData', userId, year],
        () => api.getPerformanceByYearAndUserWithGoal(userId, parseInt(year)),
        {
            // userId と year が存在する場合のみクエリを実行する条件
            enabled: !!userId && !!year,
            onError: (error) => {
                // エラーが発生した場合、ユーザーに通知
                toast.error("データの取得に失敗しました。");
            }
        }
    );
};

export const useResultsByYearForEdit = (userId, year) => {
    return useQuery(
        // queryKey
        ['performanceResultsForEdit', userId, year],
        () => api.getResultsByYearForEdit(userId, year),
        {
            // refetchOnWindowFocus: false, // ウィンドウフォーカス時にリフェッチ
            onSuccess: () => {
                // データの取得に成功したときの処理
            },
            onError: () => {
                // エラーハンドリング
                toast.error('編集用業績データの取得に失敗しました。');
            },
        }
    );
};


// 登録機能
export const useStoreOrUpdatePerformanceResult = (userId: string, year: string) => {
    const navigate = useNavigate();

    return async (data) => {
        try {
            const result = await api.storeOrUpdatePerformance(data);
            toast.success('スタッフ業績の更新に成功しました。');
            navigate('/hc-user/performance/staff', { state: { userId, year } });
            return result;
        } catch (error) {
            toast.error(error.message || 'スタッフ業績の更新に失敗しました。');
            throw error;
        }
    }
};


// 評価期間内のユーザー合計取得
export const fetchUserTotalInEvaluationPeriod = async (userId: number, year: string, month: string) => {
    try {
        const data = await api.listTotalOfUserInEvaluationPeriod(userId, year, month);
        return data;  // フロントエンドで使用するためのデータを返す
    } catch (error) {
        toast.error("評価期間内の業績合計の取得に失敗しました。");
        throw error;
    }
};

export const fetchPerformanceDataByMonthWithGoal = async (userId: number, year: number, month: number) => {
    try {
        const data = await api.listByMonthOfUserInEvaluationPeriod(userId, year, month);
        return data;  // フロントエンドで使用するためのデータを返す
    } catch (error) {
        toast.error("月別の目標値を含む業績の取得に失敗しました。");
        throw error;
    }
};

export const fetchDetailPerformanceDataByMonthWithGoal = async (userId: number, performanceItemId: number, year: number, month: number) => {
    try {
        const data = await api.detailByMonthOfUserInEvaluationPeriod(userId, performanceItemId, year, month);
        return data;  // フロントエンドで使用するためのデータを返す
    } catch (error) {
        toast.error("月別の目標値を含む業績の取得に失敗しました。");
        throw error;
    }
};

// 更新&登録&削除
export const useChangePerformanceResultMutation = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    return useMutation(
        performanceResults => {
            return api.updatePerformanceResult(performanceResults);
        },
        {
            onSuccess: () => {
                queryClient.resetQueries();
                toast.success('業績の更新に成功しました。');
                navigate('/hc-user/performance/staff');
            },
            onError: () => {
                toast.error('業績の更新に失敗しました。');
            }
        }
    );
};
