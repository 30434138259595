import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useCompanyDetail } from "../../../Queries/CompanyQuery";
import { useAuthority } from "../../../Contexts/AuthorityContext";
import LoaderComponent from "../../../Components/Layout/Loader";

// 各種コンポーネント
import HrEvaluation from "./HrEvaluation";
import HrPerformance from "./HrPerformance";
import HrElements from "./HrElements";

const HrBoard = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState(1);
  const [isShowEvaluationTable, setIsShowEvaluationTable] = useState(false);
  const [loading, setLoading] = useState(true); // ローディング管理

  const navigate = useNavigate();
  const location = useLocation();

  // 権限情報を取得
  const { authority, isLoading: isAuthorityLoading } = useAuthority();

  // 会社情報を取得
  const { data: companyDetail, isSuccess: isCompanySuccess, isLoading: isCompanyLoading } = useCompanyDetail();

  // ロジックの更新: 権限と会社情報に基づく処理
  useEffect(() => {
    if (!isAuthorityLoading && !isCompanyLoading && isCompanySuccess && companyDetail) {
      // 権限が "0" の場合は無条件で表示
      if (authority == "0") {
        setIsShowEvaluationTable(true);
      } else {
        // 権限が "0" 以外の場合、会社情報に応じて表示制御
        setIsShowEvaluationTable(companyDetail.company.is_show_evaluation_table === true);
      }
      setLoading(false); // ローディング完了
    }
  }, [authority, isAuthorityLoading, isCompanyLoading, isCompanySuccess, companyDetail]);

  // URLのパスに応じてタブをセット
  useEffect(() => {
    switch (location.pathname) {
      case "/hc-user/hr/evaluation":
        setActiveTab(1);
        break;
      case "/hc-user/hr/performance":
        setActiveTab(2);
        break;
      case "/hc-user/hr/elements":
        setActiveTab(3);
        break;
      default:
        break;
    }
  }, [location.pathname]);

  // タブをクリックしたときの動作
  const handleTabClick = (tabNum) => {
    setActiveTab(tabNum);
    switch (tabNum) {
      case 1:
        navigate("/hc-user/hr/evaluation");
        break;
      case 2:
        navigate("/hc-user/hr/performance");
        break;
      case 3:
        if (isShowEvaluationTable) {
          navigate("/hc-user/hr/elements");
        }
        break;
      default:
        break;
    }
  };

  // データが読み込み中の場合、ローディング画面を表示
  if (isAuthorityLoading || isCompanyLoading || loading) {
    return <LoaderComponent />;
  }

  return (
    <div className="l-board">
      <div className="l-board__inner">
        <div className="p-board__tab">
          <li
            id="tab1"
            className={`p-tab__btn ${activeTab === 1 ? "is_active" : ""}`}
            onClick={() => handleTabClick(1)}
          >
            評価
          </li>
          <li
            id="tab2"
            className={`p-tab__btn ${activeTab === 2 ? "is_active" : ""}`}
            onClick={() => handleTabClick(2)}
          >
            業績
          </li>
          {isShowEvaluationTable && (
            <li
              id="tab3"
              className={`p-tab__btn ${activeTab === 3 ? "is_active" : ""}`}
              onClick={() => handleTabClick(3)}
            >
              考課素点要素
            </li>
          )}
        </div>

        <div id="panel1" className={`p-tab__panel ${activeTab === 1 ? "is_active" : ""}`}>
          <HrEvaluation />
        </div>
        <div id="panel2" className={`p-tab__panel ${activeTab === 2 ? "is_active" : ""}`}>
          <HrPerformance />
        </div>
        {isShowEvaluationTable && (
          <div id="panel3" className={`p-tab__panel ${activeTab === 3 ? "is_active" : ""}`}>
            <HrElements />
          </div>
        )}
      </div>
    </div>
  );
};

export default HrBoard;
