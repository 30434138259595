import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AddModal from '../../../Components/Modal/AddModal';
import MasterModal from '../../../Components/Modal/MasterModal';
import { useEvaluationItemListOfWork, useCreateEvaluationItem, useUpdateEvaluationItem, useDeleteEvaluationItem } from '../../../Queries/EvaluationItemQuery';
import { useOccupationList } from '../../../Queries/OccupationQuery';
import LoaderComponent from '../../../Components/Layout/Loader';
import { useAuthority } from '../../../Contexts/AuthorityContext';

const EvaluationItemList = () => {
    const { authority } = useAuthority();
    const [isLoading, setIsLoading] = useState(true);
    const [isAddModal, setIsAddModal] = useState(false);
    const [isMasterModal, setIsMasterModal] = useState(false);
    const [selectedMasterId, setSelectedMasterId] = useState(null);
    const [selectedMasterName, setSelectedMasterName] = useState('');
    const [selectedMasterDetail, setselectedMasterDetail] = useState('');
    const [selectedMasterOccupation, setSelectedMasterOccupation] = useState('');


    const handleModalOpen = (isOpen, setIsOpen) => () => setIsOpen(isOpen);
    const handleModalClose = (setIsOpen) => () => setIsOpen(false);

    const handleCloseMasterModal = () => {
        setIsMasterModal(false);
    };
    const updateItem = useUpdateEvaluationItem(handleCloseMasterModal);
    const createItem = useCreateEvaluationItem();
    const deleteItem = useDeleteEvaluationItem();
    const { data: items, status: itemsStatus } = useEvaluationItemListOfWork('items');
    const { data: occupations, status: occupationStatus } = useOccupationList('occupations');
    const handleMasterModalOpen = (id, name, occupation, detail) => {
        setSelectedMasterId(null);
        setSelectedMasterName('');
        setselectedMasterDetail('');
        setSelectedMasterOccupation('');
        setTimeout(() => {
            setSelectedMasterId(id);
            setSelectedMasterName(name);
            setselectedMasterDetail(detail);
            setSelectedMasterOccupation(occupation ? occupation.id : null);
        }, 0);
        setIsMasterModal(true);
    };

    useEffect(() => {
        setIsLoading(itemsStatus === 'loading' || occupationStatus === 'loading');
    }, [itemsStatus, occupationStatus]);
    if (isLoading) return <LoaderComponent />;

    return (
        <div className="l-board">
            {authority == '0' && (
                <>
                    <AddModal
                        title="勤務項目追加"
                        isOpen={isAddModal}
                        optionDetail={true}
                        optionRole={false}
                        optionOccupation={false}
                        advanceEvaluationType={'work'}
                        allOccupation={occupations}
                        handleClose={handleModalClose(setIsAddModal)}
                        createMutation={createItem}
                    />
                    <MasterModal
                        title="勤務項目編集"
                        isOpen={isMasterModal}
                        optionDetail={true}
                        optionRole={false}
                        optionOccupation={false}
                        allOccupation={occupations}
                        handleClose={handleModalClose(setIsMasterModal)}
                        selectedId={selectedMasterId}
                        selectedName={selectedMasterName}
                        selectedDetail={selectedMasterDetail}
                        selectedOccupation={selectedMasterOccupation}
                        updateMutation={updateItem}
                        deleteMutation={deleteItem}
                    />
                </>
            )}
            <div className="l-board__inner">

                {authority == '0' && (
                    <div className="l-board__wrap p-board__btn">
                        <div className="l-flex p-board__btn--area space-around">
                            <Link
                                className="p-board__btn--item c-btn c-btn__green icon icon-plus"
                                onClick={handleModalOpen(true, setIsAddModal)}
                            >勤務項目を追加</Link>
                        </div>
                    </div>
                    )}
                <div className="l-board__wrap p-board__main">
                    <div className="p-board__main--box">
                        <table className="p-table">
                            <thead>
                                <tr>
                                    <th>名称</th>
                                    <th>詳細</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items?.map(({ id, name, occupation, detail }) => (
                                    <tr
                                        key={id}
                                        onClick={authority == '0' ? () => handleMasterModalOpen(id, name, occupation || {id: null, name: "全て"}, detail) : null}
                                    >
                                        <td style={{whiteSpace: "nowrap"}}>{name}</td>
                                        <td>{detail}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EvaluationItemList;
