// UserEducationRoutes.jsx
import EducationBoard from '../Pages/User/Education/EducationBoard.tsx';
import EducationResultDetail from '../Pages/User/Education/EducationResultDetail.tsx';
import EducationTrainingDetail from '../Pages/User/Education/EducationTrainingDetail.tsx';
import EducationCurriculumBoard from '../Pages/User/Education/EducationCurriculumBoard.tsx';

export const educationRoutes = [
    { path: '/hc-user/education/progress', element: <EducationBoard />},
    { path: '/hc-user/education/result', element: <EducationBoard />},
    { path: '/hc-user/education/result/detail/:curriculumResultId', element: <EducationResultDetail />},
    { path: '/hc-user/education/training', element: <EducationBoard />},
    { path: '/hc-user/education/training/detail/:trainingRecordId', element: <EducationTrainingDetail />},
    { path: '/hc-user/education/curriculum/detail/:userId/:educationCurriculumId', element: <EducationCurriculumBoard />},
    { path: '/hc-user/education/curriculum/result/:userId/:educationCurriculumId', element: <EducationCurriculumBoard />}
];
