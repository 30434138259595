import { AxiosError } from 'axios';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import * as EducationCurriculumAPI from "../Api/EducationCurriculumAPI";
import { EducationCurriculum } from '../Types/EducationCurriculum';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';



// カリキュラム詳細
export const useGetEducationCurriculumDetail = (id: number) => {
    return useQuery(['educationCurriculumDetail', id], () => EducationCurriculumAPI.getEducationCurriculumDetail(id), {
        onSuccess: () => {
            // toast.success('カリキュラムの詳細が正常に取得されました。');
        },
        onError: (error: AxiosError) => {
            // Check if the error response contains a data object with a message property
            if (error.response && error.response.data && error.response.data.data && error.response.data.data.message) {
                toast.error(error.response.data.data.message);
            } else {
                toast.error('カリキュラムの詳細の取得に失敗しました。');
            }
        }
    });
}


// カリキュラム一覧
export const useFetchUserCurriculums = () => {
    return useQuery("userCurriculums", EducationCurriculumAPI.fetchUserCurriculums, {
        onSuccess: () => {
            // toast.success("ユーザーのカリキュラムの取得に成功しました。");
        },
        onError: (error) => {
            // toast.error("ユーザーのカリキュラムの取得に失敗しました。");
        }
    });
};
// カリキュラム追加
export const useAddEducationCurriculum = (onSuccess) => {
    const queryClient = useQueryClient();
    return useMutation(
        (newCurriculumData: EducationCurriculum) =>
            EducationCurriculumAPI.addEducationCurriculum(newCurriculumData),
        {
            onSuccess: () => {
                queryClient.resetQueries();
                toast.success('カリキュラム項目が追加されました。');
                onSuccess(); // onSuccess コールバックを呼び出す
            },
            onError: (error: any) => {
                toast.error(error.response?.data?.message || 'カリキュラムの追加に失敗しました。');
            }
        }
    );
};

// カリキュラム更新
export const useUpdateEducationCurriculum = (onSuccess) => {
    const queryClient = useQueryClient();
    return useMutation(
        (curriculumData) => EducationCurriculumAPI.updateEducationCurriculum(curriculumData),
        {
            onSuccess: () => {
                queryClient.resetQueries();
                toast.success('カリキュラムが更新されました。');
                if (onSuccess) onSuccess();
            },
            onError: (error: AxiosError) => {
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error('カリキュラムの更新に失敗しました。');
                }
            }
        }
    );
};

// カリキュラム削除
export const useDeleteEducationCurriculum = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    return useMutation(EducationCurriculumAPI.deleteEducationCurriculum, {
        onSuccess: (data) => {
            queryClient.resetQueries();
            navigate('/hc-user/education/progress/');
            toast.success('カリキュラムが削除されました。');
        },
        onError: (error: AxiosError) => {
            // Check if the error response contains a data object with a message property
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error('カリキュラムの削除に失敗しました。');
            }
        }
    });
}
