import React, { useState } from 'react';

const RangeForm = ({ keyPrefix, id, value, onChangeRadioValue }) => {
    const [selectedRadio, setSelectedRadio] = useState(value - 1);

    const handleRadioChange = (e, index) => {
        setSelectedRadio(index);
        onChangeRadioValue(+e.target.value, id)
    };

    return (
        <ul className="c-range__list">
            {[...Array(7)].map((_, index) => (
                <li key={index} className="c-range__item">
                    <input
                        id={`${keyPrefix}-radio-${index}`}
                        type="radio"
                        value={index + 1}
                        checked={selectedRadio === index}
                        onChange={(e) => handleRadioChange(e, index)}
                    />
                    <label
                        htmlFor={`${keyPrefix}-radio-${index}`}
                        className={selectedRadio === index ? "active" : ""}
                    ></label>
                </li>
            ))}
        </ul>
    );
};

export default RangeForm;
