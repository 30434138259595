import React from "react";

const SelectField = ({ label, options, register, name, error }) => (
  <div className="p-input__area">
    <label className="c-label">{label}</label>
    <select {...register(name)} className="c-input u-mt4">
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    {error && <p className="p-input__error">{error}</p>}
  </div>
);

export default SelectField;
