// ShopPerformanceResultQuery.ts
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as api from "../Api/ShopPerformanceResultAPI";
import { useNavigate } from "react-router-dom";

/* 取得（一般） */
export const useShopPerformanceResultsForYear = (
  shopId: number,
  year: number
) => {
  return useQuery(
    ["fetchShopPerformanceResults", shopId, year],
    async () => api.fetchShopPerformanceResults(shopId, year),
    {
      onSuccess: (data) => {
        // 成功時の処理（必要に応じてdataを使用するなど）
      },
      onError: (error) => {
        // エラー時の処理をここに記述
        // toast.error('店舗業績結果の取得に失敗しました。');
      },
    }
  );
};

/* 取得（編集用） */
export const useEditShopPerformanceResultsForYear = (shopId, year) => {
  const navigate = useNavigate();

  return useQuery(
    ["fetchEditShopPerformanceResults", shopId, year],
    () => api.fetchEditShopPerformanceResults(shopId, year),
    {
      onSuccess: (data) => {
        // 成功時の処理
      },
      onError: (error) => {
        const status = error?.response?.status;
        const errorMessage =
          error?.response?.data?.message ||
          "店舗業績結果の取得（編集用）に失敗しました。";
        toast.error(errorMessage);
        if (status === 403) {
          navigate("/hc-user/performance/shop"); // 403エラー時に指定パスにリダイレクト
        }
      },
    }
  );
};

/* 更新 */
export const useUpdateShopPerformance = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (shopPerformanceResults) =>
      api.updateShopPerformance(shopPerformanceResults),
    {
      onSuccess: (data) => {
        // 成功時の処理
        toast.success("店舗業績の更新に成功しました。");
        navigate("/hc-user/performance/shop/");
        queryClient.resetQueries();
      },
      onError: (error) => {
        if (error && error.errors) {
          // すべてのエラーメッセージを1つの文字列に結合
          const errorMessages = Object.values(error.errors).flat().join("\n");
          toast.error(errorMessages);
        } else {
          toast.error("店舗業績の更新に失敗しました。");
        }
      },
    }
  );

  return mutation;
};
