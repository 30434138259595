import React, { useState, useEffect, useReducer } from 'react';
import { useFetchUserCurriculums } from '../../Queries/EducationCurriculumQuery';
import { useUsersForSelectQuery, useAssistantList } from '../../Queries/UserQuery';
import LoaderComponent from '../Layout/Loader';
import DatePicker from 'react-datepicker';
import { ja } from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

interface CurriculumResultEditModalProps {
    isOpen: boolean;
    handleClose: () => void;
    selectedId: number;
    editDate: any;
    updateMutation: any;
}

const CurriculumResultEditModal: React.VFC<CurriculumResultEditModalProps> =
({ isOpen, handleClose, selectedId, editDate, updateMutation }) => {
    const fetchUserCurriculumsResponse = useFetchUserCurriculums();
    const curriculums = fetchUserCurriculumsResponse.data || [];
    const { data: users } = useUsersForSelectQuery();
    const { data: assistants } = useAssistantList();

    const [formData, setFormData] = useState({
        curriculumId: editDate?.curriculumId || '',
        educatorType: editDate?.educator_id === null ? 'outer' : (editDate?.educatorType || 'inner'),
        educatorUserId: editDate?.educatorUserId || '',
        externalEducatorName: editDate?.externalEducatorName || '',
        educatedUserId: editDate?.educatedUserId || '',
        doneOn: editDate?.doneOn || new Date(),
        startHour: editDate?.startHour || '0',
        startMinute: editDate?.startMinute || '00',
        endHour: editDate?.endHour || '0',
        endMinute: editDate?.endMinute || '00',
        curriculumDetail: editDate?.curriculumDetail || '',
        curriculumRemark: editDate?.curriculumRemark || '',
    });

    const [isLoading, setIsLoading] = useState(true);


    const getTimeAndMinute = (timeString) => {
        const [hour, minute] = timeString.split(':');
        return { hour: String(parseInt(hour, 10)), minute };
    };

    useEffect(() => {
        if (editDate) {
            const { hour: startHour, minute: startMinute } = getTimeAndMinute(editDate.start_time);
            const { hour: endHour, minute: endMinute } = getTimeAndMinute(editDate.finish_time);

            setFormData({
                curriculumId: editDate.id || '',
                educatorType: editDate.educator_id === null ? 'outer' : (editDate.educatorType || 'inner'),
                educatorUserId: editDate.educated_user_id || '',
                externalEducatorName: editDate.educator_name || '',
                educatedUserId: editDate.educatedUserId || '',
                doneOn: editDate.done_on || new Date(),
                startHour,
                startMinute,
                endHour,
                endMinute,
                curriculumDetail: editDate.curriculum_detail || '',
                curriculumRemark: editDate.curriculum_remark || '',
            });
            setIsLoading(false);
        }
    }, [editDate]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleUpdateBtnClick = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await updateMutation.mutateAsync({
                ...formData,
                id: selectedId,
            });
            toast.success('更新が成功しました！');
            handleClose();
        } catch (err) {
            toast.error('更新に失敗しました。');
        }
    };

    const handleCloseBtnClick = () => {
        handleClose();
    };

    if (!isOpen) return null;

    if (isLoading) {
        return <LoaderComponent />;
    }

    return (
        <div className="p-modal" onClick={handleCloseBtnClick}>
            <div className="p-modal__content" onClick={(e) => e.stopPropagation()}>
                <button
                    className="p-modal__delete"
                    onClick={handleCloseBtnClick}>
                    <img src={'/image/icon_btn_batsu_green.png'} />
                </button>
                <h3 className="p-modal__headline">教育実績編集</h3>

                <div className="p-input__area">
                    <div className="area-label">
                        <label className="c-label">カリキュラム名</label>
                    </div>
                    <div className="area-input">
                        <select
                            name="curriculumId"
                            value={formData.curriculumId}
                            onChange={handleInputChange}
                            className="c-input c-input__text"
                        >
                            {curriculums?.map(curriculum => (
                                <option key={curriculum.id} value={curriculum.id}>{curriculum.name}: {curriculum.detail}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="p-input__area">
                    <div className="area-label">
                        <label className="c-label">教育担当者</label>
                    </div>
                    <div className="c-input__checkbox">
                        <input
                            id="inner"
                            type="radio"
                            name="educatorType"
                            value="inner"
                            checked={formData.educatorType === 'inner'}
                            onChange={handleInputChange}
                        />
                        <label htmlFor="inner">社内教育担当者</label>
                    </div>
                    <div className="c-input__checkbox">
                        <input
                            id="outer"
                            type="radio"
                            name="educatorType"
                            value="outer"
                            checked={formData.educatorType === 'outer'}
                            onChange={handleInputChange}
                        />
                        <label htmlFor="outer">社外教育担当者</label>
                    </div>
                </div>

                {formData.educatorType === 'inner' && (
                    <div className="p-input__area">
                        <div className="area-label">
                            <label className="c-label">社内教育担当者</label>
                        </div>
                        <div className="area-input">
                            <select
                                name="educatorUserId"
                                value={formData.educatorUserId}
                                onChange={handleInputChange}
                                className="c-input c-input__text"
                            >
                                {users?.map(user => (
                                    <option key={user.id} value={user.id}>{user.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}

                {formData.educatorType === 'outer' && (
                    <div className="p-input__area">
                        <div className="area-label">
                            <label className="c-label">外部レッスン担当者</label>
                        </div>
                        <div className="area-input">
                            <input
                                name="externalEducatorName"
                                value={formData.externalEducatorName}
                                onChange={handleInputChange}
                                className="c-input c-input__text"
                                type="text"
                            />
                        </div>
                    </div>
                )}

                <div className="p-input__area">
                    <div className="area-label">
                        <label className="c-label">受講者</label>
                    </div>
                    <div className="area-input">
                        <select
                            name="educatedUserId"
                            value={formData.educatedUserId}
                            onChange={handleInputChange}
                            className="c-input c-input__text"
                        >
                            {assistants?.map(user => (
                                <option key={user.id} value={user.id}>{user.name}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="p-input__area">
                    <div className="area-label">
                        <label className="c-label">実施日</label>
                    </div>
                    <div className="area-input">
                        <DatePicker
                            name="doneOn"
                            selected={new Date(formData.doneOn)}
                            onChange={(date) => setFormData({ ...formData, doneOn: date })}
                            className="c-input c-input__text"
                            dateFormat="yyyy/MM/dd"
                            dateFormatCalendar={'yyyy年 MMMM'}
                            locale={ja}
                        />
                    </div>
                </div>

                <div className="p-input__area">
                    <div className="area-label">
                        <label className="c-label">開始・終了時間</label>
                    </div>
                    <div className="area-input l-flex">
                        <div className="c-box__two">
                            <label className="c-label c-label__sub">開始時間</label>
                            <div className="l-flex">
                                <select
                                    name="startHour"
                                    value={formData.startHour}
                                    onChange={handleInputChange}
                                    className="c-input c-input__time form-control u-mt4"
                                >
                                    {Array.from({ length: 24 }).map((_, i) => (
                                        <option key={i} value={i}>{`${i}`}</option>
                                    ))}
                                </select>
                                <p className="u-ml4 u-mr4 u-mt4">:</p>
                                <select
                                    name="startMinute"
                                    value={formData.startMinute}
                                    onChange={handleInputChange}
                                    className="c-input c-input__time form-control u-mt4"
                                >
                                    {[...Array(12)].map((_, i) => {
                                        const value = (i * 5).toString();
                                        return <option key={i} value={value}>{value.padStart(2, '0')}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="c-box__two">
                            <label className="c-label c-label__sub">終了時間</label>
                            <div className="l-flex">
                                <select
                                    name="endHour"
                                    value={formData.endHour}
                                    onChange={handleInputChange}
                                    className="c-input c-input__time form-control u-mt4"
                                >
                                    {Array.from({ length: 24 }).map((_, i) => (
                                        <option key={i} value={i}>{`${i}`}</option>
                                    ))}
                                </select>
                                <p className="u-ml4 u-mr4 u-mt4">:</p>
                                <select
                                    name="endMinute"
                                    value={formData.endMinute}
                                    onChange={handleInputChange}
                                    className="c-input c-input__time form-control u-mt4"
                                >
                                    {[...Array(12)].map((_, i) => {
                                        const value = (i * 5).toString();
                                        return <option key={i} value={value}>{value.padStart(2, '0')}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-input__area">
                    <div className="area-label">
                        <label className="c-label">詳細</label>
                    </div>
                    <div className="area-input">
                        <textarea
                            name="curriculumDetail"
                            value={formData.curriculumDetail}
                            onChange={handleInputChange}
                            className="c-input c-input__textarea"
                            rows={4}
                        ></textarea>
                    </div>
                </div>

                <div className="p-input__area">
                    <div className="area-label">
                        <label className="c-label">備考</label>
                    </div>
                    <div className="area-input">
                        <textarea
                            name="curriculumRemark"
                            value={formData.curriculumRemark}
                            onChange={handleInputChange}
                            className="c-input c-input__textarea"
                            rows={4}
                        ></textarea>
                    </div>
                </div>

                <div className="p-input__area">
                    <button
                        className="c-btn c-btn__green c-btn__bgGreen c-btn__small position-center"
                        onClick={handleUpdateBtnClick}
                    >
                        更新する
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CurriculumResultEditModal;
