import React from "react";
import { Link } from "react-router-dom";

const NoAuthority: React.FC = () => (
  <div className="l-board">
    <div className="l-board__inner">
      <div className="l-board__wrap p-board__main">
        <h3 className="c-headline__main">アクセス拒否</h3>
        <p>アクセス権限がありません。</p>
      </div>
    </div>
  </div>
);

export default NoAuthority;
