import axios from 'axios';
import { EvaluationRule } from '../Types/EvaluationRule';

export const fetchEvaluationPeriods = async (): Promise<any> => {
    const endpoint = '/api/users/evaluation-rule/year-month-list/';
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const updateEvaluationRule = async (evaluationRule: EvaluationRule): Promise<EvaluationRule> => {
    try {
        const response = await axios.put(`/api/users/evaluation-rule/${evaluationRule.id}`, evaluationRule);
        return response.data;
    } catch (error) {
        throw error;
    }
};
