import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useUpdateCompanyHoliday, useUpdateUserHoliday, useUpdateAllHoliday } from '../../Queries/HolidayQuery';
import { toast } from "react-toastify";

interface HolidayEditModalProps {
    // モーダル周り
    isOpen: boolean;
    handleOpen: () => void;
    handleClose: () => void;

    // 取得した情報
    selectedUserId: number;
    selectedCompanyId: number;
    selectedMonday: string;
    selectedTuesday: string;
    selectedWednesday: string;
    selectedThursday: string;
    selectedFriday: string;
    selectedSaturday: string;
    selectedSunday: string;
    selectedHoliday: string;
}


const HolidayEditModal: React.VFC<HolidayEditModalProps> = (props) => {
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Holiday'];
    const initialEditValues = days.reduce((acc, day) => {
        acc[day.toLowerCase()] = props[`selected${day}`] || '';
        return acc;
    }, {});

    const [editValues, setEditValues] = useState(initialEditValues);

    const updateAllHoliday = useUpdateAllHoliday();
    const updateCompanyHoliday = useUpdateCompanyHoliday();
    const updateUserHoliday = useUpdateUserHoliday();

    const [isAllUserChecked, setIsAllUserChecked] = useState(false);
    const handleAllUserChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsAllUserChecked(e.target.checked);
    };

    useEffect(() => {
        let updatedValues = {};
        days.forEach(day => {
            updatedValues[day.toLowerCase()] = props[`selected${day}`];
        });
        setEditValues(updatedValues);
    }, days.map(day => props[`selected${day}`]));

    const queryClient = useQueryClient();

    const handleUpdate = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()

        let mutation, mutationData = { ...editValues };

        if (isAllUserChecked) {
            mutation = updateAllHoliday;
            mutationData.company_id = props.selectedCompanyId;
        } else if(props.selectedUserId) {
            mutation = updateUserHoliday;
            mutationData.user_id = props.selectedUserId;
        } else if(props.selectedCompanyId) {
            mutation = updateCompanyHoliday;
            mutationData.company_id = props.selectedCompanyId;
        } else {
            toast.error('不明なエラーです。');
            return;
        }

        mutation.mutate(mutationData, {
            onSuccess: () => {
                queryClient.resetQueries();
                props.handleClose();
                setEditValues(initialEditValues);
            },
            onError: (error) => {}
        });
    };

    const dayMapping = {
        Monday: '月曜日',
        Tuesday: '火曜日',
        Wednesday: '水曜日',
        Thursday: '木曜日',
        Friday: '金曜日',
        Saturday: '土曜日',
        Sunday: '日曜日',
        Holiday: '祝日'
    };

    if (!props.isOpen) {
        return null;
    }

    return (
        <div className="p-modal">
            <div className="p-modal__content">
                <button className="p-modal__delete" onClick={props.handleClose}>
                    <img src={'/image/icon_btn_batsu_green.png'} />
                </button>
                <h3 className="p-modal__headline">休日編集</h3>
                <div className="p-input__area">
                    <table className="p-table__holiday">
                        <thead>
                            <tr>
                                <th></th>
                                <th>通常</th>
                                <th>所定休日</th>
                            </tr>
                        </thead>
                        <tbody>
                            {days.map((day, index) => (
                                <tr key={index}>
                                    {/* <td>{day === 'Holiday' ? '祝日' : `${day}曜日`}</td> */}
                                    <td>{dayMapping[day]}</td>
                                    <td className="c-input__radio">
                                        <input
                                            type="radio"
                                            name={day.toLowerCase()}
                                            value="0"
                                            id={`${day}Work`}
                                            checked={editValues[day.toLowerCase()] === '0'}
                                            onChange={(e) =>
                                                setEditValues(prev => ({
                                                    ...prev,
                                                    [day.toLowerCase()]: e.target.value
                                                }))
                                            }
                                        />
                                        <label htmlFor={`${day}Work`}></label>
                                    </td>
                                    <td className="c-input__radio">
                                        <input
                                            type="radio"
                                            name={day.toLowerCase()}
                                            value="1"
                                            id={`${day}Rest`}
                                            checked={editValues[day.toLowerCase()] === '1'}
                                            onChange={(e) =>
                                                setEditValues(prev => ({
                                                    ...prev,
                                                    [day.toLowerCase()]: e.target.value
                                                }))
                                            }
                                        />
                                        <label htmlFor={`${day}Rest`}></label>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {props.selectedCompanyId && (
                    <div className="p-input__area u-tAlign--center">
                        <input
                            type="checkbox"
                            id="allUser"
                            name="allUser"
                            className="u-mr8"
                            onChange={handleAllUserChange}
                        />
                        <label htmlFor="allUser">全てのスタッフに反映させる</label>
                    </div>
                )}
                <div className="p-input__area">
                    <button
                        className="c-btn c-btn__green c-btn__bgGreen c-btn__small position-center"
                        onClick={handleUpdate}
                    >
                        更新
                    </button>
                </div>
            </div>
        </div>
    );
};

export default HolidayEditModal;
