// /resources/js/Components/Form/RadioFieldGroup.tsx

const RadioFieldGroup = ({ label, options, name, value, onChange, error }) => {
  return (
    <div className="p-input__area">
      <label className="c-label">{label}</label>
      {options.map((option) => (
        <div className="c-input__radio u-mt4" key={option.value}>
          <input
            id={`${name}_${option.value}`}
            type="radio"
            name={name}
            value={option.value}
            checked={value === option.value}
            onChange={(e) => onChange(Number(e.target.value))}
          />
          <label htmlFor={`${name}_${option.value}`}>{option.label}</label>
        </div>
      ))}
      {error && <p className="p-input__error">{error}</p>}
    </div>
  );
};

export default RadioFieldGroup;
