import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getEvaluationTableDetailsByType, useUpdateEvaluationDetails } from "../../../Queries/EvaluationTableQuery";
import { useUpdateEvaluationTableVisibility, useCompanyDetail } from "../../../Queries/CompanyQuery";
import LoaderComponent from "../../../Components/Layout/Loader";
import { toast } from "react-toastify";

const HrElementsEdit = (): JSX.Element => {
  const params = useParams();
  const occupation = params.occupation;
  const navigate = useNavigate();

  /* データ */
  const [details, setDetails] = useState([]);
  const [initialDetails, setInitialDetails] = useState([]);
  const [isActive, setIsActive] = useState(false); // 初期状態で非表示に設定
  const [loading, setLoading] = useState(true); // ローディング状態管理

  // `useCompanyDetail`フックで会社詳細情報を取得
  const { data: companyDetail, isSuccess } = useCompanyDetail();

  // 更新フックの取得
  const { mutateAsync: updateEvaluation } = useUpdateEvaluationDetails();
  const { mutateAsync: updateVisibility } = useUpdateEvaluationTableVisibility();

  // 初期データ取得
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // 考課表の詳細データを取得
        const fetchedDetails = await getEvaluationTableDetailsByType(occupation);
        setDetails(fetchedDetails);
        setInitialDetails([...fetchedDetails]);

        // 会社詳細情報からis_show_evaluation_tableの値を反映
        if (isSuccess && companyDetail && typeof companyDetail.company.is_show_evaluation_table !== "undefined") {
          setIsActive(companyDetail.company.is_show_evaluation_table === true); // trueなら公開
        }
      } catch (error) {
        console.error("データ取得エラー:", error);
        toast.error("データの取得に失敗しました。");
      } finally {
        setLoading(false); // ローディングを終了
      }
    };

    fetchInitialData();
  }, [occupation, isSuccess, companyDetail]);

  // トグルボタンのクリック処理
  const handleToggleClick = () => {
    setIsActive(!isActive);
  };

  const handleEvaluationUpdateClick = async (e) => {
    e.preventDefault();

    try {
      // 差分だけを取得して更新する
      const updates = details
        .map((item) => {
          return item.detail.map((detail, index) => {
            const initialDetail = initialDetails.find((i) => i.id === item.id)?.detail[index];
            if (!initialDetail || JSON.stringify(detail) !== JSON.stringify(initialDetail)) {
              return {
                id: detail.id,
                score_point: detail.level,
                evaluation_item_id: item.id,
                detail: detail.detail,
              };
            }
            return null;
          });
        })
        .flat()
        .filter((update) => update !== null);

      // 公開の有無も差分として検知
      const visibilityChanged = companyDetail.company.is_show_evaluation_table !== isActive;

      if (updates.length > 0 || visibilityChanged) {
        // 更新処理
        if (updates.length > 0) {
          await updateEvaluation(updates); // 考課素点要素の更新
        }
        if (visibilityChanged) {
          await updateVisibility(isActive); // 公開状態の更新
        }
        toast.success("データが更新されました！");
        navigate("/hc-user/hr/elements");
      } else {
        toast.info("変更がありません。");
      }
    } catch (error) {
      console.error(error);
      toast.error("更新中にエラーが発生しました。");
    }
  };

  // ローディング中であればLoaderComponentを表示
  if (loading) return <LoaderComponent />;

  return (
    <>
      <div className="l-board l-board__edit">
        <div className="l-board__inner">
          <div className="l-board__wrap p-board__btn l-flex space-between">
            <div className="p-board__btn--area l-flex">
              <div className="p-board__btn--input">
                <div className="p-toggle__wrap">
                  <p className="p-toggle__txt">
                    考課素点要素の公開の有無
                    <br />
                    <span className="p-toggle__sub">緑が表示です。</span>
                  </p>
                  <div className={`p-toggle__switch ${isActive ? "is_active" : ""}`} onClick={handleToggleClick}>
                    <div className={`p-toggle__switch--btn ${isActive ? "is_active" : ""}`}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="l-board__wrap p-board__main scroll-x">
            <div className="p-board__main--box p-input__area">
              <table className="p-table__elements">
                <thead>
                  <tr>
                    <th className="sticky"></th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                  </tr>
                </thead>
                <tbody>
                  {details.map((item, itemIndex) => (
                    <tr key={item.id}>
                      <th className="sticky">{item.name}</th>
                      {item.detail.map((detail, index) => (
                        <td key={index}>
                          <textarea
                            className="c-input__textarea u-bgGray u-bGray"
                            value={detail.detail || ""}
                            onChange={(e) =>
                              handleTextareaChange(itemIndex, detail.level, e)
                            }
                          ></textarea>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="l-button">
        <button className="c-btn c-btn__bgGreen c-btn__submit" onClick={handleEvaluationUpdateClick}>
          考課素点要素を更新
        </button>
      </div>
    </>
  );
};

export default HrElementsEdit;
