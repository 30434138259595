import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

/* コンポーネント */
import AttendanceClock from "./AttendanceClock";
import AttendanceReport from "./AttendanceReport";
import AttendanceHoliday from "./AttendanceHoliday";

const AttendanceBoard = (): JSX.Element => {
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  // URLを参照してURL
  useEffect(() => {
    switch (location.pathname) {
      case "/hc-user/attendance/detail":
        setActiveTab(1);
        break;
      case "/hc-user/attendance/report":
        setActiveTab(2);
        break;
      case "/hc-user/attendance/holiday":
        setActiveTab(3);
        break;
      default:
        break;
    }
  }, [location.pathname]);

  // タブ機能
  const handleTabClick = (tabNum) => {
    setActiveTab(tabNum);
    switch (tabNum) {
      case 1:
        navigate("/hc-user/attendance/detail");
        break;
      case 2:
        navigate("/hc-user/attendance/report");
        break;
      case 3:
        navigate("/hc-user/attendance/holiday");
        break;
      default:
        break;
    }
  };

  return (
    <div className="l-board">
      <div className="l-board__inner">
        <div className="p-board__tab">
          <li
            id="tab1"
            className={`p-tab__btn ${activeTab === 1 ? "is_active" : ""}`}
            onClick={() => handleTabClick(1)}
          >
            詳細
          </li>
          <li
            id="tab2"
            className={`p-tab__btn ${activeTab === 2 ? "is_active" : ""}`}
            onClick={() => handleTabClick(2)}
          >
            レポート
          </li>
          <li
            id="tab3"
            className={`p-tab__btn ${activeTab === 3 ? "is_active" : ""}`}
            onClick={() => handleTabClick(3)}
          >
            休日設定
          </li>
        </div>

        <div
          id="panel1"
          className={`p-tab__panel ${activeTab === 1 ? "is_active" : ""}`}
        >
          <AttendanceClock />
        </div>
        <div
          id="panel2"
          className={`p-tab__panel ${activeTab === 2 ? "is_active" : ""}`}
        >
          <AttendanceReport />
        </div>
        <div
          id="panel3"
          className={`p-tab__panel ${activeTab === 3 ? "is_active" : ""}`}
        >
          <AttendanceHoliday />
        </div>
      </div>
    </div>
  );
};
export default AttendanceBoard;
