import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchCurriculumProgress, useDeleteCurriculumProgress } from '../../../Queries/CurriculumProgressQuery';
import LoaderComponent from '../../../Components/Layout/Loader';
import CurriculumProgressAddModal from '../../../Components/Modal/CurriculumProgressAddModal';
import ConfirmDeleteModal from '../../../Components/Modal/ConfirmDeleteModal';
import { formatDate } from '../../../Utils/formatUtils';
import { useAuthority } from '../../../Contexts/AuthorityContext';

const EducationCurriculumResult = (): JSX.Element => {
    const { authority } = useAuthority();
    /* ページ内データ */
    const { userId, educationCurriculumId } = useParams<{ userId: string, educationCurriculumId: string }>();
    const { data: curriculumProgress, isLoading, isError } = useFetchCurriculumProgress(Number(userId), Number(educationCurriculumId));
    const deleteCurriculumProgress = useDeleteCurriculumProgress();
    /* モーダル */
    // 追加
    const [ isAddModal, setIsAddModal ] = useState(false);
    const handleAddModalOpen = () => {
        setIsAddModal(true);
    };
    const handleAddModalClose = () => {
        setIsAddModal(false);
    };
    // 削除
    const [ isDeleteModal, setIsDeleteModal ] = useState(false);
    const handleDeleteModalOpen = () => {
        setIsDeleteModal(true);
    };
    const handleDeleteModalClose = () => {
        setIsDeleteModal(false);
    };

    if(isLoading) return <LoaderComponent />

    return (
        <>
            <CurriculumProgressAddModal
                isOpen={isAddModal}
                handleClose={handleAddModalClose}
                educationCurriculumId={educationCurriculumId}
                userId={userId}
            />
            <ConfirmDeleteModal
                title="進捗状況削除"
                isOpen={isDeleteModal}
                handleClose={handleDeleteModalClose}
                selectedId={curriculumProgress?.id}
                deleteMutation={deleteCurriculumProgress}
            />
            {/* <EditModal
                isOpen={isEditModal}
                handleClose={handleEditModalClose}
                editData={curriculumProgress}
                // updateMutation={updateCompany}
            /> */}
            { (authority === '0' || authority === '1') && (
                <div className="l-board__wrap p-board__btn no-border">

                    {curriculumProgress && curriculumProgress.completion_date ? (
                        <>
                            {/* 削除ボタン */}
                            <div className="l-board__btn--box">
                                <button
                                    className="c-btn c-btn__red p-board__btn--item"
                                    onClick={handleDeleteModalOpen}
                                >修了取消</button>
                            </div>
                        </>
                    ) : (
                        <div className="l-board__btn--box">
                            {/* 登録ボタン */}
                            <button
                                className="c-btn c-btn__blue p-board__btn--item"
                                onClick={handleAddModalOpen}
                            >修了登録</button>
                        </div>
                    )}
                </div>
            )}
            <div className="l-board__wrap p-board__main">
                <table className="p-table p-table__detail">
                    <tbody>
                        <tr>
                            <td className="headline">受講者</td>
                            <td>{curriculumProgress?.student_name}</td>
                        </tr>
                        <tr>
                            <td className="headline">終了日</td>
                            {/* <td>{curriculumProgress?.completion_date}</td> */}
                            <td>{curriculumProgress?.completion_date ? formatDate(curriculumProgress.completion_date) : ''}</td>
                        </tr>
                        <tr>
                            <td className="headline">累計教育時間</td>
                            <td>{curriculumProgress?.curriculum_time}</td>
                        </tr>
                        <tr>
                            <td className="headline">カリキュラム名</td>
                            <td>{curriculumProgress?.curriculum_name}</td>
                        </tr>
                        <tr>
                            <td className="headline">教育担当者</td>
                            <td>{curriculumProgress?.educator_name}</td>
                        </tr>
                        <tr>
                            <td className="headline">教育記録</td>
                            <td>{curriculumProgress?.curriculum_record}</td>
                        </tr>
                        <tr>
                            <td className="headline">記入者</td>
                            <td>{curriculumProgress?.enter_name}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};
export default EducationCurriculumResult;
