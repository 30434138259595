import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import InputTextField from "../Form/InputTextField";
import InputSubField from "../Form/InputSubField";
import TimeSelectBox from "../Form/TimeSelectBox";
import RadioFieldGroup from "../Form/RadioFieldGroup";
import isEqual from "lodash/isEqual";

const CompanyFormModal = ({ isOpen, handleClose, editData, handleUpdate }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...editData.company,
      breakHour: editData.company.breakAt
        ? editData.company.breakAt.split(":")[0]
        : "",
      breakMinute: editData.company.breakAt
        ? editData.company.breakAt.split(":")[1]
        : "",
      startHour: editData.company.startAt
        ? editData.company.startAt.split(":")[0]
        : "",
      startMinute: editData.company.startAt
        ? editData.company.startAt.split(":")[1]
        : "",
      finishHour: editData.company.finishAt
        ? editData.company.finishAt.split(":")[0]
        : "",
      finishMinute: editData.company.finishAt
        ? editData.company.finishAt.split(":")[1]
        : "",
      evaluationMonth: String(editData.evaluationRule.evaluationMonth),
      evaluationPeriod: String(editData.evaluationRule.evaluationPeriod),
    },
  });

  // 現在のフォームデータを監視
  const onSubmit = (data) => {
    // フォームの値を監視して、変更されたかどうかをチェックします。
    const isBreakAtChanged = watch("breakAt") !== editData.company.breakAt;
    const isStartAtChanged = watch("startAt") !== editData.company.startAt;
    const isFinishAtChanged = watch("finishAt") !== editData.company.finishAt;

    // 変更があった場合は秒を追加し、なければそのまま使用します。
    const formattedBreakAt = isBreakAtChanged
      ? `${data.breakAt}:00`
      : data.breakAt;
    const formattedStartAt = isStartAtChanged
      ? `${data.startAt}:00`
      : data.startAt;
    const formattedFinishAt = isFinishAtChanged
      ? `${data.finishAt}:00`
      : data.finishAt;

    const formattedData = {
      ...data,
      id: editData.company.id,
      breakAt: formattedBreakAt,
      startAt: formattedStartAt,
      finishAt: formattedFinishAt,
    };

    // 不要なプロパティを除外
    delete formattedData.evaluationMonth;
    delete formattedData.evaluationPeriod;
    delete formattedData.breakHour;
    delete formattedData.breakMinute;
    delete formattedData.startHour;
    delete formattedData.startMinute;
    delete formattedData.finishHour;
    delete formattedData.finishMinute;

    // 差分がある場合、フォーマット変換を実施
    const evaluationRuleData = {
      id: editData.evaluationRule.id,
      evaluationMonth: data.evaluationMonth,
      evaluationPeriod: data.evaluationPeriod,
    };

    // 差分検出
    const hasCompanyChanges = !isEqual(editData.company, formattedData);
    const hasEvaluationRuleChanges = !isEqual(
      editData.evaluationRule,
      evaluationRuleData
    );
    console.log(JSON.stringify(formattedData, null, 2));
    // console.log(JSON.stringify(editData.evaluationRule, null, 2));
    // console.log(hasCompanyChanges);
    // console.log(hasEvaluationRuleChanges);

    if (!hasCompanyChanges && !hasEvaluationRuleChanges) {
      toast.error("更新データがありません。");
      return;
    }

    // 更新処理
    handleUpdate({
      companyData: formattedData,
      evaluationRuleData,
    });
  };

  if (!isOpen) return null;

  return (
    <div className="p-modal" onClick={handleClose}>
      <div className="p-modal__content" onClick={(e) => e.stopPropagation()}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <button className="p-modal__delete" onClick={handleClose}>
            <img src={"/image/icon_btn_batsu_green.png"} />
          </button>
          <h3 className="p-modal__headline">会社情報を編集</h3>

          {/* InputTextField と InputSubField コンポーネントの使用例 */}
          <InputTextField
            label="会社名"
            {...register("companyName", { required: "会社名は必須です" })}
            error={errors.companyName?.message}
          />
          <InputTextField
            label="会社名(かな)"
            {...register("companyNameKana")}
          />
          <InputTextField label="代表者役職" {...register("position")} />

          {/* 代表者 */}
          <div className="p-input__area">
            <label className="c-label">代表者</label>
            <div className="l-flex">
              <InputSubField
                label="姓"
                type="text"
                {...register("topLastName", { required: "姓は必須です" })}
              />
              <InputSubField
                label="名"
                type="text"
                {...register("topFirstName", { required: "名は必須です" })}
              />
            </div>
            <div className="l-flex u-mt4">
              <InputSubField
                label="せい"
                type="text"
                {...register("topLastNameKana", { required: "せいは必須です" })}
              />
              <InputSubField
                label="な"
                type="text"
                {...register("topFirstNameKana", { required: "なは必須です" })}
              />
            </div>
          </div>

          <InputTextField
            label="連絡先電話番号"
            type="tel"
            {...register("contactTel", {
              required: "連絡先電話番号は必須です",
              maxLength: {
                value: 12,
                message: "電話番号は12文字以下で入力してください",
              },
            })}
          />

          <InputTextField
            label="郵便番号(ハイフン不要)"
            type="text"
            {...register("postal", {
              required: "郵便番号は必須です",
              pattern: {
                value: /^[0-9]{7}$/,
                message: "有効な郵便番号を入力してください",
              },
            })}
          />

          <InputTextField
            label="住所"
            type="text"
            {...register("address", { required: "住所は必須です" })}
          />

          <InputTextField
            label="会社電話番号"
            type="tel"
            {...register("companyTel", {
              maxLength: {
                value: 12,
                message: "電話番号は12文字以下で入力してください",
              },
            })}
          />

          {/* 勤務時間 */}
          <div className="p-input__area">
            <label className="c-label">勤務時間</label>
            <div className="l-flex">
              <Controller
                control={control}
                name="startAt"
                rules={{ required: "始業時間は必須です" }}
                render={({ field }) => (
                  <TimeSelectBox
                    label="始業時間"
                    hourValue={field.value ? field.value.split(":")[0] : ""}
                    minuteValue={field.value ? field.value.split(":")[1] : ""}
                    setHour={(hour) =>
                      field.onChange(
                        `${hour}:${
                          field.value ? field.value.split(":")[1] : ""
                        }`
                      )
                    }
                    setMinute={(minute) =>
                      field.onChange(
                        `${
                          field.value ? field.value.split(":")[0] : ""
                        }:${minute}`
                      )
                    }
                  />
                )}
              />

              <Controller
                control={control}
                name="finishAt"
                rules={{ required: "終業時間は必須です" }}
                render={({ field }) => (
                  <TimeSelectBox
                    label="終業時間"
                    hourValue={field.value ? field.value.split(":")[0] : ""}
                    minuteValue={field.value ? field.value.split(":")[1] : ""}
                    setHour={(hour) =>
                      field.onChange(
                        `${hour}:${
                          field.value ? field.value.split(":")[1] : ""
                        }`
                      )
                    }
                    setMinute={(minute) =>
                      field.onChange(
                        `${
                          field.value ? field.value.split(":")[0] : ""
                        }:${minute}`
                      )
                    }
                  />
                )}
              />
            </div>
          </div>

          {/* 賃金締切日 */}
          <div className="p-input__area">
            <label className="c-label">賃金締切日</label>
            <div className="u-mt4">
              <Controller
                control={control}
                name="closingDay"
                rules={{ required: "賃金締切日は必須です" }}
                render={({ field }) => (
                  <select
                    className="c-input c-input__time form-control"
                    {...field}
                  >
                    {Array.from({ length: 32 }, (_, i) => (
                      <option key={i} value={i}>
                        {i === 0 ? "月末" : `${i}日`}
                      </option>
                    ))}
                  </select>
                )}
              />
            </div>
          </div>

          {/* 法定労働時間 */}
          <Controller
            control={control}
            name="legalWorkingHour"
            rules={{ required: "法定労働時間は必須です" }}
            render={({ field }) => (
              <RadioFieldGroup
                label="法定労働時間を選択"
                options={[
                  { value: 40, label: "40時間" },
                  { value: 44, label: "44時間" },
                ]}
                name="legal_working_hour"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />

          {/* 休憩時間 */}
          <div className="p-input__area">
            <label className="c-label">休憩時間</label>
            <div className="l-flex">
              <Controller
                name="breakAt"
                control={control}
                render={({ field }) => {
                  // 時間と分を分割（秒は無視）
                  const [hour, minute] = field.value
                    ? field.value.split(":")
                    : ["01", "00"];

                  return (
                    <TimeSelectBox
                      hourValue={hour}
                      minuteValue={minute}
                      setHour={(newHour) => {
                        // "時" の値を更新
                        field.onChange(`${newHour.padStart(2, "0")}:${minute}`);
                      }}
                      setMinute={(newMinute) => {
                        // "分" の値を更新
                        field.onChange(`${hour}:${newMinute.padStart(2, "0")}`);
                      }}
                    />
                  );
                }}
              />
            </div>
          </div>

          {/* 評価ルール */}
          <div className="p-input__area">
            <label className="c-label">評価ルール</label>
            <div className="l-flex">
              <div className="c-box__two">
                <label className="c-label c-label__sub">評価開始月</label>
                <Controller
                  name="evaluationMonth"
                  control={control}
                  render={({ field }) => (
                    <select className="c-input u-mt4" {...field} required>
                      {[...Array(12)].map((_, i) => {
                        const value = (i + 1).toString();
                        return (
                          <option key={i} value={value}>
                            {value + "月"}
                          </option>
                        );
                      })}
                    </select>
                  )}
                />
              </div>
              <div className="c-box__two">
                <label className="c-label c-label__sub">評価頻度</label>
                <Controller
                  name="evaluationPeriod"
                  control={control}
                  render={({ field }) => (
                    <select className="c-input u-mt4" {...field} required>
                      <option value="6">6ヶ月毎</option>
                      <option value="12">12ヶ月毎</option>
                    </select>
                  )}
                />
              </div>
            </div>
          </div>

          {/* 更新ボタン */}
          <div className="p-input__area">
            <button
              className="c-btn c-btn__green c-btn__bgGreen c-btn__small position-center"
              type="submit"
            >
              更新
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default CompanyFormModal;
