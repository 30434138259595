import { AxiosError } from 'axios';
import * as api from "../Api/RoleAPI"
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from "react-toastify";

const useRoleList = () => {
    return useQuery('roleList', () => api.getRoleList())
}

const useCreateRole = () => {
    const queryClient = useQueryClient();
    return useMutation(api.createRole, {
        onSuccess: () => {
            queryClient.invalidateQueries('roles');
            toast.success('役割の登録に成功しました。');
        },
        onError: (error: AxiosError) => {
            if (error.response?.data.errors) {
                Object.values(error.response?.data.errors).forEach((messages: any) => {
                    messages.forEach((message: string) => {
                        toast.error(message)
                    })
                })
            } else {
                toast.error('役割の登録に失敗しました。');
            }
        }
    })
}

const useUpdateRole = () => {
    const queryClient = useQueryClient();
    return useMutation(api.updateRole, {
        onSuccess: () => {
            queryClient.invalidateQueries('roles');
            toast.success('役割情報の更新に成功しました。');
        },
        onError: (error: AxiosError) => {
            if (error.response?.data.errors) {
                Object.values(error.response?.data.errors).forEach((messages: any) => {
                    messages.forEach((message: string) => {
                        toast.error(message)
                    })
                })
            } else {
                toast.error('役割情報の更新に失敗しました。');
            }
        }
    })
}

const useDeleteRole = () => {
    const queryClient = useQueryClient();
    return useMutation(api.deleteRole, {
        onSuccess: () => {
            queryClient.invalidateQueries('roles');
            toast.success('役割を削除しました。');
        },
        onError: (error: AxiosError) => {
            if (error.response?.data.errors) {
                Object.values(error.response?.data.errors).forEach((messages: any) => {
                    messages.forEach((message: string) => {
                        toast.error(message)
                    })
                })
            } else {
                toast.error('役割の削除に失敗しました。');
            }
        }
    })
}

export {
    useRoleList,
    useCreateRole,
    useUpdateRole,
    useDeleteRole
}
