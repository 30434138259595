import axios, { AxiosResponse } from "axios";
import { EducationCurriculum } from "../Types/EducationCurriculum";
import { convertKeysToCamelCase } from "../Utils/convertToCamelCase";

const BASE_URL = "/api/users/education-curriculum";

// カリキュラム一覧
export const fetchUserCurriculums = async () => {
    const response = await axios.get(`${BASE_URL}/list`);
    return response.data.data;
};

// カリキュラム詳細
export const getEducationCurriculumDetail = async (educationCurriculumId: number): Promise<EducationCurriculum> => {
    const response = await axios.get(`${BASE_URL}/detail/${educationCurriculumId}`);
    return convertKeysToCamelCase(response.data.data);
};

// カリキュラム追加
export const addEducationCurriculum = async (data: EducationCurriculum): Promise<EducationCurriculum> => {
    const response: AxiosResponse<EducationCurriculum> = await axios.post(`${BASE_URL}/add`, data);
    return response.data;
};

// 更新
export const updateEducationCurriculum = async ({ id, ...curriculumData }: EducationCurriculum & { id: number }) => {
    const { data } = await axios.put<EducationCurriculum>(
        `/api/users/education-curriculum/${id}`, curriculumData
    )
    return data;
}

export const deleteEducationCurriculum = async (id: number) => {
    const { data } = await axios.delete<EducationCurriculum>(
        `/api/users/education-curriculum/${id}`
    )
    return data
}
