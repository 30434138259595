// UserCompanyRoutes.jsx
import React from 'react';
import { Route } from 'react-router-dom';
import CompanyBoard from '../Pages/User/Company/CompanyBoard.tsx';
import EmploymentStatusList from '../Pages/User/EmploymentStatus/EmploymentStatusList.tsx';
import OccupationList from '../Pages/User/Occupation/OccupationList.tsx';
import RankList from '../Pages/User/Rank/RankList.tsx';
import RoleList from '../Pages/User/Role/RoleList.tsx';
import EvaluationItemList from '../Pages/User/EvaluationItem/EvaluationItemList.tsx';

export const companyRoutes = [
    {
        path: '/hc-user/company/detail',
        element: <CompanyBoard />
    },
    {
        path: '/hc-user/company/master',
        element: <CompanyBoard />
    },
    {
        path: '/hc-user/company/rule',
        element: <CompanyBoard />
    },
    {
        path: '/hc-user/company/employment/',
        element: <EmploymentStatusList />
    },
    {
        path: '/hc-user/company/occupation/',
        element: <OccupationList />
    },
    {
        path: '/hc-user/company/rank/',
        element: <RankList />
    },
    {
        path: '/hc-user/company/role/',
        element: <RoleList />
    },
    {
        path: '/hc-user/company/evaluation/',
        element: <EvaluationItemList />
    }
];
