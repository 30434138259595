import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getCurriculumProgress,
  addCurriculumProgress,
  deleteCurriculumProgress,
} from "../Api/CurriculumProgressAPI";
import { toast } from "react-toastify";
import { CurriculumProgress } from "../Types/CurriculumProgress";

export const useFetchCurriculumProgress = (
  userId: number,
  educationCurriculumId: number
) => {
  return useQuery(
    ["curriculumProgress", userId, educationCurriculumId],
    () => getCurriculumProgress(userId, educationCurriculumId),
    {
      onSuccess: () => {
        // 成功時の処理
      },
      onError: (error: any) => {
        // 404 エラーが発生した場合、エラーを無視して処理を続行
        if (error.response && error.response.status === 404) {
          return;
        }
        // その他のエラーの場合、エラーメッセージを表示
        toast.error(`エラーメッセージ: ${error.message}`);
      },
    }
  );
};

export const useAddCurriculumProgress = () => {
  return useMutation(
    (data: Omit<CurriculumProgress, "id" | "created_at" | "updated_at">) =>
      addCurriculumProgress(data),
    {
      onError: () => {
        toast.error("カリキュラムの終了ができませんでした。");
      },
      onSuccess: () => {
        // onSuccessオプションを追加
        toast.success("カリキュラムを終了しました。");
      },
    }
  );
};

export const useDeleteCurriculumProgress = () => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => deleteCurriculumProgress(id), {
    onSuccess: () => {
      // この'curriculumProgress'は適切なクエリキーに置き換える必要があります。
      // queryClient.invalidateQueries('curriculumProgress');
      toast.success("カリキュラム進行状況を削除しました。");
    },
    onError: () => {
      toast.error("カリキュラムの進行状況を削除できませんでした。");
    },
  });
};
