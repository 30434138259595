import axiosInstance from "./axiosInstance";
import axios, { AxiosResponse } from "axios";
import { UserWork } from "../Types/UserWork";

// URLにuserIdをクエリパラメータとして追加
export const getAttendanceStatus = async (userId?: string): Promise<string> => {
  const url = userId
    ? `/api/users/user-work/check/${userId}`
    : "/api/users/user-work/check";
  const response: AxiosResponse<{ attendanceStatus: string }> = await axios.get(
    url
  );
  return response.data.attendanceStatus;
};

// 労働開始
export const startWorkSession = async (userId?: string): Promise<string> => {
  const url = userId
    ? `/api/users/user-work/work-start/${userId}`
    : "/api/users/user-work/work-start";
  const response: AxiosResponse<{ message: string }> = await axios.post(url);
  return response.data.message;
};

// 労働終了
export const finishWorkSession = async (userId?: string): Promise<string> => {
  const url = userId
    ? `/api/users/user-work/work-finish/${userId}`
    : "/api/users/user-work/work-finish";
  const response: AxiosResponse<{ message: string }> = await axios.post(url);
  return response.data.message;
};

// 休憩開始
export const startBreakSession = async (
  userId?: string
): Promise<{ message: string }> => {
  const url = userId
    ? `/api/users/user-work/break-start/${userId}`
    : "/api/users/user-work/break-start";
  const response: AxiosResponse<{ message: string }> = await axios.post(url);
  return response.data;
};

// 休憩終了
export const endBreakSession = async (
  userId?: string
): Promise<{ message: string; attendance_status: string }> => {
  const url = userId
    ? `/api/users/user-work/break-finish/${userId}`
    : "/api/users/user-work/break-finish";
  const response: AxiosResponse<{
    message: string;
    attendance_status: string;
  }> = await axios.post(url);
  return response.data;
};

// 出勤日数
export const fetchAttendanceCount = async (
  userId: number,
  yearMonth: string
) => {
  const { data } = await axios.get(
    `/api/users/user-work/attendance-count/${userId}/${yearMonth}`
  );
  return data;
};

// 出勤データ
export const fetchAttendanceData = async (
  userId: number,
  yearMonth: string
): Promise<{ date: string; attendanceData: UserWork }[]> => {
  const response: AxiosResponse<{ date: string; attendanceData: UserWork }[]> =
    await axios.get(
      `/api/users/user-work/attendance-data/${userId}/${yearMonth}`
    );
  return response.data.data;
};

// 勤怠_ユーザーの実働時間
export const fetchActualWorkHours = async (
  userId: number,
  yearMonth: string
): Promise<any> => {
  const response = await axios.get(
    `/api/users/user-work/actual-work-hours/${userId}/${yearMonth}`
  );
  return response.data;
};

// 該当ユーザーの該当期間の実動時間
export const fetchTotalWorkHours = async (
  userId: number,
  yearMonth: string
): Promise<any> => {
  const response = await axios.get(
    `/api/users/user-work/late-night-hours/${userId}/${yearMonth}`
  );
  return response.data;
};
// 該当ユーザーの該当期間の残業時間
export const fetchOvertimeHours = async (
  userId: number,
  yearMonth: string
): Promise<any> => {
  const response = await axios.get(
    `/api/users/user-work/overtime-hours/${userId}/${yearMonth}`
  );
  return response.data;
};
// 該当ユーザーの該当期間の深夜労働時間
export const fetchLateNightHours = async (
  userId: number,
  yearMonth: string
): Promise<any> => {
  const response = await axios.get(
    `/api/users/user-work/late-night-hours/${userId}/${yearMonth}`
  );
  return response.data;
};

// 勤怠_ユーザーの休日数
export const fetchHolidayCount = async (
  userId: number,
  yearMonth: string
): Promise<any> => {
  const response = await axios.get(
    `/api/users/user-work/holiday-count/${userId}/${yearMonth}`
  );
  return response.data;
};

// 勤怠_ユーザーの休日出勤数
export const fetchHolidayAttendanceCount = async (
  userId: number,
  yearMonth: string
): Promise<any> => {
  const response = await axios.get(
    `/api/users/user-work/holiday-attendance-count/${userId}/${yearMonth}`
  );
  return response.data;
};

// 出勤データ編集ページデータ取得
export const fetchEditableAttendanceData = async (
  userId: number,
  yearMonth: string
): Promise<UserWork[]> => {
  const response: AxiosResponse<UserWork[]> = await axios.get(
    `/api/users/user-work/attendance-data/edit/${userId}/${yearMonth}`
  );
  return response.data;
};

// 出勤データ更新
export const updateAttendanceData = async ({
  updatedData,
}: {
  updatedData: UserWork[];
}): Promise<any> => {
  const response = await axiosInstance.put(
    "/api/users/user-work/attendance-data/update",
    {
      updatedData,
    }
  );
  return response.data;
};
