import React, { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AttendanceButton from './components/AttendanceButton';
import { useAttendanceStatus, useStartWorkSession, useFinishWorkSession, useStartBreakSession, useEndBreakSession } from "../../../Queries/UserWorkQuery";

import LoaderComponent from '../../../Components/Layout/Loader';;
import { Audio } from 'react-loader-spinner'

const AttendanceClock = (): JSX.Element => {
    /* 画面系 */
    const [isLoading, setIsLoading] = useState(true);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [workDone, setWorkDone] = useState('');

    /* 勤怠ボタンの状態 */
    const [isAttendanceActive, setAttendanceActive] = useState('');  // 出勤ボタンの状態
    const [isLeaveActive, setLeaveActive] = useState('');  // 退勤ボタンの状態
    const [isBreakStartActive, setBreakStartActive] = useState('');  // 休憩入りボタンの状態
    const [isBreakEndActive, setBreakEndActive] = useState('');  // 休憩戻りボタンの状態

    /* 勤怠機能 */
    const { mutateAsync: startWork } = useStartWorkSession();
    const { mutateAsync: finishWork } = useFinishWorkSession();
    const { mutateAsync: startBreak } = useStartBreakSession();
    const { mutateAsync: endBreak } = useEndBreakSession();

    /* レポートデータ */
    // 現状
    const { data: checkAttendanceStatus } = useAttendanceStatus();

    /* 勤怠 */
    // 勤怠機能: 出勤
    const handleAttendance = () => startWork();
    // 勤怠機能: 退勤
    const handleLeavingWork = () => finishWork();
    // 勤怠機能: 休憩開始
    const handleBreakStart = () => startBreak();
    // 勤怠機能: 休憩終了
    const handleBreakEnd = () => endBreak();

    // 勤怠ステータスを常時取得
    useEffect(() => {
        setIsLoading(true);
        if (checkAttendanceStatus == 0) {
            setAttendanceActive(true);
            setLeaveActive(false);
            setBreakStartActive(false);
            setBreakEndActive(false);
            setWorkDone(false);
        } else if (checkAttendanceStatus == 1) {
            setAttendanceActive(false);
            setLeaveActive(true);
            setBreakStartActive(true);
            setBreakEndActive(false);
            setWorkDone(false);
        } else if (checkAttendanceStatus == 2) {
            setAttendanceActive(false);
            setLeaveActive(false);
            setBreakStartActive(false);
            setBreakEndActive(true);
            setWorkDone(false);
        } else if (checkAttendanceStatus == 3) {
            setAttendanceActive(false);
            setLeaveActive(true);
            setBreakStartActive(true);
            setBreakEndActive(false);
            setWorkDone(false);
        } else if (checkAttendanceStatus == 4) {
            setAttendanceActive(false);
            setLeaveActive(false);
            setBreakStartActive(false);
            setBreakEndActive(true);
            setWorkDone(false);
        } else if (checkAttendanceStatus == 5) {
            setAttendanceActive(false);
            setLeaveActive(true);
            setBreakStartActive(false);
            setBreakEndActive(false);
            setWorkDone(false);
        } else if (checkAttendanceStatus == 6) {
            setAttendanceActive(false);
            setLeaveActive(false);
            setBreakStartActive(false);
            setBreakEndActive(false);
            setWorkDone(true);
        }
        setIsLoading(false);
    }, [checkAttendanceStatus]);

    // 日付を出力する記述
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // getMonth()は0-11を返す
        const day = date.getDate();
        const weekdays = ["日", "月", "火", "水", "木", "金", "土"];
        const weekday = weekdays[date.getDay()]; // getDay()は0（日曜）-6（土曜）を返す
        return `${year}年${month}月${day}日(${weekday})`;
    }

    // 時間
    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);

    return (
        <>
            {isLoading ? (
                // <div>ローディング中...</div>
                <LoaderComponent />
            ) : (
                <div className="l-board__wrap">
                    <div className="p-attendance__wrap">
                        <div className="p-attendance__clock">
                            <p className="date">{formatDate(currentTime)}</p>
                            <p className="time">{currentTime.toLocaleTimeString()}</p>
                            {workDone && <p className="finish">本日の業務もお疲れ様でした！</p>}
                        </div>
                        <div className="p-attendance__btn">
                            <div className="p-attendance__btn--list">
                                <AttendanceButton
                                    isColor="color-green"
                                    isActive={isAttendanceActive}
                                    onClick={handleAttendance}
                                    label="出勤" />
                                <AttendanceButton
                                    isColor="color-green"
                                    isActive={isLeaveActive}
                                    onClick={handleLeavingWork}
                                    label="退勤" />
                                <AttendanceButton
                                    isColor="color-red"
                                    isActive={isBreakStartActive}
                                    onClick={handleBreakStart}
                                    label="休憩開始" />
                                <AttendanceButton
                                    isColor="color-red"
                                    isActive={isBreakEndActive}
                                    onClick={handleBreakEnd}
                                    label="休憩終了" />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
export default AttendanceClock;
