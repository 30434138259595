import { AxiosError } from 'axios';
import * as api from "../Api/OccupationAPI";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from "react-toastify";

// 一覧
export const useOccupationList = () => {
    return useQuery('occupationList', () => api.getOccupationList())
}

// 更新
export const useUpdateOccupation = () => {
    const queryClient = useQueryClient();
    return useMutation(api.updateOccupation, {
        onSuccess: () => {
            queryClient.invalidateQueries('occupationList');  // <--- ここを修正
            toast.success('更新に成功しました。');
        },
        onError: (error: AxiosError) => {
            if (error.response?.data.errors) {
                Object.values(error.response?.data.errors).forEach((messages: any) => {
                    messages.forEach((message: string) => {
                        toast.error(message)
                    })
                })
            } else {
                toast.error('更新に失敗しました。');
            }
        }
    })
}
