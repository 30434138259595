import React from 'react';
import LoaderComponent from '../../../../Components/Layout/Loader';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";

interface DataItem {
    name: string;
    売上: number;
    目標: number;
}

interface Props {
    data: DataItem[];
    itemName: string;
}


const CustomBarChart: React.FC<Props> = ({ data, itemName }) => {
    if (data === null) {
        return <LoaderComponent />;
    }
    return (
        <BarChart
            width={800}
            height={300}
            data={data}
            margin={{
                top: 35,
                right: 0,
                left: 0,
                bottom: 0
            }}
        >
            <text x={400} y={20} textAnchor="middle" dominantBaseline="middle" fontSize="16" fontWeight="bold">{itemName}</text>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="結果" fill="#1EC9A9" />
            <Bar dataKey="目標" fill="#2D8DBF" />
        </BarChart>
    );
}

export default CustomBarChart;
