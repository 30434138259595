import * as api from "../Api/HolidayAPI"
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from "react-toastify";

export const useHolidays = () => {
    return useQuery('holidays', api.getHolidays, {
        onError: (error) => {
            toast.error(`エラーメッセージ: ${error.message}`);
        }
    });
}

export const useUpdateCompanyHoliday = () => {
    const queryClient = useQueryClient();
    return useMutation(api.updateCompanyHoliday, {
        onSuccess: () => {
            queryClient.invalidateQueries('holidays');
            toast.success('会社の休日情報が更新されました。');
        },
        onError: (error) => {
            toast.error(`エラーメッセージ: ${error.message}`);
        },
    });
}

export const useUpdateUserHoliday = () => {
    const queryClient = useQueryClient();
    return useMutation(api.updateUserHoliday, {
        onSuccess: () => {
            queryClient.invalidateQueries('holidays');
            toast.success('ユーザーの休日情報が更新されました。');
        },
        onError: (error) => {
            toast.error(`エラーメッセージ: ${error.message}`);
        },
    });
}

export const useUpdateAllHoliday = () => {
    const queryClient = useQueryClient();
    return useMutation(api.updateAllHoliday, {
        onSuccess: () => {
            queryClient.invalidateQueries('holidays');
            toast.success('会社・全ユーザーの休日情報が更新されました。');
        },
        onError: (error) => {
            toast.error(`エラーメッセージ: ${error.message}`);
        },
    });
}
