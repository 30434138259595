// フロント
import { Link } from 'react-router-dom';
import { useOccupationList } from '../../../Queries/OccupationQuery';
import LoaderComponent from '../../../Components/Layout/Loader';

const CompanyMaster = (): JSX.Element => {
    const { data: occupations, isLoading } = useOccupationList();
    if(isLoading) return <LoaderComponent />
    return (
        <div className="l-board__wrap p-board__main">
            <ul className="p-list__master">
                <li className="p-list__master--item">
                    <Link to="/hc-user/company/employment">雇用形態</Link>
                </li>
                <li className="p-list__master--item">
                    <Link to="/hc-user/company/occupation">職種</Link>
                </li>
                <li className="p-list__master--item">
                    <Link to="/hc-user/company/rank">職位</Link>
                </li>
                <li className="p-list__master--item">
                    <Link to="/hc-user/company/role">役割</Link>
                </li>
                <li className="p-list__master--item">
                    <Link to="/hc-user/company/evaluation">勤務項目</Link>
                </li>
                {/* <li className="p-list__master--item">
                    <Link to={`/hc-user/company/performance-goal/${occupations[0]?.id}`}>業績目標</Link>
                </li> */}
            </ul>
        </div>
    );
}
export default CompanyMaster;
