// EducationProgress.tsx

import React, { useState, useEffect, useMemo } from "react";
import {
  useEducationCategories,
  useEducationCategoriesWithCurriculum,
} from "../../../Queries/EducationCategoryQuery";
import { useAssistantList } from "../../../Queries/UserQuery";
import EducationCurriculumFormModal from "../../../Components/Modal/EducationCurriculumFormModal";
import { useAddEducationCurriculum } from "../../../Queries/EducationCurriculumQuery";
import { useNavigate } from "react-router-dom";
import LoaderComponent from "../../../Components/Layout/Loader";
import { useAuthority } from "../../../Contexts/AuthorityContext";

interface Assistant {
  id: number;
  is_authority: string;
  last_name: string;
  first_name: string;
  // 他のフィールドも必要に応じて追加
}

interface EducationCurriculum {
  id: number;
  name: string;
  detail: string;
  curriculum_progresses: Array<{
    complete_on: string | null;
  }>;
}

interface EducationCategory {
  id: number;
  name: string;
  education_curriculums: EducationCurriculum[];
  // 他のフィールドも必要に応じて追加
}

const EducationProgress = (): JSX.Element => {
  const { authority, userId, isLoading: authorityLoading } = useAuthority();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // 新規登録用のMutation
  const educationCurriculumMutation = useAddEducationCurriculum(handleCloseModal);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: assistants, status: assistantsStatus } = useAssistantList();
  const { data: educationCategories, status: educationCategoriesStatus } =
    useEducationCategories();
  const [selectedAssistantId, setSelectedAssistantId] = useState<number | null>(null);

  // フィルタリングロジックを useMemo で最適化
  const filteredAssistants = useMemo<Assistant[]>(() => {
    if (!assistants) return [];

    switch (authority) {
      case 0:
        // 管理者: 全てのデータを返す
        console.log("Authority 0: Returning all assistants");
        return assistants;
      case 1:
        // 教育: is_authority が '0' 以外のデータのみ
        console.log("Authority 1: Filtering assistants where is_authority !== '0'");
        return assistants.filter(assistant => assistant.is_authority !== "0");
      case 2:
        // 一般: ログインしている自分のデータのみ
        console.log("Authority 2: Filtering assistants where id === userId");
        return assistants.filter(assistant => assistant.id === userId);
      default:
        // その他: 空配列を返す
        console.log("Authority not recognized: Returning empty array");
        return [];
    }
  }, [assistants, authority, userId]);

  // ログイン不可またはデータがない場合のメッセージ表示
  const noAssistants = useMemo<boolean>(() => {
    if (authority === null || ![0, 1, 2].includes(authority)) {
      return true;
    }
    if (authority === 2 && filteredAssistants.length === 0) {
      return true;
    }
    return false;
  }, [authority, filteredAssistants]);

  useEffect(() => {
    console.log("Authority:", authority);
    console.log("Assistants fetched:", assistants);
    console.log("Filtered assistants:", filteredAssistants);

    if (filteredAssistants && filteredAssistants.length > 0) {
      setSelectedAssistantId(filteredAssistants[0].id);
    } else {
      setSelectedAssistantId(null);
    }
  }, [filteredAssistants, assistants, authority]);

  // カテゴリー一覧処理
  const [categories, setCategories] = useState<EducationCategory[]>([]);
  const categoriesQueryResult =
    useEducationCategoriesWithCurriculum(selectedAssistantId);
  const categoriesData = selectedAssistantId
    ? categoriesQueryResult.data
    : null;
  const categoriesDataStatus = selectedAssistantId
    ? categoriesQueryResult.status
    : "idle";

  // hover時にアイコンの画像を変える
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  // 達成状況の%の計算式
  const calculateProgressPercentage = (curriculums: EducationCurriculum[] | undefined): number => {
    if (!curriculums || curriculums.length === 0) {
      return 0;
    }
    const completedCount = curriculums.filter(
      (curriculum) =>
        curriculum.curriculum_progresses &&
        curriculum.curriculum_progresses.length > 0 &&
        curriculum.curriculum_progresses[0].complete_on !== null
    ).length;
    return Math.round((completedCount / curriculums.length) * 100);
  };

  // ユーザー
  const handleAssistantChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = parseInt(event.target.value, 10);
    setSelectedAssistantId(selectedId);
  };

  useEffect(() => {
    if (categoriesData) {
      setCategories(categoriesData);
    } else {
      setCategories([]);
    }
  }, [categoriesData]);

  // 選択機能
  const [detailShow, setDetailShow] = useState<{ [key: number]: boolean }>({});
  const [rotatedIndices, setRotatedIndices] = useState<{ [key: number]: boolean }>({});

  const handleDetail = (index: number) => {
    setDetailShow((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
    setRotatedIndices((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // ローディング状態の統合
  const isLoading =
    authorityLoading ||
    assistantsStatus === "loading" ||
    educationCategoriesStatus === "loading" ||
    categoriesDataStatus === "loading";

  if (isLoading) return <LoaderComponent />;

  return (
    <>
      <EducationCurriculumFormModal
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        mutationFunction={educationCurriculumMutation}
        educationCategories={educationCategories}
      />
      {noAssistants ? (
        <div className="l-board__wrap p-board__main">
          <p>該当するアシスタントが存在しません。</p>
        </div>
      ) : (
        <>
          <div className="l-board__wrap p-board__btn">
            {/* authorityが2以外の場合にのみセレクトボックスを表示 */}
            {authority !== 2 && (
              <div className="l-flex p-board__btn--area">
                <div className="p-board__btn--input">
                  <select
                    className="c-input c-input__select type-shadow"
                    onChange={handleAssistantChange}
                    value={selectedAssistantId || ""}
                  >
                    {filteredAssistants.map((assistant) => (
                      <option key={assistant.id} value={assistant.id}>
                        {assistant.last_name} {assistant.first_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div className="l-flex p-board__btn--area space-around">
              {authority === 0 && ( // 管理者のみ表示
                <button
                  type="button"
                  className="p-board__btn--item c-btn c-btn__green icon icon-plus pc-only"
                  onClick={() => setIsModalOpen(true)}
                >
                  カリキュラム項目を追加
                </button>
              )}
            </div>
          </div>
          <div className="l-board__wrap p-board__main">
            <div className="p-board__main--box">
              <table className="p-table p-table__more">
                <thead>
                  <tr>
                    <th>カリキュラムカテゴリー</th>
                    <th>達成状況</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {categories &&
                    categories.map((category, index) => (
                      <React.Fragment key={category.id}>
                        <tr
                          className={`more${index} ${
                            detailShow[index] ? "is_show" : ""
                          }`}
                          onClick={() => handleDetail(index)}
                          onMouseEnter={() => setHoveredIndex(index)}
                          onMouseLeave={() => setHoveredIndex(null)}
                        >
                          <td>{category.name}</td>
                          <td>
                            {calculateProgressPercentage(
                              category.education_curriculums
                            )}
                            %
                          </td>
                          <td></td>
                          <td className="u-tAlign--right">
                            <img
                              style={{
                                width: "32px",
                                transform: rotatedIndices[index]
                                  ? "rotate(90deg)"
                                  : "none",
                                transition: "transform 0.2s ease",
                              }}
                              src="/image/icon_circle_arrow_right_green.png"
                              alt="Toggle Details"
                            />
                          </td>
                        </tr>

                        {/* 詳細部分 */}
                        <tr
                          className={`detail-area detail${index} u-bNone cursor-auto ${
                            detailShow[index] ? "is_show" : ""
                          }`}
                        >
                          <td colSpan={4}>
                            <table className="p-table">
                              <thead>
                                <tr className="cursor-auto">
                                  <th>達成状況</th>
                                  <th>カリキュラム項目</th>
                                  <th>詳細</th>
                                </tr>
                              </thead>
                              <tbody className="u-bNone">
                                {category.education_curriculums &&
                                  category.education_curriculums.map(
                                    (curriculum) => (
                                      <tr
                                        key={curriculum.id}
                                        onClick={() =>
                                          navigate(
                                            `/hc-user/education/curriculum/result/${selectedAssistantId}/${curriculum.id}`
                                          )
                                        }
                                      >
                                        <td>
                                          {curriculum.curriculum_progresses &&
                                          curriculum.curriculum_progresses.length > 0 &&
                                          curriculum.curriculum_progresses[0]
                                            .complete_on !== null ? (
                                            <span className="c-tag c-tag__complete">
                                              終了
                                            </span>
                                          ) : (
                                            <span className="c-tag c-tag__incomplete">
                                              未了
                                            </span>
                                          )}
                                        </td>
                                        <td>{curriculum.name}</td>
                                        <td>{curriculum.detail}</td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EducationProgress;
