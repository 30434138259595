import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useUpdateCalculationRules } from '../../Queries/CalculationRuleQuery';

const CalculationRuleEditModal = ({ isOpen, handleClose, rulesData }) => {
    const { control, handleSubmit, setValue, getValues } = useForm();
    const { updateAllRules } = useUpdateCalculationRules(handleClose);

    // ここに periods と rounds を定義
    const periods = [1, 5, 10, 15, 30];
    const rounds = [
        { value: '0', label: '切り捨て' },
        { value: '1', label: '切り上げ' }
    ];

    useEffect(() => {
        rulesData.forEach(rule => {
            setValue(`rules[${rule.target}].period`, rule.period);
            setValue(`rules[${rule.target}].round`, rule.round);
        });
    }, [rulesData, setValue]);

    const getLabel = (target) => {
        switch (target) {
            case '0': return '始業';
            case '1': return '終業';
            case '2': return '休憩開始';
            case '3': return '休憩終了';
            default: return '';
        }
    };

    const onSubmit = async data => {
        const updatedData = Object.entries(data.rules).map(([target, { period, round }]) => ({
            id: rulesData.find(rule => rule.target === target).id,
            period,
            round
        }));
        await updateAllRules(updatedData, rulesData);
        handleClose(); // データ更新後にモーダルを閉じる
    };

    if (!isOpen) return null;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-modal" onClick={handleClose}>
                <div className="p-modal__content" onClick={e => e.stopPropagation()}>
                    <button className="p-modal__delete" onClick={handleClose}>
                        <img src={'/image/icon_btn_batsu_green.png'} alt="Close" />
                    </button>
                    <h3 className="p-modal__headline">労働時間ルール</h3>

                    {rulesData.map((rule) => (
                        <div className="p-input__area" key={rule.target}>
                            <div className="area-label">
                                <label>{getLabel(rule.target)}</label>
                            </div>
                            <div className="area-input">
                                <div className="l-flex">
                                    <div className="c-box__two">
                                        <label htmlFor={`rules[${rule.target}].period`} className="c-label c-label__sub">時間区分</label>
                                        <Controller
                                            name={`rules[${rule.target}].period`}
                                            control={control}
                                            defaultValue={rule.period}
                                            render={({ field }) => (
                                                <select {...field}>
                                                    {periods.map((value) => (
                                                        <option key={value} value={value}>
                                                            {value}分
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        />
                                    </div>
                                    <div className="c-box__two">
                                        <label htmlFor={`rules[${rule.target}].round`} className="c-label c-label__sub">端数の扱い</label>
                                        <Controller
                                            name={`rules[${rule.target}].round`}
                                            control={control}
                                            defaultValue={rule.round}
                                            render={({ field }) => (
                                                <select {...field}>
                                                    {rounds.map(({ value, label }) => (
                                                        <option key={value} value={value}>
                                                            {label}
                                                        </option>
                                                    ))}
                                                </select>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="p-input__area">
                        <button className="c-btn c-btn__green c-btn__bgGreen c-btn__small position-center" type="submit">
                            更新
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default CalculationRuleEditModal;
