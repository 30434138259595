import axios from "axios";
import { PerformanceItem } from "../Types/PerformanceItem"

// 一覧
export const getPerformanceItemList = async () => {
    const { data } = await axios.get<PerformanceItem[]>(
        `/api/users/performance-item/list/`
    );
    return data
}

// 一覧_職種別
export const getPerformanceItemOfOccupationList = async (id: number) => {
    const { data } = await axios.get<PerformanceItem[]>(
        `/api/users/performance-item/list-occupation/${id}`
    );
    return data
}

