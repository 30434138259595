import axios from 'axios';

export const getUserGoalsForYear = async (userId: number, year: string) => {
    const response = await axios.get(`/api/users/performance-user-goal/list/user/${userId}/${year}`);
    return response.data;
}

// 目標取得
export const fetchPerformanceUserGoal = async (userId: number, year: string) => {
    const response = await fetch(`/api/users/performance-user-goal/edit/${userId}/${year}`);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
};

// 更新処理
export const updatePerformanceUserGoals = async (userId: number, differences: any) => {
    const response = await axios.post(`/api/users/performance-user-goal/update/${userId}`, differences);
    return response.data;
};
