import { AxiosError } from 'axios';
import * as api from "../Api/ShopAPI";
import { useQuery, useMutation, useQueryClient, QueryClient } from 'react-query';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

export const useShopList = () => {
    return useQuery('shops', () => api.getShopList(), {
        onError: (error: AxiosError) => {
            toast.error('店舗の取得に失敗しました。運営者にお問い合わせください。');
        }
    });
}

export const useShopsForSelectQuery = () => {
    return useQuery('shopsForSelect', api.fetchShopsForSelect, {
        // staleTime: 1000 * 60 * 5, // オプション: データが古くなるまでの時間（ここでは5分）
        // cacheTime: 1000 * 60 * 30, // オプション: キャッシュされるデータの存続時間（ここでは30分）
        // onError, onSuccess などのその他のオプションを必要に応じて追加
    });
};
export const useShopDetail = (id: number) => {
    return useQuery(['shopDetail', id], () => api.getShopDetail(id));
}

export const useShopEdit = (id: number) => {
    return useQuery(['shopEdit', id], () => api.getShopEdit(id));
}

export const useUpdateShop = (successCallback) => {
    const queryClient = useQueryClient();

    return useMutation(api.updateShop, {
        onSuccess: (data, variables) => {
            queryClient.resetQueries();
            toast.success('更新に成功しました。');
            if (successCallback) {
                successCallback();
            }
        },
        onError: (error) => {
            toast.error('更新に失敗しました。');
            console.error("更新エラー:", error);
        }
    });
};

export const useCreateShop = () => {
    const queryClient = useQueryClient();
    return useMutation(api.createShop, {
        onSuccess: () => {
            queryClient.invalidateQueries('shops');
            toast.success('ショップの登録に成功しました。');
        },
        onError: (error: AxiosError) => {
            if (error.response?.data.errors) {
                Object.values(error.response?.data.errors).forEach((messages: any) => {
                    messages.forEach((message: string) => {
                        toast.error(message)
                    })
                })
            } else {
                toast.error('ショップの登録に失敗しました。');
            }
        }
    })
}

export const useDeleteShop = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate();
    return useMutation(api.deleteShop, {
        onSuccess: () => {
            queryClient.invalidateQueries('shops');
            toast.success('削除に成功しました。');
            navigate('/hc-user/shop/');
        },
        onError: () => {
            toast.error('更新に失敗しました。');
        }
    })
}
