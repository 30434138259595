// ShopPerformanceResultAPI.ts
import axios from "axios";
import { convertKeysToCamelCase } from "../Utils/convertToCamelCase";

/* 取得系（一般） */
export const fetchShopPerformanceResults = async (
  shopId: number,
  year: number
) => {
  try {
    const response = await axios.get(
      `/api/users/shop-performance-result/list/${shopId}/${year}`
    );
    // レスポンスデータのキーをキャメルケースに変換
    return convertKeysToCamelCase(response.data.data);
  } catch (error) {
    console.error("Error fetching shop performance:", error);
    throw error;
  }
};

/* 取得系（編集用） */
export const fetchEditShopPerformanceResults = async (
  shopId: number,
  year: number
) => {
  try {
    const response = await axios.get(
      `/api/users/shop-performance-result/edit/${shopId}/${year}`
    );
    // レスポンスデータのキーをキャメルケースに変換
    return convertKeysToCamelCase(response.data.data);
  } catch (error) {
    console.error("Error fetching shop performance for editing:", error);
    throw error;
  }
};

/* 更新系 */
export const updateShopPerformance = async (shopPerformanceResults: any[]) => {
  const response = await axios.put(
    "/api/users/shop-performance-result/update",
    shopPerformanceResults
  );
  return response.data;
};
