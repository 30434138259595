import axios from "axios";
import { Occupation } from "../Types/Occupation"

// 一覧
export const getOccupationList = async () => {
    const { data } = await axios.get<Occupation[]>(
        '/api/users/occupation/list'
    );
    return data
}

// 更新
export const updateOccupation = async ({id, name}: {id: number, name: string}) => {
    const { data } = await axios.put<Occupation[]>(
        `/api/users/occupation/update/${id}`, { id, name }
    );
    return data
}
