import React, { useState } from 'react';
import { useHolidays } from '../../../Queries/HolidayQuery';
import HolidayEditModal from '../../../Components/Modal/HolidayEditModal';
import LoaderComponent from '../../../Components/Layout/Loader';
import { useAuthority } from '../../../Contexts/AuthorityContext';

const AttendanceHoliday = (): JSX.Element => {
    const { authority } = useAuthority();
    const { data: holidays, isLoading, error } = useHolidays();
    const [ isEditModal, setIsEditModal ] = useState(false);

    // 取得する情報
    const [ selectedCompanyId, setSelectedEditCompanyId ] = useState('');
    const [ selectedUserId, setSelectedEditUserId ] = useState('');
    const [ selectedMonday, setSelectedEditMonday ] = useState('');
    const [ selectedTuesday, setSelectedEditTuesday ] = useState('');
    const [ selectedWednesday, setSelectedEditWednesday ] = useState('');
    const [ selectedThursday, setSelectedEditThursday ] = useState('');
    const [ selectedFriday, setSelectedEditFriday ] = useState('');
    const [ selectedSaturday, setSelectedEditSaturday ] = useState('');
    const [ selectedSunday, setSelectedEditSunday ] = useState('');
    const [ selectedHoliday, setSelectedEditHoliday ] = useState('');

    /* モーダル挙動 */
    // オープン
    const handleEditModalOpen = (companyId: number, userId: number, monday: string, tuesday: string, wednesday: string, thursday: string, friday: string, saturday: string, sunday: string, holiday: string) => {
        // リセット
        setSelectedEditCompanyId('');
        setSelectedEditUserId('');
        setSelectedEditMonday('');
        setSelectedEditTuesday('');
        setSelectedEditWednesday('');
        setSelectedEditThursday('');
        setSelectedEditFriday('');
        setSelectedEditSaturday('');
        setSelectedEditSunday('');
        setSelectedEditHoliday('');
        // 更新
        setTimeout(() => {
            setSelectedEditCompanyId(companyId || '');
            setSelectedEditUserId(userId || '');
            setSelectedEditMonday(monday);
            setSelectedEditTuesday(tuesday);
            setSelectedEditWednesday(wednesday);
            setSelectedEditThursday(thursday);
            setSelectedEditFriday(friday);
            setSelectedEditSaturday(saturday);
            setSelectedEditSunday(sunday);
            setSelectedEditHoliday(holiday);
        }, 0);
        setIsEditModal(true);
    };
    // クローズ
    const handleEditModalClose = () => {
        setIsEditModal(false);
    };

    if (error) {
        return <div>An error occurred</div>;
    }

    return (
        <>
            <HolidayEditModal
                isOpen={isEditModal}
                handleClose={handleEditModalClose}
                selectedCompanyId={selectedCompanyId}
                selectedUserId={selectedUserId}
                selectedMonday={selectedMonday}
                selectedTuesday={selectedTuesday}
                selectedWednesday={selectedWednesday}
                selectedThursday={selectedThursday}
                selectedFriday={selectedFriday}
                selectedSaturday={selectedSaturday}
                selectedSunday={selectedSunday}
                selectedHoliday={selectedHoliday}
            />
            {isLoading ? (
                <LoaderComponent />
            ) : (
                <>
                    <div className="l-board__wrap p-board__main">
                        <div className="p-board__main--box">
                            <table className="p-table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>月</th>
                                        <th>火</th>
                                        <th>水</th>
                                        <th>木</th>
                                        <th>金</th>
                                        <th>土</th>
                                        <th>日</th>
                                        <th>祝</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {holidays.map((holiday, index) => (
                                        <tr
                                            key={index}
                                            onClick={authority == '0' ? () => handleEditModalOpen(holiday.company_id, holiday.user_id, holiday.monday, holiday.tuesday, holiday.wednesday, holiday.thursday, holiday.friday, holiday.saturday, holiday.sunday, holiday.holiday) : undefined}
                                        >
                                            <td>{holiday.user ? `${holiday.user.last_name} ${holiday.user.first_name}` : '会社全体'}</td>
                                            <td className="u-tSmall">{holiday.monday === "0" ? "通常" : holiday.monday === "1" ? "所定休日" : "法定休日"}</td>
                                            <td className="u-tSmall">{holiday.tuesday === "0" ? "通常" : holiday.tuesday === "1" ? "所定休日" : "法定休日"}</td>
                                            <td className="u-tSmall">{holiday.wednesday === "0" ? "通常" : holiday.wednesday === "1" ? "所定休日" : "法定休日"}</td>
                                            <td className="u-tSmall">{holiday.thursday === "0" ? "通常" : holiday.thursday === "1" ? "所定休日" : "法定休日"}</td>
                                            <td className="u-tSmall">{holiday.friday === "0" ? "通常" : holiday.friday === "1" ? "所定休日" : "法定休日"}</td>
                                            <td className="u-tSmall">{holiday.saturday === "0" ? "通常" : holiday.saturday === "1" ? "所定休日" : "法定休日"}</td>
                                            <td className="u-tSmall">{holiday.sunday === "0" ? "通常" : holiday.sunday === "1" ? "所定休日" : "法定休日"}</td>
                                            <td className="u-tSmall">{holiday.holiday === "0" ? "通常" : holiday.holiday === "1" ? "所定休日" : "法定休日"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default AttendanceHoliday;
