import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AddModal from '../../../Components/Modal/AddModal';
import MasterModal from '../../../Components/Modal/MasterModal';
import { useEmploymentStatusList, useCreateEmploymentStatus, useUpdateEmploymentStatus, useDeleteEmploymentStatus } from '../../../Queries/EmploymentStatusQuery';
import LoaderComponent from '../../../Components/Layout/Loader';
import { useAuthority } from '../../../Contexts/AuthorityContext';

const EmploymentStatusList = (): JSX.Element => {
    const { authority } = useAuthority();
    const [isAddModal, setIsAddModal] = useState(false);
    const [isMasterModal, setIsMasterModal] = useState(false);
    const [selectedMasterId, setSelectedEmploymentId] = useState(null);
    const [selectedMasterName, setSelectedEmploymentName] = useState('');
    const updateEmploymentStatus = useUpdateEmploymentStatus();
    const deleteEmploymentStatus = useDeleteEmploymentStatus();
    const createEmploymentStatus = useCreateEmploymentStatus();
    // const { data: employments, isLoading } = useEmploymentStatusList();
    const { data: employments, isLoading } = useEmploymentStatusList('employments');

    const handleAddModalOpen = () => setIsAddModal(true);
    const handleAddModalClose = () => setIsAddModal(false);
    const handleAddModal = async (name: string, success: boolean) => setIsAddModal(false);

    const handleMasterModalOpen = (id: number, name: string) => {
        setSelectedEmploymentId(null);
        setSelectedEmploymentName('');
        setTimeout(() => {
            setSelectedEmploymentId(id);
            setSelectedEmploymentName(name);
        }, 0);
        setIsMasterModal(true);
    };
    const handleMasterModalClose = () => {
        setIsMasterModal(false);
    };

    if (isLoading) return <LoaderComponent />;

    return (
        <div className="l-board">
            {authority == '0' && (
                <>
                    <AddModal
                        title="雇用形態追加"
                        isOpen={isAddModal}
                        optionDetail={false}
                        optionRank={false}
                        optionOccupation={false}
                        handleClose={handleAddModalClose}
                        createMutation={createEmploymentStatus}
                    />
                    <MasterModal
                        title="職位編集"
                        optionDetail={false}
                        optionRole={false}
                        optionOccupation={false}
                        isOpen={isMasterModal}
                        handleClose={handleMasterModalClose}
                        selectedId={selectedMasterId}
                        selectedName={selectedMasterName}
                        updateMutation={updateEmploymentStatus}
                        deleteMutation={deleteEmploymentStatus}
                    />
                </>
            )}
            <div className="l-board__inner">
                {authority == '0' && (
                    <div className="l-board__wrap p-board__btn">
                        <div className="l-flex p-board__btn--area space-around">
                            <Link
                                className="p-board__btn--item c-btn c-btn__green icon icon-plus"
                                onClick={handleAddModalOpen}
                            >雇用形態を追加</Link>
                        </div>
                    </div>
                )}
                <div className="l-board__wrap p-board__main">
                    <div className="p-board__main--box">
                        <table className="p-table">
                            <thead>
                                <tr>
                                    <th>雇用形態名</th>
                                    <th>該当スタッフ名</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employments && employments.map((employment) => (
                                    <tr
                                        key={employment.id}
                                        onClick={authority == '0' ? () => handleMasterModalOpen(employment.id, employment.name) : null}
                                    >
                                        <td>{employment.name}</td>
                                        <td>{employment.users ? employment.users.map((user) => `${user.last_name} ${user.first_name}`).join(', ') : ''}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmploymentStatusList;
