/*
    業績目標編集
    /hc-user/performance/goal/edit/user/{userId}/{year}
*/
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import LoaderComponent from '../../../Components/Layout/Loader';
import isEqual from 'lodash/isEqual';
import { getPerformanceUserGoal, useUpdatePerformanceUserGoal } from '../../../Queries/PerformanceUserGoalQuery';

interface Goal {
    goal_id?: string;
    item_id: string;
    goal?: string;
}

interface MonthData {
    month: string;
    goal: Goal[];
}

interface EditData {
    data: MonthData[];
}

interface RouteParams {
    userId: string;
    year: string;
}

const PerformanceUserGoalEdit = (): JSX.Element => {
    const { userId, year } = useParams<RouteParams>();
    const userIdNum = parseInt(userId, 10);
    const yearNum = parseInt(year, 10);
    const [data, setData] = useState<EditData | null>(null); // フェッチしたデータを保持
    const [editData, setEditData] = useState<EditData | null>(null); // 編集中のデータを保持
    const [initialEditData, setInitialEditData] = useState<EditData | null>(null); // 初期状態のデータを保持
    const [isLoading, setIsLoading] = useState(true);

    const { mutate } = useUpdatePerformanceUserGoal();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fetchedData = await getPerformanceUserGoal(userIdNum, yearNum);
                setData(fetchedData); // フェッチしたデータをdata状態に設定
                setIsLoading(false);
            } catch (error) {
                toast.error("データの取得に失敗しました。");
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, [userIdNum, yearNum]);

    // 初期データの保存
    useEffect(() => {
        if (data) {
            setInitialEditData(data);
            setEditData(data);
        }
    }, [data]);

    /* データ操作 */
    // 入力
    const handleInputChange = (monthIndex: number, index: number, value: string) => {
        const newData = JSON.parse(JSON.stringify(editData));
        newData.data[monthIndex].goal[index].goal = value;
        setEditData(newData);
    };

    // リセット
    const handleResetClick = (monthIndex: number) => {
        const newData = JSON.parse(JSON.stringify(editData));
        newData.data[monthIndex].goal.forEach(goal => {
            goal.goal = null; // 同じ列のデータを空にする
        });
        setEditData(newData);
    };

    /* 差分取得処理 */
    const getDifferences = (initialData, currentData, isEqual) => {
        const differences = [];
        if (!initialData || !currentData) return differences;

        initialData.data.forEach((initialMonthData, monthIndex) => {
            const currentMonthData = currentData.data[monthIndex];
            if (!initialMonthData || !currentMonthData) return;

            initialMonthData.goal.forEach((initialGoal, goalIndex) => {
                const currentGoal = currentMonthData.goal[goalIndex];
                if (!initialGoal || !currentGoal || isEqual(initialGoal, currentGoal)) return;

                differences.push({
                    id: currentGoal.goal_id ? parseInt(currentGoal.goal_id, 10) : null,
                    performance_item_id: parseInt(currentGoal.item_id, 10),
                    target_year: yearNum, // `yearNum`は関数の引数かグローバル変数から得る必要があります。
                    target_month: parseInt(initialMonthData.month.replace("月", ""), 10),
                    goal: currentGoal.goal ? parseInt(currentGoal.goal, 10) : null,
                });
            });
        });

        return differences;
    };


    /* 更新処理 */
    const handleSubmit = async () => {
        // 初期データと入力データの差分を確認
        const differences = getDifferences(initialEditData, editData, isEqual);
        if(differences.length > 0) {
            // toast.info('差分があるよ！');
            mutate({ userId: userIdNum, data: differences });
        } else {
            toast.info('変更されたデータがありません。');
        }
    };

    if (isLoading) return <LoaderComponent />

    return (
        <>
            <div className="l-board is_scroll l-board__edit">
                <div className="l-board__inner">
                    <div className="l-board__wrap p-board__main">
                        <div className="p-board__main--box p-input__area">
                            {/* {errorMessages && (
                                <div className="c-msgError" dangerouslySetInnerHTML={{ __html: errorMessages }} />
                            )} */}
                            <table className="p-table p-table__small">
                                <thead>
                                    <tr>
                                        <th>該当月</th>
                                            {editData?.items?.map((item, index) => (
                                                <th key={index}>{item.name}</th>
                                            ))}
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {editData?.data?.map((monthData, monthIndex) => (
                                        <tr key={monthIndex}>
                                            <td>{monthData.month}</td>
                                            {monthData.goal.map((goal, index) => (
                                                <td key={index}>
                                                    <input
                                                        type="text"
                                                        className="c-input c-input__time c-input__data"
                                                        value={goal.goal ?? ""}
                                                        onChange={(e) => handleInputChange(monthIndex, index, e.target.value)}
                                                    />
                                                </td>
                                            ))}
                                            <td>
                                                <button
                                                    className="c-btn c-btn__red c-btn__table"
                                                    onClick={() => handleResetClick(monthIndex)} // リセットボタンのクリックイベント追加
                                                >
                                                    リセット
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="l-button">
                <button
                    className="c-btn c-btn__bgGreen c-btn__submit"
                    onClick={handleSubmit}
                >業績を更新</button>
            </div>
        </>
    );
};

export default PerformanceUserGoalEdit;
