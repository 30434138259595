import { useState } from 'react';
import { Link } from 'react-router-dom';
import AddModal from '../../../Components/Modal/AddModal';
import MasterModal from '../../../Components/Modal/MasterModal';
import { useRankList, useCreateRank, useUpdateRank, useDeleteRank } from '../../../Queries/RankQuery';
import { useRoleList } from '../../../Queries/RoleQuery';
import LoaderComponent from '../../../Components/Layout/Loader';
import { useAuthority } from '../../../Contexts/AuthorityContext';

const RankList = (): JSX.Element => {
    const { authority } = useAuthority();
    const [isAddModal, setIsAddModal] = useState(false);
    const [isMasterModal, setIsMasterModal] = useState(false);
    const [selectedMaster, setSelectedMaster] = useState({id: null, name: '', role: []});
    const createRank = useCreateRank();
    const updateRank = useUpdateRank();
    const deleteRank = useDeleteRank();

    const { data: ranks, status: rankStatus } = useRankList('ranks');
    const { data: roles, status: roleStatus } = useRoleList('roles');
    const isLoading = rankStatus === 'loading' || roleStatus === 'loading';

    const handleModalOpen = (isAdd, id, name, role) => {
        if (isAdd) {
            setIsAddModal(true);
        } else {
            setSelectedMaster({id, name, role});
            setIsMasterModal(true);
        }
    };

    const handleModalClose = (isAdd) => {
        if (isAdd) {
            setIsAddModal(false);
        } else {
            setIsMasterModal(false);
        }
    };

    if (isLoading) return <LoaderComponent />;

    return (
        <div className="l-board">
            {authority == '0' && (
                <>
                    <AddModal
                        title="職位追加"
                        optionDetail={false}
                        optionRole={true}
                        optionOccupation={false}
                        allRole={roles}
                        isOpen={isAddModal}
                        handleClose={() => handleModalClose(true)}
                        selectedRole={selectedMaster.role}
                        createMutation={createRank}
                    />

                    <MasterModal
                        title="職位編集"
                        optionDetail={false}
                        optionRole={true}
                        optionOccupation={false}
                        allRole={roles}
                        isOpen={isMasterModal}
                        handleClose={() => handleModalClose(false)}
                        selectedId={selectedMaster.id}
                        selectedName={selectedMaster.name}
                        selectedRole={selectedMaster.role}
                        updateMutation={updateRank}
                        deleteMutation={deleteRank}
                    />
                </>
            )}

            <div className="l-board__inner">

                {authority == '0' && (
                    <div className="l-board__wrap p-board__btn">
                        <div className="l-flex p-board__btn--area space-around">
                            <Link
                                className="p-board__btn--item c-btn c-btn__green icon icon-plus"
                                onClick={() => handleModalOpen(true)}
                            >職位を追加</Link>
                        </div>
                    </div>
                )}

                <div className="l-board__wrap p-board__main">
                    <div className="p-board__main--box">
                        <table className="p-table">
                            <thead>
                                <tr>
                                    <th>名称</th>
                                    <th>役割</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ranks.map((rank) => (
                                    <tr
                                        key={rank.id}
                                        // className="p-list__master--item"
                                        onClick={authority == '0' ? () => handleModalOpen(false, rank.id, rank.name, rank.rank_role_relations.map(relation => ({id: relation.role.id, name: relation.role.name}))) : null}
                                    >
                                        <td style={{whiteSpace: "nowrap"}}>{rank.name}</td>
                                        <td>
                                            <ul className="l-flex flex-wrap">
                                                {rank.rank_role_relations?.map((relation, index, array) => (
                                                    <li className="u-cGray" key={relation.id}>
                                                        {relation.role?.name || "職位が見つかりません"}
                                                        {index !== array.length - 1 ? "/" : ""}
                                                    </li>
                                                ))}
                                            </ul>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RankList;
