/*
    業績目標編集
    /hc-user/performance/goal/edit/company/{occupationId}/{year}
*/
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { usePerformanceCompanyGoal, useUpdatePerformanceCompanyGoal } from '../../../Queries/PerformanceCompanyGoalQuery';
import LoaderComponent from '../../../Components/Layout/Loader';
import isEqual from 'lodash/isEqual';

const PerformanceCompanyGoalEdit = () => {
    const { occupationId, year } = useParams();
    const occupationIdNum = parseInt(occupationId, 10);
    const yearNum = parseInt(year, 10);
    const { data, isLoading } = usePerformanceCompanyGoal(occupationIdNum, yearNum);
    const [editData, setEditData] = useState();
    const [initialData, setInitialData] = useState();

    const { mutate } = useUpdatePerformanceCompanyGoal(occupationIdNum);

    // データの読み込みが完了したら、ディープコピーを作成して状態を更新
    useEffect(() => {
        if (data) {
            const deepCopyData = JSON.parse(JSON.stringify(data));
            setInitialData(deepCopyData);
            setEditData(deepCopyData);
        }
    }, [data]);

    const handleInputChange = (monthIndex, index, value) => {
        setEditData(prevData => {
            const newData = JSON.parse(JSON.stringify(prevData)); // 現在の状態をディープコピー
            newData.data[monthIndex].goal[index].goal = value;
            return newData;
        });
    };

    const handleResetClick = (monthIndex) => {
        setEditData(prevData => {
            const newData = JSON.parse(JSON.stringify(prevData)); // 現在の状態をディープコピー
            newData.data[monthIndex].goal.forEach(goal => {
                goal.goal = null;
            });
            return newData;
        });
    };

    const handleSubmit = async () => {
        if (!initialData || !editData) return;

        const differences = editData.data.flatMap((monthData, monthIndex) => {
            return monthData.goal.map((goal, goalIndex) => {
                const initialGoal = initialData.data[monthIndex].goal[goalIndex];
                // isEqual を使用して、goal値だけでなく、全体の構造を比較
                if (!isEqual(goal, initialGoal)) {
                    return {
                        ...goal,
                        target_year: yearNum,
                        target_month: parseInt(monthData.month.replace('月', ''), 10),
                    };
                }
                return null;
            }).filter(item => item !== null);
        });

        if (differences.length > 0) {
            mutate({ data: differences });
        } else {
            toast.info('変更されたデータはありません。');
        }
    };


    if (isLoading || !editData) return <LoaderComponent />;

    return (
        <>
            <div className="l-board is_scroll l-board__edit">
                <div className="l-board__inner">
                    <div className="l-board__wrap p-board__main">
                        <div className="p-board__main--box p-input__area">
                            {/* {errorMessages && (
                                <div className="c-msgError" dangerouslySetInnerHTML={{ __html: errorMessages }} />
                            )} */}
                            <table className="p-table p-table__small">
                                <thead>
                                    <tr>
                                        <th>該当月</th>
                                        {editData?.items?.map((item, index) => (
                                            <th key={index}>{item.name}</th>
                                        ))}
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {editData?.data?.map((monthData, monthIndex) => (
                                        <tr key={monthIndex}>
                                            <td>{monthData.month}</td>
                                            {monthData.goal.map((goal, index) => (
                                                <td key={index}>
                                                    <input
                                                        type="text"
                                                        className="c-input c-input__time c-input__data"
                                                        value={goal.goal ?? ""}
                                                        onChange={(e) => handleInputChange(monthIndex, index, e.target.value)}
                                                    />
                                                </td>
                                            ))}
                                            <td>
                                                <button
                                                    className="c-btn c-btn__red c-btn__table"
                                                    onClick={() => handleResetClick(monthIndex)} // リセットボタンのクリックイベント追加
                                                >
                                                    リセット
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="l-button">
                <button
                    className="c-btn c-btn__bgGreen c-btn__submit"
                    onClick={handleSubmit}
                >業績を更新</button>
            </div>
        </>
    );
};

export default PerformanceCompanyGoalEdit;
