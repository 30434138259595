import axios from "axios";
import { Rank } from "../Types/Rank"

// 一覧
const getRankList = async () => {
    const { data } = await axios.get<Rank[]>(
        '/api/users/rank/list'
    );
    return data
}

// 追加
const createRank = async (newRank: { name: string }) => {
    const { data } = await axios.post<Rank[]>(
        '/api/users/rank/add', newRank
    );
    return data
}

// // 編集
// const getRankEdit = async (id : number) => {
//     const { data } = await axios.get<Rank[]>(
//         `/api/users/employment/edit/${id}`
//     );
//     return data
// }

// 更新
const updateRank = async ({id, name, role}: {id: number, name: string, role: any}) => {
    const { data } = await axios.put<Rank[]>(
        `/api/users/rank/update/${id}`,
        { id, name, role }
    );
    return data
}

// 削除
const deleteRank = async (id: number) => {
    const { data } = await axios.delete(
        `/api/users/rank/delete/${id}`
    );
    return data;
}

export {
    getRankList,
    createRank,
    updateRank,
    deleteRank
};
