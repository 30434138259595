// /resources/js/Components/Form/DateField.tsx

import React from "react";
import DatePicker from "react-datepicker";
import { ja } from "date-fns/locale";
import { isValid } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";

interface DateFieldProps {
  label: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
  error?: string;
  onReset?: () => void;
  showReset?: boolean;
}

const DateField: React.FC<DateFieldProps> = ({
  label,
  value,
  onChange,
  error,
  onReset,
  showReset,
}) => {
  // 選択された日付が有効かどうかを確認
  const selectedDate = value && isValid(value) ? value : null;

  const handleChange = (date: Date | null) => {
    if (date && isValid(date)) {
      // 有効な日付の場合のみ処理
      onChange(date);
    } else {
      // 無効な日付の場合はnullを設定
      onChange(null);
    }
  };

  return (
    <div className="p-input__area">
      <label className="c-label">{label}</label>
      <div className="area-input l-flex">
        <DatePicker
          className={`c-input c-input__text ${error ? "is-invalid" : ""}`}
          selected={selectedDate} // Date オブジェクトとして渡す
          onChange={handleChange} // Date オブジェクトを渡す
          dateFormat="yyyy/MM/dd"
          dateFormatCalendar={"yyyy年 MMMM"}
          locale={ja}
        />
        {showReset && (
          <button
            className="u-cRed c-btn c-btn__red c-btn__table u-ml8"
            type="button"
            onClick={onReset}
          >
            リセット
          </button>
        )}
      </div>
      {error && <p className="p-input__error">{error}</p>}
    </div>
  );
};

export default DateField;
