import { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { updateEvaluationRule, fetchEvaluationPeriods } from "../Api/EvaluationRuleAPI";
import { EvaluationRule } from '../Types/EvaluationRule';
import { toast } from 'react-toastify';

export const useEvaluationPeriods = () => {
    const [data, setData] = useState(null);
    const [status, setStatus] = useState('idle');

    useEffect(() => {
        const fetchData = async () => {
            setStatus('loading');
            try {
                const periods = await fetchEvaluationPeriods();
                setData(periods);
                setStatus('succeeded');
            } catch (error) {
                toast.error('評価期間の取得に失敗しました。');
                setStatus('failed');
            }
        };

        fetchData();
    }, []);

    return { data, status };
};

export const useUpdateEvaluationRule = () => {
    const queryClient = useQueryClient();
    const [status, setStatus] = useState('idle');

    const mutation = useMutation((newEvaluationRule: EvaluationRule) => updateEvaluationRule(newEvaluationRule), {
        onSuccess: () => {
            // queryClient.invalidateQueries('evaluationPeriods');
            queryClient.resetQueries();
            toast.success('評価規則の更新に成功しました。');
        },
        onError: (error: any) => {
            setStatus('failed');
            toast.error(`評価規則の更新に失敗しました: ${error.message}`);
        },
    });

    return { mutation, status };
};
