import axios from 'axios';

const BASE_URL = "/api/users/curriculum-result";  // 基本のURL

// 一覧機能
export const listResultsByUserAndDate = async (userId: number, yearMonth: string) => {
    const response = await axios.get(`${BASE_URL}/user/${userId}/date/${yearMonth}`);
    return response.data;
};

// 登録機能
export const addCurriculumResult = async (data: any) => {
    const response = await axios.post(`${BASE_URL}/add`, data);
    return response.data;
};

// 詳細
export const getCurriculumResultDetail = async (curriculumResultId: number) => {
    const response = await axios.get(`${BASE_URL}/detail/${curriculumResultId}`);
    return response.data;
};

// 削除
export const deleteCurriculumResult = async (id: number) => {
    const response = await axios.delete(`/api/users/curriculum-result/${id}`);
    return response.data;
};

// 編集
export const getEditCurriculumResult = async (id: number) => {
    const response = await axios.get(`/api/users/curriculum-result/edit/${id}`);
    return response.data;
};

// 更新機能
export const updateCurriculumResult = async (id: number, data: any) => {
    const response = await axios.put(`/api/users/curriculum-result/${id}`, data);
    return response.data;
};
