import axios, { AxiosResponse } from "axios";
import { EducationCategory } from "../Types/EducationCategory";

export const getEducationCategories = async (): Promise<EducationCategory[]> => {
    const response: AxiosResponse<EducationCategory[]> = await axios.get(`/api/users/education-category/list`);
    return response.data;
}

export const getListWithCurriculum = async (userId: string): Promise<EducationCategory[]> => {
    const response: AxiosResponse<EducationCategory[]> = await axios.get(`/api/users/education-category/list-with-curriculum/${userId}`);
    return response.data;
}
