import React, { useState, useEffect } from "react";
import { useUsersForSelectQuery } from "../../../Queries/UserQuery";
import {
  useFetchAttendanceCount,
  useFetchAttendanceData,
  useFetchLateNightHours,
  useFetchOvertimeHours,
  useFetchActualWorkHours,
  useFetchHolidayAttendanceCount,
} from "../../../Queries/UserWorkQuery";
import { useYearMonthSinceCreation } from "../../../Queries/CompanyQuery";
import { useExportAttendanceMutate } from "../../../Queries/ExcelExportQuery";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderComponent from "../../../Components/Layout/Loader";
import { checkAttendanceErrors } from "../../../Utils/attendanceErrorUtils";
import { formatDateToMonthDay } from "../../../Utils/formatUtils";
import { useAuthority } from "../../../Contexts/AuthorityContext";

const AttendanceReport = (): JSX.Element => {
  const { authority } = useAuthority();
  /* ライブラリ */
  const location = useLocation();
  /* ページ内のデータ */
  const [selectedYearMonth, setSelectedYearMonth] = useState(
    location.state?.selectedYearMonth || ""
  );
  const [selectedUserId, setSelectedUserId] = useState(
    location.state?.selectedUserId || ""
  );
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const { data: users, status: usersStatus } = useUsersForSelectQuery();
  const { data: yearMonthList, status: yearMonthListStatus } =
    useYearMonthSinceCreation();
  const { data: attendanceData, status: attendanceDataStatus } =
    useFetchAttendanceData(Number(selectedUserId), selectedYearMonth); // 出勤日数
  const { data: attendanceCount, status: attendanceCountStatus } =
    useFetchAttendanceCount(Number(selectedUserId), selectedYearMonth); // 労働時間
  const { data: holidayAttendanceCount, status: holidayAttendanceCountStatus } =
    useFetchHolidayAttendanceCount(Number(selectedUserId), selectedYearMonth); // 勤怠_ユーザーの休日出勤数

  // 実動時間、残業時間、深夜労働時間のデータを取得
  const { data: actualWorkHours, status: actualWorkHoursStatus } =
    useFetchActualWorkHours(Number(selectedUserId), selectedYearMonth);
  const { data: overtimeHours, status: overtimeHoursStatus } =
    useFetchOvertimeHours(Number(selectedUserId), selectedYearMonth);
  const { data: lateNightHours, status: lateNightHoursStatus } =
    useFetchLateNightHours(Number(selectedUserId), selectedYearMonth);

  /* レポートデータ */
  const isAttendanceLoading =
    usersStatus === "loading" ||
    yearMonthListStatus === "loading" ||
    attendanceDataStatus === "loading" ||
    actualWorkHoursStatus === "loading" ||
    overtimeHoursStatus === "loading" ||
    lateNightHoursStatus === "loading";

  // 勤怠変更画面から遷移する際のプログラム
  useEffect(() => {
    if (location.state?.userId) {
      setSelectedUserId(location.state.userId);
    } else if (users?.length > 0) {
      setSelectedUserId(users[0].id);
    }

    if (location.state?.selectedYearMonth) {
      const formattedYearMonth =
        location.state.selectedYearMonth.slice(0, 4) +
        "-" +
        location.state.selectedYearMonth.slice(4, 6);
      setSelectedYearMonth(formattedYearMonth);
    } else if (yearMonthList?.length > 0) {
      setSelectedYearMonth(yearMonthList[0]);
    }
  }, [location.state, users, yearMonthList]);

  /* 勤怠データチェック処理 */
  useEffect(() => {
    if (attendanceData) {
      const absenceErrors = checkAttendanceErrors(attendanceData);
      setErrorMessages([...absenceErrors]);
    }
  }, [attendanceData]);

  // 出力データを整形
  const formattedErrorMessages = errorMessages.join("<br>");
  if (formattedErrorMessages) {
    const errorMsgElement = document.querySelector(".c-msgError");
    if (errorMsgElement) {
      errorMsgElement.innerHTML = formattedErrorMessages;
    }
  }

  /* 選択系 */
  const handleYearMonthChange = (event) =>
    setSelectedYearMonth(event.target.value);
  const handleUserChange = (event) => setSelectedUserId(event.target.value);

  /* 勤怠ダウンロード機能 */
  // ダウンロードローディング
  const [isDownloading, setIsDownloading] = useState(false);
  // ダウンロード関数
  const onDownload = async (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (authority !== "0") {
      toast.error("権限が付与されていないため実行できません。");
      event.preventDefault(); // 権限がない場合のイベントキャンセル
      return;
    }
    useExportAttendanceMutate(
      selectedUserId,
      selectedYearMonth,
      attendanceData,
      users,
      setIsDownloading
    ).catch((error) => {
      // 403エラーをキャッチしてフロントでアラートを表示
      if (error.response && error.response.status === 403) {
        const errorMessage =
          error.response.data?.message ||
          "権限が付与されていないため実行できません。";
        toast.error(errorMessage); // APIが返したエラーメッセージを表示
      } else {
        toast.error("エクセルのダウンロード中にエラーが発生しました。");
      }
    });
  };

  // const onAttendanceEditClick = (event) => {
  const onAttendanceEditClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    // if (authority !== 0) {
    //   toast.error("権限が付与されていないため実行できません。");
    //   event.preventDefault();
    //   return;
    // }
  };

  if (isAttendanceLoading) return <LoaderComponent />;

  return (
    <>
      <div className="l-board__wrap p-board__btn">
        <div className="l-flex p-board__btn--area">
          <div className="p-board__btn--input">
            <select
              className="c-input c-input__select type-shadow"
              onChange={handleYearMonthChange}
              value={selectedYearMonth}
            >
              {yearMonthList &&
                yearMonthList.map((yearMonth, index) => {
                  // "-" で分割して、年と月を取得
                  const [year, month] = yearMonth.split("-");
                  return (
                    <option
                      key={index}
                      value={yearMonth}
                    >{`${year}年${month}月`}</option>
                  );
                })}
            </select>
          </div>
          <div className="p-board__btn--input">
            <select
              className="c-input c-input__select type-shadow"
              onChange={handleUserChange}
              value={selectedUserId}
            >
              {users &&
                users.map((user, index) => (
                  <option key={index} value={user.id}>
                    {user.last_name} {user.first_name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        {authority == '0' && (
        <div className="l-flex p-board__btn--area space-around">
          <Link
            to={{
              pathname: `/hc-user/attendance/edit/${selectedUserId}/${selectedYearMonth.replace(
                "-",
                ""
              )}`,
            }}
            className="p-board__btn--item c-btn c-btn__blue pc-only"
            onClick={onAttendanceEditClick}
          >
            勤怠データを編集
          </Link>
          <a
            className="p-board__btn--item c-btn c-btn__green icon icon-download"
            onClick={onDownload}
            disabled={isDownloading}
          >
            {isDownloading ? "ダウンロード中..." : "勤怠をダウンロード"}
          </a>
        </div>
        )}
      </div>
      <div className="l-board__wrap p-board__main p-board__main--flex">
        <div className="c-fGrow-2 p-board__main--box">
          <h3 className="c-headline__main">タイムカード</h3>
          {formattedErrorMessages && (
            <p
              className="c-msgError"
              dangerouslySetInnerHTML={{ __html: formattedErrorMessages }}
            ></p>
          )}
          <table className="p-table p-table__fixed">
            <thead>
              <tr>
                <th>日付</th>
                <th>勤務時間</th>
                <th>休憩時間</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {attendanceData &&
                Array.isArray(attendanceData) &&
                attendanceData.map((work, index) => {
                  // const formattedDate = formatDateToMonthDay(work.workOn);
                  const formattedDate = work.workOn
                    ? formatDateToMonthDay(work.workOn)
                    : "不正な日付";
                  let dayOfWeekClass = work.isDayoff ? "u-cRed" : "";
                  let rowClass = "";
                  if (
                    work.startAt === null &&
                    (work.finishAt ||
                      work.firstRestStartAt ||
                      work.firstRestFinishAt ||
                      work.secondRestStartAt ||
                      work.secondRestFinishAt)
                  ) {
                    rowClass = "u-bgRed";
                  } else if (work.startAt !== null && work.finishAt === null) {
                    rowClass = "u-bgRed";
                  }

                  return (
                    <tr key={index} className={rowClass}>
                      <td className={dayOfWeekClass}>
                        {formattedDate}({work.dayOfWeek})
                      </td>
                      <td className="u-tAlign--right">
                        {work.startAt}-{work.finishAt}
                      </td>
                      <td className="u-tAlign--right">
                        {work.firstRestStartAt}-{work.firstRestFinishAt}
                      </td>
                      <td className="u-tAlign--right">
                        {work.secondRestStartAt}-{work.secondRestFinishAt}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="c-fGrow-1 p-board__main--box">
          <h3 className="c-headline__main">集計</h3>
          <table className="p-table p-table__detail">
            <tbody>
              <tr>
                <td className="headline">出勤日数</td>
                <td className="u-tAlign--right">
                  {attendanceCount && attendanceCount}日
                </td>
              </tr>
              <tr>
                <td className="headline">実働時間</td>
                <td className="u-tAlign--right">
                  {actualWorkHours && actualWorkHours}
                </td>
              </tr>
              <tr>
                <td className="headline">残業時間</td>
                <td className="u-tAlign--right">
                  {overtimeHours && overtimeHours}
                </td>
              </tr>
              <tr>
                <td className="headline">深夜時間</td>
                <td className="u-tAlign--right">
                  {lateNightHours && lateNightHours}
                </td>
              </tr>
              <tr>
                <td className="headline">休日出勤数</td>
                <td className="u-tAlign--right">
                  {holidayAttendanceCount &&
                    holidayAttendanceCount.holiday_attendance_count}
                  日
                </td>
              </tr>
              <tr>
                <td className="headline">休日時間数</td>
                <td className="u-tAlign--right">
                  {holidayAttendanceCount &&
                    holidayAttendanceCount.holiday_attendance_hours}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default AttendanceReport;
