import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import StaffAddModal from '../../../Components/Modal/_StaffAddModal';
import StaffFormModal from '../../../Components/Modal/StaffFormModal';
import { useUserList } from '../../../Queries/UserQuery';
import LoaderComponent from '../../../Components/Layout/Loader';
import { useRankList } from '../../../Queries/RankQuery';
import { useShopsForSelectQuery } from '../../../Queries/ShopQuery';
import { useEmploymentStatusList } from '../../../Queries/EmploymentStatusQuery';
import { useOccupationList } from '../../../Queries/OccupationQuery';
import { useCreateUserWithHoliday, useCreateKintaiAccount } from '../../../Queries/UserQuery';
import { useAuthority } from '../../../Contexts/AuthorityContext';

const getAuthority = (isAuthority) => {
    switch (isAuthority) {
        case '0':
            return "管理者";
        case '1':
            return "教育担当者";
        case '2':
            return "一般";
        case '9':
            return "勤怠用";
        default:
            return "不明な権限";
    }
}
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // JavaScriptの月は0から始まるため1を加えます
    const day = date.getDate();
    return `${year}年${month}月${day}日`;
}

const StaffList = (): JSX.Element => {
    const { authority } = useAuthority();
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const shops = useShopsForSelectQuery();
    const employmentStatuses = useEmploymentStatusList();
    const occupations = useOccupationList();
    const ranks = useRankList();
    const createUserWithHoliday = useCreateUserWithHoliday();
    const createKintaiAccount = useCreateKintaiAccount();

    const handleAdd = (userData, onSuccess) => {
        if (userData.isKintaiAccount) {
            createKintaiAccount.mutate(userData, { onSuccess });
        } else {
            createUserWithHoliday.mutate(userData, { onSuccess });
        }
    };

    const navigate = useNavigate();
    const [isAddModal, setIsAddModal] = useState(false);
    const { data: users, isLoading } = useUserList();
    /* 追加モーダル */
    // オープン
    const handleAddModalOpen = () => {
        setIsAddModalOpen(true);
    };

    // 詳細ページへ遷移
    const navigateStaffDetail = (userId) => {
        const url = `/hc-user/staff/detail/${userId}`;
        navigate(url);
    };

    /* ローディング */
    if (isLoading || shops.isLoading || employmentStatuses.isLoading || occupations.isLoading || ranks.isLoading) {
        return <LoaderComponent />;
    }

    return (
        <div className="l-board">
            {authority == '0' && (
                <StaffFormModal
                    isOpen={isAddModalOpen}
                    handleClose={() => setIsAddModalOpen(false)}
                    shopList={shops.data}
                    employmentStatusList={employmentStatuses.data}
                    occupationList={occupations.data}
                    rankList={ranks.data}
                    onAction={handleAdd}
                />
            )}
            <div className="l-board__inner">
                {authority == '0' && (
                    <div className="l-board__wrap p-board__btn">
                        <div className="l-flex p-board__btn--area space-around">
                            <Link
                                className="p-board__btn--item c-btn c-btn__green icon icon-plus"
                                onClick={handleAddModalOpen}
                            >スタッフ追加</Link>
                        </div>
                    </div>
                )}
                <div className="l-board__wrap p-board__main">
                    <div className="c-fGrow-2 p-board__main--box">
                        <table className="p-table">
                            <thead>
                                <tr>
                                    <th>コード<br />権限</th>
                                    <th>名前</th>
                                    <th>職種<br />所属店舗</th>
                                    <th>入社日<br />退社日</th>
                                    <th>雇用形態<br />職位</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map(user => (
                                    <tr
                                        key={user.id}
                                        onClick={() => navigateStaffDetail(user.id)}
                                    >
                                        <td>{user.code}<br />{getAuthority(user.isAuthority)}</td>
                                        <td>{user.lastName} {user.firstName}<br />{user.lastNameKana} {user.firstNameKana}</td>
                                        <td>{user.isAuthority !== '9' ? (user.occupationId ? user.occupation.name : <span className="u-tSub">未設定</span>) : ''}<br />{user.isAuthority !== '9' ? (user.shopId ? user.shop.name : <span className="u-tSub">未設定</span>) : ''}</td>
                                        {/* <td>{user.isAuthority !== '9' ? (user.joinedOn ? formatDate(user.joinedOn) : <span className="u-tSub">未設定</span>) : ''}<br />{user.isAuthority !== '9' ? (user.retirementOn ? formatDate(user.retirementOn) : <span className="u-tSub">未設定</span>) : ''}</td> */}
                                        <td>{user.isAuthority !== '9' ? (user.joinedOn ? formatDate(user.joinedOn) : <span className="u-tSub">未設定</span>) : ''}<br />{user.isAuthority !== '9' ? (user.retirementOn ? formatDate(user.retirementOn) : '') : ''}</td>
                                        <td>{user.isAuthority !== '9' ? (user.employmentStatusId ? user.employmentStatus.name : <span className="u-tSub">未設定</span>) : ''}<br />{user.isAuthority !== '9' ? (user.rankId ? user.rank.name : <span className="u-tSub">未設定</span>) : ''}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default StaffList;
