// DonutChart.tsx
import React, { Component } from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';

export default class DonutChart extends Component {
    render() {
        const { numerator, denominator } = this.props;
        // パーセンテージ計算時に100%を超える値も表示する
        const percentage = denominator > 0 ? Math.round((numerator / denominator) * 100) : 0;

        // グラフ表示用には、numerator が denominator を超えた場合でもグラフは100%で表示されるようにする
        const pieChartData = [
            { name: 'Filled', value: Math.min(numerator, denominator) }, // 実際の値か、分母の値のいずれか小さい方
            { name: 'Empty', value: Math.max(0, denominator - numerator) }, // 分母から分子を引いた値、または0
        ];

        return (
            <PieChart width={90} height={90}>
                <Pie
                    data={pieChartData}
                    cx="50%"
                    cy="50%"
                    startAngle={90}
                    endAngle={-270}
                    dataKey="value"
                    innerRadius={35}
                    outerRadius={45}
                >
                    {pieChartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={index === 0 ? "#20C9A9" : "#f3f6f9"} />
                    ))}
                    <Label
                        value={`${percentage > 100 ? `${percentage}%` : `${percentage}%`}`} // 100%を超える値もそのまま表示
                        position="center"
                        fill="#20C9A9"
                        style={{ fontSize: "26px", fontWeight: "bold", fontFamily: "Roboto" }}
                    />
                </Pie>
            </PieChart>
        );
    }
}
