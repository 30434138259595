import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink, Link, useLocation, useNavigate } from "react-router-dom";
import { useUserProfile } from "../../Queries/UserQuery";
import urlPatterns from "../../urlPatterns";
import { toast } from "react-toastify";

function UserLayout() {
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const [csrfToken, setCsrfToken] = useState("");
  const { data: profile } = useUserProfile();

  /* 戻る機能 */
  const [title, setTitle] = useState("見出し");
  const [backUrl, setBackUrl] = useState("");
  const [activeStates, setActiveStates] = useState({
    isAttActive: false, // 勤怠
    isEduActive: false, // 教育・研修
    isPerActive: false, // 業績
    isHrActive: false, // 人事考課
    isStaActive: false, // スタッフ
    isShoActive: false, // 店舗
    isComActive: false, // 会社
  });

  useEffect(() => {
    const fetchCsrfToken = async () => {
      const { data } = await axios.get("/csrf-token");
      setCsrfToken(data.csrfToken);
    };
    fetchCsrfToken();
  }, []);

  /* 各ページのコンポーネント */
  useEffect(() => {
    for (const patternObj of urlPatterns) {
      if (pathname.match(patternObj.pattern)) {
        const result = patternObj.action();
        if (result) {
          // 追加: resultが存在するか確認する
          setTitle(result.title || title);
          setBackUrl(result.url || "");
          setActiveStates((prevState) => ({
            isAttActive: false,
            isEduActive: false,
            isPerActive: false,
            isHrActive: false,
            isStaActive: false,
            isShoActive: false,
            isComActive: false,
            ...result,
          }));
        }
        break;
      }
    }
  }, [pathname]);

  // ログアウト機能
  const handleLogout = async () => {
    try {
      const response = await axios.post(
        "/logout",
        {},
        {
          headers: {
            "X-CSRF-TOKEN": csrfToken,
          },
        }
      );
      if (response.status === 204 || response.status === 200) {
        window.location.href = "/login";
      } else {
        toast.error("ログアウトに失敗しました。");
      }
    } catch (error) {}
  };

  return (
    <>
      {/* ヘッダー */}
      <header className="l-header">
        <div className="l-header__wrap">
          <div className="l-header__profile">
            <Link className="profile-button" to="/hc-user/mypage/">
              <img src={"/image/icon_profile.png"} />
              <p>
                {profile?.lastName} {profile?.firstName}
              </p>
            </Link>
          </div>
          <div className="l-header__headline">
            {backUrl && (
              <Link to={backUrl} className="c-btn c-btn__back">
                <img src={"/image/icon_arrow_left_green.png"} />
              </Link>
            )}
            <h1 className="ttl">{title}</h1>
          </div>
          <div className="l-header__menu">
            <input id="drawer-checkbox" type="checkbox" />
            <label id="drawer-icon" htmlFor="drawer-checkbox">
              <span></span>
              <span></span>
              <span></span>
            </label>
            <label id="drawer-close" htmlFor="drawer-checkbox"></label>
            <nav id="drawer-content">
              <ul className="p-header">
                <li className="p-header__item sp-only">
                  <Link
                    to="/hc-user/attendance/detail/"
                    className="p-header__link"
                  >
                    勤怠
                  </Link>
                </li>
                <li className="p-header__item">
                  <Link
                    to="/hc-user/education/progress/"
                    className="p-header__link"
                  >
                    教育・研修
                  </Link>
                </li>
                <li className="p-header__item">
                  <Link
                    to="/hc-user/performance/shop/"
                    className="p-header__link"
                  >
                    業績
                  </Link>
                </li>
                <li className="p-header__item">
                  <Link to="/hc-user/hr/evaluation" className="p-header__link">
                    人事考課
                  </Link>
                </li>
                <li className="p-header__item">
                  <Link to="/hc-user/staff/" className="p-header__link">
                    スタッフ
                  </Link>
                </li>
                <li className="p-header__item">
                  <Link to="/hc-user/shop/" className="p-header__link">
                    店舗
                  </Link>
                </li>
                <li className="p-header__item">
                  <Link
                    to="/hc-user/company/detail/"
                    className="p-header__link"
                  >
                    会社
                  </Link>
                </li>
                <li className="p-header__item">
                  <Link to="/hc-user/mypage/" className="p-header__link">
                    マイページ
                  </Link>
                </li>
                <li className="p-header__item">
                  <a onClick={handleLogout} className="p-header__link">
                    ログアウト
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
      {/* ナビ */}
      <nav className="l-nav">
        <div className="p-nav">
          <div className="p-nav__logo">
            <img src={"/image/logo_HAIRCULUM.png"} width="118" height="24" />
          </div>
          <ul className="p-nav__link">
            {/* 勤怠 */}
            <li className="p-nav__link--item">
              <NavLink
                className={`icon-people ${
                  activeStates.isAttActive ? "active" : ""
                }`}
                to="/hc-user/attendance/detail/"
              >
                勤怠
              </NavLink>
            </li>
            {/* 教育・研修 */}
            <li className="p-nav__link--item">
              <NavLink
                className={`icon-people ${
                  activeStates.isEduActive ? "active" : ""
                }`}
                to="/hc-user/education/progress/"
              >
                教育・研修
              </NavLink>
            </li>
            {/* 業績 */}
            <li className="p-nav__link--item">
              <NavLink
                className={`icon-people ${
                  activeStates.isPerActive ? "active" : ""
                }`}
                to="/hc-user/performance/shop/"
              >
                業績
              </NavLink>
            </li>
            {/* 人事考課 */}
            <li className="p-nav__link--item">
              <NavLink
                className={`icon-people ${
                  activeStates.isHrActive ? "active" : ""
                }`}
                to="/hc-user/hr/evaluation"
              >
                人事考課
              </NavLink>
            </li>
            {/* スタッフ */}
            <li className="p-nav__link--item pc-only">
              <NavLink
                className={`icon-people ${
                  activeStates.isStaActive ? "active" : ""
                }`}
                to="/hc-user/staff/"
              >
                スタッフ
              </NavLink>
            </li>
            {/* 店舗 */}
            <li className="p-nav__link--item pc-only">
              <NavLink
                className={`icon-people ${
                  activeStates.isShoActive ? "active" : ""
                }`}
                to="/hc-user/shop/"
              >
                店舗
              </NavLink>
            </li>
            {/* 会社 */}
            <li className="p-nav__link--item pc-only">
              <NavLink
                className={`icon-people ${
                  activeStates.isComActive ? "active" : ""
                }`}
                to="/hc-user/company/detail/"
              >
                会社
              </NavLink>
            </li>
          </ul>
          <a className="p-nav__logout" onClick={handleLogout}>
            ログアウト
          </a>
        </div>
      </nav>
    </>
  );
}
export default UserLayout;
