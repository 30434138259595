// ファイル名: InputTextField.tsx
import React, { forwardRef } from 'react';

const InputTextField = forwardRef(({ label, type, name, value, onChange, error }, ref) => (
    <div className="p-input__area">
        <label className="c-label">{label}</label>
        <input
            ref={ref}
            type={type}
            name={name}
            className="c-input u-mt4"
            value={value}
            onChange={onChange} />
        {error && <p className="p-input__error">{error}</p>}
    </div>
));


export default InputTextField;
