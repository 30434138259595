import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as PerformanceCompanyGoalAPI from '../Api/PerformanceCompanyGoalAPI';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

export const usePerformanceGoalsForOccupationAndYear = (occupationId: number, year: number) => {
    return useQuery(['performanceGoals', occupationId, year], () => PerformanceCompanyGoalAPI.fetchPerformanceGoalsForOccupationAndYear(occupationId, year));
}

export const usePerformanceCompanyGoal = (occupationId: number, year: string) => {
    return useQuery(['performanceCompanyGoal', occupationId, year], () => PerformanceCompanyGoalAPI.getPerformanceCompanyGoal(occupationId, year));
};


export const useUpdatePerformanceCompanyGoal = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const mutation = useMutation(
        performanceCompanyGoals => PerformanceCompanyGoalAPI.updatePerformanceCompanyGoals(performanceCompanyGoals),
        {
            onSuccess: (response) => {
                toast.success('業績の更新に成功しました。');
                navigate('/hc-user/performance/goal');
                queryClient.resetQueries();
            },
            onError: (error: any) => {
                // エラーメッセージの取得と表示
                const errorMessage = error.response?.data?.message || '業績の更新に失敗しました。';
                toast.error(errorMessage);
            }
        }
    );
    return mutation;
};

