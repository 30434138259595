import axios from 'axios';
import { convertKeysToCamelCase } from "../Utils/convertToCamelCase";

// 年・ユーザーと紐づく年別業績一覧with目標値
export const getPerformanceByYearAndUserWithGoal = async (userId: number, year: number) => {
    try {
        const response = await axios.get(`/api/users/performance-result/list-user-year-with-goal/${userId}/${year}`);
        return response.data;
    } catch (error) {
        throw new Error("Error fetching performance data.");
    }
};

// // 年・ユーザーと紐づく年別業績一覧
// export const getPerformanceByYearAndUser = async (userId: number, year: number) => {
//     try {
//         const response = await axios.get(`/api/users/performance-result/list-user-year/${userId}/${year}`);
//         return response.data;
//     } catch (error) {
//         throw new Error("Error fetching performance data.");
//     }
// };

// 編集データ
export const getResultsByYearForEdit = async (userId, year) => {
    try {
        const response = await axios.get(`/api/users/performance-result/edit/${userId}/${year}`);
        return convertKeysToCamelCase(response.data.data);
    } catch (error) {
        console.error('Error fetching performance results for edit:', error);
        throw error;
    }
};

// 登録&更新
export const storeOrUpdatePerformance = async (performanceResults) => {
    try {
        const response = await axios.post(`/api/users/performance-result/add/`, { performance_results: performanceResults });
        return response.data;
    } catch (error) {
        console.error("Error storing or updating performance data:", error);
        if (error.response && error.response.status === 422) {
            throw new Error(error.response.data.message || "Validation error.");
        }
        throw new Error("Error storing or updating performance data.");
    }
};


export const detailByMonthOfUserInEvaluationPeriod = async (userId: number, performanceItemId: number, year: number, month: number) => {
    try {
        const response = await axios.get(`/api/users/performance-result/detail-by-month/user/${userId}/item/${performanceItemId}/period/${year}/${month}`);
        return response.data;
    } catch (error) {
        throw new Error("Error fetching monthly performance data with goal.");
    }
};

// 評価期間内のユーザー合計取得
export const listTotalOfUserInEvaluationPeriod = async (userId: number, year: string, month: string) => {
    try {
        const response = await axios.get(`/api/users/performance-result/list-total/user/${userId}/period/${year}/${month}`);
        return response.data;
    } catch (error) {
        throw new Error("Error fetching performance total data.");
    }
};

// ユーザーと紐づく評価期間内の月別業績一覧with目標値
export const listByMonthOfUserInEvaluationPeriod = async (userId: number, year: number, month: number) => {
    try {
        const response = await axios.get(`/api/users/performance-result/list-by-month/user/${userId}/period/${year}/${month}`);
        return response.data;
    } catch (error) {
        throw new Error("Error fetching monthly performance data with goal.");
    }
};


// 登録&更新&削除
export const updatePerformanceResult = async (performanceResults) => {
    const response = await axios.post(`/api/users/performance-result/change`, performanceResults);
    return response.data;
};
