import { useParams, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useShopDetail, useDeleteShop, useUpdateShop } from "../../../Queries/ShopQuery";
import ConfirmDeleteModal from '../../../Components/Modal/ConfirmDeleteModal';
import LoaderComponent from '../../../Components/Layout/Loader';
import { Shop } from "../../../Types/Shop";
import ShopFormModal from '../../../Components/Modal/ShopFormModal';
import { useAuthority } from '../../../Contexts/AuthorityContext';

const ShopDetail = (): JSX.Element => {
    const { authority } = useAuthority();
    const { shopId } = useParams<{ shopId: string }>();

    const { data: shop, status: shopStatus } = useShopDetail(shopId) as { data: Shop | undefined, status: string };

    // ショップ編集モーダルの表示/非表示
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const handleEditModalOpen = () => setIsEditModalOpen(true);
    const handleEditModalClose = () => setIsEditModalOpen(false);

    /* 削除モーダル */
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const handleDeleteModalOpen = () => setIsDeleteModal(true);
    const handleDeleteModalClose = () => setIsDeleteModal(false);

    const deleteShop = useDeleteShop();
    const updateShop = useUpdateShop(handleEditModalClose);

    const isLoading = ['loading'].includes(shopStatus);
    if (isLoading) return <LoaderComponent />;

    return (
        <div className="l-board">
            <ShopFormModal
                isOpen={isEditModalOpen}
                handleClose={handleEditModalClose}
                shopDetail={shop}
                onAction={updateShop.mutate}
                isEditMode={true}
            />
            {/* <ShopEditModal
                isOpen={isShopEditModalOpen}
                handleClose={handleShopEditModalClose}
                shopData={shop}
            /> */}
            <ConfirmDeleteModal
                title="店舗名削除"
                isOpen={isDeleteModal}
                handleClose={handleDeleteModalClose}
                selectedId={shopId}
                deleteMutation={deleteShop}
            />
            <div className="l-board__inner">
                {authority == '0' && (
                    <div className="l-board__wrap p-board__btn no-border">
                        <div className="l-board__btn--box">
                            <button
                                className="c-btn c-btn__blue c-btn__table"
                                onClick={handleEditModalOpen}
                            >編集</button>
                        </div>
                        <div className="l-board__btn--box">
                            <button
                                className="c-btn c-btn__red c-btn__table"
                                onClick={handleDeleteModalOpen}
                            >削除</button>
                        </div>
                    </div>
                )}
                <div className="l-board__wrap p-board__main">
                    <table className="p-table p-table__detail">
                        <tbody>
                            <tr>
                                <td className="headline">店舗名</td>
                                <td>{shop?.name}</td>
                            </tr>
                            <tr>
                                <td className="headline">スタッフ一覧</td>
                                <td>{shop?.users && shop.users.length > 0
                                        ? shop.users.map((user) => `${user.last_name} ${user.first_name}`).join(', ')
                                        : '該当者なし'}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ShopDetail;
