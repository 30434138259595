// TrainingRecordModal.tsx

import React, { useState, useEffect, useMemo } from "react";
import { useUsersForSelectQuery } from "../../Queries/UserQuery";
import LoaderComponent from "../Layout/Loader";
import {
  useStoreTrainingRecord,
  useTrainingRecordDetail,
  useUpdateTrainingRecord,
} from "../../Queries/TrainingRecordQuery";
import DatePicker from "react-datepicker";
import { ja } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import { utcToZonedTime, format } from "date-fns-tz";
/* Context */
import { useAuthority } from "../../Contexts/AuthorityContext";

interface TrainingRecordModalProps {
  isOpen: boolean;
  handleClose: () => void;
  refetchData?: () => void;
  trainingRecordId?: number;
}

const defaultTrainingData = {
  traineeId: "",
  instructorName: "",
  instructorId: "",
  trainingItem: "",
  trainingName: "",
  trainingDetail: "",
  completeOn: "",
};

const TrainingRecordModal: React.VFC<TrainingRecordModalProps> = ({
  isOpen,
  handleClose,
  refetchData,
  trainingRecordId,
}) => {
  const { authority, userId } = useAuthority(); // AuthorityとuserIdを取得
  const { data: trainingDetail, isLoading: isTrainingDetailLoading } =
    isOpen && trainingRecordId && trainingRecordId > 0
      ? useTrainingRecordDetail(trainingRecordId)
      : { data: null, isLoading: false };
  const {
    data: users,
    isLoading: isUsersLoading,
    error: usersError,
  } = useUsersForSelectQuery();
  const [instructorType, setInstructorType] = useState("inner");
  useEffect(() => {
    console.log("useUsersForSelectQuery - isLoading:", isUsersLoading);
    console.log("useUsersForSelectQuery - error:", usersError);
    console.log("useUsersForSelectQuery - data:", users);
  }, [isUsersLoading, usersError, users]);
  const [trainingData, setTrainingData] = useState(defaultTrainingData);
  const { mutate: storeTrainingRecord, error: storeError } =
    useStoreTrainingRecord(handleClose);
  const { mutate: updateTrainingRecord, error: updateError } =
    useUpdateTrainingRecord();

  const [startHour, setStartHour] = useState("09");
  const [startMinute, setStartMinute] = useState("00");
  const [finishHour, setFinishHour] = useState("17");
  const [finishMinute, setFinishMinute] = useState("00");

  // 編集モードと追加モードの切り替え
  const isEdit = trainingRecordId !== undefined && trainingRecordId > 0;

  /* フィルタリングロジックを useMemo で最適化 */
  const filteredUsers = useMemo(() => {
    if (!users) return [];

    switch (authority) {
      case 0:
        // 管理者: 全てのユーザーを返す
        return users;
      case 1:
        // 教育: is_authority が '1' または '2' のユーザーのみ
        return users.filter(
          (user) => user.is_authority === "1" || user.is_authority === "2"
        );
      case 2:
        // 一般: ログインユーザーのみ
        return users.filter((user) => user.id === userId);
      default:
        return [];
    }
  }, [users, authority, userId]);

  /* ページ内データの初期設定 */
  useEffect(() => {
    if (isEdit && trainingDetail && isOpen) {
      const jstDate = utcToZonedTime(trainingDetail.complete_on, "Asia/Tokyo");
      const formattedDate = format(jstDate, "yyyy/MM/dd", {
        timeZone: "Asia/Tokyo",
      });

      setTrainingData({
        traineeId: trainingDetail.trainee_id.toString(),
        instructorName: trainingDetail.instructor_name,
        instructorId: trainingDetail.instructor_id.toString(),
        trainingItem: trainingDetail.training_item,
        trainingName: trainingDetail.training_name,
        trainingDetail: trainingDetail.training_detail,
        completeOn: formattedDate,
      });

      const [startHr, startMin] = trainingDetail.start_time.split(":");
      const [finishHr, finishMin] = trainingDetail.finish_time.split(":");
      setStartHour(startHr);
      setStartMinute(startMin);
      setFinishHour(finishHr);
      setFinishMinute(finishMin);

      // Set instructorType based on whether instructor is internal or external
      // Assuming that external instructors have instructorId = 0 or some other logic
      setInstructorType(trainingDetail.instructor_id !== 0 ? "inner" : "outer");
    } else if (!isEdit && isOpen && users && filteredUsers.length > 0) {
      const currentDate = new Date();
      const formattedDate = format(currentDate, "yyyy/MM/dd");

      if (authority === 2 && userId) {
        // 一般ユーザーの場合、初期値をログインユーザーに設定
        const user = users.find((user) => user.id === userId);
        if (user) {
          setTrainingData({
            ...defaultTrainingData,
            traineeId: user.id.toString(),
            instructorId: instructorType === "inner" ? user.id.toString() : "",
            instructorName:
              instructorType === "inner"
                ? `${user.last_name} ${user.first_name}`
                : "",
            completeOn: formattedDate,
          });
        }
      } else {
        // 管理者や教育者の場合、最初のユーザーを初期値として設定
        const firstUser = filteredUsers[0];
        setTrainingData({
          ...defaultTrainingData,
          traineeId: firstUser.id.toString(),
          instructorId:
            instructorType === "inner" ? firstUser.id.toString() : "",
          instructorName:
            instructorType === "inner"
              ? `${firstUser.last_name} ${firstUser.first_name}`
              : "",
          completeOn: formattedDate,
        });
      }
    }
  }, [
    isEdit,
    isOpen,
    trainingDetail,
    users,
    filteredUsers,
    authority,
    userId,
    instructorType,
  ]);

  const handleUpdate =
    (field: keyof typeof trainingData) => (value: string | Date) => {
      let formattedValue;
      if (value instanceof Date) {
        // 日付の場合、フォーマットを適用
        formattedValue = format(value, "yyyy/MM/dd");
      } else {
        formattedValue = value;
      }
      setTrainingData((prev) => ({ ...prev, [field]: formattedValue }));
    };

  const handleInstructorTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newInstructorType = e.target.value;
    setInstructorType(newInstructorType);

    if (newInstructorType === "outer") {
      // 外部レッスン担当者の場合、instructorNameを空にする
      setTrainingData((prev) => ({
        ...prev,
        instructorName: "",
        instructorId: "",
      }));
    } else if (newInstructorType === "inner" && filteredUsers.length > 0) {
      // 社内レッスン担当者の場合、現在選択中のinstructorIdに基づいてinstructorNameを設定する
      const selectedUser =
        filteredUsers.find(
          (user) => user.id === Number(trainingData.instructorId)
        ) || filteredUsers[0];
      setTrainingData((prev) => ({
        ...prev,
        instructorId: selectedUser.id.toString(),
        instructorName: `${selectedUser.last_name} ${selectedUser.first_name}`,
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // 2桁のゼロ埋め形式に変換
    const formattedStartHour = startHour.padStart(2, "0");
    const formattedFinishHour = finishHour.padStart(2, "0");

    const dataToSend = {
      ...trainingData,
      startTime: `${formattedStartHour}:${startMinute}`,
      finishTime: `${formattedFinishHour}:${finishMinute}`,
    };

    if (isEdit && trainingRecordId) {
      updateTrainingRecord({ ...dataToSend, id: trainingRecordId });
    } else {
      storeTrainingRecord(dataToSend);
    }
  };

  const handleCloseBtnClick = () => {
    setInstructorType("inner");
    setTrainingData(defaultTrainingData);
    handleClose();
  };

  const isLoading = isUsersLoading || (isEdit && isTrainingDetailLoading);

  if (!isOpen) return null;

  return (
    <div className="p-modal" onClick={handleCloseBtnClick}>
      <div className="p-modal__content" onClick={(e) => e.stopPropagation()}>
        <button className="p-modal__delete" onClick={handleCloseBtnClick}>
          <img src="/image/icon_btn_batsu_green.png" alt="Close" />
        </button>
        <h3 className="p-modal__headline">
          {isEdit ? "研修記録更新" : "研修記録追加"}
        </h3>
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <form onSubmit={handleSubmit}>
            {/* 研修受講者選択 */}
            <div className="p-input__area">
              <div className="area-label">
                <label className="c-label">研修受講者</label>
              </div>
              <div className="area-input">
                <select
                  className="c-input c-input__text"
                  value={trainingData.traineeId}
                  onChange={(e) => handleUpdate("traineeId")(e.target.value)}
                  disabled={authority === 2} // 一般ユーザーの場合、変更不可にする
                  required
                >
                  {filteredUsers.map((user) => (
                    <option key={user.id} value={user.id.toString()}>
                      {user.last_name} {user.first_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* 研修担当者の選択 */}
            <div className="p-input__area">
              <div className="area-label">
                <label className="c-label">研修担当者</label>
              </div>
              <div className="c-input__checkbox">
                <input
                  id="inner"
                  type="radio"
                  name="educatorType"
                  value="inner"
                  checked={instructorType === "inner"}
                  onChange={handleInstructorTypeChange}
                  required
                />
                <label htmlFor="inner">社内レッスン担当者</label>
              </div>
              <div className="c-input__checkbox">
                <input
                  id="outer"
                  type="radio"
                  name="educatorType"
                  value="outer"
                  checked={instructorType === "outer"}
                  onChange={handleInstructorTypeChange}
                />
                <label htmlFor="outer">外部レッスン担当者</label>
              </div>
              {instructorType === "inner" && (
                <div className="area-input">
                  <select
                    className="c-input c-input__text"
                    value={trainingData.instructorId}
                    onChange={(e) =>
                      handleUpdate("instructorId")(e.target.value)
                    }
                    disabled={authority === 2} // 一般ユーザーの場合、変更不可にする
                    required
                  >
                    {filteredUsers.map((user) => (
                      <option key={user.id} value={user.id.toString()}>
                        {user.last_name} {user.first_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {instructorType === "outer" && (
                <div className="area-input">
                  <input
                    className="c-input c-input__text"
                    type="text"
                    value={trainingData.instructorName}
                    onChange={(e) =>
                      handleUpdate("instructorName")(e.target.value)
                    }
                    required
                  />
                </div>
              )}
            </div>

            {/* 研修項目、研修名、研修記録 */}
            <div className="p-input__area">
              <label className="c-label">研修項目</label>
              <input
                className="c-input u-mt4"
                type="text"
                value={trainingData.trainingItem}
                onChange={(e) => handleUpdate("trainingItem")(e.target.value)}
                required
              />
            </div>
            <div className="p-input__area">
              <label className="c-label">研修名</label>
              <input
                className="c-input u-mt4"
                type="text"
                value={trainingData.trainingName}
                onChange={(e) => handleUpdate("trainingName")(e.target.value)}
                required
              />
            </div>
            <div className="p-input__area">
              <div className="area-label">
                <label className="c-label">研修実施日</label>
              </div>
              <div className="area-input">
                <DatePicker
                  className="c-input c-input__text"
                  selected={
                    trainingData.completeOn
                      ? new Date(trainingData.completeOn.replace(/\//g, "-"))
                      : null
                  }
                  onChange={(date: Date) => handleUpdate("completeOn")(date)}
                  dateFormat="yyyy/MM/dd"
                  locale={ja}
                  required
                />
              </div>
            </div>
            <div className="p-input__area">
              <label className="c-label">開始・終了時間</label>
              <div className="area-input l-flex">
                <div className="c-box__two">
                  <label className="c-label c-label__sub">開始時間</label>
                  <div className="l-flex">
                    <select
                      className="c-input c-input__time"
                      value={startHour}
                      onChange={(e) => setStartHour(e.target.value)}
                      required
                    >
                      {[...Array(24)].map((_, i) => (
                        <option key={i} value={i.toString().padStart(2, "0")}>
                          {i.toString().padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                    <span>:</span>
                    <select
                      className="c-input c-input__time"
                      value={startMinute}
                      onChange={(e) => setStartMinute(e.target.value)}
                      required
                    >
                      {[...Array(60)].map((_, i) => (
                        <option key={i} value={i.toString().padStart(2, "0")}>
                          {i.toString().padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="c-box__two">
                  <label className="c-label c-label__sub">終了時間</label>
                  <div className="l-flex">
                    <select
                      className="c-input c-input__time"
                      value={finishHour}
                      onChange={(e) => setFinishHour(e.target.value)}
                      required
                    >
                      {[...Array(24)].map((_, i) => (
                        <option key={i} value={i.toString().padStart(2, "0")}>
                          {i.toString().padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                    <span>:</span>
                    <select
                      className="c-input c-input__time"
                      value={finishMinute}
                      onChange={(e) => setFinishMinute(e.target.value)}
                      required
                    >
                      {[...Array(60)].map((_, i) => (
                        <option key={i} value={i.toString().padStart(2, "0")}>
                          {i.toString().padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-input__area">
              <label className="c-label">研修記録</label>
              <textarea
                className="c-input c-input__textarea u-mt4"
                value={trainingData.trainingDetail}
                onChange={(e) => handleUpdate("trainingDetail")(e.target.value)}
                required
              ></textarea>
            </div>
            <div className="p-input__area">
              <button
                className="c-btn c-btn__green c-btn__bgGreen c-btn__small position-center"
                type="submit"
              >
                {isEdit ? "更新する" : "追加する"}
              </button>
            </div>
            {/* エラーメッセージ表示 */}
            {(storeError || updateError) && (
              <div className="error-message">
                {isEdit
                  ? "研修記録の更新に失敗しました。"
                  : "研修記録の追加に失敗しました。"}
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
};
export default TrainingRecordModal;
