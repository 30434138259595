import { useState } from "react";
import { Link } from "react-router-dom";
import {
  useCompanyEvaluationRuleQuery,
  useUpdateCompanyEvaluationRuleMutation,
} from "../../../Queries/CompanyEvaluationRuleQuery";
import FormModal from "../../../Components/Modal/CompanyFormModal";
import LoaderComponent from "../../../Components/Layout/Loader";
import { useAuthority } from "../../../Contexts/AuthorityContext";

const CompanyDetail = (): JSX.Element => {
  const { authority } = useAuthority();
  const [isEditModal, setIsEditModal] = useState(false);
  const { data: companyEvaluationRule, isLoading } =
    useCompanyEvaluationRuleQuery();
  const handleEditModalOpen = () => setIsEditModal(true);
  const handleEditModalClose = () => setIsEditModal(false);
  const updateCompanyEvaluationRule =
    useUpdateCompanyEvaluationRuleMutation(handleEditModalClose);

  function formatTime(timeStr) {
    if (!timeStr) return "";
    const [hours, minutes] = timeStr.split(":");
    return `${parseInt(hours)}:${minutes}`;
  }

  if (isLoading) return <LoaderComponent />;

  const company = companyEvaluationRule?.company;
  const evaluationRule = companyEvaluationRule?.evaluationRule;

  return (
    <>
      {authority == "0" && (
        <FormModal
          isOpen={isEditModal}
          handleClose={handleEditModalClose}
          editData={{ company, evaluationRule }} // ここを修正
          handleUpdate={updateCompanyEvaluationRule.mutate}
        />
      )}

      {authority == "0" && (
        <div className="l-board__wrap p-board__btn no-border">
          <div className="l-flex p-board__btn--area space-around">
            <Link
              className="c-btn c-btn__blue c-btn__table"
              onClick={handleEditModalOpen}
            >
              編集
            </Link>
          </div>
        </div>
      )}

      <div className="l-board__wrap p-board__main">
        <div className="p-board__main--box">
          <table className="p-table p-table__detail">
            <tbody>
              <tr>
                <td className="headline">会社名</td>
                <td>
                  {company?.companyNameKana}
                  <br />
                  {company?.companyName}
                </td>
              </tr>
              <tr>
                <td className="headline">会社住所</td>
                <td>
                  〒{company?.postal}
                  <br />
                  {company?.address}
                </td>
              </tr>
              <tr>
                <td className="headline">会社電話番号</td>
                <td>
                  {company?.companyTel ? (
                    company?.companyTel
                  ) : (
                    <span className="u-tSub">未設定</span>
                  )}
                </td>
              </tr>
              <tr>
                <td className="headline">代表者</td>
                <td>
                  <span className="u-tSub">{company?.position}</span>
                  <br />
                  {company?.topLastNameKana} {company?.topFirstNameKana}
                  <br />
                  {company?.topLastName} {company?.topFirstName}
                </td>
              </tr>
              <tr>
                <td className="headline">連絡先電話番号</td>
                <td>
                  {company?.contactTel ? (
                    company?.contactTel
                  ) : (
                    <span className="u-tSub">未設定</span>
                  )}
                </td>
              </tr>
              <tr>
                <td className="headline">労働時間</td>
                <td>
                  {formatTime(company?.startAt)}〜
                  {formatTime(company?.finishAt)}
                </td>
              </tr>
              <tr>
                <td className="headline">賃金締切日</td>
                <td>
                  {company?.closingDay === -1
                    ? "月末"
                    : `${company?.closingDay}日`}
                </td>
              </tr>
              <tr>
                <td className="headline">法定労働時間</td>
                <td>
                  {company?.legalWorkingHour ? (
                    `${company?.legalWorkingHour}時間`
                  ) : (
                    <span className="u-tSub">未設定</span>
                  )}
                </td>
              </tr>
              <tr>
                <td className="headline">休憩時間</td>
                <td>{formatTime(company?.breakAt)}</td>
              </tr>
              <tr>
                <td className="headline">評価ルール</td>
                <td>
                  開始月: {evaluationRule?.evaluationMonth}月 / 頻度:{" "}
                  {evaluationRule?.evaluationPeriod}ヶ月毎
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CompanyDetail;
