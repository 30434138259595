import React from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient, UseMutationResult } from 'react-query';
import { toast } from 'react-toastify';
import InputTextField from '../Form/InputTextField';

interface PasswordModalProps {
    isOpen: boolean;
    handleClose: () => void;
    selectedId: number | null;
    updateMutation: UseMutationResult<any, any, { id: number; password: string; passwordConfirmation: string }, unknown>;
}

interface IFormInput {
    password: string;
    passwordConfirmation: string;
}

const PasswordEditModal: React.VFC<PasswordModalProps> = ({ isOpen, handleClose, selectedId, updateMutation }) => {
    const { register, handleSubmit, formState: { errors }, watch, reset } = useForm<IFormInput>();

    const handleUpdate = (data: IFormInput) => {
        if (selectedId === null) {
            toast.error('ユーザーが選択されていません');
            return;
        }

        updateMutation.mutate({
            id: selectedId,
            password: data.password,
            passwordConfirmation: data.passwordConfirmation
        }, {
            onSuccess: () => {
                // 成功時にモーダルを閉じる
                handleClose();
                // フォームのデータをリセットする
                reset();
            }
        });
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className="p-modal">
            <div className="p-modal__content">
                <button className="p-modal__delete" onClick={handleClose}>
                    <img src={'/image/icon_btn_batsu_green.png'} />
                </button>
                <h3 className="p-modal__headline">パスワード</h3>
                <form onSubmit={handleSubmit(handleUpdate)}>

                    <InputTextField
                        label="パスワード（英数字8桁以上）"
                        type="password"
                        name="password"
                        {...register("password", {
                            required: "パスワードは必須です",
                            minLength: { value: 8, message: "パスワードは8文字以上でなければなりません" },
                        })}
                        error={errors.password?.message || ''}
                    />
                    <InputTextField
                        label="パスワード（確認用）"
                        type="password"
                        name="passwordConfirmation"
                        {...register("passwordConfirmation", {
                            required: "パスワードの確認は必須です",
                            validate: value => value === watch("password") || "パスワードが一致しません"
                        })}
                        error={errors.passwordConfirmation?.message || ''}
                    />
                    <div className="p-input__area">
                        <button
                            className="c-btn c-btn__green c-btn__bgGreen c-btn__small position-center"
                            type="submit"
                        >
                            更新する
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PasswordEditModal;
