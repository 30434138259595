// /resources/js/Components/Form/TimeSelectBox.tsx

import React from "react";

interface TimeSelectBoxProps {
  label: string;
  hourValue: string;
  minuteValue: string;
  setHour: (value: string) => void;
  setMinute: (value: string) => void;
  error?: string;
}

const TimeSelectBox: React.FC<TimeSelectBoxProps> = ({
  label,
  hourValue,
  minuteValue,
  setHour,
  setMinute,
  error,
}) => {
  // 空または未定義の場合に '00' を使用
  const formattedHour = hourValue ? hourValue.padStart(2, "0") : "00";
  const formattedMinute = minuteValue ? minuteValue.padStart(2, "0") : "00";

  return (
    <div className="c-box__two">
      <label className="c-label c-label__sub">{label}</label>
      <div className="l-flex">
        <select
          className={`c-input c-input__time form-control u-mt4 ${error ? "is-invalid" : ""}`}
          value={formattedHour}
          onChange={(e) => setHour(e.target.value)}
          required
        >
          {[...Array(24)].map((_, i) => {
            const hour = i.toString().padStart(2, "0");
            return (
              <option key={i} value={hour}>
                {hour}
              </option>
            );
          })}
        </select>
        <p className="u-ml4 u-mr4">:</p>
        <select
          className={`c-input c-input__time form-control u-mt4 ${error ? "is-invalid" : ""}`}
          value={formattedMinute}
          onChange={(e) => setMinute(e.target.value)}
          required
        >
          {[...Array(6)].map((_, i) => {
            const minute = (i * 10).toString().padStart(2, "0");
            return (
              <option key={i} value={minute}>
                {minute}
              </option>
            );
          })}
        </select>
      </div>
      {error && <p className="p-input__error">{error}</p>}
    </div>
  );
};

export default TimeSelectBox;
