import { useState } from "react";
import MasterModal from "../../../Components/Modal/MasterModal";
import {
  useOccupationList,
  useUpdateOccupation,
} from "../../../Queries/OccupationQuery";
import LoaderComponent from "../../../Components/Layout/Loader";
import { useAuthority } from "../../../Contexts/AuthorityContext";

const OccupationList = (): JSX.Element => {
  const { authority } = useAuthority();
  const [isMasterModal, setIsMasterModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const updateOccupation = useUpdateOccupation();
  // const { data: occupations, isLoading } = useOccupationList('occupations');
  const { data: occupations, isLoading } = useOccupationList();

  /* 編集モーダル */
  const handleMasterModalOpen = (id: number, name: string) => {
    setSelectedId(null);
    setSelectedName("");
    // 更新
    setTimeout(() => {
      setSelectedId(id);
      setSelectedName(name);
    }, 0);
    setIsMasterModal(true);
  };

  // モーダル: 閉じる
  const handleMasterModalClose = () => setIsMasterModal(false);

  /* ローディング */
  if (isLoading) return <LoaderComponent />;

  /* リターン */
  return (
    <div className="l-board">
      <MasterModal
        title="職種編集"
        optionDetail={false}
        optionRole={false}
        optionOccupation={false}
        limitDelete={true}
        isOpen={isMasterModal}
        handleClose={handleMasterModalClose}
        selectedId={selectedId}
        selectedName={selectedName}
        updateMutation={updateOccupation}
      />
      <div className="l-board__inner">
        <div className="l-board__wrap p-board__main">
          <div className="p-board__main--box">
            <table className="p-table">
              <thead>
                <tr>
                  <th>職種名</th>
                  <th>該当スタッフ名</th>
                </tr>
              </thead>
              <tbody>
                {occupations.map((occupation) => (
                  <tr
                    key={occupation.id}
                    // className="p-list__master--item"
                    // onClick={() => handleMasterModalOpen(occupation.id, occupation.name)}
                    onClick={
                      authority === "0"
                        ? () =>
                            handleMasterModalOpen(
                              occupation.id,
                              occupation.name
                            )
                        : null
                    }
                  >
                    <td>{occupation.name}</td>
                    <td>
                      {occupation.users
                        ? occupation.users
                            .map(
                              (user) => `${user.last_name} ${user.first_name}`
                            )
                            .join(", ")
                        : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OccupationList;
