// /resources/js/Components/Modal/TrainingEditModal.tsx

import React, { useEffect, useRef } from "react";
import { useUsersForSelectQuery } from "../../Queries/UserQuery";
import LoaderComponent from "../Layout/Loader";
import { useForm, Controller } from "react-hook-form";
import { useUpdateTrainingRecord } from "../../Queries/TrainingRecordQuery";
import RadioFieldGroup from "../Form/RadioFieldGroup";
import InputTextField from "../Form/InputTextField";
import TimeSelectBox from "../Form/TimeSelectBox";
import TextareaField from "../Form/TextareaField";
import SelectField from "../Form/SelectField";
import DateField from "../Form/DateField";
import "react-datepicker/dist/react-datepicker.css";

/* 時間 */
import { format } from "date-fns"; // 日付フォーマット用
import { toast } from "react-toastify";

interface TrainingEditModalProps {
  isOpen: boolean;
  handleClose: () => void;
  trainingDetail: TrainingRecord | undefined; // propsとしてtrainingDetailを受け取る
  refetchData: () => void; // 追加
}

interface FormValues {
  traineeId: string;
  instructorType: "inner" | "outer"; // 追加
  instructorName: string;
  instructorId: string;
  trainingItem: string;
  trainingName: string;
  trainingDetail: string;
  completeOn: Date | null;
  startTime: string;
  finishTime: string;
}

const defaultTrainingData: FormValues = {
  traineeId: "",
  instructorType: "inner", // デフォルト値を設定
  instructorName: "",
  instructorId: "",
  trainingItem: "",
  trainingName: "",
  trainingDetail: "",
  completeOn: null, // Date オブジェクトに変更
  startTime: "",
  finishTime: "",
};

const TrainingEditModal: React.VFC<TrainingEditModalProps> = ({
  isOpen,
  handleClose,
  trainingDetail,
  refetchData, // 追加
}) => {
  const { data: users, status: usersStatus } = useUsersForSelectQuery();
  const { mutate: updateTrainingRecord, isLoading: isUpdateLoading } =
    useUpdateTrainingRecord();

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: defaultTrainingData,
  });

  const isFirstRender = useRef(true); // 初期リセットフラグ

  // 初期値設定
  useEffect(() => {
    if (isOpen && trainingDetail) {
      console.log("Fetched Training Detail:", trainingDetail); // デバッグ用

      const completeOnDate = trainingDetail.completeOn
        ? new Date(trainingDetail.completeOn)
        : null;

      // `startTime` と `finishTime` を "HH:MM" 形式に変換
      const startTime = trainingDetail.startTime
        ? format(new Date(`1970-01-01T${trainingDetail.startTime}`), "HH:mm")
        : "00:00";
      const finishTime = trainingDetail.finishTime
        ? format(new Date(`1970-01-01T${trainingDetail.finishTime}`), "HH:mm")
        : "00:00";
      const instructorTypeValue =
        trainingDetail.instructorId !== 0 ? "inner" : "outer";

      reset({
        traineeId: trainingDetail.traineeId.toString() || "",
        instructorType: instructorTypeValue, // 追加
        instructorName: trainingDetail.instructorName || "",
        instructorId:
          trainingDetail.instructorId !== 0
            ? trainingDetail.instructorId.toString()
            : "",
        trainingItem: trainingDetail.trainingItem || "",
        trainingName: trainingDetail.trainingName || "",
        trainingDetail: trainingDetail.trainingDetail || "",
        completeOn: completeOnDate,
        startTime: startTime,
        finishTime: finishTime,
      });

      console.log("Form Values after reset:", getValues());

      isFirstRender.current = false; // 初期リセット完了

      // If instructorType is inner, set instructorName from user data
      if (trainingDetail.instructorId !== 0) {
        const user = users?.find(
          (user) => user.id === trainingDetail.instructorId
        );
        if (user) {
          setValue("instructorId", user.id.toString());
          setValue("instructorName", `${user.last_name} ${user.first_name}`);
        }
      } else {
        // outerの場合、instructorIdを0に設定し、instructorNameを空に
        setValue("instructorId", "0");
        setValue("instructorName", "");
      }
    } else if (!isOpen) {
      reset(defaultTrainingData); // モーダルが閉じられたらフォームをリセット
      isFirstRender.current = true; // フラグをリセット
    }
  }, [isOpen, trainingDetail, reset, users]);

  // 研修担当者タイプの変更に応じてフィールドを更新
  const selectedInstructorType = watch("instructorType");

  useEffect(() => {
    if (isFirstRender.current) {
      return; // 初期リセット時は何もしない
    }

    if (selectedInstructorType === "outer") {
      setValue("instructorId", "0");
      setValue("instructorName", "");
    } else if (selectedInstructorType === "inner" && users?.length > 0) {
      const firstUser = users[0];
      setValue("instructorId", firstUser.id.toString());
      setValue(
        "instructorName",
        `${firstUser.last_name} ${firstUser.first_name}`
      );
    }
  }, [selectedInstructorType, users, setValue]);

  // 更新処理
  const handleUpdateSubmit = (data: FormValues) => {
    if (!trainingDetail) {
      // エラーハンドリングはカスタムフック側で行うため、ここでは削除
      return;
    }

    // `instructor_id`に基づいて`instructor_name`を設定
    const instructorName =
      data.instructorId !== "0"
        ? users?.find((user) => user.id.toString() === data.instructorId)
            ?.last_name +
          " " +
          users?.find((user) => user.id.toString() === data.instructorId)
            ?.first_name
        : data.instructorName;

    const dataToSend = {
      id: trainingDetail.id,
      trainee_id: Number(data.traineeId),
      instructor_id: data.instructorId !== "0" ? Number(data.instructorId) : 0, // 'outer'の場合は0
      instructor_name: instructorName || null,
      training_item: data.trainingItem,
      training_name: data.trainingName,
      training_detail: data.trainingDetail,
      complete_on: data.completeOn
        ? format(data.completeOn, "yyyy-MM-dd")
        : null,
      start_time: data.startTime, // "HH:MM" 形式
      finish_time: data.finishTime, // "HH:MM" 形式
    };

    console.log("Submitting Data:", dataToSend); // デバッグ用

    updateTrainingRecord(dataToSend, {
      onSuccess: (response) => {
        if (response.message === "データに変更がありません。") {
          toast.error("変更データがありません。");
        } else {
          refetchData(); // データの再取得をトリガー
          handleClose(); // モーダルを閉じる
        }
      },
      // onErrorはカスタムフック側で処理されるため、ここでは削除
    });
  };

  if (!isOpen) return null;

  const isLoading = usersStatus === "loading" || isUpdateLoading;

  return (
    <div className="p-modal" onClick={handleClose}>
      <div className="p-modal__content" onClick={(e) => e.stopPropagation()}>
        <button className="p-modal__delete" onClick={handleClose}>
          <img src="/image/icon_btn_batsu_green.png" alt="Close" />
        </button>
        <h3 className="p-modal__headline">研修記録更新</h3>
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <form onSubmit={handleSubmit(handleUpdateSubmit)}>
            <SelectField
              label="研修受講者"
              options={users?.map((user) => ({
                value: user.id.toString(),
                label: `${user.last_name} ${user.first_name}`,
              }))}
              register={register}
              name="traineeId"
              error={errors.traineeId?.message}
            />

            {/* 研修担当者タイプの選択 */}
            <div className="p-input__area">
              <label className="c-label">研修担当者</label>
              <Controller
                control={control}
                name="instructorType"
                render={({ field }) => (
                  <RadioFieldGroup
                    options={[
                      { value: "inner", label: "社内レッスン担当者" },
                      { value: "outer", label: "外部レッスン担当者" },
                    ]}
                    name="instructorType"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.instructorType?.message}
                  />
                )}
              />
            </div>

            {/* 内部担当者の場合の選択フィールド */}
            {selectedInstructorType === "inner" && (
              <SelectField
                label="社内レッスン担当者"
                options={users?.map((user) => ({
                  value: user.id.toString(),
                  label: `${user.last_name} ${user.first_name}`,
                }))}
                register={register}
                name="instructorId"
                error={errors.instructorId?.message}
              />
            )}

            {/* 外部担当者の場合の入力フィールド */}
            {selectedInstructorType === "outer" && (
              <InputTextField
                label="外部レッスン担当者"
                type="text"
                {...register("instructorName", {
                  required: "外部レッスン担当者名は必須です。",
                })}
                error={errors.instructorName?.message}
              />
            )}

            {/* 研修項目 */}
            <InputTextField
              label="研修項目"
              type="text"
              {...register("trainingItem", {
                required: "研修項目は必須です。",
              })}
              error={errors.trainingItem?.message}
            />
            {/* 研修名 */}
            <InputTextField
              label="研修名"
              type="text"
              {...register("trainingName", { required: "研修名は必須です。" })}
              error={errors.trainingName?.message}
            />
            {/* 研修日: completeDate */}
            <Controller
              name="completeOn"
              control={control}
              render={({ field }) => (
                <DateField
                  label="研修実施日"
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.completeOn?.message}
                />
              )}
            />

            {/* 時間：startTime / finishTime */}
            <div className="p-input__area">
              <div className="area-label">
                <label className="c-label">開始・終了時間</label>
              </div>
              <div className="area-input l-flex">
                {/* 開始時間 */}
                <Controller
                  name="startTime"
                  control={control}
                  render={({ field }) => (
                    <TimeSelectBox
                      label="開始時間"
                      hourValue={field.value.split(":")[0]}
                      minuteValue={field.value.split(":")[1]}
                      setHour={(value: string) => {
                        const [_, minute] = field.value.split(":");
                        setValue("startTime", `${value}:${minute}`);
                      }}
                      setMinute={(value: string) => {
                        const [hour, _] = field.value.split(":");
                        setValue("startTime", `${hour}:${value}`);
                      }}
                      error={errors.startTime?.message}
                    />
                  )}
                />
                {/* 終了時間 */}
                <Controller
                  name="finishTime"
                  control={control}
                  render={({ field }) => (
                    <TimeSelectBox
                      label="終了時間"
                      hourValue={field.value.split(":")[0]}
                      minuteValue={field.value.split(":")[1]}
                      setHour={(value: string) => {
                        const [_, minute] = field.value.split(":");
                        setValue("finishTime", `${value}:${minute}`);
                      }}
                      setMinute={(value: string) => {
                        const [hour, _] = field.value.split(":");
                        setValue("finishTime", `${hour}:${value}`);
                      }}
                      error={errors.finishTime?.message}
                    />
                  )}
                />
              </div>
            </div>
            {/* 研修記録 */}
            <TextareaField
              label="研修記録"
              {...register("trainingDetail", {
                required: "研修記録は必須です。",
              })}
              error={errors.trainingDetail?.message}
            />
            <div className="p-input__area">
              <button
                type="submit" // ボタンのタイプを明示的に指定
                className="c-btn c-btn__green c-btn__bgGreen c-btn__small position-center"
                disabled={isUpdateLoading} // ロード中はボタンを無効化
              >
                {isUpdateLoading ? "更新中..." : "更新する"}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
export default TrainingEditModal;
