import React, { useState } from 'react';
import { useQueryClient, UseMutationResult } from 'react-query';

interface AddModalProps {
    title: string;
    isOpen: boolean;
    handleClose: () => void;
    allRole: any[];
    optionRole: boolean;
    selectedRole: any[];
    advanceEvaluationType: string;
    optionDetail: boolean;
    createMutation: UseMutationResult<any, any, any, any>;
}

const AddModal: React.VFC<AddModalProps> = ({
    title, isOpen, handleClose, allRole, advanceEvaluationType,
    optionRole, selectedRole, optionDetail, createMutation
}) => {
    const [addName, setAddName] = useState('');
    const [addDetail, setAddDetail] = useState('');
    const [editRole, setEditRole] = useState<any[]>(selectedRole || []);
    const queryClient = useQueryClient();

    const handleInputChange = (setter: React.Dispatch<React.SetStateAction<any>>) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.type === 'checkbox') {
            const role = allRole.find((r: any) => r.id === Number(e.target.id));
            const value = e.target.checked
                ? [...editRole, role]
                : editRole.filter((v: any) => v.id !== role.id);
            setter(value);
        } else {
            setter(e.target.value);
        }
    };


    const handleAdd = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        let roles = editRole.map((role: any) => role.id);
        let payload = {
            name: addName,
            ...(optionDetail ? { detail: addDetail } : {}),
            ...(advanceEvaluationType ? { evaluationType: advanceEvaluationType } : {}),
        };
        if (optionRole) payload = { ...payload, role: roles };

        createMutation.mutate(payload, {
            onSuccess: () => {
                queryClient.resetQueries();
                handleClose();
                setAddName('');
                setAddDetail('');
            },
            onError: (error) => { },
        });
    };


    const handleModalClose = () => {
        setAddName('');
        setAddDetail('');
        setEditRole([]);
        handleClose();
    };

    if (!isOpen) return null;

    return (
        <div className="p-modal" onClick={handleModalClose}>
            <div className="p-modal__content" onClick={(e) => e.stopPropagation()}>
                <button className="p-modal__delete" onClick={handleModalClose}>
                    <img src={'/image/icon_btn_batsu_green.png'} alt="Close" />
                </button>
                <h3 className="p-modal__headline">{title}</h3>
                <div className="p-input__area">
                    <label className="c-label">名称</label>
                    <input
                        className="c-input u-mt4"
                        type="text"
                        value={addName}
                        onChange={handleInputChange(setAddName)}
                    />
                </div>
                {optionDetail && (
                    <div className="p-input__area">
                        <label className="c-label">詳細</label>
                        <textarea
                            className="c-input c-input__textarea u-mt4"
                            value={addDetail}
                            onChange={handleInputChange(setAddDetail)}
                        />
                    </div>
                )}
                {optionRole && (
                    <div className="p-input__area">
                        <label className="c-label">役割</label>
                        <ul className="p-ckb__list">
                            {allRole.map((role: any) => {
                                const isChecked = editRole.some((er: any) => er.id === role.id);
                                return (
                                    <li key={role.id} className="p-ckb__item">
                                        <input
                                            type="checkbox"
                                            // id={`role-${role.id}`}
                                            id={role.id}
                                            // value={JSON.stringify(role)}
                                            checked={isChecked}
                                            onChange={handleInputChange(setEditRole)}
                                            className="p-ckb__input"
                                        />
                                        {/* <label htmlFor={`role-${role.id}`} className="p-ckb__label"> */}
                                        <label htmlFor={role.id} className="p-ckb__label">
                                            {role.name}
                                        </label>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
                <div className="p-input__area">
                    <button
                        className="c-btn c-btn__green c-btn__bgGreen c-btn__small position-center"
                        onClick={handleAdd}
                    >
                        追加する
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddModal;
