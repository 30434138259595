import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import isEqual from 'lodash/isEqual';
import InputTextField from '../Form/InputTextField';

const ShopFormModal = ({
    isOpen,
    handleClose,
    shopDetail,
    onAction,
    isEditMode
}) => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    useEffect(() => {
        reset(shopDetail || {}); // フォームを初期化
    }, [shopDetail, reset]);

    const onSubmit = (data) => {

        if (isEditMode && isEqual(data, shopDetail)) {
            toast.error('データの変更がありません。');
            return;
        }
        // onAction を非同期関数として扱う
        onAction(data);
    };

    if (!isOpen) return null;

    return (
        <div className="p-modal" onClick={handleClose}>
            <div className="p-modal__content" onClick={(e) => e.stopPropagation()}>
                <button className="p-modal__delete" onClick={handleClose}>
                    <img src={'/image/icon_btn_batsu_green.png'} alt="Close" />
                </button>
                <h3 className="p-modal__headline">店舗名を編集</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <InputTextField
                        label="名称"
                        type="text"
                        name="name"
                        {...register('name', { required: 'このフィールドは必須です' })}
                        error={errors.name?.message}
                    />
                    <div className="p-modal__btn">
                        <button
                            className="c-btn c-btn__green c-btn__bgGreen c-btn__small"
                            type="submit"
                        >
                            更新
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ShopFormModal;
