import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// コンポーネント
import EducationProgress from './EducationProgress';
import EducationResult from './EducationResult';
import EducationTraining from './EducationTraining';

const EducationBoard = (): JSX.Element => {
    /* フロント */
    const [activeTab, setActiveTab] = useState(1);
    // const [detailIndex, setDetailIndex] = useState(0);
    // const [detailShow, setDetailShow] = useState({});

    const navigate = useNavigate();
    const location = useLocation();

    // URLを参照してURL
    useEffect(() => {
        switch (location.pathname) {
            case '/hc-user/education/progress':
                setActiveTab(1);
                break;
            case '/hc-user/education/result':
                setActiveTab(2);
                break;
            case '/hc-user/education/training':
                setActiveTab(3);
                break;
            default:
                break;
        }
    }, [location.pathname]);

    // タブ機能
    const handleTabClick = (tabNum) => {
        setActiveTab(tabNum);
        switch (tabNum) {
            case 1:
                navigate('/hc-user/education/progress');
                break;
            case 2:
                navigate('/hc-user/education/result');
                break;
            case 3:
                navigate('/hc-user/education/training');
                break;
            default:
                break;
        }
    }

    return (
        <div className="l-board">
            <div className="l-board__inner">
                <ul className="p-board__tab">
                    <li
                        id="tab1"
                        className={`p-tab__btn ${activeTab === 1 ? 'is_active' : ''}`}
                        onClick={() => handleTabClick(1)}
                    >
                        アシスタント<br />カリキュラム
                    </li>
                    <li
                        id="tab2"
                        className={`p-tab__btn ${activeTab === 2 ? 'is_active' : ''}`}
                        onClick={() => handleTabClick(2)}
                    >
                        アシスタント<br />教育実績
                    </li>
                    <li
                        id="tab3"
                        className={`p-tab__btn ${activeTab === 3 ? 'is_active' : ''}`}
                        onClick={() => handleTabClick(3)}
                    >
                        レッスン<br />研修記録
                    </li>
                </ul>
                <div
                    id="panel1"
                    className={`p-tab__panel ${activeTab === 1 ? 'is_active' : ''}`}
                >
                    <EducationProgress />
                </div>
                <div
                    className={`p-tab__panel ${activeTab === 2 ? 'is_active' : ''}`}
                    id="panel2"
                >
                    <EducationResult />
                </div>
                <div
                    className={`p-tab__panel ${activeTab === 3 ? 'is_active' : ''}`}
                    id="panel3"
                >
                    <EducationTraining />
                </div>
            </div>
        </div>
    );
}
export default EducationBoard;
