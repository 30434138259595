import axios from 'axios';
import { convertKeysToCamelCase } from "../Utils/convertToCamelCase";

// 詳細
export const fetchCompanyEvaluationRule = async () => {
    try {
        const response = await axios.get('/api/users/company-evaluation-rule/detail');
        return convertKeysToCamelCase(response.data.data);
    } catch (error) {
        throw error.response.data;
    }
};

// 更新
export const updateCompanyEvaluationRule = async (companyData, evaluationRuleData) => {
    try {
        const response = await axios.post('/api/users/company-evaluation-rule/update', {
            company: companyData,
            evaluationRule: evaluationRuleData
        });
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};
