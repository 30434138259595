// UserStaffRoutes.jsx
import React from 'react';
import { Route } from 'react-router-dom';
import StaffList from '../Pages/User/Staff/StaffList.tsx';
import StaffDetail from '../Pages/User/Staff/StaffDetail.tsx';
import ShopList from '../Pages/User/Shop/ShopList.tsx';
import ShopDetail from '../Pages/User/Shop/ShopDetail.tsx';

export const staffRoutes = [
    {
        path: '/hc-user/staff/',
        element: <StaffList />
    },
    {
        path: '/hc-user/staff/detail/:userId',
        element: <StaffDetail />
    },
    {
        path: '/hc-user/shop/',
        element: <ShopList />
    },
    {
        path: '/hc-user/shop/detail/:shopId',
        element: <ShopDetail />
    }
];
