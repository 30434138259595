// UserAttendanceRoutes.jsx
import React from 'react';
import AttendanceBoard from '../Pages/User/Attendance/AttendanceBoard.tsx';
import AttendanceEdit from '../Pages/User/Attendance/AttendanceEdit.tsx';

export const attendanceRoutes = [
    { path: '/hc-user/attendance/detail/', element: <AttendanceBoard /> },
    { path: '/hc-user/attendance/report/', element: <AttendanceBoard /> },
    { path: '/hc-user/attendance/holiday/', element: <AttendanceBoard /> },
    { path: '/hc-user/attendance/edit/:userId/:yearMonth', element: <AttendanceEdit /> }
];
