import { AxiosError } from 'axios';
import * as api from "../Api/RankAPI"
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from "react-toastify";


const useRankList = () => {
    return useQuery('rankList', () => api.getRankList())
}

// // const useRankEdit = (id: number) => {
// //     return useQuery(['employmentEdit', id], () => api.getRankEdit(id));
// // }
// const useRankEdit = (id: number | null) => {
//     return useQuery(['employmentEdit', id], () => api.getRankEdit(id), {
//         enabled: id !== null,
//     });
// }

const useCreateRank = () => {
    const queryClient = useQueryClient();
    return useMutation(api.createRank, {
        onSuccess: () => {
            queryClient.invalidateQueries('ranks');
            toast.success('職位の登録に成功しました。');
        },
        onError: (error: AxiosError) => {
            if (error.response?.data.errors) {
                Object.values(error.response?.data.errors).forEach((messages: any) => {
                    messages.forEach((message: string) => {
                        toast.error(message)
                    })
                })
            } else {
                toast.error('職位の登録に失敗しました。');
            }
        }
    })
}

const useUpdateRank = () => {
    const queryClient = useQueryClient();
    return useMutation(api.updateRank, {
        onSuccess: () => {
            queryClient.invalidateQueries('ranks');
            toast.success('職位情報の更新に成功しました。');
        },
        onError: (error: AxiosError) => {
            if (error.response?.data.errors) {
                Object.values(error.response?.data.errors).forEach((messages: any) => {
                    messages.forEach((message: string) => {
                        toast.error(message)
                    })
                })
            } else {
                toast.error('職位情報の更新に失敗しました。');
            }
        }
    })
}

const useDeleteRank = () => {
    const queryClient = useQueryClient();
    return useMutation(api.deleteRank, {
        onSuccess: () => {
            queryClient.invalidateQueries('ranks');
            toast.success('職位を削除しました。');
        },
        onError: (error: AxiosError) => {
            if (error.response?.data.errors) {
                Object.values(error.response?.data.errors).forEach((messages: any) => {
                    messages.forEach((message: string) => {
                        toast.error(message)
                    })
                })
            } else {
                toast.error('職位の削除に失敗しました。');
            }
        }
    })
}

export {
    useRankList,
    useCreateRank,
    useUpdateRank,
    useDeleteRank
}
