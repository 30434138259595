import { AxiosError } from "axios";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as UserWorkAPI from "../Api/UserWorkAPI";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { UserWork } from "../Types/UserWork";

export const useAttendanceStatus = (userId?: string) => {
  return useQuery(["attendanceStatus", userId], () =>
    UserWorkAPI.getAttendanceStatus(userId)
  );
};

/* 出退勤機能 */
export const useStartWorkSession = (userId?: string) => {
  const queryClient = useQueryClient();
  return useMutation(() => UserWorkAPI.startWorkSession(userId), {
    onSuccess: (data) => {
      toast.success("出勤しました。");
      queryClient.resetQueries();
    },
    onError: (error: AxiosError) => {
      toast.error("出勤に失敗しました。");
    },
  });
};

export const useFinishWorkSession = (userId?: string) => {
  const queryClient = useQueryClient();
  return useMutation(() => UserWorkAPI.finishWorkSession(userId), {
    onSuccess: () => {
      toast.success("退勤しました。");
      queryClient.resetQueries();
    },
    onError: (error: AxiosError) => {
      toast.error("退勤に失敗しました。");
    },
  });
};

export const useStartBreakSession = (userId?: string) => {
  const queryClient = useQueryClient();
  return useMutation(() => UserWorkAPI.startBreakSession(userId), {
    onSuccess: (data) => {
      toast.success("休憩開始しました。");
      queryClient.resetQueries();
    },
    onError: (error: AxiosError) => {
      toast.error("休憩開始に失敗しました。");
    },
  });
};

export const useEndBreakSession = (userId?: string) => {
  const queryClient = useQueryClient();
  return useMutation(() => UserWorkAPI.endBreakSession(userId), {
    onSuccess: (data) => {
      toast.success("休憩終了しました。");
      queryClient.resetQueries();
    },
    onError: (error: AxiosError) => {
      toast.error("休憩終了に失敗しました。");
    },
  });
};

// 出勤日数
export const useFetchAttendanceCount = (userId: number, yearMonth: string) => {
  return useQuery(
    ["attendanceCount", userId, yearMonth],
    () => UserWorkAPI.fetchAttendanceCount(userId, yearMonth),
    {
      enabled: !!userId && !!yearMonth,
    }
  );
};

// 労働データ
export const useFetchAttendanceData = (userId: number, yearMonth: string) => {
  return useQuery(
    ["attendanceData", userId, yearMonth],
    () =>
      UserWorkAPI.fetchAttendanceData(userId, yearMonth).then((response) => {
        return response;
      }),
    {
      enabled: !!userId && !!yearMonth,
    }
  );
};

// 該当ユーザーの該当期間の実動時間
export const useFetchActualWorkHours = (userId: number, yearMonth: string) => {
  return useQuery(
    ["actualWorkHours", userId, yearMonth],
    () => UserWorkAPI.fetchActualWorkHours(userId, yearMonth),
    {
      enabled: !!userId && !!yearMonth,
    }
  );
};
// 該当ユーザーの該当期間の残業時間
export const useFetchOvertimeHours = (userId: number, yearMonth: string) => {
  return useQuery(
    ["overtimeHours", userId, yearMonth],
    () => UserWorkAPI.fetchOvertimeHours(userId, yearMonth),
    {
      enabled: !!userId && !!yearMonth,
    }
  );
};

// 該当ユーザーの該当期間の深夜労働時間
export const useFetchLateNightHours = (userId: number, yearMonth: string) => {
  return useQuery(
    ["lateNightHours", userId, yearMonth],
    () => UserWorkAPI.fetchLateNightHours(userId, yearMonth),
    {
      enabled: !!userId && !!yearMonth,
    }
  );
};

// 勤怠_ユーザーの休日数
export const useFetchHolidayCount = (userId: number, yearMonth: string) => {
  return useQuery(
    ["holidayCount", userId, yearMonth],
    () => UserWorkAPI.fetchHolidayCount(userId, yearMonth),
    {
      enabled: !!userId && !!yearMonth,
    }
  );
};
// 勤怠_ユーザーの休日出勤数
export const useFetchHolidayAttendanceCount = (
  userId: number,
  yearMonth: string
) => {
  return useQuery(
    ["holidayAttendanceCount", userId, yearMonth],
    () => UserWorkAPI.fetchHolidayAttendanceCount(userId, yearMonth),
    {
      enabled: !!userId && !!yearMonth,
    }
  );
};

// 出勤データ編集ページデータ取得
export const useFetchEditableAttendanceData = (
  userId: number,
  yearMonth: string
) => {
  return useQuery(
    ["editAttendanceData", userId, yearMonth],
    () => UserWorkAPI.fetchEditableAttendanceData(userId, yearMonth),
    {
      enabled: !!userId && !!yearMonth,
    }
  );
};

// 出勤データ更新
export const useUpdateAttendanceData = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation(
    ({ userId, updatedData }: { userId: string; updatedData: UserWork[] }) => {
      // データの処理
      const filteredData = updatedData.map((data) => {
        const {
          id,
          workOn,
          startAt,
          finishAt,
          firstRestStartAt,
          firstRestFinishAt,
          secondRestStartAt,
          secondRestFinishAt,
        } = data;
        return {
          id, // id はオプショナル
          userId,
          workOn,
          startAt,
          finishAt,
          firstRestStartAt,
          firstRestFinishAt,
          secondRestStartAt,
          secondRestFinishAt,
        };
      });
      return UserWorkAPI.updateAttendanceData({ updatedData: filteredData });
    },
    {
      onSuccess: (data, variables) => {
        toast.success("出勤データが更新されました。");
        queryClient.invalidateQueries(['attendance', variables.userId]); // 特定のクエリをリフェッチ
        navigate("/hc-user/attendance/report", {
          state: { userId: variables.userId },
        });
      },
      // onErrorはインターセプターで一元化しているため不要
    }
  );
};
