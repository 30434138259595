import axios from "axios";
import { toast } from "react-toastify";

// 環境変数からベースURLを取得（必要に応じて設定）
const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL || "",
  headers: {
    "Content-Type": "application/json",
  },
});

// レスポンスインターセプターでエラーハンドリングを一元化
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 403) {
      toast.error("権限が付与されていないため実行できません。");
    } else if (error.response?.data?.message) {
      // バックエンドからの具体的なエラーメッセージを表示
      toast.error(error.response.data.message);
    } else {
      toast.error("出勤データの更新に失敗しました。管理者にお問い合わせください。");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
