import axios from "axios";
import { EmploymentStatus } from "../Types/EmploymentStatus"

// 一覧
const getEmploymentStatusList = async () => {
    const { data } = await axios.get<EmploymentStatus[]>(
        '/api/users/employment/list'
    );
    return data
}

// 追加
const createEmploymentStatus = async (newEmploymentStatus: { name: string }) => {
    const { data } = await axios.post<EmploymentStatus[]>(
        '/api/users/employment/add', newEmploymentStatus
    );
    return data
}

// // 編集
// const getEmploymentStatusEdit = async (id : number) => {
//     const { data } = await axios.get<EmploymentStatus[]>(
//         `/api/users/employment/edit/${id}`
//     );
//     return data
// }

// 更新
const updateEmploymentStatus = async ({id, name}: {id: number, name: string}) => {
    const { data } = await axios.put<EmploymentStatus[]>(
        `/api/users/employment/update/${id}`, { id, name }
    );
    return data
}

// 削除
const deleteEmploymentStatus = async (id: number) => {
    const { data } = await axios.delete(
        `/api/users/employment/delete/${id}`
    );
    return data;
}

export {
    getEmploymentStatusList,
    createEmploymentStatus,
    updateEmploymentStatus,
    deleteEmploymentStatus
};
