import { useAuthority } from './Contexts/AuthorityContext';
import { Navigate } from 'react-router-dom';

const urlPatterns = [
  {
    pattern: /^\/hc-user\/attendance\/edit\/\d+\/\d+\/?$/,
    action: (match, authority) => {
      if (authority === 0) { // 権限0の場合のみ許可
        return {
          title: "勤怠編集",
          url: "/hc-user/attendance/report",
          isAttActive: true,
        };
      } else {
        return <Navigate to="/no-authority" replace />;
      }
    },
  },
  {
    pattern: /^\/hc-user\/attendance\/(detail|report|holiday)\/?$/,
    action: () => ({
      title: "勤怠",
      isAttActive: true,
    }),
  },
  {
    pattern: /^\/hc-user\/education\/(progress|result|training)\/?$/,
    action: () => ({
      title: "教育・研修",
      isEduActive: true,
    }),
  },
  {
    pattern: /^\/hc-user\/education\/training\/detail\/\d+\/?$/,
    action: () => ({
      url: "/hc-user/education/training",
      title: "研修記録詳細",
      isEduActive: true,
    }),
  },
  {
    pattern: /^\/hc-user\/education\/curriculum\/(detail|result)\/\d+\/\d+\/?$/,
    action: () => ({
      url: "/hc-user/education/progress",
      title: "達成状況詳細",
      isEduActive: true,
    }),
  },
  {
    pattern: /^\/hc-user\/education\/result\/detail\/\d+$/,
    action: () => ({
      url: "/hc-user/education/result",
      title: "教育実績詳細",
      isEduActive: true,
    }),
  },
  {
    // pattern: /^\/hc-user\/performance\/(shop|staff|goal)\/?$/,
    pattern: /^\/hc-user\/performance\/(shop|staff|goal(?:\/[^\/]+)?)\/?$/,
    action: () => ({
      title: "業績",
      isPerActive: true,
    }),
  },
  {
    pattern: /^\/hc-user\/performance\/goal\/edit\/(company|user)\/\d+\/\d+$/,
    action: () => ({
      url: "/hc-user/performance/goal",
      title: "業績目標編集",
      isPerActive: true,
    }),
  },
  {
    pattern: /^\/hc-user\/performance\/shop\/edit\/\d+\/\d+$/,
    action: () => ({
      url: "/hc-user/performance/shop",
      title: "店舗業績編集",
      isPerActive: true,
    }),
  },
  {
    pattern: /^\/hc-user\/performance\/staff\/edit\/\d+\/\d+$/,
    action: () => ({
      url: "/hc-user/performance/staff",
      title: "スタッフ業績編集",
      isPerActive: true,
    }),
  },
  {
    // pattern: /^\/hc-user\/hr\/(evaluation|performance|elements)\/$/,
    pattern: /^\/hc-user\/hr\/(evaluation|performance|elements)\/?$/,
    action: () => ({
      title: "人事考課",
      isHrActive: true,
    }),
  },
  {
    pattern: /^\/hc-user\/hr\/evaluation\/compare\/?$/,
    action: () => ({
      url: "/hc-user/hr/evaluation",
      title: "評価を比較する",
      isHrActive: true,
    }),
  },
  {
    pattern:
      /^\/hc-user\/hr\/performance\/detail\/\d+\/\d+\/\d{4}\/\d{1,2}\/?$/,
    action: () => ({
      url: "/hc-user/hr/performance",
      title: "業績詳細",
      isHrActive: true,
    }),
  },
  {
    pattern: /^\/hc-user\/hr\/evaluation\/edit\/\d+\/\d{6}\/?$/,
    action: () => ({
      url: "/hc-user/hr/evaluation",
      title: "人事考課シート編集",
      isHrActive: true,
    }),
  },
  {
    pattern: /^\/hc-user\/hr\/elements\/edit\/\d+\/(stylist|assistant)\/?$/,
    action: () => ({
      url: "/hc-user/hr/elements",
      title: "考課素点要素編集",
      isHrActive: true,
    }),
  },
  {
    pattern: /^\/hc-user\/staff\/?$/,
    action: () => ({
      title: "スタッフ",
      isStaActive: true,
    }),
  },
  {
    pattern: /^\/hc-user\/staff\/add$/,
    action: () => ({
      url: "/hc-user/staff/",
      title: "スタッフ追加",
      isStaActive: true,
    }),
  },
  {
    pattern: /^\/hc-user\/staff\/detail\//,
    action: () => ({
      url: "/hc-user/staff",
      title: "スタッフ詳細",
      isStaActive: true,
    }),
  },
  {
    pattern: /^\/hc-user\/shop\/?$/,
    action: () => ({
      title: "店舗",
      isShoActive: true,
    }),
  },
  {
    pattern: /^\/hc-user\/shop\/detail\//,
    action: () => ({
      url: "/hc-user/shop",
      title: "店舗詳細",
      isShoActive: true,
    }),
  },
  {
    pattern: /^\/hc-user\/calculation\/edit$/,
    action: () => ({
      url: "/hc-user/calculation",
      title: "労働時間",
    }),
  },
  {
    pattern: /^\/hc-user\/company\/(detail|rule|master)\/?$/,
    action: () => ({
      title: "会社",
      isComActive: true,
    }),
  },
  {
    pattern: /^\/hc-user\/company\/edit/,
    action: () => ({
      url: "/hc-user/company/master",
      title: "会社編集",
    }),
  },
  {
    pattern: /^\/hc-user\/company\/employment/,
    action: () => ({
      url: "/hc-user/company/master",
      title: "雇用形態",
      isComActive: "true",
    }),
  },
  {
    pattern: /^\/hc-user\/company\/occupation/,
    action: () => ({
      url: "/hc-user/company/master",
      title: "職種",
      isComActive: "true",
    }),
  },
  {
    pattern: /^\/hc-user\/company\/rank/,
    action: () => ({
      url: "/hc-user/company/master",
      title: "職位",
      isComActive: "true",
    }),
  },
  {
    pattern: /^\/hc-user\/company\/role/,
    action: () => ({
      url: "/hc-user/company/master",
      title: "役割",
      isComActive: "true",
    }),
  },
  {
    pattern: /^\/hc-user\/company\/evaluation/,
    action: () => ({
      url: "/hc-user/company/master",
      title: "勤務項目",
      isComActive: "true",
    }),
  },
  {
    pattern:
      /^\/hc-user\/company\/performance-goal\/edit\/company\/(\d+)\/\d{4}\/?$/,
    action: () => {
      // URLを取得
      const url = window.location.pathname;
      const matches = url.match(
        /^\/hc-user\/company\/performance-goal\/edit\/company\/(\d+)\/\d{4}\/?$/
      );
      if (matches && matches[1]) {
        // 新しいURLを生成
        const newUrl = `/hc-user/company/performance-goal/${matches[1]}`;
        return {
          url: newUrl,
          title: "会社業績目標編集",
          isComActive: "true",
        };
      }
    },
  },
  {
    pattern:
      /^\/hc-user\/company\/performance-goal\/edit\/user\/(\d+)\/\d{4}\/?$/,
    action: () => {
      return {
        url: `/hc-user/company/master`,
        title: "スタッフ業績目標編集",
        isComActive: "true",
      };
    },
  },
  {
    pattern: /^\/hc-user\/company\/performance-goal\/(\d+)\/?$/,
    action: () => ({
      title: "業績目標",
      isComActive: "true",
      url: "/hc-user/company/master",
    }),
  },
  {
    pattern: /^\/hc-user\/mypage/,
    action: () => ({
      title: "マイページ",
    }),
  },
];
export default urlPatterns;
