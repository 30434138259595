// /resources/js/Pages/User/Education/EducationTrainingDetail.tsx

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import TrainingEditModal from "../../../Components/Modal/TrainingEditModal";
import {
  useTrainingRecordDetail,
  useDeleteTrainingRecord,
} from "../../../Queries/TrainingRecordQuery";
import LoaderComponent from "../../../Components/Layout/Loader";
import ConfirmDeleteModal from "../../../Components/Modal/ConfirmDeleteModal";
import { formatDate, formatTime } from "../../../Utils/formatUtils";
import { useAuthority } from "../../../Contexts/AuthorityContext";

type AuthorityType = 0 | 1 | 2 | 9;

const EducationTrainingDetail = (): JSX.Element => {
  const { authority } = useAuthority(); // authority は number | null
  const { trainingRecordId } = useParams<{ trainingRecordId: string }>();

  // React Queryからデータとロード状態を取得
  const {
    data: trainingDetail,
    isLoading: isDetailLoading,
    refetch: refetchData, // refetchを取得
  } = useTrainingRecordDetail(Number(trainingRecordId));

  const deleteTraining = useDeleteTrainingRecord();

  /* 編集モーダル */
  const [isEditModal, setIsEditModal] = useState(false);
  const handleEditModalOpen = () => {
    setIsEditModal(true);
  };
  const handleEditModalClose = () => {
    setIsEditModal(false);
  };

  /* 削除モーダル */
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const handleDeleteModalOpen = () => {
    setIsDeleteModal(true);
  };
  const handleDeleteModalClose = () => {
    setIsDeleteModal(false);
  };

  // authorityが 0 または 1 の場合にtrueを返す関数
  const isAuthorized = () => [0, 1].includes(authority as AuthorityType);

  return (
    <>
      {isAuthorized() && (
        <>
          <TrainingEditModal
            isOpen={isEditModal}
            handleClose={handleEditModalClose}
            trainingDetail={trainingDetail}
            refetchData={refetchData} // refetchDataを渡す
          />
          <ConfirmDeleteModal
            title="研修記録削除"
            isOpen={isDeleteModal}
            handleClose={handleDeleteModalClose}
            selectedId={trainingRecordId}
            deleteMutation={deleteTraining}
            refetchData={refetchData} // 必要に応じて渡す
          />
        </>
      )}

      <div className="l-board">
        <div className="l-board__inner">
          {isDetailLoading ? (
            <LoaderComponent />
          ) : (
            <>
              {isAuthorized() && (
                <div className="l-board__wrap p-board__btn no-border">
                  <div className="l-board__btn--box">
                    <button
                      className="c-btn__table c-btn c-btn__blue"
                      onClick={handleEditModalOpen}
                    >
                      編集
                    </button>
                  </div>
                  <div className="l-board__btn--box">
                    <button
                      className="c-btn__table c-btn c-btn__red"
                      onClick={handleDeleteModalOpen}
                    >
                      削除
                    </button>
                  </div>
                </div>
              )}
              <div className="l-board__wrap p-board__main">
                <table className="p-table p-table__detail">
                  <tbody>
                    <tr>
                      <td className="headline">受講者</td>
                      <td>{trainingDetail?.traineeName || "-"}</td>
                    </tr>
                    <tr>
                      <td className="headline">研修担当者</td>
                      <td>{trainingDetail?.instructorName || "-"}</td>
                    </tr>
                    <tr>
                      <td className="headline">研修項目</td>
                      <td>{trainingDetail?.trainingItem || "-"}</td>
                    </tr>
                    <tr>
                      <td className="headline">研修名称</td>
                      <td>{trainingDetail?.trainingName || "-"}</td>
                    </tr>
                    <tr>
                      <td className="headline">研修記録</td>
                      <td>{trainingDetail?.trainingDetail || "-"}</td>
                    </tr>
                    <tr>
                      <td className="headline">研修実施日</td>
                      <td>
                        {trainingDetail?.completeOn
                          ? formatDate(trainingDetail.completeOn)
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td className="headline">研修時間</td>
                      <td>
                        {trainingDetail?.startTime
                          ? formatTime(trainingDetail.startTime)
                          : "-"}
                        〜
                        {trainingDetail?.finishTime
                          ? formatTime(trainingDetail.finishTime)
                          : "-"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default EducationTrainingDetail;
