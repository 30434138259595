import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import isEqual from "lodash/isEqual";
import { toast } from "react-toastify";
import { isValid, parseISO } from "date-fns"; // isValid をインポート
/* 入力系コンポーネント */
import RadioFieldGroup from "../Form/RadioFieldGroup";
import InputTextField from "../Form/InputTextField";
import InputSubField from "../Form/InputSubField";
import SelectField from "../Form/SelectField";
import DateField from "../Form/DateField";
import { useAuthority } from "../../Contexts/AuthorityContext";

const StaffFormModal = ({
  isOpen,
  handleClose,
  shopList,
  employmentStatusList,
  occupationList,
  rankList,
  onAction,
  editData = null,
}) => {
  const { authority } = useAuthority();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    mode: "onBlur",
    defaultValues: editData || {},
  });

  // occupations設定
  useEffect(() => {
    if (!editData && occupationList && occupationList.length > 0) {
      setValue("occupationId", occupationList[0].id);
    }
  }, [editData, occupationList, setValue]);

  // 編集データの初期値設定
  useEffect(() => {
    if (editData) {
      setValue("shopId", editData.shopId || "");
      setValue("rankId", editData.rankId || "");
      setValue("employmentStatusId", editData.employmentStatusId || "");
      setValue("occupationId", editData.occupationId || "");
      setValue("joinedOn", editData.joinedOn || null);
      setValue("retirementOn", editData.retirementOn || null);
      if (editData.isAuthority == 9) {
        setValue("isKintaiAccount", true);
      }
    }
  }, [editData, setValue]);

  const isEditMode = editData !== null;

  const onSubmit = (data) => {
    const successCallback = () => {
      handleClose();
      reset();
    };

    if (isEditMode) {
      const watchedFields = watch();
      if (!isEqual(editData, watchedFields)) {
        onAction({ ...data, id: editData.id }, successCallback);
      } else {
        toast.error("変更データがありません。");
      }
    } else {
      onAction(data, successCallback);
    }
  };

  const isKintaiAccount = watch("isKintaiAccount", false);
  const showKintaiAccountCheckbox =
    !editData || (editData && editData.isAuthority === "9");

  // 勤怠のチェックボックスによって変更する
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "isKintaiAccount") {
        if (value.isKintaiAccount === true) {
          setValue("isAuthority", "9"); // 勤怠アカウントにチェックされた場合
        } else {
          setValue("isAuthority", "2"); // 勤怠アカウントのチェックが外れた場合
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  if (!isOpen) return null;

  return (
    <div className="p-modal" onClick={handleClose}>
      <div className="p-modal__content" onClick={(e) => e.stopPropagation()}>
        <button className="p-modal__delete" onClick={handleClose}>
          <img src={"/image/icon_btn_batsu_green.png"} />
        </button>
        <h3 className="p-modal__headline">
          {isEditMode ? "スタッフ編集" : "スタッフ追加"}
        </h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          {showKintaiAccountCheckbox && (
            <div className="p-input__area">
              <div className="area-label">
                <label className="c-label">勤怠アカウント</label>
              </div>
              <div className="area-input u-tAlign--center">
                <input type="checkbox" {...register("isKintaiAccount")} />
              </div>
            </div>
          )}
          {!isKintaiAccount && (
            <>
              {authority == "0" && (
                <>
                  <InputTextField
                    label="コード(半角数字6桁)"
                    type="text"
                    {...register("code", {
                      required: "コードは必須です",
                      maxLength: {
                        value: 6,
                        message: "コードは6桁以下でなければなりません",
                      },
                    })}
                    error={errors.code?.message || ""}
                  />
                  <SelectField
                    label="権限範囲"
                    name="isAuthority"
                    register={register}
                    options={[
                      { value: "2", label: "一般" },
                      { value: "1", label: "教育担当者" },
                      { value: "0", label: "管理者" },
                    ]}
                    error={errors.isAuthority?.message || ""}
                  />
                </>
              )}
              <div className="p-input__area">
                <div className="area-label">
                  <label className="c-label">名前</label>
                </div>
                <div className="area-input">
                  <div className="l-flex align-top">
                    <InputSubField
                      label="姓"
                      type="text"
                      name="lastName"
                      {...register("lastName", { required: "姓は必須です" })}
                      error={errors.lastName?.message || ""}
                    />
                    <InputSubField
                      label="名"
                      type="text"
                      name="firstName"
                      {...register("firstName", { required: "名は必須です" })}
                      error={errors.firstName?.message || ""}
                    />
                  </div>
                </div>
                <div className="area-input">
                  <div className="l-flex align-top">
                    <InputSubField
                      label="せい"
                      type="text"
                      name="lastNameKana"
                      {...register("lastNameKana", {
                        required: "せいは必須です",
                      })}
                      error={errors.lastNameKana?.message || ""}
                    />
                    <InputSubField
                      label="な"
                      type="text"
                      name="firstNameKana"
                      {...register("firstNameKana", {
                        required: "なは必須です",
                      })}
                      error={errors.firstNameKana?.message || ""}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <InputTextField
            label="メールアドレス"
            type="email"
            name="email"
            {...register("email", {
              required: "メールアドレスは必須です",
              pattern: {
                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,9}$/,
                message: "無効なメールアドレス形式です",
              },
            })}
            error={errors.email?.message || ""}
          />
          {!isEditMode && (
            <>
              {/* パスワード */}
              <InputTextField
                label="パスワード（英数字8桁以上）"
                type="password"
                name="password"
                {...register("password", {
                  required: "パスワードは必須です",
                  minLength: {
                    value: 8,
                    message: "パスワードは8文字以上でなければなりません",
                  },
                })}
                error={errors.password?.message || ""}
              />
              {/* 確認用パスワード */}
              <InputTextField
                label="パスワード(確認用)"
                type="password"
                name="passwordConfirmation"
                {...register("passwordConfirmation", {
                  required: "パスワードの確認は必須です",
                  validate: (value) =>
                    value === watch("password") || "パスワードが一致しません",
                })}
                error={errors.passwordConfirmation?.message || ""}
              />
            </>
          )}

          {authority == "0" && (
            <>
              {!isKintaiAccount && (
                <>
                  <Controller
                    control={control}
                    name="joinedOn"
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DateField
                        label="入社日"
                        value={value ? new Date(value) : null}
                        onChange={onChange}
                        error={error?.message || ""}
                        onReset={() => setValue("joinedOn", null)}
                        showReset={true}
                      />
                    )}
                  />
                  {isEditMode && (
                    <Controller
                      control={control}
                      name="retirementOn"
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <DateField
                          label="退社日"
                          value={value ? new Date(value) : null}
                          onChange={onChange}
                          error={error?.message || ""}
                          onReset={() => setValue("retirementOn", null)}
                          showReset={true} // リセット機能
                        />
                      )}
                    />
                  )}
                  <SelectField
                    label="所属店舗"
                    options={[
                      ...shopList.map((shop) => ({
                        value: shop.id,
                        label: shop.name,
                      })),
                      { value: "", label: "未設定" }, // 末尾に「未設定」を追加
                    ]}
                    register={register}
                    name="shopId"
                    error={errors.shopId?.message || ""}
                  />
                  <SelectField
                    label="雇用形態"
                    options={[
                      { value: "", label: "未設定" },
                      ...employmentStatusList.map((status) => ({
                        value: status.id.toString(),
                        label: status.name,
                      })),
                    ]}
                    register={register}
                    name="employmentStatusId"
                    error={errors.employmentStatusId?.message || ""}
                  />

                  <SelectField
                    label="職位"
                    options={[
                      { value: "", label: "未設定" },
                      ...rankList.map((rank) => ({
                        value: rank.id.toString(),
                        label: rank.name,
                      })),
                    ]}
                    register={register}
                    name="rankId"
                    error={errors.rankId?.message || ""}
                  />
                  <Controller
                    control={control}
                    name="occupationId"
                    rules={{ required: "職種は必須です" }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <RadioFieldGroup
                        label="職種"
                        options={occupationList.map((occupation) => ({
                          value: occupation.id,
                          label: occupation.name,
                        }))}
                        name="occupationId"
                        value={value}
                        onChange={onChange}
                        error={error?.message || ""}
                      />
                    )}
                  />
                </>
              )}
            </>
          )}
          <div className="p-input__area">
            <button
              className="c-btn c-btn__green c-btn__bgGreen c-btn__small position-center"
              type="submit"
            >
              {isEditMode ? "編集する" : "追加する"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default StaffFormModal;
