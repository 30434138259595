import axios from "axios";
import { Role } from "../Types/Role"

// 一覧
const getRoleList = async () => {
    const { data } = await axios.get<Role[]>(
        '/api/users/role/list'
    );
    return data
}

// 追加
const createRole = async (newRole: { name: string, detail: string }) => {
    const { data } = await axios.post<Role[]>(
        '/api/users/role/add', newRole
    );
    return data
}

// // 編集
// const getRoleEdit = async (id : number) => {
//     const { data } = await axios.get<Role[]>(
//         `/api/users/employment/edit/${id}`
//     );
//     return data
// }

// 更新
const updateRole = async ({id, name, detail}: {id: number, name: string, detail: string}) => {
    const { data } = await axios.put<Role[]>(
        `/api/users/role/update/${id}`, { id, name, detail }
    );
    return data
}

// 削除
const deleteRole = async (id: number) => {
    const { data } = await axios.delete(
        `/api/users/role/delete/${id}`
    );
    return data;
}

export {
    getRoleList,
    createRole,
    updateRole,
    deleteRole
};
