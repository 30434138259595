import { useMutation, useQuery, useQueryClient } from 'react-query';
import { listResultsByUserAndDate, addCurriculumResult, getCurriculumResultDetail, deleteCurriculumResult, getEditCurriculumResult, updateCurriculumResult } from '../Api/CurriculumResultAPI';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// 一覧機能
export const useFetchResultsByUserAndDate = (userId: number, yearMonth: string) => {
    return useQuery(['resultsByUserAndDate', userId, yearMonth], () => listResultsByUserAndDate(userId, yearMonth), {
        onSuccess: () => {
            // toast.success("カリキュラム実績の取得に成功しました。");
        },
        onError: (error) => {
            console.error("Error fetching results by user and date:", error);
            toast.error("カリキュラム実績の取得に失敗しました。");
        },
        enabled: !!userId && !!yearMonth // この行を追加
    });
};


// 登録機能: add
export const useAddCurriculumResult = (onCloseModal) => {
    const queryClient = useQueryClient();
    return useMutation(addCurriculumResult, {
        onSuccess: () => {
            toast.success('教育実績を追加しました。');
            queryClient.resetQueries();
            onCloseModal(); // モーダルを閉じる
        },
        onError: (error) => {
            console.error("エラー理由:", error);
            toast.error("カリキュラム実績の追加に失敗しました。");
        }
    });
};

// 詳細
export const useFetchCurriculumResultDetail = (curriculumResultId: number) => {
    return useQuery(['curriculumResultDetail', curriculumResultId], () => getCurriculumResultDetail(curriculumResultId), {
        onSuccess: () => {
            // toast.success("カリキュラム実績の詳細を取得しました。");
        },
        onError: (error) => {
            console.error("エラー理由:", error);
            toast.error("カリキュラム実績の詳細の取得に失敗しました。");
        },
        enabled: !!curriculumResultId
    });
};

// 削除
export const useDeleteCurriculumResult = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    return useMutation(deleteCurriculumResult, {
        onSuccess: () => {
            queryClient.invalidateQueries('curriculumResults'); // キャッシュを無効化
            navigate('/hc-user/education/result');
            toast.success('教育実績の削除に成功しました！');
        },
        onError: () => {
            toast.error('予期せぬエラーが発生しました。管理者にお問い合わせください。');
        },
    });
};

// 編集
export const useEditCurriculumResult = (id: number) => {
    return useQuery(["curriculumResult", id], () => getEditCurriculumResult(id), {
        onError: () => {
            toast.error("予期せぬエラーが発生しました。管理者にお問い合わせください。");
        }
    });
};

export const useUpdateCurriculumResult = () => {
    const queryClient = useQueryClient();

    return useMutation(updateCurriculumResult, {
        onSuccess: () => {
            queryClient.invalidateQueries('curriculumResults');
        },
        onError: (error) => {
            toast.error('予期せぬエラーが発生しました。管理者にお問い合わせください。');
        }
    });
};

