import React from "react";
import { Route } from "react-router-dom";
import HrBoard from "../Pages/User/Hr/HrBoard.tsx";
import HrCompareEvaluation from "../Pages/User/Hr/HrCompareEvaluation.tsx";
import HrEvaluationEdit from "../Pages/User/Hr/HrEvaluationEdit.tsx";
import HrPerformanceDetail from "../Pages/User/Hr/HrPerformanceDetail.tsx";
import HrElementsEdit from "../Pages/User/Hr/HrElementsEdit.tsx";

export const hrRoutes = [
  {
    path: "/hc-user/hr/evaluation",
    element: <HrBoard />,
  },
  {
    path: "/hc-user/hr/evaluation/compare",
    element: <HrCompareEvaluation />,
  },
  {
    path: "/hc-user/hr/evaluation/edit/:userId/:yearMonth",
    element: <HrEvaluationEdit />,
  },
  {
    path: "/hc-user/hr/performance",
    element: <HrBoard />,
  },
  {
    path: "/hc-user/hr/performance/detail/:userId/:performanceItemId/:year/:month",
    element: <HrPerformanceDetail />,
  },
  {
    path: "/hc-user/hr/elements",
    element: <HrBoard />,
  },
  {
    path: "/hc-user/hr/elements/edit/:companyId/:occupation",
    element: <HrElementsEdit />,
  },
];
