import React from 'react';
type AttendanceButtonProps = {
    isColor: string;
    isActive: boolean;
    onClick: () => void;
    label: string;
};

const AttendanceButton: React.VFC<AttendanceButtonProps> = ({ isColor, isActive, onClick, label }) => {
    return (
        <button
            className={`btn ${isColor} ${isActive ? 'is_active' : ''}`}
            onClick={isActive ? onClick : () => { alert('不明なエラーです。管理者に問い合わせください。'); }}>
            {label}
        </button>

    );
};

export default AttendanceButton;
