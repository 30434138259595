import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

/* コンポーネント */
import CompanyMaster from './CompanyMaster';
import CompanyRule from './CompanyRule';
import CompanyDetail from './CompanyDetail';

const CompanyBoard = (): JSX.Element => {
    const [activeTab, setActiveTab] = useState(1);
    const navigate = useNavigate();
    const location = useLocation();

    // URLを参照してURL
    useEffect(() => {
        switch (location.pathname) {
            case '/hc-user/company/detail':
                setActiveTab(1);
                break;
            case '/hc-user/company/rule':
                setActiveTab(2);
                break;
            case '/hc-user/company/master':
                setActiveTab(3);
                break;
            default:
                break;
        }
    }, [location.pathname]);

    // タブ機能
    const handleTabClick = (tabNum) => {
        setActiveTab(tabNum);
        switch (tabNum) {
            case 1:
                navigate('/hc-user/company/detail');
                break;
            case 2:
                navigate('/hc-user/company/rule');
                break;
            case 3:
                navigate('/hc-user/company/master');
                break;
            default:
                break;
        }
    }

    return (
        <div className="l-board">
            <div className="l-board__inner">
                <ul className="p-board__tab">
                    <li
                        id="tab1"
                        className={`p-tab__btn ${activeTab === 1 ? 'is_active' : ''}`}
                        onClick={() => handleTabClick(1)}
                    >会社詳細</li>
                    <li
                        id="tab2"
                        className={`p-tab__btn ${activeTab === 2 ? 'is_active' : ''}`}
                        onClick={() => handleTabClick(2)}
                    >労働時間ルール</li>
                    <li
                        id="tab3"
                        className={`p-tab__btn ${activeTab === 3 ? 'is_active' : ''}`}
                        onClick={() => handleTabClick(3)}
                    >マスター</li>
                </ul>
                {/* PANEL1: 会社詳細 */}
                <div
                    id="panel1"
                    className={`p-tab__panel ${activeTab === 1 ? 'is_active' : ''}`}
                >
                    <CompanyDetail />
                </div>
                {/* panel2: 休日設定 */}
                <div
                    className={`p-tab__panel ${activeTab === 2 ? 'is_active' : ''}`}
                    id="panel2"
                >
                    <CompanyRule />
                </div>
                {/* panel3: マスター */}
                <div
                    className={`p-tab__panel ${activeTab === 3 ? 'is_active' : ''}`}
                    id="panel3"
                >
                    <CompanyMaster />
                </div>
            </div>
        </div>
    );
}

export default CompanyBoard;
