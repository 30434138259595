// /resources/js/Api/TrainingRecordAPI.ts

import axios from "axios";
import { TrainingRecord } from "../Types/TrainingRecord"; // 型定義をインポート
import { convertKeysToCamelCase } from "../Utils/convertToCamelCase"; // TypeScriptファイルに変更

const BASE_URL = "/api/users/training-record"; // フルURLを指定

// トレーニング記録をユーザーIDと年月で取得
export const fetchTrainingRecordsByUserAndDate = async (
  user_id: number,
  year_month: string
): Promise<{ data: TrainingRecord[] }> => {
  try {
    const response = await axios.get(`${BASE_URL}/list-by-user-and-date`, {
      params: {
        user_id,
        year_month,
      },
    });
    const data = convertKeysToCamelCase(response.data);
    return data;
  } catch (error: any) {
    throw error;
  }
};

// トレーニング記録を保存
export const storeTrainingRecord = async (data: TrainingRecord) => {
  try {
    const response = await axios.post(`${BASE_URL}/add/`, data);
    return convertKeysToCamelCase(response.data);
  } catch (error: any) {
    if (error.response && error.response.status === 422) {
      const errors = error.response.data.errors;
      const errorMessages = Object.values(errors).flat().join("\n");
      return Promise.reject(errorMessages);
    }
    throw error;
  }
};

// トレーニング記録の詳細を取得
export const fetchTrainingRecordDetail = async (trainingRecordId: number) => {
  const response = await axios.get(`${BASE_URL}/detail/${trainingRecordId}`);
  return convertKeysToCamelCase(response.data);
};

// トレーニング記録を更新
export const updateTrainingRecord = async (data: TrainingRecord) => {
  try {
    const response = await axios.put(`${BASE_URL}/update/${data.id}`, data); // update/{id} に変更
    return convertKeysToCamelCase(response.data);
  } catch (error: any) {
    if (error.response && error.response.status === 422) {
      const errors = error.response.data.errors;
      const errorMessages = Object.values(errors).flat().join("\n");
      return Promise.reject(errorMessages);
    }
    throw error.response?.data?.message || "研修記録の更新に失敗しました。";
  }
};


// トレーニング記録を削除
export const deleteTrainingRecord = async (id: number) => {
  const response = await axios.delete(`${BASE_URL}/delete/${id}`);
  return convertKeysToCamelCase(response.data);
};
