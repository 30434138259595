import * as api from "../Api/PerformanceItemAPI"
import { useQuery } from 'react-query';

export const usePerformanceItemList = () => {
    return useQuery('performanceItems', async () => api.getPerformanceItemList())
}

export const usePerformanceItemOfOccupationList = (occupationId: number) => {
    return useQuery(['performanceItems', occupationId], () => api.getPerformanceItemOfOccupationList(occupationId))
}

