import React, { useState, useEffect } from 'react';
import LoaderComponent from '../../../Components/Layout/Loader';
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { useUpdateShopPerformance, useEditShopPerformanceResultsForYear } from '../../../Queries/ShopPerformanceResultQuery';
import isEqual from 'lodash/isEqual';

const PerformanceShopEdit = (): JSX.Element => {
    const { shopId, year } = useParams<{ shopId: string; year: string }>();
    const { data, isLoading } = useEditShopPerformanceResultsForYear(shopId, year) || {};
    const { shopPerformanceItems, shopPerformanceResults } = data || {};

    const { handleSubmit, control, setValue, getValues } = useForm({
        defaultValues: {
            shopPerformanceResults: {}
        }
    });

    const updateShopPerformanceMutation = useUpdateShopPerformance();

    useEffect(() => {
        // 初期値のセットアップ
        if (shopPerformanceResults) {
            const initialValues = {};
            shopPerformanceResults.forEach(({ month, result }) => {
                initialValues[month] = result.map((item, index) => ({
                    ...item,
                    point: item.point || ''
                }));
            });
            setValue('shopPerformanceResults', initialValues);
        }
    }, [shopPerformanceResults, setValue]);

    // 更新処理
    const onSubmit = async () => {
        const currentValues = getValues('shopPerformanceResults');
        const differences = Object.entries(currentValues).flatMap(([month, items]) => {
            return items.flatMap((item, index) => {
                const originalItem = (shopPerformanceResults.find(m => m.month.toString() === month)?.result || [])[index];
                if (!isEqual(item.point, originalItem?.point)) {
                    return {
                        id: originalItem?.id,
                        shopId: shopId,
                        shopPerformanceItemId: originalItem?.shopPerformanceItemId,
                        inputOn: `${year}-${month.padStart(2, '0')}`,
                        point: item.point
                    };
                }
                return [];
            });
        });

        if (differences.length > 0) {
            await updateShopPerformanceMutation.mutateAsync(differences);
        } else {
            toast.info('更新データがありません。');
        }
    };

    // リセットボタンのイベントハンドラー

    const handleReset = (month) => {
        const results = getValues(`shopPerformanceResults[${month}]`);
        results.forEach((_, index) => {
            setValue(`shopPerformanceResults[${month}][${index}].point`, '');
        });
    };


    if (isLoading) return <LoaderComponent />;

    return (
        <>
            <div className="l-board is_scroll">
                <div className="l-board__inner">
                    {isLoading ? (
                        <LoaderComponent />
                    ) : (
                        <div className="l-board__wrap p-board__main">
                            <div className="p-board__main--box p-input__area">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <table className="p-table p-table__small">
                                        <thead>
                                            <tr>
                                                <th>該当月</th>
                                                {shopPerformanceItems && shopPerformanceItems.map((item) => (
                                                    <th key={item.id}>{item.name}</th>
                                                ))}
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {shopPerformanceResults && shopPerformanceResults.map(({ month, result }) => (
                                                <tr key={month}>
                                                    <td>{`${month}月`}</td>
                                                    {result.map((item, index) => (
                                                        <td key={index}>
                                                            <Controller
                                                                name={`shopPerformanceResults[${month}][${index}].point`}
                                                                control={control}
                                                                defaultValue={item.point || ''}
                                                                render={({ field }) => (
                                                                    <input
                                                                        type="text"
                                                                        className="c-input c-input__time c-input__data"
                                                                        {...field}
                                                                    />
                                                                )}
                                                            />
                                                        </td>
                                                    ))}
                                                    <td>
                                                        <button
                                                            type="button"
                                                            className="c-btn c-btn__red c-btn__table"
                                                            onClick={() => handleReset(month)}>リセット</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="l-button">
                                        <button
                                            className="c-btn c-btn__bgGreen c-btn__submit"
                                            type="submit"
                                        >
                                            店舗業績を更新
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default PerformanceShopEdit;
