import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PerformanceShop from "./PerformanceShop";
import PerformanceStaff from "./PerformanceStaff";
import PerformanceGoal from "../PerformanceGoal/PerformanceGoal";

import { useOccupationList } from "../../../Queries/OccupationQuery";
import PerformanceGoalDetail from "../PerformanceGoal/PerformanceGoalDetail";
import LoaderComponent from "../../../Components/Layout/Loader";

const PerformanceBoard = (): JSX.Element => {
  const { data: occupations, isLoading } = useOccupationList();
  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();

  // URLを参照してURL
  useEffect(() => {
    switch (location.pathname) {
      case "/hc-user/performance/shop":
        setActiveTab(1);
        break;
      case "/hc-user/performance/staff":
        setActiveTab(2);
        break;
      case "/hc-user/performance/goal":
        // case '/hc-user/performance/goal/stylist':
        setActiveTab(3);
        break;
      default:
        break;
    }
  }, [location.pathname, occupations]);

  // タブ機能
  const handleTabClick = (tabNum) => {
    setActiveTab(tabNum);
    switch (tabNum) {
      case 1:
        navigate("/hc-user/performance/shop");
        break;
      case 2:
        navigate("/hc-user/performance/staff");
        break;
      case 3:
        navigate("/hc-user/performance/goal");
        // navigate('/hc-user/performance/goal/stylist');
        break;
      default:
        break;
    }
  };

  if (isLoading) return <LoaderComponent />;

  return (
    <div className="l-board">
      <div className="l-board__inner">
        <ul className="p-board__tab">
          <li
            id="tab1"
            className={`p-tab__btn ${activeTab === 1 ? "is_active" : ""}`}
            onClick={() => handleTabClick(1)}
          >
            店舗業績
          </li>
          <li
            id="tab2"
            className={`p-tab__btn ${activeTab === 2 ? "is_active" : ""}`}
            onClick={() => handleTabClick(2)}
          >
            スタッフ業績
          </li>
          <li
            id="tab3"
            className={`p-tab__btn ${activeTab === 3 ? "is_active" : ""}`}
            onClick={() => handleTabClick(3)}
          >
            業績目標
          </li>
        </ul>
        <div
          id="panel1"
          className={`p-tab__panel ${activeTab === 1 ? "is_active" : ""}`}
        >
          <PerformanceShop />
        </div>
        <div
          className={`p-tab__panel ${activeTab === 2 ? "is_active" : ""}`}
          id="panel2"
        >
          <PerformanceStaff />
        </div>
        <div
          className={`p-tab__panel ${activeTab === 3 ? "is_active" : ""}`}
          id="panel3"
        >
          <PerformanceGoal />
        </div>
      </div>
    </div>
  );
};
export default PerformanceBoard;
