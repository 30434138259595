import React from 'react';
import LoaderComponent from '../Layout/Loader';
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller } from 'react-hook-form';
import RadioFieldGroup from '../Form/RadioFieldGroup';
import InputTextField from '../Form/InputTextField';
import TextareaField from '../Form/TextareaField';
import TimeSelectBox from '../Form/TimeSelectBox';
import SelectField from '../Form/SelectField';
import DateField from '../Form/DateField';
import isEqual from 'lodash/isEqual';

interface CurriculumResultAddModalProps {
    isOpen: boolean;
    isClose: () => void;
    onAction: any;
    userList: any[];
    assistantList: any[];
    educationCurriculumList: any[];
}

const CurriculumResultAddModal: React.VFC<CurriculumResultAddModalProps> = ({
    isOpen,
    isClose,
    onAction,
    userList,
    assistantList,
    educationCurriculumList = []
}) => {

    const today = new Date();
    const formattedToday = today.toISOString().substring(0, 10);
    const defaultCurriculumId = educationCurriculumList.length > 0 ? educationCurriculumList[0].id : null;
        // educatorType の初期値を設定する関数
    const getDefaultEducatorType = () => {
        return educationCurriculumList.length > 0 ? "inner" : "outer";
    };

    // educatorId の初期値を設定する関数
    const getDefaultEducatorId = () => {
        return getDefaultEducatorType() === 'inner' && userList.length > 0 ? userList[0].id : null;
    };
    const defaultValues = {
        educatorType: getDefaultEducatorType(),
        educationCurriculumId: defaultCurriculumId,
        educatorId: getDefaultEducatorId(),
        educatedUserId: null,
        educatorName: "",
        curriculumDetail: "",
        curriculumRemark: "",
        startTime: "09:00",
        finishTime: "10:00",
        doneOn: formattedToday
    };

    const { register, handleSubmit, control, formState: { errors }, watch, setValue } = useForm({
        defaultValues: defaultValues
    });



    const educatorType = watch("educatorType");

    // 登録機能
    const onSubmit = (data) => {
        onAction.mutate(data, {
            onSuccess: () => {
                isClose(); // モーダルを閉じる
            }
        });
    };

    // モーダルを閉じる
    const handleCloseBtnClick = () => {
        isClose();
    };

    if (!isOpen) return null;

    return (
        <div className="p-modal" onClick={handleCloseBtnClick}>
            <div className="p-modal__content" onClick={(e) => e.stopPropagation()}>

                <button
                    className="p-modal__delete"
                    onClick={handleCloseBtnClick}>
                    <img src={'/image/icon_btn_batsu_green.png'} />
                </button>
                <h3 className="p-modal__headline">教育実績追加</h3>
                <form onSubmit={handleSubmit(onSubmit)}>

                    {/* カリキュラム: education_curriculums */}
                    <SelectField
                        label="カリキュラム名"
                        options={educationCurriculumList.map(curriculum => ({
                            value: curriculum.id,
                            label: `${curriculum.name}: ${curriculum.detail}`
                        }))}
                        register={register}
                        name="educationCurriculumId"
                        error={errors.educationCurriculumId?.message}
                    />
                    {/* 教育担当者: educator_id */}
                    <Controller
                        control={control}
                        name="educatorType"
                        render={({ field: { onChange, value } }) => (
                            <RadioFieldGroup
                                label="教育担当者を選択"
                                options={[
                                    { value: 'inner', label: '社内教育担当者' },
                                    { value: 'outer', label: '社外教育担当者' }
                                ]}
                                name="educatorType"
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                    {educatorType === 'inner' && (
                        <SelectField
                            label="社内教育担当者"
                            options={userList.map(user => ({
                                value: user.id,
                                label: `${user.last_name} ${user.first_name}`
                            }))}
                            register={register}
                            name="educatorId"
                            error={errors.educatorId?.message}
                        />
                    )}
                    {/* 教育担当者名: educator_name */}
                    {educatorType === 'outer' && (
                        <InputTextField
                            label="社外教育担当者"
                            type="text"
                            {...register("educatorName")}
                            error={errors.educatorName?.message}
                        />
                    )}
                    {/* 受講者: educated_user_id */}
                    <SelectField
                        label="受講者"
                        options={assistantList.map(assistant => ({
                            value: assistant.id,
                            label: `${assistant.last_name} ${assistant.first_name}`
                        }))}
                        register={register}
                        name="educated_user_id"
                        error={errors.educatedUserId?.message}
                    />
                    {/* 実施日: doneOn */}
                    <Controller
                        control={control}
                        name="doneOn"
                        rules={{ required: "実施日は必須です" }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <DateField
                                label="実施日"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                            />
                        )}
                    />
                    {/* 時間: start_time / finish_time */}
                    <div className="p-input__area">
                        <div className="area-label">
                            <label className="c-label">開始・終了時間</label>
                        </div>
                        <div className="area-input l-flex">
                            <Controller
                                control={control}
                                name="startTime"
                                rules={{ required: "開始時間は必須です" }}
                                render={({ field }) => (
                                    <TimeSelectBox
                                        label="開始時間"
                                        hourValue={field.value ? field.value.split(':')[0] : ''}
                                        minuteValue={field.value ? field.value.split(':')[1] : ''}
                                        setHour={(hour) => {
                                            field.onChange(`${hour}:${field.value ? field.value.split(':')[1] : ''}`);
                                            setValue("startTime", `${hour}:${field.value ? field.value.split(':')[1] : ''}`); // 秒を含まない形式に変更
                                        }}
                                        setMinute={(minute) => {
                                            field.onChange(`${field.value ? field.value.split(':')[0] : ''}:${minute}`);
                                            setValue("startTime", `${field.value ? field.value.split(':')[0] : ''}:${minute}`); // 秒を含まない形式に変更
                                        }}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                name="finishTime"
                                rules={{ required: "終了時間は必須です" }}
                                render={({ field }) => (
                                    <TimeSelectBox
                                        label="終了時間"
                                        hourValue={field.value ? field.value.split(':')[0] : ''}
                                        minuteValue={field.value ? field.value.split(':')[1] : ''}
                                        setHour={(hour) => {
                                            field.onChange(`${hour}:${field.value ? field.value.split(':')[1] : ''}`);
                                            setValue("finishTime", `${hour}:${field.value ? field.value.split(':')[1] : ''}`); // 秒を含まない形式に変更
                                        }}
                                        setMinute={(minute) => {
                                            field.onChange(`${field.value ? field.value.split(':')[0] : ''}:${minute}`);
                                            setValue("finishTime", `${field.value ? field.value.split(':')[0] : ''}:${minute}`); // 秒を含まない形式に変更
                                        }}
                                    />
                                )}
                            />
                            {/* <Controller
                                control={control}
                                name="finishTime"
                                rules={{ required: "終了時間は必須です" }}
                                render={({ field }) => (
                                    <TimeSelectBox
                                        label="終了時間"
                                        hourValue={field.value ? field.value.split(':')[0] : ''}
                                        minuteValue={field.value ? field.value.split(':')[1] : ''}
                                        setHour={(hour) => field.onChange(`${hour}:${field.value ? field.value.split(':')[1] : ''}`)}
                                        setMinute={(minute) => field.onChange(`${field.value ? field.value.split(':')[0] : ''}:${minute}`)}
                                    />
                                )}
                            /> */}
                        </div>
                    </div>
                    {/* 教育内容: curriculumDetail */}
                    <TextareaField
                        label="教育内容"
                        {...register("curriculumDetail", { maxLength: 2000 })}
                        error={errors.curriculumDetail?.message}
                    />

                    {/* 備考: curriculumRemark */}
                    <TextareaField
                        label="備考"
                        {...register("curriculumRemark", { maxLength: 2000 })}
                        error={errors.curriculumRemark?.message}
                    />
                    {/* 追加ボタン */}
                    <div className="p-input__area">
                        <button
                            className="c-btn c-btn__green c-btn__bgGreen c-btn__small position-center"
                            type="submit"
                        >
                            追加する
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
export default CurriculumResultAddModal;
