import { useState } from 'react';
import { Link } from 'react-router-dom';
import AddModal from '../../../Components/Modal/AddModal';
import MasterModal from '../../../Components/Modal/MasterModal';
import { useRoleList, useCreateRole, useUpdateRole, useDeleteRole } from '../../../Queries/RoleQuery';
import LoaderComponent from '../../../Components/Layout/Loader';
import { useAuthority } from '../../../Contexts/AuthorityContext';

const CompanyDetail = (): JSX.Element => {
    const { authority } = useAuthority();
    const [isAddModal, setIsAddModal] = useState(false);
    const [isMasterModal, setIsMasterModal] = useState(false);
    const [selectedMasterId, setSelectedMasterId] = useState(null);
    const [selectedMasterName, setSelectedMasterName] = useState('');
    const [selectedMasterDetail, setselectedMasterDetail] = useState('');
    const createRole = useCreateRole();
    const updateRole = useUpdateRole();
    const deleteRole = useDeleteRole();

    const { data: roles, isLoading } = useRoleList();

    const handleAddModalOpen = () => setIsAddModal(true);
    const handleAddModalClose = () => setIsAddModal(false);

    const handleMasterModalOpen = (id: number, name: string, description: string) => {
        setSelectedMasterId(id);
        setSelectedMasterName(name);
        setselectedMasterDetail(description);
        setTimeout(() => {
            setIsMasterModal(true);
        }, 0);
    };

    const handleMasterModalClose = () => setIsMasterModal(false);

    if (isLoading) return <LoaderComponent />;

    return (
        <div className="l-board">
            {authority == '0' && (
                <>
                    <AddModal
                        title="役割追加"
                        optionDetail={true}
                        optionRank={false}
                        optionOccupation={false}
                        isOpen={isAddModal}
                        handleClose={handleAddModalClose}
                        createMutation={createRole}
                    />

                    <MasterModal
                        title="役割編集"
                        optionDetail={true}
                        optionRank={false}
                        optionOccupation={false}
                        isOpen={isMasterModal}
                        handleClose={handleMasterModalClose}
                        selectedId={selectedMasterId}
                        selectedName={selectedMasterName}
                        selectedDetail={selectedMasterDetail}
                        updateMutation={updateRole}
                        deleteMutation={deleteRole}
                    />
                </>
            )}
            <div className="l-board__inner">
                {authority == '0' && (
                    <div className="l-board__wrap p-board__btn">
                        <div className="l-flex p-board__btn--area space-around">
                            <Link
                                className="p-board__btn--item c-btn c-btn__green icon icon-plus"
                                onClick={handleAddModalOpen}
                            >役割を追加</Link>
                        </div>
                    </div>
                )}
                <div className="l-board__wrap p-board__main">
                    <div className="p-board__main--box">
                        <table className="p-table">
                            <thead>
                                <tr>
                                    <th>名称</th>
                                    <th>詳細</th>
                                </tr>
                            </thead>
                            <tbody>
                                {roles && roles.map((role) => (
                                    <tr
                                        key={role.id}
                                        onClick={authority == '0' ? () => handleMasterModalOpen(role.id, role.name, role.detail) : null}
                                    >
                                        <td>{role.name}</td>
                                        <td>{role.detail}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompanyDetail;
