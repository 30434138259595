import React, { forwardRef } from 'react';

const InputSubField = forwardRef(({ label, type, name, value, onChange, error }, ref) => (
    <div className="c-box__two">
        <label className="c-label c-label__sub">{label}</label>
        <input
            ref={ref}
            type={type}
            name={name}
            className="c-input u-mt4"
            value={value}
            onChange={onChange} />
        {error && <p className="p-input__error">{error}</p>}
    </div>
));

export default InputSubField;
