// import * as EvaluationResultEditAPI from "../Api/EvaluationResultEditAPI";
// import { toast } from "react-toastify";

// export const editUserEvaluationResult = async (
//     userId: string,
//     yearMonth: string,
//     sendData,
// ): Promise<any> => {
//     try {
//         const data = await EvaluationResultEditAPI.editEvaluationResult(
//             userId,
//             yearMonth,
//             sendData
//         );
//         toast.success("人事考課表を更新しました！");
//         return data;
//     } catch (error) {
//         toast.error("人事考課表の更新に失敗しました。");
//         return null;
//     }
// };

import { useMutation, useQueryClient } from 'react-query';
import * as api from '../Api/EvaluationResultEditAPI';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const useEditUserEvaluationResult = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return useMutation(
        ({ userId, yearMonth, sendData }) => api.editEvaluationResult(userId, yearMonth, sendData),
        {
            onSuccess: () => {
                toast.success("人事考課表を更新しました！");
                navigate("/hc-user/hr/evaluation");
                queryClient.resetQueries();
            },
            onError: (error) => {
                toast.error("人事考課表の更新に失敗しました。");
                // エラーハンドリングの詳細なロジック
            }
        }
    );
};
