import axios from 'axios';
import { CurriculumProgress } from '../Types/CurriculumProgress';

// 取得: show
export const getCurriculumProgress = async (userId: number, educationCurriculumId: number): Promise<CurriculumProgress | null> => {
    try {
        const response = await axios.get<CurriculumProgress>(`/api/users/curriculum-progress/detail/${userId}/${educationCurriculumId}`);
        return response.data;
    } catch (error: any) {
        // 適切なエラー処理をここに追加
        return null;
    }
};


// 登録: add
export const addCurriculumProgress = async (data: Omit<CurriculumProgress, 'id' | 'created_at' | 'updated_at'>) => {
    const response = await axios.post<CurriculumProgress>('/api/users/curriculum-progress/add/', data);
    return response.data;
};

// 削除: delete
export const deleteCurriculumProgress = async (id: number) => {
    const response = await axios.delete(`/api/users/curriculum-progress/delete/${id}`);
    return response.data;
};
