import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useUsersForSelectQuery } from "../../Queries/UserQuery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AttendanceButton from "../User/Attendance/components/AttendanceButton";
import {
  useAttendanceStatus,
  useStartWorkSession,
  useFinishWorkSession,
  useStartBreakSession,
  useEndBreakSession,
} from "../../Queries/UserWorkQuery";
import LoaderComponent from "../../Components/Layout/Loader";

// import Loader from './../../../../js/Components/Loader';

const AttendanceBoard = (): JSX.Element => {
  /* import */
  const queryClient = useQueryClient();

  /* 画面系 */
  const [isLoading, setIsLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [workDone, setWorkDone] = useState("");
  const { data: users, status: usersStatus } = useUsersForSelectQuery();

  /* 勤怠機能 */
  const { mutateAsync: startWork } = useStartWorkSession();
  const { mutateAsync: finishWork } = useFinishWorkSession();
  const { mutateAsync: startBreak } = useStartBreakSession();
  const { mutateAsync: endBreak } = useEndBreakSession();

  /* レポートデータ */
  // 現状
  // const { data: checkAttendanceStatus } = useAttendanceStatus();
  const location = useLocation();
  const [selectedUserId, setSelectedUserId] = useState(
    location.state?.selectedUserId || ""
  );
  const { data: checkAttendanceStatus } = useAttendanceStatus(selectedUserId);

  useEffect(() => {
    setIsLoading(true);
    queryClient.invalidateQueries(["attendanceStatus", selectedUserId]);
    setIsLoading(false);
  }, [selectedUserId, queryClient]);

  /* 勤怠 */
  // 日付を出力する記述
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth()は0-11を返す
    const day = date.getDate();
    const weekdays = ["日", "月", "火", "水", "木", "金", "土"];
    const weekday = weekdays[date.getDay()]; // getDay()は0（日曜）-6（土曜）を返す
    return `${year}年${month}月${day}日(${weekday})`;
  };

  // 時間
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const [workStatus, setWorkStatus] = useState({
    isAttendanceActive: false,
    isLeaveActive: false,
    isBreakStartActive: false,
    isBreakEndActive: false,
  });

  // 一つの関数で勤怠状態を更新
  const updateWorkStatus = (status) => {
    setWorkStatus({
      isAttendanceActive: status === 0,
      isLeaveActive: status === 1 || status === 3 || status === 5,
      isBreakStartActive: status === 1 || status === 3,
      isBreakEndActive: status === 2 || status === 4,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    updateWorkStatus(checkAttendanceStatus);
    setIsLoading(false);
  }, [checkAttendanceStatus]);

  // 共通の勤怠操作処理
  const handleWorkAction = async (action) => {
    try {
      await action(selectedUserId); // selectedUserIdを引数として渡す
      queryClient.resetQueries();
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // エラー処理
      } else {
        console.error(error);
      }
    }
  };

  // 選択系
  const handleUserChange = (event) => setSelectedUserId(event.target.value);

  if (isLoading) return <LoaderComponent />;

  return (
    <div className="l-board">
      <div className="l-board__inner">
        <div className="l-board__wrap">
          <div className="p-attendance__wrap">
            <div className="p-attendance__clock">
              <p className="date">{formatDate(currentTime)}</p>
              <p className="time">{currentTime.toLocaleTimeString()}</p>
              {workDone && (
                <p className="finish">本日の業務もお疲れ様でした！</p>
              )}
            </div>
            <div className="p-attendance__btn">
              <div className="p-attendance__btn--user">
                <select
                  className="c-input type-shadow"
                  onChange={handleUserChange}
                  value={selectedUserId}
                >
                  {users &&
                    users.map((user, index) => (
                      <option key={index} value={user.id}>
                        {user.last_name} {user.first_name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="p-attendance__btn--list">
                <AttendanceButton
                  isColor="color-green"
                  isActive={workStatus.isAttendanceActive}
                  onClick={() => handleWorkAction(startWork)} // ここを改修
                  label="出勤"
                />
                <AttendanceButton
                  isColor="color-green"
                  isActive={workStatus.isLeaveActive}
                  onClick={() => handleWorkAction(finishWork)} // ここを改修
                  label="退勤"
                />
                <AttendanceButton
                  isColor="color-red"
                  isActive={workStatus.isBreakStartActive}
                  onClick={() => handleWorkAction(startBreak)} // ここを改修
                  label="休憩開始"
                />
                <AttendanceButton
                  isColor="color-red"
                  isActive={workStatus.isBreakEndActive}
                  onClick={() => handleWorkAction(endBreak)} // ここを改修
                  label="休憩終了"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AttendanceBoard;
