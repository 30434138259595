import axios from 'axios';

export const fetchPerformanceGoalsForOccupationAndYear = async (occupationId: number, year: number) => {
    const response = await axios.get(`/api/users/performance-company-goal/list/occupation/${occupationId}/year/${year}`);
    return response.data;
}

export const getPerformanceCompanyGoal = async (occupationId: number, year: string) => {
    try {
        const response = await axios.get(`/api/users/performance-company-goal/edit/${occupationId}/${year}`);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};


export const updatePerformanceCompanyGoals = async (differences: any) => {
    const response = await axios.post('/api/users/performance-company-goal/update', differences);
    return response.data;
};
