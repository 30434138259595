import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as TrainingRecordAPI from "../Api/TrainingRecordAPI";
import { useNavigate } from "react-router-dom";
import { TrainingRecord } from "../Types/TrainingRecord";

// 成功時の処理まとめ
const onSuccessHandler = (
  message: string,
  handleClose?: () => void,
  refetchData?: () => void
) => {
  toast.success(message);
  if (refetchData) {
    refetchData();
  }
  if (handleClose) {
    handleClose();
  }
};

// トレーニング記録をユーザーIDと年月で取得
export const useTrainingRecordsByUserAndDate = (
  userId: number | null,
  yearMonth: string
) => {
  return useQuery<{ data: TrainingRecord[] }, Error>(
    ["trainingRecords", userId, yearMonth],
    () =>
      TrainingRecordAPI.fetchTrainingRecordsByUserAndDate(userId!, yearMonth),
    {
      onError: () => {
        toast.error("研修記録一覧の取得に失敗しました。");
      },
      enabled: userId !== null && yearMonth !== "", // クエリの有効化条件を追加
    }
  );
};

// トレーニング記録を保存
export const useStoreTrainingRecord = (
  handleClose?: () => void,
  refetchData?: () => void
) => {
  const queryClient = useQueryClient();

  return useMutation<TrainingRecord, Error, TrainingRecord>(
    (trainingData) => TrainingRecordAPI.storeTrainingRecord(trainingData),
    {
      onSuccess: () => {
        onSuccessHandler("研修記録を追加しました。", handleClose, refetchData);
        queryClient.invalidateQueries("trainingRecords"); // キャッシュを無効化して再フェッチ
      },
      onError: (error) => {
        toast.error(error || "研修記録の追加に失敗しました。");
      },
    }
  );
};

// トレーニング記録を更新
export const useUpdateTrainingRecord = () => {
  const queryClient = useQueryClient();
  return useMutation<TrainingRecord, string, TrainingRecord>(
    (trainingData) => TrainingRecordAPI.updateTrainingRecord(trainingData),
    {
      onSuccess: (data, variables, context) => {
        // 特定のトレーニングレコードの詳細を無効化
        queryClient.invalidateQueries(["trainingRecordDetail", data.id]);
        // トレーニングレコード一覧を無効化
        queryClient.invalidateQueries("trainingRecords");

        // refetchDataが渡されていればそれも実行する
        if (context?.refetchData) {
          context.refetchData();
        }

        toast.success("研修記録が更新されました。");
      },
      onError: (errorMessage: string) => {
        toast.error(errorMessage || "研修記録の更新に失敗しました。");
      },
    }
  );
};


// トレーニング記録の詳細を取得
export const useTrainingRecordDetail = (trainingRecordId: number) => {
  return useQuery<TrainingRecord, Error>(
    ["trainingRecordDetail", trainingRecordId],
    () => TrainingRecordAPI.fetchTrainingRecordDetail(trainingRecordId),
    {
      onError: () => {
        toast.error("研修記録の詳細取得に失敗しました。");
      },
    }
  );
};

// トレーニング記録を削除
export const useDeleteTrainingRecord = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation<void, Error, number>(
    (trainingRecordId: number) =>
      TrainingRecordAPI.deleteTrainingRecord(trainingRecordId),
    {
      onError: () => {
        toast.error("研修記録の削除に失敗しました。");
      },
      onSuccess: () => {
        toast.success("研修記録が削除されました。");
        queryClient.invalidateQueries("trainingRecords");
        navigate("/hc-user/education/training/");
      },
    }
  );
};
