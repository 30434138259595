import React, { useState, useEffect } from 'react';
import CustomBarChart from './components/BarChart';
import LoaderComponent from '../../../Components/Layout/Loader';
import { useParams } from 'react-router-dom';
import { fetchPerformanceDataByMonthWithGoal, fetchDetailPerformanceDataByMonthWithGoal } from './../../../Queries/PerformanceResultQuery';

const HrPerformanceDetail = (): JSX.Element => {
    const [performanceData, setPerformanceData] = useState(null);
    // const [chartData, setChartData] = useState([]);
    const [chartData, setChartData] = useState({ items: [], data: [] });
    const [totals, setTotals] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { userId, performanceItemId, year, month } = useParams<{ userId: string, performanceItemId: string, year: string, month: string }>();

    // 合計値を計算する関数
    const calculateTotals = (performanceData) => {
        if (!performanceData || !performanceData.data) {
            return [];
        }

        // 各項目ごとに合計を計算
        return performanceData.items.map(item => {
            let totalResult = 0;
            let totalGoal = 0;

            performanceData.data.forEach(dataItem => {
                const data = dataItem.data.find(d => d.id === item.id);
                if (data) {
                    totalResult += data.result || 0;
                    totalGoal += data.goal || 0;
                }
            });

            return { id: item.id, totalResult, totalGoal };
        });
    };

    /* チャート用のデータ */
    useEffect(() => {
        setIsLoading(true);

        const performanceDataPromise = fetchPerformanceDataByMonthWithGoal(Number(userId), Number(year), Number(month));
        const chartDataPromise = fetchDetailPerformanceDataByMonthWithGoal(Number(userId), Number(performanceItemId), Number(year), Number(month));

        Promise.all([performanceDataPromise, chartDataPromise])
            .then(([performanceDataResponse, chartDataResponse]) => {
                setPerformanceData(performanceDataResponse);
                setTotals(calculateTotals(performanceDataResponse));

                // ここでitemsをどのように設定するかは、chartDataResponseの構造に依存します
                const items = chartDataResponse.items || []; // 仮定：chartDataResponseにitemsプロパティが存在する

                const formattedChartData = chartDataResponse.data.map(item => {
                    return {
                        name: `${item.month}月`,
                        結果: item.result || 0,
                        目標: Number(item.goal) || 0,
                    };
                });

                setChartData({ items: items, data: formattedChartData });
            })
            .catch(error => {
                console.error("Failed to fetch performance data:", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [userId, performanceItemId, year, month]);


    const formatNumber = (num, itemName) => {
        if (num === null || num === undefined) {
            return '-';
        }
        const unit = ['技術(指名)売上額', '技術(フリー)売上額', '物販売上', '技術売上', '物販売上'].includes(itemName) ? '円' : '人';
        return `${num.toLocaleString()}${unit}`;
    }

    if (isLoading) return <LoaderComponent />;

    return (
        <>
            <div className="l-board is_scroll">
                <div className="l-board__inner">
                    <div className="l-board__wrap p-board__main">
                        <CustomBarChart
                            data={chartData.data}
                            itemName={chartData.items && chartData.items.length > 0 ? chartData.items[0].name : ''}
                        />
                    </div>
                </div>
            </div>
            <div className="l-board is_scroll">
                <div className="l-board__inner">
                    <div className="l-board__wrap p-board__main">
                        <div className="p-board__main--box">
                            <table className="p-table">
                                <thead>
                                    <tr>
                                        <th>該当月</th>
                                        {performanceData && performanceData.items.map((item, index) => {
                                            return (
                                                <th key={index}>{item.name}</th>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {performanceData && performanceData.data.map((dataItem, dataIndex) => {
                                        return (
                                            <tr key={dataIndex}>
                                                <td>{dataItem.year}年{dataItem.month}月</td>
                                                {performanceData.items.map((item, itemIndex) => {
                                                    const data = dataItem.data.find(d => d.id === item.id);
                                                    return (
                                                        <td key={itemIndex} className="u-tAlign--right">
                                                            {formatNumber(data?.result, item.name)}<br />
                                                            <span className="u-tSub">(目標: {formatNumber(data?.goal, item.name)})</span>
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td>合計</td>
                                        {totals.map((total, index) => (
                                            <td key={index} className="u-tAlign--right">
                                                {formatNumber(total.totalResult, performanceData.items.find(item => item.id === total.id)?.name)}<br />
                                                <span className="u-tSub">(目標: {formatNumber(total.totalGoal, performanceData.items.find(item => item.id === total.id)?.name)})</span>
                                            </td>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HrPerformanceDetail;
