import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

/* -------------- Components -------------- */
import UserLayout from "../Components/Layout/UserLayout.tsx";
import ToastModal from "../Components/Modal/ToastModal.tsx";

/* -------------- Routes -------------- */
import { attendanceRoutes } from "./UserAttendanceRoutes";
import { educationRoutes } from "./UserEducationRoutes";
import { performanceRoutes } from "./UserPerformanceRoutes";
import { hrRoutes } from "./UserHrRoutes";
import { staffRoutes } from "./UserStaffRoutes";
import { companyRoutes } from "./UserCompanyRoutes";

/* -------------- Provider -------------- */
import { AuthorityProvider, useAuthority } from "../Contexts/AuthorityContext";

/* -------------- Pages -------------- */
/* マイページ */
import MypageDetail from "../Pages/User/Mypage/MypageDetail.tsx";
/* 勤怠用ボード */
import ClockBoard from "../Pages/Attendance/AttendanceBoard.tsx";
/* No Authority Page */
import NoAuthority from "../Pages/NoAuthority.tsx"; // 後述するファイル
/* Attendance Edit Page */
import AttendanceEdit from "../Pages/User/Attendance/AttendanceEdit.tsx";

/* 他のページインポート... */

interface ProtectedRouteProps {
  children: React.ReactElement;
  requiredAuthority: number;
}

/**
 * ProtectedRouteコンポーネント
 * 必要な権限がある場合に子コンポーネントをレンダリングし、ない場合はリダイレクトします。
 *
 * @param {React.ReactElement} children - 保護されたコンポーネント
 * @param {number} requiredAuthority - 必要な権限レベル
 */
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredAuthority }) => {
  const { authority, isLoading } = useAuthority();

  console.log("ProtectedRoute authority:", authority, "requiredAuthority:", requiredAuthority);

  if (isLoading) {
    return <div>Loading...</div>; // ローディング中の表示
  }

  if (authority === null) {
    return <Navigate to="/no-authority" replace />; // 権限情報が取得できない場合
  }

  if (authority !== requiredAuthority) {
    return <Navigate to="/no-authority" replace />;
  }

  return children;
};

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
      mutations: {
        retry: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <AuthorityProvider>
        <BrowserRouter>
          <UserLayout />
          <ToastModal
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
          <Routes>
            {/* 権限不足時のリダイレクト先 */}
            <Route path="/no-authority" element={<NoAuthority />} />

            {/* 勤怠 */}
            {attendanceRoutes.map((route, index) => {
              // 特定のルートに対してProtectedRouteを適用
              if (route.path === "/hc-user/attendance/edit/:userId/:yearMonth") {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <ProtectedRoute requiredAuthority={0}>
                        <AttendanceEdit />
                      </ProtectedRoute>
                    }
                  />
                );
              }
              // 他のルートはそのままレンダリング
              return <Route key={index} path={route.path} element={route.element} />;
            })}

            {/* 教育 */}
            {educationRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}

            {/* 業績 */}
            {performanceRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}

            {/* 人事考課 */}
            {hrRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}

            {/* スタッフ */}
            {staffRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}

            {/* 会社: 詳細/編集 */}
            {companyRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}

            {/* マイページ */}
            <Route path="/hc-user/mypage/" element={<MypageDetail />} />

            {/* 勤怠用 */}
            <Route path="/attendance-board/" element={<ClockBoard />} />

            {/* 他のルート... */}
          </Routes>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={true} position="right" />
      </AuthorityProvider>
    </QueryClientProvider>
  );
}

const container = document.getElementById("app");
const root = createRoot(container!); // TypeScriptの非nullアサーション
root.render(<App />);
