import axios from 'axios';

// 一覧
export const fetchEvaluationTable = async () => {
    try {
        const response = await axios.get('/api/users/evaluation-table/list');
        return response.data;
    } catch (error) {
        throw new Error(`API call failed: ${error}`);
    }
};

// 職種別一覧
export const fetchEvaluationTableByType = async (type: string) => {
    try {
        const response = await axios.get(`/api/users/evaluation-table/list/${type}`);
        return response.data;
    } catch (error) {
        throw new Error(`API call failed: ${error}`);
    }
};

// 人事考課更新
export const updateEvaluationTable = async (data: any) => {
    try {
        const response = await axios.post('/api/users/evaluation-table/update', data);
        return response.data;
    } catch (error) {
        throw new Error(`API call failed: ${error}`);
    }
};
