import React, { useEffect, useState } from 'react';
import { useQueryClient, UseMutationResult } from 'react-query';
import { toast } from "react-toastify";
import ConfirmDeleteModal from './ConfirmDeleteModal';

interface MasterModalProps {
    title: string;
    isOpen: boolean;
    optionDetail: boolean;
    optionRole: boolean;
    optionOccupation: boolean;
    limitDelete?: boolean;
    handleOpen: (name: string) => void;
    handleClose: () => void;
    selectedId: number | null;
    selectedName: string;
    selectedDetail: string;
    selectedRole: any[];
    selectedOccupation: number | null;
    allRole: any[];
    allOccupation: any[];
    updateMutation: UseMutationResult;
    deleteMutation: UseMutationResult;
}

const MasterModal: React.VFC<MasterModalProps> = ({
    title, isOpen, optionDetail, optionRole, optionOccupation, limitDelete,
    selectedId, handleClose, selectedName, selectedDetail, selectedRole,
    selectedOccupation, allRole, allOccupation, updateMutation, deleteMutation
}) => {
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [editName, setEditName] = useState(selectedName || '');
    const [editDetail, setEditDetail] = useState(selectedDetail || '');
    const [editRole, setEditRole] = useState(selectedRole || []);
    const [editOccupation, setEditOccupation] = useState(selectedOccupation || '');
    const queryClient = useQueryClient();

    const handleDeleteModalOpen = () => setIsDeleteModal(true);
    const handleDeleteModalClose = () => setIsDeleteModal(false);

    const handleCloseBtnClick = () => {
        handleModalClose(false);
        handleClose();
    };

    const handleModalClose = (clearValues = true) => {
        if (clearValues) {
            setEditName('');
            setEditDetail('');
            setEditRole([]);
            setEditOccupation('');
        }
        handleClose();
    };

    const handleChange = (setter: React.Dispatch<React.SetStateAction<any>>) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setter(e.target.value);
    };

    const handleCheckboxChange = (value: any, list: any[], setter: React.Dispatch<React.SetStateAction<any[]>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setter(e.target.checked ? [...list, value] : list.filter(v => v.id !== value.id));
    };

    const handleUpdate = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (!editName) {
            toast.error('名称は必須です。');
            return;
        }

        let payload: any = { id: selectedId, name: editName };
        if (optionDetail) payload = { ...payload, detail: editDetail };
        if (optionRole) payload = { ...payload, role: editRole.map((role: any) => role.id) };
        if (optionOccupation) payload = { ...payload, occupation: editOccupation };

        updateMutation.mutate(payload, {
            onSuccess: () => {
                queryClient.resetQueries();
                handleCloseBtnClick();
            },
            onError: (error) => {}
        });
    };

    useEffect(() => {
        setEditName(selectedName || '');
        setEditDetail(selectedDetail || '');
        setEditRole(selectedRole || []);
        setEditOccupation(selectedOccupation || '');
    }, [selectedName, selectedDetail, selectedRole, selectedOccupation]);

    if (!isOpen) return null;

    return (
        <div className="p-modal">
            <ConfirmDeleteModal
                isOpen={isDeleteModal}
                handleClose={() => handleDeleteModalClose()}
                selectedId={selectedId}
                handleMasterModalClose={handleCloseBtnClick}
                deleteMutation={deleteMutation}
            />
            <div className="p-modal__content">
                <button className="p-modal__delete" onClick={handleCloseBtnClick}>
                    <img src={'/image/icon_btn_batsu_green.png'} alt="Close" />
                </button>
                <h3 className="p-modal__headline">{title}</h3>
                <div className="p-input__area">
                    <label className="c-label">名称</label>
                    <input className="c-input u-mt4" type="text" value={editName} onChange={handleChange(setEditName)} required />
                </div>
                {optionOccupation && (
                    <div className="p-input__area">
                        <label className="c-label">対象職種</label>
                        {[{ id: 0, name: '全て' }, ...allOccupation].map(occupation => (
                            <div key={occupation.id} className="c-input__checkbox">
                                <input
                                    type="radio"
                                    name="occupation"
                                    id={occupation.id.toString()}
                                    checked={editOccupation === occupation.id}
                                    onChange={() => setEditOccupation(occupation.id)}
                                />
                                <label htmlFor={occupation.id.toString()}>{occupation.name}</label>
                            </div>
                        ))}
                    </div>
                )}
                {optionDetail && (
                    <div className="p-input__area">
                        <label className="c-label">詳細</label>
                        <textarea className="c-input c-input__textarea u-mt4" value={editDetail} onChange={handleChange(setEditDetail)} />
                    </div>
                )}
                {optionRole && (
                    <div className="p-input__area">
                        <label className="c-label">役割</label>
                        <ul className="p-ckb__list">
                            {allRole.map(role => {
                                const isChecked = editRole.some(er => er.id === role.id);
                                return (
                                    <li key={role.id} className="p-ckb__item">
                                        <input
                                            type="checkbox"
                                            id={role.id}
                                            checked={isChecked}
                                            onChange={handleCheckboxChange(role, editRole, setEditRole)}
                                            className="p-ckb__input"
                                        />
                                        <label htmlFor={role.id} className="p-ckb__label">
                                            {role.name}
                                        </label>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
                <div className="p-modal__btn">
                    {!limitDelete && (
                        <button className="c-btn c-btn__red c-btn__bgRed c-btn__small u-mr10" onClick={handleDeleteModalOpen}>
                            削除する
                        </button>
                    )}
                    <button className="c-btn c-btn__green c-btn__bgGreen c-btn__small" onClick={handleUpdate}>
                        更新する
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MasterModal;
