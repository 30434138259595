import { toast } from 'react-toastify';
import { fetchEvaluationTable, fetchEvaluationTableByType, updateEvaluationTable } from '../Api/EvaluationTableAPI';
import { useNavigate } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';

// 一覧
export const getEvaluationTableDetails = async () => {
    try {
        const data = await fetchEvaluationTable();
        return data.items;
    } catch (error) {
        console.error(`Fetching evaluation table details failed: ${error}`);
        return [];
    }
};

// 職種別一覧
export const getEvaluationTableDetailsByType = async (type: string) => {
    try {
        const data = await fetchEvaluationTableByType(type);
        return data; // Removed .items, since the data is the array itself
    } catch (error) {
        console.error(`Fetching evaluation table details by type failed: ${error}`);
        toast.error('人事考課を取得できませんでした。');
        return [];
    }
};

// 更新
export const useUpdateEvaluationDetails = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    return useMutation(async (updates: any[]) => {
        await updateEvaluationTable(updates);
    }, {
        onSuccess: () => {
            queryClient.resetQueries('evaluationDetails'); // 'evaluationDetails' は更新されるデータのクエリキーです
            toast.success('考課素点要素の更新に成功しました。');
            navigate('/hc-user/hr/elements');
        },
        onError: (error: any) => {
            toast.error(`エラー: ${error.message || "予期せぬエラーが発生しました。管理者にお問い合わせください。"}`);
        }
    });
};
