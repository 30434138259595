import React, { useState, useEffect } from "react";
import ToggleOccupationBtn from "../../../Components/Common/ToggleOccupationBtn";
import { Link } from "react-router-dom";
import { useCompanyDetail } from "../../../Queries/CompanyQuery";
import { useOccupationList } from "../../../Queries/OccupationQuery";
import { getEvaluationTableDetails } from "../../../Queries/EvaluationTableQuery";
import LoaderComponent from "../../../Components/Layout/Loader";
import { toast } from "react-toastify";
import { useAuthority } from "../../../Contexts/AuthorityContext";

const HrElements = (): JSX.Element => {
  const { authority } = useAuthority();
  const [details, setDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [occupationNames, setOccupationNames] = useState([]);
  const [occupationIds, setOccupationIds] = useState([]);
  const [companyId, setCompanyId] = useState();
  const { data: company, status: companyStatus } = useCompanyDetail();
  const { data: occupations, status: occupationsStatus } = useOccupationList();
  const [occupationActiveIndex, setOccupationActiveIndex] = useState(null);

  useEffect(() => {
    if (
      occupations &&
      occupations.length > 0 &&
      occupationActiveIndex === null
    ) {
      // occupations 配列が空でない場合、初期値を設定
      setOccupationActiveIndex(occupations[0].id);
    }
  }, [occupations]);
  /* 会社データ設定 */
  useEffect(() => {
    if (company && company.company.id) {
      setCompanyId(company.company.id);
    }
  }, [company]);

  const filterDetailsByOccupation = (occupationId) => {
    const occupation = occupations.find((occ) => occ.id === occupationId);
    const occupationLabel = occupation?.label;
    return details
      .filter(
        (item) =>
          item.occupation === "all" || item.occupation === occupationLabel
      )
      .map((item) => ({
        ...item,
        details: item.details || [], // details が undefined の場合は空の配列を使用
      }));
  };

  useEffect(() => {
    if (occupations) {
      const names = occupations.map((occupation) => occupation.name);
      const ids = occupations.map((occupation) => occupation.id);
      setOccupationNames(names);
      setOccupationIds(ids);
    }
  }, [occupations]);

  const renderDetail = (detail) => {
    if (!detail) {
      return { __html: "" };
    }
    return { __html: detail.replace(/\n/g, "<br />") };
  };

  // 人事考課編集画面から遷移時に、対象のユーザーのスタイリスト・アシスタントの情報に応じてタブの初期値を変更
  useEffect(() => {
    const storedActiveIndex = sessionStorage.getItem("activeIndex");
    if (storedActiveIndex) {
      const index = occupationIds.indexOf(parseInt(storedActiveIndex));
      if (index !== -1) {
        setOccupationActiveIndex(parseInt(storedActiveIndex));
      }
      sessionStorage.removeItem("activeIndex"); // 使用後は削除
    }
  }, [occupationIds]);

  const setActiveIndex = (index) => {
    const occupationId = occupationIds[index];
    setOccupationActiveIndex(occupationId);
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const fetchedDetails = await getEvaluationTableDetails();
        setDetails(fetchedDetails);
      } catch (error) {
        toast.error(
          "予期せぬエラーが発生しました。管理者にお問い合わせください。"
        );
      }
      setIsLoading(false);
    }
    fetchData();
  }, []);

  const urlSuffix =
    occupations && occupationActiveIndex !== undefined
      ? occupations.find((occ) => occ.id === occupationActiveIndex)?.label
      : "";

  if (
    isLoading ||
    companyStatus === "loading" ||
    occupationsStatus === "loading"
  ) {
    return <LoaderComponent />;
  }

  if (companyStatus === "error") {
    // エラー表示または別の処理
    return <div>エラーが発生しました。</div>;
  }

  return (
    <>
      <div className="l-board__wrap p-board__btn l-flex space-between">
        <div className="p-board__btn--area">
          <div className="p-board__btn--input">
            <ToggleOccupationBtn
              activeIndex={occupationActiveIndex}
              setActiveIndex={setActiveIndex}
              btnTexts={occupationNames}
              occupationIds={occupationIds}
            />
          </div>
        </div>
        {authority == "0" && (
          <div className="p-board__btn--area">
            <Link
              className="p-board__btn--item c-btn c-btn__blue"
              to={`/hc-user/hr/elements/edit/${companyId}/${urlSuffix}`}
            >
              考課素点要素を編集
            </Link>
          </div>
        )}
      </div>
      <div className="l-board__wrap p-board__main scroll-x">
        <div className="l-board__wrap p-board__main scroll-x">
          <div className="p-board__main--box">
            <table className="p-table__elements">
              <thead>
                <tr>
                  <th className="sticky"></th>
                  <th>1</th>
                  <th>2</th>
                  <th>3</th>
                  <th>4</th>
                  <th>5</th>
                  <th>6</th>
                  <th>7</th>
                </tr>
              </thead>
              <tbody>
                {occupationActiveIndex !== null &&
                  filterDetailsByOccupation(occupationActiveIndex).map(
                    (item) => (
                      <tr key={item.name}>
                        <th className="sticky">{item.name}</th>
                        {item.details.map((detail, index) => (
                          <td
                            key={`${item.name}-${index}`}
                            dangerouslySetInnerHTML={renderDetail(
                              detail.detail
                            )}
                          ></td>
                        ))}
                      </tr>
                    )
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default HrElements;
