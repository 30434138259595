import React, { PureComponent } from 'react';
import {
    Radar, RadarChart, PolarGrid, Legend,
    PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer
} from 'recharts';

export default class ChartRadar extends PureComponent {
    formatLabel = (text) => {
        if (text.length > 4) {
            return `${text.slice(0, 4)}...`;
        }
        return text;
    }

    render() {
        const { beforeEvaluationData, afterEvaluationData } = this.props;

        const formattedBeforeData = beforeEvaluationData ? beforeEvaluationData.map(data => ({
            ...data,
            subject: this.formatLabel(data.subject),
            beforeScore: data.A, // Assuming 'A' is the key for before evaluation score
        })) : [];

        const formattedAfterData = afterEvaluationData ? afterEvaluationData.map(data => ({
            ...data,
            subject: this.formatLabel(data.subject),
            afterScore: data.A, // Assuming 'A' is the key for after evaluation score
        })) : [];

        const combinedData = formattedBeforeData.map((data, index) => ({
            ...data,
            afterScore: formattedAfterData[index] ? formattedAfterData[index].afterScore : 0
        }));
        const maxScore = Math.max(...combinedData.map(d => Math.max(d.beforeScore, d.afterScore)));

        return (
            <RadarChart
                cx="50%"
                cy="50%"
                outerRadius={75}
                width={250}
                height={180}
                data={combinedData}
            >
                <PolarGrid stroke="#ddd" />
                <PolarAngleAxis
                    dataKey="subject"
                    domain={[0, 7]}
                    tick={{fontSize: 8}}
                    tickCount={8}
                    fill="#aaa"
                />
                <PolarRadiusAxis
                    angle={30}
                    domain={[0, 7]}
                    tick={{fontSize: 0}}
                    tickCount={8}
                    axisLine={false}
                />
                <Radar name="前期評価" dataKey="beforeScore" stroke="#f49f61" fill="#eeb992" fillOpacity={0.3} />
                <Radar name="後期評価" dataKey="afterScore" stroke="#1EC9A9" fill="#b4ece1" fillOpacity={0.3} />
                <Legend />
            </RadarChart>
        );
    }
}
