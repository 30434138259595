import { useState, useEffect } from "react";
import {
  useUserProfile,
  useUpdateUser,
  useUpdateUserPassword,
} from "../../../Queries/UserQuery";
import StaffFormModal from "../../../Components/Modal/StaffFormModal";
import PasswordEditModal from "../../../Components/Modal/PasswordEditModal";
import LoaderComponent from "../../../Components/Layout/Loader";
/* Query */
import { useRankList } from "../../../Queries/RankQuery";
import { useShopsForSelectQuery } from "../../../Queries/ShopQuery";
import { useOccupationList } from "../../../Queries/OccupationQuery";
import { useEmploymentStatusList } from "../../../Queries/EmploymentStatusQuery";

const MypageDetail = () => {
  const { data: user, isLoading: isUserLoading } = useUserProfile();
  const { data: shops, isLoading: isShopsLoading } = useShopsForSelectQuery();
  const { data: employmentStatuses, isLoading: isEmploymentStatusesLoading } =
    useEmploymentStatusList();
  const { data: occupations, isLoading: isOccupationsLoading } =
    useOccupationList();
  const { data: ranks, isLoading: isRanksLoading } = useRankList();
  const updateUser = useUpdateUser();
  const updateUserPassword = useUpdateUserPassword();
  const [isEditModal, setIsEditModal] = useState(false);
  const [isPasswordModal, setIsPasswordModal] = useState(false);

  const Loading =
    isUserLoading ||
    isShopsLoading ||
    isEmploymentStatusesLoading ||
    isOccupationsLoading ||
    isRanksLoading;
  if (Loading) return <LoaderComponent />;

  const formatDate = (date) => {
    return date
      ? `${new Date(date).getFullYear()}年${
          new Date(date).getMonth() + 1
        }月${new Date(date).getDate()}日`
      : "未設定";
  };

  const getAuthority = (is_authority) => {
    return ["管理者", "教育担当者", "なし"][is_authority] || "不明な権限";
  };

  const handleEditModal = () => setIsEditModal(!isEditModal);
  const handlePasswordModal = () => setIsPasswordModal(!isPasswordModal);

  const handleEdit = (user) => {
    const onSuccess = () => {
      setIsEditModal(false);
    };
    updateUser.mutate(user, { onSuccess });
  };
  return (
    <div className="l-board">
      <StaffFormModal
        isOpen={isEditModal}
        handleClose={handleEditModal}
        onAction={handleEdit}
        editData={user}
        shopList={shops}
        employmentStatusList={employmentStatuses}
        occupationList={occupations}
        rankList={ranks}
      />
      <PasswordEditModal
        isOpen={isPasswordModal}
        selectedId={user.id}
        handleClose={handlePasswordModal}
        updateMutation={updateUserPassword}
      />

      <div className="l-board__inner">
        <div className="l-board__wrap p-board__btn no-border">
          <div className="l-board__btn--box">
            <button
              className="c-btn c-btn__blue c-btn__table"
              onClick={handleEditModal}
            >
              編集
            </button>
          </div>
        </div>

        <div className="l-board__wrap p-board__main">
          <div className="p-board__main--box">
            <table className="p-table p-table__detail">
              <tbody>
                <tr>
                  <td className="headline">コード</td>
                  <td>{user.code}</td>
                </tr>
                <tr>
                  <td className="headline">お名前</td>
                  <td>
                    <span className="u-tSub">
                      {user.lastNameKana} {user.firstNameKana}
                    </span>
                    <br />
                    {user.lastName} {user.firstName}
                  </td>
                </tr>
                <tr>
                  <td className="headline">メールアドレス</td>
                  <td>{user.email}</td>
                </tr>
                <tr>
                  <td className="headline">パスワード</td>
                  <td>
                    <button
                      className="c-btn c-btn__blue c-btn__table"
                      onClick={handlePasswordModal}
                    >
                      編集
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className="headline">所属店舗</td>
                  <td>{user.shopId ? user.shop.name : "未設定"}</td>
                </tr>
                <tr>
                  <td className="headline">権限</td>
                  <td>{getAuthority(user.isAuthority)}</td>
                </tr>
                <tr>
                  <td className="headline">雇用形態</td>
                  <td>
                    {user.employmentStatusId
                      ? user.employmentStatus.name
                      : "未設定"}
                  </td>
                </tr>
                <tr>
                  <td className="headline">職種</td>
                  <td>{user.occupationId ? user.occupation.name : "未設定"}</td>
                </tr>
                <tr>
                  <td className="headline">職位</td>
                  <td>{user.rankId ? user.rank.name : "未設定"}</td>
                </tr>
                <tr>
                  <td className="headline">入社日</td>
                  <td>{formatDate(user.joinedOn)}</td>
                </tr>
                <tr>
                  <td className="headline">退社日</td>
                  <td>{formatDate(user.retirementOn)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MypageDetail;
