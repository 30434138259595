// EducationResult.tsx

import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";

/* Query */
import {
  useAssistantList,
  useUsersForSelectQuery,
} from "../../../Queries/UserQuery";
import { useYearMonthSinceCreation } from "../../../Queries/CompanyQuery";
import { useAddCurriculumResult } from "../../../Queries/CurriculumResultQuery";
import { useFetchUserCurriculums } from "../../../Queries/EducationCurriculumQuery";
import { useFetchResultsByUserAndDate } from "../../../Queries/CurriculumResultQuery";

/* Components */
import LoaderComponent from "../../../Components/Layout/Loader";
import CurriculumResultAddModal from "../../../Components/Modal/CurriculumResultAddModal";

/* Context */
import { useAuthority } from "../../../Contexts/AuthorityContext";

/* Utils */
import { formatDate } from "../../../Utils/formatUtils";

interface Assistant {
  id: number;
  is_authority: string;
  last_name: string;
  first_name: string;
  // 他のフィールドも必要に応じて追加
}

interface EducationCurriculum {
  id: number;
  name: string;
  detail: string;
  curriculum_progresses: Array<{
    complete_on: string | null;
  }>;
}

interface EducationCategory {
  id: number;
  name: string;
  education_curriculums: EducationCurriculum[];
  // 他のフィールドも必要に応じて追加
}

interface CurriculumResult {
  id: number;
  done_on: string;
  start_time: string;
  finish_time: string;
  educator_name: string;
  curriculum_detail: string;
}

const EducationResult = (): JSX.Element => {
  const { authority, userId, isLoading: authorityLoading } = useAuthority(); // userIdを取得
  const navigate = useNavigate();
  const queryParams = queryString.parse(location.search);

  /* 追加モーダル */
  const [isAddModal, setIsAddModal] = useState(false);

  /* ページ内のデータ */
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [selectedYearMonth, setSelectedYearMonth] = useState<string>("");

  /* API */
  const { data: users, status: usersStatus } = useUsersForSelectQuery();
  const { data: assistants, status: assistantsStatus } = useAssistantList();
  const { data: yearMonthList, status: yearMonthListStatus } =
    useYearMonthSinceCreation();
  const {
    data: educationCurriculumList,
    status: educationCurriculumListStatus,
  } = useFetchUserCurriculums();

  const handleCloseModal = () => {
    setIsAddModal(false);
  };
  /* モーダルの登録処理 */
  const curriculumResultMutation = useAddCurriculumResult(handleCloseModal);

  // フィルタリングロジックを useMemo で最適化
  const filteredAssistants = useMemo<Assistant[]>(() => {
    if (!assistants) return [];

    switch (authority) {
      case 0:
        // 管理者: 全てのデータを返す
        console.log("Authority 0: Returning all assistants");
        return assistants;
      case 1:
        // 教育: is_authority が '0' 以外のデータのみ
        console.log(
          "Authority 1: Filtering assistants where is_authority !== '0'"
        );
        return assistants.filter((assistant) => assistant.is_authority !== "0");
      case 2:
        // 一般: ログインしている自分のデータのみ
        console.log("Authority 2: Filtering assistants where id === userId");
        return assistants.filter((assistant) => assistant.id === userId);
      default:
        // その他: 空配列を返す
        console.log("Authority not recognized: Returning empty array");
        return [];
    }
  }, [assistants, authority, userId]);

  // ログイン不可またはデータがない場合のメッセージ表示
  const noAssistants = useMemo<boolean>(() => {
    if (authority === null || ![0, 1, 2].includes(authority)) {
      return true;
    }
    if (authority === 2 && filteredAssistants.length === 0) {
      return true;
    }
    return false;
  }, [authority, filteredAssistants]);

  // selectタブの初期値
  useEffect(() => {
    if (authority === 2 && userId !== null) {
      // 一般ユーザーの場合、自分自身のIDを設定
      setSelectedUserId(userId);
    } else if (filteredAssistants && filteredAssistants.length > 0) {
      setSelectedUserId(filteredAssistants[0].id);
    }
    if (yearMonthList && yearMonthList.length > 0) {
      setSelectedYearMonth(yearMonthList[0]);
    }
  }, [filteredAssistants, yearMonthList, authority, userId]);

  /* 教育実績データ */
  const { data: results, status: resultsStatus } = useFetchResultsByUserAndDate(
    selectedUserId,
    selectedYearMonth
  );

  /* 選択系 */
  // 年月
  const handleYearMonthChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedYearMonth(event.target.value);
  };

  // ユーザー
  const handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = parseInt(event.target.value, 10);
    setSelectedUserId(selectedId);
  };

  /* 追加モーダル */
  const handleAddModalOpen = () => {
    setIsAddModal(true);
  };

  const isLoading =
    usersStatus === "loading" ||
    resultsStatus === "loading" ||
    assistantsStatus === "loading" ||
    yearMonthListStatus === "loading" ||
    educationCurriculumListStatus === "loading";

  const formatTime = (time: string): string => {
    const [hour, minute] = time.split(":");
    return `${parseInt(hour, 10)}:${minute}`;
  };

  // クエリパラメータの反映
  useEffect(() => {
    if (queryParams.yearMonth) {
      setSelectedYearMonth(queryParams.yearMonth as string);
    }

    if (queryParams.userId) {
      setSelectedUserId(Number(queryParams.userId));
    }
  }, [queryParams.yearMonth, queryParams.userId]);

  // デバッグ用のログ
  useEffect(() => {
    console.log("Authority:", authority);
    console.log("User ID:", userId);
    console.log("Assistants fetched:", assistants);
    console.log("Filtered assistants:", filteredAssistants);
    console.log("Selected User ID:", selectedUserId);
    console.log("Selected Year-Month:", selectedYearMonth);
  }, [
    authority,
    userId,
    assistants,
    filteredAssistants,
    selectedUserId,
    selectedYearMonth,
  ]);

  if (isLoading) return <LoaderComponent />;

  return (
    <>
      <CurriculumResultAddModal
        isOpen={isAddModal}
        isClose={handleCloseModal}
        onAction={curriculumResultMutation}
        userList={users}
        assistantList={assistants}
        educationCurriculumList={educationCurriculumList}
      />

      {/* セレクトボックスとボタンの表示条件 */}
      {assistants && !noAssistants ? (
        <>
          <div className="l-board__wrap p-board__btn no-border">
            <div className="l-flex p-board__btn--area space-around">
              {/* 年月選択セレクトボックス */}
              <div className="p-board__btn--input">
                <select
                  className="c-input c-input__select type-shadow"
                  onChange={handleYearMonthChange}
                  value={selectedYearMonth}
                >
                  {yearMonthList &&
                    yearMonthList.map((yearMonth, index) => {
                      // "-" で分割して、年と月を取得
                      const [year, month] = yearMonth.split("-");
                      return (
                        <option
                          key={index}
                          value={yearMonth}
                        >{`${year}年${month}月`}</option>
                      );
                    })}
                </select>
              </div>

              {/* アシスタント選択セレクトボックス */}
              {/* is_authority == 1 または 2 の場合にのみ表示 */}
              {(authority == 0 || authority == 1) && (
                <div className="p-board__btn--input">
                  <select
                    className="c-input c-input__select type-shadow"
                    onChange={handleUserChange}
                    value={selectedUserId !== null ? selectedUserId : ""}
                  >
                    {filteredAssistants.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.last_name} {user.first_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {/* 教育実績を追加ボタン: 管理者と教育者のみ表示 */}
              {(authority === 0 || authority === 1) && (
                <button
                  type="button"
                  className="p-board__btn--item c-btn c-btn__green icon icon-plus pc-only"
                  onClick={handleAddModalOpen}
                >
                  教育実績を追加
                </button>
              )}
            </div>
          </div>
          <div className="l-board__wrap p-board__main">
            <div className="p-board__main--box">
              <table className="p-table">
                <thead>
                  <tr>
                    <th>日付</th>
                    <th>時間</th>
                    <th>指導スタッフ</th>
                    <th>教育内容</th>
                  </tr>
                </thead>
                <tbody>
                  {results?.data?.map((result) => (
                    <tr
                      key={result.id}
                      onClick={() =>
                        navigate(
                          `/hc-user/education/result/detail/${result.id}`
                        )
                      }
                    >
                      <td>{formatDate(result.done_on)}</td>
                      <td>
                        {formatTime(result.start_time)}-
                        {formatTime(result.finish_time)}
                      </td>
                      <td>{result.educator_name}</td>
                      <td>{result.curriculum_detail}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div className="l-board__wrap p-board__main">
          該当するアシスタントが存在しません。
        </div>
      )}
    </>
  );
};
export default EducationResult;
