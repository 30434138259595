import axios from "axios";
import { Shop } from "../Types/Shop"

// ショップ一覧
export const getShopList = async () => {
    const { data } = await axios.get<Shop[]>(
        `/api/users/shop/list`
    );
    return data
}

// select用ショップ一覧
export const fetchShopsForSelect = async () => {
    const { data } = await axios.get<Shop[]>(`/api/users/shop/list/select`);
    return data
}

// ショップ詳細
export const getShopDetail = async (id: number) => {
    const { data } = await axios.get<Shop>(
        `/api/users/shop/detail/${id}`
    );
    return data
}

// ショップ編集
export const getShopEdit = async (id : number) => {
    const { data } = await axios.get<Shop[]>(
        `/api/users/shop/edit/${id}`
    );
    return data
}

// ショップ追加
export const createShop = async (shop: { name: string }) => {  // 引数を追加
    const { data } = await axios.post<Shop[]>(
        `/api/users/shop/add`, shop // newShopをリクエスト本体として送信
    );
    return data
}

// ショップ更新
export const updateShop = async (shopData: { id: number, name: string }) => {
    const { data } = await axios.put<Shop>(`/api/users/shop/update/${shopData.id}`, shopData);
    return data;
}

// ショップ削除
export const deleteShop = async (id: number) => {
    const { data } = await axios.delete(
        `/api/users/shop/delete/${id}`
    );
    return data;
}
