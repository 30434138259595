import React, { useState, useEffect } from "react";
import RangeForm from "./components/RangeForm";
import LoaderComponent from "../../../Components/Layout/Loader";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { getResultsForUser } from "../../../Queries/EvaluationResultQuery";
import { useUserOccupation } from "../../../Queries/UserQuery";
import { useEditUserEvaluationResult } from "../../../Queries/EvaluationResultEditQuery";

const HrEvaluationEdit = (): JSX.Element => {
    const { userId, yearMonth } = useParams();
    const [evaluationResults, setEvaluationResults] = useState(null);
    const { data: userOccupation, isLoading: isOccupationLoading } = useUserOccupation(parseInt(userId));
    const [getData, setGetData] = useState(null);
    const [editData, setEditData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const editMutation = useEditUserEvaluationResult();
    // 職種に応じて可変
    useEffect(() => {
        if (userOccupation) {
            switch (userOccupation.occupation_label) {
                case 'stylist':
                    sessionStorage.setItem('activeIndex', '0');
                    break;
                case 'assistant':
                    sessionStorage.setItem('activeIndex', '1');
                    break;
                default:
                    // デフォルトの処理やエラーハンドリング
                    break;
            }
        }
    }, [userId, yearMonth]);

    useEffect(() => {
        setIsLoading(isOccupationLoading || editMutation.isLoading); // Update loading state based on occupation loading or mutation loading
    }, [isOccupationLoading, editMutation.isLoading]);


    useEffect(() => {
        async function fetchData() {
            try {
                const data = await getResultsForUser(userId, yearMonth);
                const modifiedData = {
                    review: data.review,
                    review_values: [
                        ...data.role_evaluations,
                        ...data.performance_evaluations,
                        ...data.work_evaluations,
                        ...data.job_evaluations,
                    ].reduce((acc, elem, index) => ({
                        ...acc,
                        [elem?.id || index]: elem.result || 0,
                    }), {}),
                };
                setEvaluationResults(data);
                setGetData(modifiedData);
                setEditData(modifiedData);
            } catch (error) {
                toast.error("Failed to fetch evaluation results.");
            } finally {
                setIsLoading(false); // Ensure loading state is updated when data fetching is complete
            }
        }
        if (userId && yearMonth) {
            fetchData();
        }
    }, [userId, yearMonth]);

    const onChangeReview = (e) => {
        setEditData((prev) => ({
            ...prev,
            review: e.target.value,
        }));
    };

    const onChangeRadioValue = (newValue, id) => {
        setEditData((prev) => ({
            ...prev,
            review_values: {
                ...prev?.review_values,
                [id]: newValue,
            },
        }));
    };

    const handleSubmit = async () => {
        if (editMutation.isLoading) return;

        const editReviewValues = editData?.review_values;
        const editReview = editData?.review.trim();
        const filteredReviewValues = JSON.parse(JSON.stringify(editReviewValues));

        const sendData = {
            review: editReview,
            review_values: filteredReviewValues,
        };

        if (sendData.review.length > 4000) {
            toast.error("『総合評価』は4000文字以下で入力してください");
            return;
        }

        const isSameAsBefore =
            getData?.review === sendData.review &&
            JSON.stringify(getData?.review_values) ===
            JSON.stringify(sendData.review_values);

        if (isSameAsBefore) {
            toast.error("データに変更がありません。");
            return;
        }

        Object.keys(filteredReviewValues).forEach((key) => {
            if (!filteredReviewValues[key] || getData?.review_values[key] === filteredReviewValues[key]) {
                delete filteredReviewValues[key];
            }
        });

        const hasWrongValue = Object.keys(sendData.review_values).some(
            (key) =>
                sendData.review_values[key] < 1 ||
                sendData.review_values[key] > 7
        );

        if (hasWrongValue) {
            toast.error("評価は7段階です。");
            return;
        }


        editMutation.mutate({ userId, yearMonth, sendData });
    };

    if(isLoading) return <LoaderComponent />
    return (
        <>
            <div className="l-board l-board__edit">
                <div className="l-board__inner">
                    <div className="l-board__wrap p-board__btn">
                        <div className="p-board__btn--area">
                            <button
                                className="p-board__btn--item c-btn c-btn__blue"
                                onClick={() => {
                                    window.open("/hc-user/hr/elements", "_blank", "noopener,noreferrer");
                                }}
                            >
                                人事考課を確認
                            </button>
                        </div>
                    </div>
                    <div className="l-board__wrap p-board__main border-bottom">
                        <div className="p-board__main--box">
                            <h3 className="c-headline__main">総合評価</h3>
                            <div className="p-eva__input">
                                <p className="p-eva__input--headline"></p>
                                <div className="p-eva__input--form">
                                    <textarea
                                        name=""
                                        className="c-input c-input__textarea"
                                        id=""
                                        value={editData?.review || ""}
                                        onChange={(e) => onChangeReview(e)}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="l-board__wrap p-board__main border-bottom">
                        <div className="p-board__main--box">
                            <h3 className="c-headline__main">
                                仕事評価・役割評価（7段階）
                            </h3>
                            {evaluationResults &&
                                evaluationResults.job_evaluations.map(
                                    (evaluation, index) => (
                                        <div
                                            key={index}
                                            className="p-eva__input"
                                        >
                                            <p className="p-eva__input--headline">
                                                {evaluation.name}
                                            </p>
                                            <div className="p-eva__input--form">
                                                <RangeForm
                                                    keyPrefix={`job-${index}`}
                                                    id={evaluation.id}
                                                    value={
                                                        editData
                                                            ?.review_values[
                                                            evaluation.id
                                                        ] || 0
                                                    }
                                                    onChangeRadioValue={
                                                        onChangeRadioValue
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                )}

                            {evaluationResults &&
                                evaluationResults.role_evaluations.map(
                                    (evaluation, index) => (
                                        <div
                                            key={index}
                                            className="p-eva__input"
                                        >
                                            <p className="p-eva__input--headline">
                                                {evaluation.name}
                                            </p>
                                            <div className="p-eva__input--form">
                                                <RangeForm
                                                    keyPrefix={`role-${index}`}
                                                    id={evaluation.id}
                                                    value={
                                                        editData
                                                            ?.review_values[
                                                            evaluation.id
                                                        ] || 0
                                                    }
                                                    onChangeRadioValue={
                                                        onChangeRadioValue
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                )}
                        </div>
                    </div>
                    <div className="l-board__wrap p-board__main border-bottom">
                        <div className="p-board__main--box">
                            <h3 className="c-headline__main">
                                業績評価（7段階）
                            </h3>
                            {evaluationResults &&
                                evaluationResults.performance_evaluations.map(
                                    (evaluation, index) => (
                                        <div
                                            key={index}
                                            className="p-eva__input"
                                        >
                                            <p className="p-eva__input--headline">
                                                {evaluation.name}
                                            </p>
                                            <div className="p-eva__input--form">
                                                <RangeForm
                                                    keyPrefix={`performance-${index}`}
                                                    id={evaluation.id}
                                                    value={
                                                        editData
                                                            ?.review_values[
                                                            evaluation.id
                                                        ] || 0
                                                    }
                                                    onChangeRadioValue={
                                                        onChangeRadioValue
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                )}
                        </div>
                    </div>
                    <div className="l-board__wrap p-board__main u-mb2em">
                        <div className="p-board__main--box">
                            <h3 className="c-headline__main">
                                勤務評価（加点）
                            </h3>
                            {evaluationResults &&
                                evaluationResults.work_evaluations.map(
                                    (evaluation, index) => (
                                        <div
                                            key={index}
                                            className="p-eva__input"
                                        >
                                            <p className="p-eva__input--headline">
                                                {evaluation.name}
                                            </p>
                                            <div className="p-eva__input--form">
                                                <RangeForm
                                                    keyPrefix={`work-${index}`}
                                                    id={evaluation.id}
                                                    value={
                                                        editData
                                                            ?.review_values[
                                                            evaluation.id
                                                        ] || 0
                                                    }
                                                    onChangeRadioValue={
                                                        onChangeRadioValue
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )
                                )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="l-button">
                <button
                    className="c-btn c-btn__bgGreen c-btn__submit"
                    onClick={handleSubmit}
                >人事考課を更新</button>
            </div>
        </>
    );
};
export default HrEvaluationEdit;
