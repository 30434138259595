
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getUserGoalsForYear, fetchPerformanceUserGoal, updatePerformanceUserGoals } from './../Api/PerformanceUserGoalAPI';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom'

export const useUserGoalsForYear = (userId: number, year: string) => {
    return useQuery(['userGoalsForYear', userId, year], () => getUserGoalsForYear(userId, year));
}

export const getPerformanceUserGoal = async (userId: number, year: string) => {
    try {
        const data = await fetchPerformanceUserGoal(userId, year);
        return data;
    } catch (error) {
        console.error("Error fetching performance user goal data: ", error);
        throw error;
    }
};

export const useUpdatePerformanceUserGoal = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient(); // Declare the queryClient constant
    const mutation = useMutation(
        (data: { userId: number, data: any[] }) => {
            return updatePerformanceUserGoals(data.userId, data.data);
        },
        {
            onSuccess: (response) => {
                // if (response && response.data && response.data.status === 'success') {
                    queryClient.resetQueries();
                    toast.success('業績の更新に成功しました。');
                    navigate(`/hc-user/performance/goal`);
                // }
            },
            onError: (error) => {
                if (error.response && error.response.status === 422 && error.response.data.errors) {
                    // 422のエラー内容を表示
                    toast.error(`業績の更新に失敗しました。エラー: ${JSON.stringify(error.response.data.errors)}`);
                } else {
                    toast.error('業績の更新に失敗しました。');
                }
            }
        }
    );

    return mutation;
};
