import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import LoaderComponent from "../../../Components/Layout/Loader";
import { toast } from "react-toastify";
import isEqual from "lodash/isEqual";
import {
  useResultsByYearForEdit,
  useChangePerformanceResultMutation,
} from "../../../Queries/PerformanceResultQuery";

const PerformanceStaffEdit = (): JSX.Element => {
  const { userId, year } = useParams<{ userId: string; year: string }>();
  const { handleSubmit, setValue, watch, control } = useForm();
  const changePerformanceResult = useChangePerformanceResultMutation();
  const { data: editData, isLoading } = useResultsByYearForEdit(userId, year);

  // 初期値設定
  useEffect(() => {
    if (editData) {
      editData.performanceResults.forEach((monthData, monthIndex) => {
        monthData.results.forEach((result, resultIndex) => {
          const fieldName = `performanceResults[${monthIndex}].results[${resultIndex}].result`;
          setValue(fieldName, result.result || "");
        });
      });
    }
  }, [editData, setValue]);

  // 更新機能
  const handleUpdateEditData = async (formData) => {
    const changedData = [];
    // formData.performanceResults をループする
    formData.performanceResults.forEach((monthData, monthIndex) => {
      monthData.results.forEach((result, resultIndex) => {
        const originalResult =
          editData.performanceResults[monthIndex].results[resultIndex];
        // ID と performanceItemId を含めて比較
        if (
          !isEqual({ ...originalResult, result: result.result }, originalResult)
        ) {
          changedData.push({
            ...originalResult,
            userId: userId,
            result: result.result,
            inputOn: `${year}-${String(
              editData.performanceResults[monthIndex].month
            ).padStart(2, "0")}`,
          });
        }
      });
    });
    if (changedData.length > 0) {
      await changePerformanceResult.mutateAsync(changedData);
    } else {
      toast.error("更新データがありません。");
    }
  };

  // リセット機能
  const handleReset = (monthIndex) => {
    const monthData = editData.performanceResults[monthIndex];
    monthData.results.forEach((_, resultIndex) => {
      const fieldName = `performanceResults[${monthIndex}].results[${resultIndex}].result`;
      setValue(fieldName, ""); // ここでは初期値を空文字列に設定します
    });
  };

  if (isLoading) return <LoaderComponent />;

  return (
    <form onSubmit={handleSubmit(handleUpdateEditData)}>
      <div className="l-board l-board__edit is_scroll">
        <div className="l-board__inner">
          <div className="l-board__wrap p-board__main">
            <div className="p-board__main--box p-input__area">
              {/* {errorMessages && (
                                <div className="c-msgError" dangerouslySetInnerHTML={{ __html: errorMessages }} />
                            )} */}

              <table className="p-table p-table__small">
                <thead>
                  <tr>
                    <th>該当月</th>
                    {editData &&
                      editData.performanceItems.map((item, index) => (
                        <th key={index}>{item.name}</th>
                      ))}
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {editData &&
                    editData.performanceResults.map((monthData, monthIndex) => (
                      <tr key={monthIndex}>
                        <td>{monthData.month}月</td>
                        {monthData.results.map((result, resultIndex) => (
                          <td key={resultIndex}>
                            <Controller
                              name={`performanceResults[${monthIndex}].results[${resultIndex}].result`}
                              control={control}
                              defaultValue={result.result || ""}
                              render={({ field }) => (
                                <input
                                  type="text"
                                  className="c-input c-input__time c-input__data"
                                  {...field}
                                />
                              )}
                            />
                          </td>
                        ))}
                        <td>
                          <button
                            type="button"
                            className="c-btn c-btn__red c-btn__table"
                            onClick={() => handleReset(monthIndex)}
                          >
                            リセット
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="l-button">
        <button className="c-btn c-btn__bgGreen c-btn__submit" type="submit">
          スタッフ業績を更新
        </button>
      </div>
    </form>
  );
};

export default PerformanceStaffEdit;
