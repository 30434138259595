import React, { forwardRef } from 'react';

const TextareaField = forwardRef(({ label, name, error, ...rest }, ref) => (
    <div className="p-input__area">
        <label className="c-label">{label}</label>
        <textarea
            ref={ref}
            name={name}
            className="c-input c-input__textarea u-mt4"
            {...rest} />
        {error && <p className="p-input__error">{error}</p>}
    </div>
));

export default TextareaField;
