import React, { useState, useEffect } from "react";
import LoaderComponent from "../../../Components/Layout/Loader";
import { Link } from "react-router-dom";
import { useShopsForSelectQuery } from "../../../Queries/ShopQuery";
import { useFetchYearsSinceCreation } from "../../../Queries/CompanyQuery";
import { useShopPerformanceResultsForYear } from "../../../Queries/ShopPerformanceResultQuery";
import { useAuthority } from "../../../Contexts/AuthorityContext";

const PerformanceShop = (): JSX.Element => {
  const { authority, userShopId } = useAuthority();

  // 型安全なアクセスとデフォルト値の設定
  const savedYear = sessionStorage.getItem("performanceShopSelectedYear");
  const savedShopId = sessionStorage.getItem("performanceSelectedShopId");

  const initialYear = String(new Date().getFullYear());

  const [selectedYear, setSelectedYear] = useState(savedYear || initialYear);
  const [selectedShopId, setSelectedShopId] = useState(savedShopId || "");
  const [performanceData, setPerformanceData] = useState<any>(null);

  const { data: shops, isLoading: isLoadingShops } = useShopsForSelectQuery();
  const { data: yearList, isLoading: isLoadingYearList } =
    useFetchYearsSinceCreation();
  const { data: shopPerformanceData, isLoading: isLoadingShopPerformanceData } =
    useShopPerformanceResultsForYear(
      Number(selectedShopId),
      Number(selectedYear)
    );

  // 全体のローディング状態の管理
  const isLoading =
    isLoadingShops || isLoadingYearList || isLoadingShopPerformanceData;

  useEffect(() => {
    if (!savedYear) {
      setSelectedYear(initialYear);
    }

    if (!savedShopId && shops?.length > 0) {
      if (authority === 0 || authority === 1) {
        // 管理者や教育担当者の場合、最初の店舗を選択
        setSelectedShopId(shops[0].id.toString());
      } else if (userShopId) {
        // 一般ユーザーの場合、自身の店舗を選択
        setSelectedShopId(userShopId.toString());
        sessionStorage.setItem("performanceSelectedShopId", userShopId.toString());
      }
    } else if (savedShopId) {
      setSelectedShopId(savedShopId);
    }
  }, [shops, yearList, savedYear, savedShopId, authority, userShopId]);

  useEffect(() => {
    if (shopPerformanceData) {
      setPerformanceData(shopPerformanceData);
    }
  }, [shopPerformanceData]);

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const year = event.target.value;
    setSelectedYear(year);
    sessionStorage.setItem("performanceShopSelectedYear", year);
  };

  const handleShopChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const shopId = event.target.value;
    setSelectedShopId(shopId);
    sessionStorage.setItem("performanceSelectedShopId", shopId);
  };

  const numberWithCommas = (x: number | string) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  // ボタン表示の判定ロジック
  const showEditButton = () => {
    console.log(
      `Authority: ${authority}, UserShopId: ${userShopId}, SelectedShopId: ${selectedShopId}`
    );
    switch (authority) {
      case "0": // 管理者
        return true;
      case "1": // 教育担当者
      case "2": // 一般ユーザー
        return Number(userShopId) === Number(selectedShopId);
      default:
        return false; // その他の権限では非表示
    }
  };

  if (isLoading) {
    return <LoaderComponent />;
  }

  if (authority !== 0 && authority !== 1) {
    if (!userShopId) {
      return <div className="l-board__wrap p-board__main">店舗に未所属です。</div>;
    }
  }

  return (
    <>
      {shops && shops.length > 0 ? (
        <>
          <div className="l-board__wrap p-board__btn">
            <div className="l-flex p-board__btn--area">
              <div className="p-board__btn--input">
                <select
                  className="c-input c-input__select type-shadow"
                  onChange={handleYearChange}
                  value={selectedYear}
                >
                  {yearList &&
                    yearList.map((year, index) => (
                      <option key={index} value={year}>
                        {year}年
                      </option>
                    ))}
                </select>
              </div>
              {(authority === 0 || authority === 1) && (
                <div className="p-board__btn--input">
                  <select
                    className="c-input c-input__select type-shadow"
                    onChange={handleShopChange}
                    value={selectedShopId}
                  >
                    {shops.map((shop) => (
                      <option key={shop.id} value={shop.id}>
                        {shop.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            {showEditButton() && (
              <div className="l-flex p-board__btn--area space-around">
                <Link
                  to={`/hc-user/performance/shop/edit/${selectedShopId}/${selectedYear}`}
                  className="p-board__btn--item c-btn c-btn__blue pc-only"
                >
                  店舗業績を編集
                </Link>
              </div>
            )}
          </div>
          <div className="l-board__wrap p-board__main">
            <div className="p-board__main--box">
              <table className="p-table p-table__fixed">
                <thead>
                  <tr>
                    <th>該当月</th>
                    {performanceData &&
                      performanceData.shopPerformanceItems.map((item) => (
                        <th key={item.id}>{item.name}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {performanceData &&
                    performanceData.shopPerformanceResults.map(
                      (monthResults, monthIndex) => (
                        <tr key={monthIndex}>
                          <td>{`${monthIndex + 1}月`}</td>
                          {monthResults.map((performanceItem, itemIndex) => {
                            if (
                              performanceItem.point !== "" &&
                              performanceItem.point != null
                            ) {
                              if (itemIndex === 2) {
                                return (
                                  <td
                                    key={itemIndex}
                                    className="u-tAlign--right"
                                  >
                                    {`${numberWithCommas(performanceItem.point)}%`}
                                  </td>
                                );
                              } else if (itemIndex === 3) {
                                return (
                                  <td
                                    key={itemIndex}
                                    className="u-tAlign--right"
                                  >
                                    {`${numberWithCommas(performanceItem.point)}人`}
                                  </td>
                                );
                              } else {
                                return (
                                  <td
                                    key={itemIndex}
                                    className="u-tAlign--right"
                                  >
                                    {`${numberWithCommas(performanceItem.point)}円`}
                                  </td>
                                );
                              }
                            } else {
                              return (
                                <td key={itemIndex} className="u-tAlign--right">
                                  -
                                </td>
                              );
                            }
                          })}
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div className="l-board__wrap p-board__main">店舗がありません。</div>
      )}
    </>
  );
};

export default PerformanceShop;
