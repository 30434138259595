import React, { useState } from 'react';
import CalculationRuleEditModal from '../../../Components/Modal/CalculationRuleEditModal';
import { useCalculationRules } from '../../../Queries/CalculationRuleQuery';
import LoaderComponent from '../../../Components/Layout/Loader';
import { useAuthority } from '../../../Contexts/AuthorityContext';

const CompanyRule = (): JSX.Element => {
    const { authority } = useAuthority();
    const { data: rulesData, isLoading } = useCalculationRules();
    const [isEditModal, setIsEditModal] = useState(false);

    const handleEditModalOpen = () => setIsEditModal(true);
    const handleEditModalClose = () => setIsEditModal(false);

    if (isLoading) return <LoaderComponent />;

    // ルールデータから各ルールを取得
    const workStartRule = rulesData?.find(rule => rule.target === '0') || {};
    const workEndRule = rulesData?.find(rule => rule.target === '1') || {};
    const breakStartRule = rulesData?.find(rule => rule.target === '2') || {};
    const breakEndRule = rulesData?.find(rule => rule.target === '3') || {};

    return (
        <>
            {authority == '0' && (
                <>
                    <CalculationRuleEditModal
                        isOpen={isEditModal}
                        handleClose={handleEditModalClose}
                        rulesData={rulesData || []}
                    />
                    <div className="l-board__wrap p-board__btn">
                        <div className="l-flex p-board__btn--area space-around">
                            <button
                                className="c-btn__table c-btn c-btn__blue"
                                onClick={handleEditModalOpen}
                            >編集</button>
                        </div>
                    </div>
                </>
            )}
            <div className="l-board__wrap p-board__main">
                <div className="p-board__main--box">
                    <table className="p-table p-table__detail">
                        <thead>
                            <tr className="cursor-auto">
                                <th>項目</th>
                                <th>時間区分</th>
                                <th>切り上げ/切り捨て</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="headline">始業</td>
                                <td>{workStartRule.period}分</td>
                                <td>{workStartRule.round === '0' ? '切り捨て' : '切り上げ'}</td>
                            </tr>
                            <tr>
                                <td className="headline">終業</td>
                                <td>{workEndRule.period}分</td>
                                <td>{workEndRule.round === '0' ? '切り捨て' : '切り上げ'}</td>
                            </tr>
                            <tr>
                                <td className="headline">休憩開始</td>
                                <td>{breakStartRule.period}分</td>
                                <td>{breakStartRule.round === '0' ? '切り捨て' : '切り上げ'}</td>
                            </tr>
                            <tr>
                                <td className="headline">休憩終了</td>
                                <td>{breakEndRule.period}分</td>
                                <td>{breakEndRule.round === '0' ? '切り捨て' : '切り上げ'}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
export default CompanyRule;
