import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LoaderComponent from "../../../Components/Layout/Loader";
import { useUsersForSelectQuery, useUserProfile } from "../../../Queries/UserQuery";
import { useEvaluationPeriods } from "../../../Queries/EvaluationRuleQuery";
import { fetchUserTotalInEvaluationPeriod } from "../../../Queries/PerformanceResultQuery";
import { useAuthority } from "../../../Contexts/AuthorityContext";

interface User {
  id: number;
  last_name: string;
  first_name: string;
  // 他のユーザー関連フィールド...
}

interface Period {
  start: string;
  end: string;
}

interface PerformanceData {
  id: number;
  name: string;
  total: number;
  goal: number;
  // 他のフィールド...
}

const HrPerformance = (): JSX.Element => {
  const { authority } = useAuthority();

  /* ページ内データ */
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [selectedPeriodStart, setSelectedPeriodStart] = useState<string>("");
  const [selectedPeriodEnd, setSelectedPeriodEnd] = useState<string>("");
  const [selectedUserName, setSelectedUserName] = useState("");

  /* 取得API */
  const { data: users, status: usersStatus } = useUsersForSelectQuery();
  const { data: profile, status: profileStatus } = useUserProfile();
  const { data: periods, status: periodsStatus } = useEvaluationPeriods();

  const [performanceData, setPerformanceData] = useState<PerformanceData[] | null>(null);

  /* ローディング */
  const [isLoading, setIsLoading] = useState<boolean>(true);

  /* format群 */
  // 年月
  const formatPeriod = (period: Period): string => {
    let startYear = period.start.slice(0, 4).slice(-2); // Extract last two digits of year
    let startMonth = parseInt(period.start.slice(4, 6), 10); // Extract month part and convert string to integer
    let endYear = period.end.slice(0, 4).slice(-2);
    let endMonth = parseInt(period.end.slice(4, 6), 10);
    return `${startYear}年${startMonth}月-${endYear}年${endMonth}月`;
  };
  // 数字に対して3桁ごとにカンマを打つプログラム
  const formatNumber = (number: number): string => {
    return new Intl.NumberFormat("ja-JP").format(number);
  };

  useEffect(() => {
    if (users && profile) {
      if (profile.isAuthority === "0") {
        // isAuthorityが"0"の場合、ユーザー選択を許可
        const userExists = users.find((user: User) => user.id === profile.id);
        if (userExists) {
          setSelectedUserId(profile.id);
          setSelectedUserName(`${userExists.last_name} ${userExists.first_name}`);
        } else {
          // profile.idが存在しない場合は最初のユーザーを設定
          setSelectedUserId(users[0].id);
          setSelectedUserName(`${users[0].last_name} ${users[0].first_name}`);
        }
      } else {
        // isAuthorityが"0"でない場合、selectedUserIdをprofile.idに固定
        setSelectedUserId(profile.id);
        const user = users.find((user: User) => user.id === profile.id);
        if (user) {
          setSelectedUserName(`${user.last_name} ${user.first_name}`);
        }
      }
    } else if (users && users.length > 0) {
      // profileがまだロードされていない場合、または存在しない場合は最初のユーザーを設定
      setSelectedUserId(users[0].id);
      setSelectedUserName(`${users[0].last_name} ${users[0].first_name}`);
    }

    if (periods && periods.length > 0) {
      setSelectedPeriodStart(periods[0].start);
      setSelectedPeriodEnd(periods[0].end);
    }
  }, [users, periods, profile]);

  // 評価実績を取得
  useEffect(() => {
    const fetchData = async () => {
      if (selectedUserId === null || selectedPeriodStart === "") return;

      setIsLoading(true);
      try {
        const year = selectedPeriodStart.slice(0, 4);
        const month = selectedPeriodStart.slice(4, 6);
        const data = await fetchUserTotalInEvaluationPeriod(
          selectedUserId,
          year,
          month
        );
        if (data) {
          setPerformanceData(data);
        } else {
          console.error(
            "No data received from fetchUserTotalInEvaluationPeriod"
          );
        }
      } catch (error: any) {
        console.error("Failed to fetch performance data:", error.message);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [selectedUserId, selectedPeriodStart]);

  // スタッフ名を取得
  useEffect(() => {
    if (selectedUserId && users) {
      const user = users.find((u: User) => u.id === selectedUserId);
      if (user) {
        setSelectedUserName(`${user.last_name} ${user.first_name}`);
      }
    }
  }, [selectedUserId, users]);

  // 人事考課表ダウンロードボタンのクリックイベントハンドラ
  const handleDownload = async () => {
    // Implement the download functionality here
  };

  /* 選択系 */
  // ユーザー
  const handleUserChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const userId = Number(event.target.value);
    setSelectedUserId(userId);
    const user = users?.find((u: User) => u.id === userId);
    if (user) {
      setSelectedUserName(`${user.last_name} ${user.first_name}`);
    }
    setIsLoading(true);
  };

  // 期間
  const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const [start, end] = event.target.value.split("-");
    setSelectedPeriodStart(start);
    setSelectedPeriodEnd(end);
    setIsLoading(true);
  };

  if (isLoading) return <LoaderComponent />;

  return (
    <>
      <div className="l-board__wrap p-board__btn">
        <div className="l-flex p-board__btn--area">
          <div className="p-board__btn--input">
            <select
              className="c-input c-input__select type-shadow"
              onChange={handlePeriodChange}
              value={`${selectedPeriodStart}-${selectedPeriodEnd}`}
            >
              {periods &&
                periods.map(
                  (
                    period: Period,
                    index: number // 3. Map through the periods
                  ) => (
                    <option key={index} value={`${period.start}-${period.end}`}>
                      {formatPeriod(period)}
                    </option>
                  )
                )}
            </select>
          </div>
          {/* 修正3と修正4: isAuthorityが0でない場合、該当箇所を非表示に */}
          {profile?.isAuthority === "0" && (
            <div className="p-board__btn--input">
              <select
                className="c-input c-input__select type-shadow"
                onChange={handleUserChange}
                value={selectedUserId !== null ? selectedUserId : ""}
              >
                {users &&
                  users.map((user: User, index: number) => (
                    <option key={index} value={user.id}>
                      {user.last_name} {user.first_name}
                    </option>
                  ))}
              </select>
            </div>
          )}
        </div>
      </div>
      <div className="l-board__wrap p-board__main">
        <div className="p-board__main--box">
          <ul className="p-list__master">
            {performanceData &&
              performanceData.map((item: PerformanceData, index: number) => (
                <li key={index} className="p-list__master--item">
                  <Link
                    to={`/hc-user/hr/performance/detail/${selectedUserId}/${item.id}/${selectedPeriodStart.slice(
                      0,
                      4
                    )}/${selectedPeriodStart.slice(4, 6)}`}
                  >
                    <div className="c-performance__box">
                      <p className="c-performance__headline">{item.name}</p>
                      <p className="c-performance__data">
                        {formatNumber(item.total)}円
                      </p>
                      <p className="c-performance__sub">
                        (目標：{formatNumber(item.goal)}円)
                      </p>
                    </div>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default HrPerformance;
