import { AxiosError } from 'axios';
import * as api from "../Api/EvaluationItemAPI";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from "react-toastify";

// 一覧
const useEvaluationItemList = () => {
    return useQuery('evaluationItemList', () => api.getEvaluationItemList())
}

// 一覧_勤務
const useEvaluationItemListOfWork = () => {
    return useQuery('evaluationItemListOfWork', () => api.getEvaluationItemListOfWork())
}

// 追加
const useCreateEvaluationItem = () => {
    const queryClient = useQueryClient();
    return useMutation(api.createEvaluationItem, {
        onSuccess: () => {
            queryClient.invalidateQueries('evaluationItems');
            toast.success('評価項目の登録に成功しました。');
        },
        onError: (error: AxiosError) => {
            if (error.response?.status === 422 && error.response?.data.errors) {
                Object.entries(error.response.data.errors).forEach(([field, messages]) => {
                    messages.forEach((message: string) => {
                        toast.error(message);
                    });
                });
            } else {
                toast.error('評価項目の登録に失敗しました。');
            }
        }
    });
};

// 更新
const useUpdateEvaluationItem = (onSuccessCallback) => {
    const queryClient = useQueryClient();
    return useMutation(api.updateEvaluationItem, {
        onSuccess: () => {
            queryClient.invalidateQueries('evaluationItems');
            toast.success('評価項目の更新に成功しました。');
            if (onSuccessCallback) onSuccessCallback();
        },
        onError: (error: AxiosError) => {
            if (error.response?.data.errors) {
                Object.values(error.response?.data.errors).forEach((messages: any) => {
                    messages.forEach((message: string) => {
                        toast.error(message);
                    });
                });
            } else {
                toast.error('評価項目の更新に失敗しました。');
            }
        }
    });
};

// 削除
const useDeleteEvaluationItem = () => {
    const queryClient = useQueryClient();
    return useMutation(api.deleteEvaluationItem, {
        onSuccess: () => {
            queryClient.invalidateQueries('evaluationItems');
            toast.success('評価項目を削除しました。');
        },
        onError: (error: AxiosError) => {
            if (error.response?.data.errors) {
                Object.values(error.response?.data.errors).forEach((messages: any) => {
                    messages.forEach((message: string) => {
                        toast.error(message);
                    });
                });
            } else {
                toast.error('評価項目の削除に失敗しました。');
            }
        }
    });
};

export {
    useEvaluationItemList,
    useEvaluationItemListOfWork,
    useCreateEvaluationItem,
    useUpdateEvaluationItem,
    useDeleteEvaluationItem
}
