import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import ToggleOccupationBtn from "../../../Components/Common/ToggleOccupationBtn";
import LoaderComponent from "../../../Components/Layout/Loader";
import { useUsersByOccupationId } from "../../../Queries/UserQuery";
import { useOccupationList } from "../../../Queries/OccupationQuery";
import { useYearMonthSinceCreation } from "../../../Queries/CompanyQuery";
import { usePerformanceItemOfOccupationList } from "../../../Queries/PerformanceItemQuery";
import { usePerformanceGoalsForOccupationAndYear } from "../../../Queries/PerformanceCompanyGoalQuery";
import { useUserGoalsForYear } from "../../../Queries/PerformanceUserGoalQuery";
import { useAuthority } from "../../../Contexts/AuthorityContext";

const PerformanceGoal = (): JSX.Element => {
  const { authority } = useAuthority();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const currentYear = new Date().getFullYear();
  const [occupationNames, setOccupationNames] = useState([]);
  const [occupationIds, setOccupationIds] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [occupationLabels, setOccupationLabels] = useState([]);

  // local storage
  const savedOccupationId = sessionStorage.getItem("occupationId");
  const savedUserId = sessionStorage.getItem("userId");

  // 選択系
  const [selectedOccupationId, setSelectedOccupationId] = useState(
    savedOccupationId ? parseInt(savedOccupationId) : null
  );
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedUser, setSelectedUser] = useState(
    savedUserId ? savedUserId : "0"
  );

  // データ
  const [yearList, setYearList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [targetGoalsData, setTargetGoalsData] = useState([]);

  const params = useParams();
  const occupationLabel = params.occupationLabel;

  /* API取得 */
  // const { data: users, status: usersStatus } = useUsersByOccupationId(Number(occupationId));
  const { data: users, status: usersStatus } = useUsersByOccupationId(
    Number(selectedOccupationId)
  );
  const { data: occupations, status: occupationsStatus } = useOccupationList();
  const { data: performanceItems, status: performanceItemsStatus } =
    usePerformanceItemOfOccupationList(Number(selectedOccupationId));
  const { data: userGoalsData, status: userGoalsDataStatus } =
    useUserGoalsForYear(selectedUser, selectedYear);
  // const { data: performanceGoalsData, status: performanceGoalsDataStatus } = usePerformanceGoalsForOccupationAndYear(Number(occupationId), selectedYear);
  const { data: performanceGoalsData, status: performanceGoalsDataStatus } =
    usePerformanceGoalsForOccupationAndYear(
      Number(selectedOccupationId),
      selectedYear
    );
  const { data: yearMonthSinceCreation, status: yearMonthSinceCreationStatus } =
    useYearMonthSinceCreation();

  const fetchTargetGoals = () => {
    const goalsData =
      selectedUser === "0" ? performanceGoalsData : userGoalsData;
    setTargetGoalsData(Array.isArray(goalsData) ? goalsData : []);
  };

  /* 更新機能 */
  useEffect(() => {
    let occupationId;
    // URLパラメーターから職種を検索
    if (occupationLabel && occupations) {
      const foundOccupation = occupations.find(
        (occupation) => occupation.label === occupationLabel
      );
      if (foundOccupation) {
        occupationId = foundOccupation.id;
      }
    }

    // ローカルストレージから職種IDを取得
    if (!occupationId && savedOccupationId) {
      occupationId = parseInt(savedOccupationId, 10);
    }

    // 初期職種をセット
    if (!occupationId && occupations && occupations.length > 0) {
      occupationId = occupations[0].id;
    }

    if (occupationId) {
      setSelectedOccupationId(occupationId);
    }
  }, [occupations, occupationLabel, savedOccupationId]);

  useEffect(() => {
    if (occupations && occupations.length > 0) {
      const names = occupations.map((occupation) => occupation.name);
      const ids = occupations.map((occupation) => occupation.id);
      const labels = occupations.map((occupation) => occupation.label);
      setOccupationNames(names);
      setOccupationIds(ids);
      setOccupationLabels(labels);
    }
  }, [occupations]);

  const handleOccupationChange = (index) => {
    const newOccupationId = occupationIds[index];
    setSelectedOccupationId(newOccupationId);
  };

  // 年変更
  const handleSelectedYearChange = (e) => {
    const newSelectedYear = e.target.value;
    setSelectedYear(newSelectedYear);
    fetchTargetGoals();
  };
  // ユーザー変更
  const handleSelectedUserChange = (e) => {
    const newSelectedUser = e.target.value;
    setSelectedUser(newSelectedUser);
    fetchTargetGoals();
  };

  // 年月の初期値セット
  useEffect(() => {
    const years = yearMonthSinceCreation
      ? [
          ...new Set(
            yearMonthSinceCreation.map((date) => new Date(date).getFullYear())
          ),
        ].sort((a, b) => a - b)
      : [];
    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    setYearList(years);
    setMonthList(months);
  }, [yearMonthSinceCreation]);

  // 目標をセット
  useEffect(() => {
    fetchTargetGoals();
  }, [
    selectedUser,
    selectedYear,
    userGoalsData,
    performanceGoalsData,
    selectedOccupationId,
  ]);

  useEffect(() => {
    setIsLoading(
      usersStatus === "loading" ||
        yearMonthSinceCreationStatus === "loading" ||
        performanceItemsStatus === "loading" ||
        userGoalsDataStatus === "loading" ||
        performanceGoalsDataStatus === "loading" ||
        occupationsStatus === "loading"
    );
  }, [
    usersStatus,
    yearMonthSinceCreationStatus,
    performanceItemsStatus,
    userGoalsDataStatus,
    performanceGoalsDataStatus,
    occupationsStatus,
  ]);

  const numberWithCommas = (x) =>
    x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const getSuffixForItemName = (itemName) => {
    const suffixMap = {
      "技術(指名)売上額": "円",
      "技術(フリー)売上額": "円",
      物販売上: "円",
      技術売上: "円",
      新規客数: "人",
      指名客数: "人",
      次回予約客数: "人",
    };
    return suffixMap[itemName] || "";
  };

  return (
    <>
      {/* <ul className="p-board__tab u-mt1em">
                {occupations?.map((occupation, index) => (
                    <li
                        key={index}
                        className={`p-tab__btn ${occupationLabel === occupation.label ? 'is_active' : ''}`}
                        onClick={() => {
                            setSelectedOccupationId(occupation.id);
                            navigate(`/hc-user/performance/goal/${occupation.label}`);
                        }}
                    >
                        {occupation.name}
                    </li>
                ))}
            </ul> */}

      {isLoading ? (
        <LoaderComponent />
      ) : (
        <>
          <div className="l-board__wrap p-board__btn l-flex space-between">
            <div className="p-board__btn--area">
              <div className="p-board__btn--input">
                <ToggleOccupationBtn
                  activeIndex={selectedOccupationId} // 職種IDを直接渡す
                  setActiveIndex={handleOccupationChange} // インデックスを職種IDに変換する関数を渡す
                  btnTexts={occupationNames}
                  occupationIds={occupationIds}
                />
              </div>
            </div>
            <div className="l-flex p-board__btn--area">
              <div className="p-board__btn--input">
                <select
                  className="c-input c-input__select type-shadow"
                  value={selectedYear}
                  onChange={handleSelectedYearChange}
                >
                  {yearList?.map((year, index) => (
                    <option key={index} value={year}>
                      {year}年
                    </option>
                  ))}
                </select>
              </div>
              <div className="p-board__btn--input">
                <select
                  className="c-input c-input__select type-shadow"
                  value={selectedUser}
                  onChange={handleSelectedUserChange}
                >
                  <option value="0">全体</option>
                  {users?.map((user, index) => (
                    <option key={index} value={user.id}>
                      {user.last_name} {user.first_name}
                    </option>
                  ))}
                </select>
              </div>
              {authority == "0" && (
                <Link
                  to={
                    selectedUser === "0"
                      ? `/hc-user/performance/goal/edit/company/${selectedOccupationId}/${selectedYear}`
                      : `/hc-user/performance/goal/edit/user/${selectedUser}/${selectedYear}`
                  }
                  className="p-board__btn--item c-btn c-btn__blue pc-only"
                  onClick={() => {
                    // sessionStorageに選択された職種IDと年、ユーザーIDを保存
                    sessionStorage.setItem(
                      "occupationId",
                      selectedOccupationId.toString()
                    );
                    sessionStorage.setItem(
                      "selectedYear",
                      selectedYear.toString()
                    );
                    sessionStorage.setItem("userId", selectedUser.toString());
                  }}
                >
                  業績目標を編集
                </Link>
              )}
            </div>
          </div>
          <div className="l-board__wrap p-board__main">
            <div className="p-board__main--box">
              <table className="p-table">
                <thead>
                  <tr>
                    <th></th>
                    {performanceItems &&
                      performanceItems.map((item, index) => (
                        <th key={index}>{item.name}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {monthList.map((month, index) => (
                    <tr key={index}>
                      <td style={{ whiteSpace: "nowrap" }}>{month}月</td>
                      {performanceItems &&
                        performanceItems.map((item, i) => {
                          const companyGoalForMonthAndItem =
                            targetGoalsData.find(
                              (goal) =>
                                goal.target_month.toString() ===
                                  month.toString() &&
                                goal.performance_item_id.toString() ===
                                  item.id.toString()
                            );
                          const suffix = getSuffixForItemName(item.name); // 追加
                          return (
                            <td key={i} className="u-tAlign--right">
                              {companyGoalForMonthAndItem
                                ? numberWithCommas(
                                    companyGoalForMonthAndItem.goal
                                  ) + suffix
                                : "-"}
                            </td>
                          );
                        })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PerformanceGoal;
