import { formatDateToMonthDay } from './formatUtils';

export const generateErrorMessages = (attendanceData) => {
    const errors = [];

    if (attendanceData && Array.isArray(attendanceData)) {
        attendanceData.forEach(data => {
            const formattedDate = formatDateToMonthDay(data.workOn);

            // 既存のエラーチェック
            if (data.startAt === null && (data.finishAt || data.firstRestStartAt || data.firstRestFinishAt || data.secondRestStartAt || data.secondRestFinishAt)) {
                errors.push(`${formattedDate}の出勤データがありません。`);
            }

            if (data.startAt !== null && data.finishAt === null) {
                errors.push(`${formattedDate}の退勤データがありません。`);
            }

        });
    }
    // 文字列の配列を1つの文字列に連結する
    return errors.join('<br>');
};
export const checkAttendanceErrors = (attendanceData) => {
    let errorMessages = [];

    if (attendanceData && Array.isArray(attendanceData)) {
        attendanceData.forEach((work) => {
            const formattedDate = formatDateToMonthDay(work.workOn);

            // 出勤データが不足している場合のチェック
            if (work.startAt === null && (work.finishAt || work.firstRestStartAt || work.firstRestFinishAt || work.secondRestStartAt || work.secondRestFinishAt)) {
                errorMessages.push(`${formattedDate}の出勤データがありません。`);
            } else if (work.startAt !== null && work.finishAt === null) {
                errorMessages.push(`${formattedDate}の退勤データがありません。`);
            }

            // 休憩時間の誤設定をチェック
            if (work.startAt && work.finishAt) {
                if (work.firstRestStartAt < work.startAt || work.firstRestFinishAt < work.startAt || work.secondRestStartAt < work.startAt || work.secondRestFinishAt < work.startAt) {
                    errorMessages.push(`${formattedDate}に始業前に休憩時間が設定されています。`);
                }
                if (work.firstRestStartAt > work.finishAt || work.firstRestFinishAt > work.finishAt || work.secondRestStartAt > work.finishAt || work.secondRestFinishAt > work.finishAt) {
                    errorMessages.push(`${formattedDate}に終業後に休憩時間が設定されています。`);
                }
            }
        });
    }

    return errorMessages;
};
