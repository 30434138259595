import axios from "axios";

const BASE_URL = "/api/users/evaluation-result/user";

export const editEvaluationResult = async (
    userId: string,
    yearMonth: string,
    sendData,
): Promise<void> => {
    await axios.put(`${BASE_URL}/${userId}/period/${yearMonth}`, sendData);
};
