import * as api from "./../Api/ExcelExportAPI";
import { toast } from "react-toastify";

// 勤怠
export const useExportAttendanceMutate = async (
  userId: string,
  yearMonth: string,
  attendanceData: any,
  users: any[],
  setIsDownloading: (isDownloading: boolean) => void
) => {
  const selectedUser = users.find((user) => user.id == userId);
  if (!selectedUser) {
    toast.error("ユーザーが見つかりません。");
    return;
  }
  setIsDownloading(true);
  try {
    const response = await api.exportAttendance(userId, yearMonth, attendanceData);

    // APIが403エラーを返した場合は権限エラーとして処理
    if (response.status === 403) {
      const errorData = await response.json();
      toast.error(
        errorData.message || "権限が付与されていないため実行できません。"
      );
      setIsDownloading(false);
      return;
    }

    // 成功した場合はExcelファイルをダウンロード
    const blob = await response.blob();
    const { last_name, first_name } = selectedUser;
    const year = yearMonth.substr(2, 2);
    const month = yearMonth.substr(5, 2);
    const desiredFilename = `${year}${month}_kintai_${last_name}${first_name}.xlsx`;

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = desiredFilename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    // エラーメッセージが既に定義されている場合はそれを表示
    const errorMessage = error.message || "エクセルのダウンロード中にエラーが発生しました。";
    toast.error(errorMessage);
    console.error("Error downloading the attendance excel:", error);
  } finally {
    setIsDownloading(false);
  }
};

// 人事考課
export const useExportPerformanceReviewMutate = () => {
  const exportPerformanceReview = async (
    selectedUserId,
    selectedPeriodStart,
    selectedUserName
  ) => {
    if (!selectedUserId) {
      toast.error("スタッフIDの取得に失敗しました。");
      return;
    }
    if (!selectedPeriodStart) {
      toast.error("評価期間の取得に失敗しました。");
      return;
    }
    if (!selectedUserName) {
      toast.error("スタッフ名の取得に失敗しました。");
      return;
    }
    try {
      const response = await api.exportPerformanceReview(
        selectedUserId,
        selectedPeriodStart
      );
      // 日付を '202401' の形式から '2401' の形式に変換
      const formattedDate = selectedPeriodStart.slice(2);
      // ダウンロードするファイルの名前を生成
      const filename = `${formattedDate}_jinji_${selectedUserName.replace(
        /\s+/g,
        "_"
      )}.xlsx`;
      // Blobを作成し、ダウンロードリンクに設定
      const url = window.URL.createObjectURL(
        new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); // 動的なファイル名
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error using export performance review:", error);
      toast.error("人事考課表のエクスポートに失敗しました。");
    }
  };
  return exportPerformanceReview;
};
