import { CalculationRule } from "../Types/CalculationRule"
import {
    fetchCalculationRules,
    getCalculationRuleDetail,
    updateCalculationRulesApi
} from "../Api/CalculationRuleAPI";
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from "react-toastify";

// 労働時間情報の一覧取得
export const useCalculationRules = () => {
    return useQuery<CalculationRule[], Error>('calculationRules', fetchCalculationRules);
};

// 労働時間情報の詳細取得
export const useCalculationRuleDetail = () => {
    return useQuery<CalculationRule[], Error>('calculationRuleDetail', getCalculationRuleDetail);
};

// 労働時間情報の更新
export const useUpdateCalculationRules = () => {
    const queryClient = useQueryClient();

    const updateAllRules = async (currentData: CalculationRule[], initialData: CalculationRule[]) => {
        const isChanged = currentData.some((currentRule, index) => {
            const initialRule = initialData[index];
            return currentRule.period !== initialRule.period || currentRule.round !== initialRule.round;
        });

        if (!isChanged) {
            toast.error('変更されておりません。');
            return;
        }

        try {
            // APIを呼び出してデータを更新
            await updateCalculationRulesApi(currentData);
            queryClient.resetQueries('calculationRules');
            toast.success("労働時間ルールが更新されました。");
        } catch (error) {
            toast.error('更新処理中にエラーが発生しました。');
        }
    };

    return { updateAllRules };
};
