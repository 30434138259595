import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useDeleteUser,
  useUpdateUser,
  useUpdateUserPassword,
  useUserDetail,
  useUpdateKintaiAccountQuery,
} from "../../../Queries/UserQuery";
import StaffFormModal from "../../../Components/Modal/StaffFormModal";
import ConfirmDeleteModal from "../../../Components/Modal/ConfirmDeleteModal";
import PasswordEditModal from "../../../Components/Modal/PasswordEditModal";
import LoaderComponent from "../../../Components/Layout/Loader";
import { formatDate } from "../../../Utils/formatUtils";

import { useRankList } from "../../../Queries/RankQuery";
import { useShopsForSelectQuery } from "../../../Queries/ShopQuery";
import { useOccupationList } from "../../../Queries/OccupationQuery";
import { useEmploymentStatusList } from "../../../Queries/EmploymentStatusQuery";
import { useAuthority } from "../../../Contexts/AuthorityContext";

const getAuthority = (is_authority) => {
  switch (is_authority) {
    case "0":
      return "管理者";
    case "1":
      return "教育担当者";
    case "2":
      return "一般";
    case "9":
      return "勤怠用アカウント";
    default:
      return "不明な権限";
  }
};

const StaffDetail = (): JSX.Element => {
  const { authority } = useAuthority();
  const { userId } = useParams<{ userId: string }>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // 状態の名前を変更
  const { data: user, isLoading } = useUserDetail(Number(userId));
  const [activeTab, setActiveTab] = useState(1);

  const shops = useShopsForSelectQuery();
  const employmentStatuses = useEmploymentStatusList();
  const occupations = useOccupationList();
  const ranks = useRankList();

  const deleteUser = useDeleteUser();
  const updateUserPassword = useUpdateUserPassword();

  const updateUser = useUpdateUser();
  const updateKintaiAccount = useUpdateKintaiAccountQuery();

  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isPasswordModal, setIsPasswordModal] = useState(false);
  const [shouldDisplayDeleteButton, setShouldDisplayDeleteButton] =
    useState(false);
  const isKintaiAccount = user?.isAuthority == "9";

  const handleTabClick = (tabNum) => setActiveTab(tabNum);
  const closeDeleteModal = () => setIsDeleteModal(false);

  const handleEdit = (user) => {
    const onSuccess = () => {
      handleEditModalClose();
    };

    if (user.isKintaiAccount) {
      updateKintaiAccount.mutate(
        { userId: user.id, email: user.email },
        { onSuccess }
      );
    } else {
      updateUser.mutate(user, { onSuccess });
    }
  };

  useEffect(() => {
    if (user) {
      setShouldDisplayDeleteButton(user.isCharge !== "0");
    }
  }, [user]);

  // 削除モーダル
  const handleDeleteModalOpen = () => setIsDeleteModal(true);
  const handleDeleteModalClose = () => setIsDeleteModal(false);

  // 編集モーダル
  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);

  // パスワード
  const handlePasswordModalOpen = () => setIsPasswordModal(true);
  const handlePasswordModalClose = () => setIsPasswordModal(false);

  if (
    isLoading ||
    shops.isLoading ||
    employmentStatuses.isLoading ||
    occupations.isLoading ||
    ranks.isLoading
  ) {
    return <LoaderComponent />;
  }

  return (
    <div className="l-board">
      {authority == "0" && (
        <>
          <StaffFormModal
            isOpen={isEditModalOpen}
            handleClose={() => setIsEditModalOpen(false)}
            shopList={shops.data}
            employmentStatusList={employmentStatuses.data}
            occupationList={occupations.data}
            rankList={ranks.data}
            onAction={handleEdit}
            editData={user} // 編集データを渡す
          />
          <ConfirmDeleteModal
            isOpen={isDeleteModal}
            handleClose={handleDeleteModalClose}
            selectedId={userId}
            deleteMutation={deleteUser}
          />
          <PasswordEditModal
            isOpen={isPasswordModal}
            selectedId={userId}
            handleClose={handlePasswordModalClose}
            updateMutation={updateUserPassword}
          />
        </>
      )}
      <div className="l-board__inner">
        {/* <ul className="p-board__tab">
                    <li
                        id="tab1"
                        className={`p-tab__btn ${activeTab === 1 ? 'is_active' : ''}`}
                        onClick={() => handleTabClick(1)}
                    >
                        詳細
                    </li>
                    <li
                        id="tab2"
                        className={`p-tab__btn ${activeTab === 2 ? 'is_active' : ''}`}
                        onClick={() => handleTabClick(2)}
                    >
                        勤怠情報
                    </li>
                </ul> */}
        {authority == "0" && (
          <div className="l-board__wrap p-board__btn no-border">
            {/* <div className="l-board__btn--box"></div>
                    <div className="l-board__btn--box">
                        <Link
                            className="c-btn c-btn__red c-btn__table"
                            onClick={() => setIsDeleteModal(true)}
                        >
                            削除
                        </Link>
                    </div> */}

            <div className="l-board__btn--box">
              <button
                className="c-btn c-btn__blue c-btn__table"
                onClick={handleEditModalOpen}
              >
                編集
              </button>
            </div>
            {/* <div className="l-board__btn--box">
                            <button
                                className="c-btn c-btn__red c-btn__table"
                                onClick={handleDeleteModalOpen}
                            >削除</button>
                        </div> */}
            {shouldDisplayDeleteButton && (
              <div className="l-board__btn--box">
                <button
                  className="c-btn c-btn__red c-btn__table"
                  onClick={handleDeleteModalOpen}
                >
                  削除
                </button>
              </div>
            )}
          </div>
        )}
        <div className="l-board__wrap p-board__main">
          <div
            id="panel1"
            className={`p-tab__panel ${activeTab === 1 ? "is_active" : ""}`}
          >
            <table className="p-table p-table__detail">
              <tbody>
                {!isKintaiAccount && (
                  <tr>
                    <td className="headline">コード</td>
                    <td>{user.code}</td>
                  </tr>
                )}
                <tr>
                  <td className="headline">お名前</td>
                  <td>
                    <span className="u-tSub">
                      {user.lastNameKana} {user.firstNameKana}
                    </span>
                    <br />
                    {user.lastName} {user.firstName}
                  </td>
                </tr>
                <tr>
                  <td className="headline">メールアドレス</td>
                  <td>{user.email}</td>
                </tr>
                {authority == "0" && (
                  <tr>
                    <td className="headline">パスワード</td>
                    <td>
                      <button
                        className="c-btn c-btn__blue c-btn__table"
                        onClick={handlePasswordModalOpen}
                      >
                        編集
                      </button>
                    </td>
                  </tr>
                )}
                {!isKintaiAccount && (
                  <>
                    <tr>
                      <td className="headline">所属店舗</td>
                      <td>
                        {user.shopId ? (
                          user.shop.name
                        ) : (
                          <span className="u-tSmall">未設定</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="headline">権限</td>
                      <td>{getAuthority(user.isAuthority)}</td>
                    </tr>
                    <tr>
                      <td className="headline">雇用形態</td>
                      <td>
                        {user.employmentStatusId ? (
                          user.employmentStatus.name
                        ) : (
                          <span className="u-tSmall">未設定</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="headline">職種</td>
                      <td>
                        {user.occupationId ? (
                          user.occupation.name
                        ) : (
                          <span className="u-tSub">未設定</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="headline">職位</td>
                      <td>
                        {user.rankId ? (
                          user.rank.name
                        ) : (
                          <span className="u-tSmall">未設定</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="headline">入社日</td>
                      <td>
                        {user.joinedOn ? (
                          formatDate(user.joinedOn)
                        ) : (
                          <span className="u-tSmall">未設定</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="headline">退社日</td>
                      <td>
                        {user.retirementOn ? formatDate(user.retirementOn) : ""}
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffDetail;
