// EvaluationResultQuery.ts
import * as EvaluationResultAPI from "../Api/EvaluationResultAPI";
import { toast } from "react-toastify";

export const getResultsForUser = async (userId: string, yearMonth: string): Promise<any> => {
    try {
        const data = await EvaluationResultAPI.fetchEvaluationResults(userId, yearMonth);
        return data;
    } catch (error) {
        toast.error('評価実績の所得に失敗しました。');
        return null;
    }
}

// Add a new function to use the fetchComparisonResults function
export const getComparisonResultsForUser = async (userId: string, beforeYearMonth: string, afterYearMonth: string): Promise<any> => {
    try {
        const data = await EvaluationResultAPI.fetchComparisonResults(userId, beforeYearMonth, afterYearMonth);
        return data;
    } catch (error) {
        toast.error('評価比較データの取得に失敗しました。');
        return null;
    }
};
