// UserPerformanceRoutes.jsx
import React from 'react';
import PerformanceBoard from '../Pages/User/Performance/PerformanceBoard.tsx';
import PerformanceStaffEdit from '../Pages/User/Performance/PerformanceStaffEdit.tsx';
import PerformanceShopEdit from '../Pages/User/Performance/PerformanceShopEdit.tsx';
import PerformanceUserGoalEdit from '../Pages/User/PerformanceGoal/PerformanceUserGoalEdit.tsx';
import PerformanceCompanyGoalEdit from '../Pages/User/PerformanceGoal/PerformanceCompanyGoalEdit.tsx';

export const performanceRoutes = [
    {
        path: '/hc-user/performance/staff',
        element: <PerformanceBoard />
    },
    {
        path: '/hc-user/performance/staff/edit/:userId/:year',
        element: <PerformanceStaffEdit />
    },
    {
        path: '/hc-user/performance/shop',
        element: <PerformanceBoard />
    },
    {
        path: '/hc-user/performance/shop/edit/:shopId/:year',
        element: <PerformanceShopEdit />
    },
    {
        path: '/hc-user/performance/goal',
        element: <PerformanceBoard />
    },
    // {
    //     path: '/hc-user/performance/goal/:occupationLabel',
    //     element: <PerformanceBoard />
    // },
    {
        path: '/hc-user/performance/goal/edit/user/:userId/:year',
        element: <PerformanceUserGoalEdit />
    },
    {
        path: '/hc-user/performance/goal/edit/company/:occupationId/:year',
        element: <PerformanceCompanyGoalEdit />
    }
];
