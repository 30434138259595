import axios from "axios";

// 勤怠
export const exportAttendance = async (userId, yearMonth, attendanceData) => {
  try {
    const response = await fetch(`/api/users/export/attendance`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId,
        yearMonth,
        attendanceData,
      }),
    });

    if (!response.ok) {
      const errorResponse = await response.json(); // エラーレスポンスの内容を取得
      console.error("Error response from API:", errorResponse); // エラーレスポンスをログに出力
      throw new Error(errorResponse.message || "APIからの応答が正常ではありません。");
    }
    return response;
  } catch (error) {
    console.error("Error in exportAttendance:", error.message); // エラーメッセージのログを追加
    throw error; // エラーを再スローする
  }
};


// 人事考課
export const exportPerformanceReview = async (
  selectedUserId: number,
  selectedPeriodStart: string
) => {
  try {
    const response = await axios({
      url: `/api/users/export/performance-review`,
      method: "POST",
      data: {
        selectedUserId,
        selectedPeriodStart,
      },
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error("Error exporting performance review:", error);
    throw error;
  }
};
